import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { Col, Collapse, CollapseProps, Row, Space, Spin } from 'antd';

import { DataQueryPageContext, DataQueryPageContextType } from '../../../../../context/data-query-page-context';

import ErrorBoundary from '../../../../../components/ErrorBoundary';

import GenericDataGraph, { GenericDataGraphParams } from './GenericDataGraph';
import GenericDataTable, { GenericDataTableParams } from './GenericDataTable';

import { renderEntity } from '../../../../../utils/ReactUtils';

import classes from '../../../DataQueryPage.module.css';
import { DataQueryConfig, DataQueryFilter, DataQueryProductRow } from '../../../../types';
import { GetUrlForQuery } from '../hooks/use-data-requester';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericOutputContainerParams = (Omit<GenericDataGraphParams, 'dataPoints'> & GenericDataTableParams) & { [key: string]: any } & {
  config?: DataQueryConfig;
  dataLoading?: boolean;
  dataError?: string | object;
  dataPoints?: Record<string, unknown> | null;
  tableColumns?: JSX.Element[];
  data?: DataQueryProductRow[];
  getUrlForGraphQuery?: GetUrlForQuery;
  getUrlForTableQuery?: GetUrlForQuery;
  product?: string;
  onDownload?: (selectedItem: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gridControl?: React.JSXElementConstructor<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphControl?: React.JSXElementConstructor<any>;
  onCursorBack?: () => void;
  onCursorNext?: () => void;
  onPageSizeChanged?: (previous: number, next: number) => void;
  onChangedFilters?: (filters: DataQueryFilter) => void;
  graphData?: DataQueryProductRow[];
  graphDataLoading?: boolean;
  xAxisColumn?: string;
  xAxisColumnType?: string;
  xAxisColumnDisplayType?: string;
  xAxisLabelColumn?: string;
};

const getPanelsExpanded = ({ metaData }: DataQueryPageContextType) => Boolean(metaData?.accordionActiveKeys?.length);

const loadingComponent = (
  <Space>
    <Spin size="small" className={classes.smallSpinner} />
    <div>Loading...</div>
  </Space>
);

const DEFAULT_PROPS = {
  config: {
    products: [],
    feed: undefined,
    currentPage: undefined,
  },
  dataPoints: {},
  tableColumns: [],
  data: [],
  getUrlForGraphQuery: () => undefined,
  getUrlForTableQuery: () => undefined,
  onDownload: () => {},
  onCursorBack: () => {},
  onCursorNext: () => {},
  onPageSizeChanged: () => {},
  onChangedFilters: () => {},
};

export default function GenericOutputContainer({
  config = DEFAULT_PROPS.config,
  dataLoading = false,
  dataError = undefined,
  dataPoints = DEFAULT_PROPS.dataPoints,
  tableColumns = DEFAULT_PROPS.tableColumns,
  data = DEFAULT_PROPS.data,
  getUrlForGraphQuery = DEFAULT_PROPS.getUrlForGraphQuery,
  getUrlForTableQuery = DEFAULT_PROPS.getUrlForTableQuery,
  product = '',
  onDownload = DEFAULT_PROPS.onDownload,
  gridControl = undefined,
  graphControl = undefined,
  onCursorBack = DEFAULT_PROPS.onCursorBack,
  onCursorNext = DEFAULT_PROPS.onCursorNext,
  onPageSizeChanged = DEFAULT_PROPS.onPageSizeChanged,
  onChangedFilters = DEFAULT_PROPS.onChangedFilters,
  graphData = undefined,
  graphDataLoading = false,
  xAxisColumn = undefined,
  xAxisColumnType = undefined,
  xAxisColumnDisplayType = undefined,
  xAxisLabelColumn = undefined,
  ...rest
}: GenericOutputContainerParams) {
  const context = useContext(DataQueryPageContext);
  const panelsExpanded = useRef<boolean | undefined>(undefined);
  const availablePanels = ['vizPanel', 'resultPanel'];

  const props = {
    config,
    dataLoading,
    dataError,
    dataPoints,
    tableColumns,
    data,
    getUrlForGraphQuery,
    getUrlForTableQuery,
    product,
    onDownload,
    ...rest,
  };

  useEffect(() => {
    panelsExpanded.current = getPanelsExpanded(context);
  }, [context.configItemId]);

  const productServerInfo = data.find(({ product: candidate }) => candidate === product);

  const accordionActiveKeys = (context?.metaData?.accordionActiveKeys || availablePanels).filter((panel) =>
    availablePanels.includes(panel)
  );

  const handleAccordionActiveKey = useCallback(
    (keys: string | string[]) => context.updateMetaConfig({ accordionActiveKeys: keys as string[] }),
    [context]
  );

  useEffect(() => {
    if (!dataLoading && data.filter(({ error }) => !error).length && !panelsExpanded.current) {
      panelsExpanded.current = true;
      context.updateMetaConfig({ accordionActiveKeys: availablePanels });
    }
  }, [dataLoading]);

  const loadingExtra = dataLoading && loadingComponent;

  const vizLoading = graphDataLoading && loadingComponent;

  const collapseItems: CollapseProps['items'] = [];
  if (dataPoints !== null) {
    collapseItems.push({
      key: 'vizPanel',
      label: 'Visualization',
      role: 'presentation',
      collapsible: undefined,
      extra: !accordionActiveKeys.includes('vizPanel') && vizLoading,
      children: (
        <ErrorBoundary componentName="Visualization">
          {renderEntity(GenericDataGraph, graphControl, {
            ...props,
            data: graphData ?? props.data,
            getUrlForQuery: getUrlForGraphQuery,
            productServerInfo,
            xAxisColumn,
            xAxisColumnType,
            xAxisColumnDisplayType,
            xAxisLabelColumn,
          })}
        </ErrorBoundary>
      ),
    });
  }
  collapseItems.push({
    key: 'resultPanel',
    label: 'Result Set (Table)',
    role: 'presentation',
    extra: !accordionActiveKeys.includes('resultPanel') && loadingExtra,
    children: (
      <ErrorBoundary componentName="Result Set (Table)">
        {renderEntity(GenericDataTable, gridControl, {
          ...props,
          getUrlForQuery: getUrlForTableQuery,
          productServerInfo,
          currentPage: config.currentPage,
          onCursorBack,
          onCursorNext,
          onPageSizeChanged,
          onChangedFilters,
        })}
      </ErrorBoundary>
    ),
  });

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Collapse activeKey={accordionActiveKeys} onChange={handleAccordionActiveKey} items={collapseItems} />
      </Col>
    </Row>
  );
}
