import { checkAxiosResponse, handleAxiosError } from '@mst-fe/shared/dist/errors/axios-errors';
import { LinkedError } from '@mst-fe/shared/dist/errors/linked-error';
import axios, { AxiosResponse } from 'axios';
import { axiosOptions, GenericAPIResponse } from '../../services/requests';
import { ConfigSchemaFormModel } from '../../_shared/models/config-schema-form-model';
import { EditJobModel } from './models/job-edit';

export interface Cluster {
  id: string;
  name: string;
}

export interface JobListItem {
  id: string;
  name: string;
  crontab: string;
  description: string;
  clusterId: string;
  clusterName: string;
  enabled: boolean;
  wbShortId: string | undefined;
  wbName: string | undefined;
  installedModuleId: string | undefined;
}

export interface JobExecution {
  id: string;
  jobName: string;
  runStatus: 'PREPARING' | 'RUNNING' | 'SUCCESS' | 'FAILED';
  startedAt: string;
  completedAt: string;
}

export class JobsApi {
  async handleApiResponse<T>(response: Promise<AxiosResponse<T>>): Promise<GenericAPIResponse | undefined> {
    const responseOrError = await handleAxiosError<T>(response);

    if (responseOrError instanceof LinkedError) {
      if (responseOrError.cause.responseStatus === 400) {
        const { responseData } = responseOrError.cause;
        const responseDataArray = Array.isArray(responseData) ? responseData : [responseData];
        const errors = (responseDataArray as ({ message?: string } | undefined)[]).map((e) => e?.message ?? '');

        return { ok: false, error: errors.join('\n') } ?? { ok: false, error: '' };
      }

      return undefined;
    }

    return { ok: true };
  }

  async list() {
    return await checkAxiosResponse(axios.get<JobListItem[]>('/api/dask-jobs/shared/jobs/', await axiosOptions()));
  }

  async jobExecutions(jobId: string): Promise<JobExecution[] | undefined> {
    return await checkAxiosResponse(
      axios.get<JobExecution[]>(`/api/dask-jobs/my-executions?jobId=${encodeURIComponent(jobId)}`, await axiosOptions())
    );
  }

  async currentExecutions(): Promise<JobExecution[] | undefined> {
    return await checkAxiosResponse(axios.get<JobExecution[]>(`/api/dask-jobs/my-executions?status=executing`, await axiosOptions()));
  }

  async logs(executionId: string): Promise<{ logs: string } | undefined> {
    return await checkAxiosResponse(axios.get<{ logs: string }>(`/api/dask-jobs/my-executions/${executionId}/`, await axiosOptions()));
  }

  async clusters(wbShortId: string): Promise<Cluster[] | undefined> {
    return await checkAxiosResponse(axios.get<Cluster[]>(`/api/dask/${wbShortId}/clusters`, await axiosOptions()));
  }

  async updateJobConfig(model: EditJobModel): Promise<GenericAPIResponse | undefined> {
    const { job, config } = model;
    return await this.handleApiResponse<GenericAPIResponse>(
      axios.post<GenericAPIResponse>(
        `/api/dask-jobs/${job.wbShortId ?? 'shared'}/jobs/${job.id}/config`,
        {
          config,
        },
        await axiosOptions()
      )
    );
  }

  async updateJobEnabled(job: JobListItem, enabled: boolean): Promise<GenericAPIResponse | undefined> {
    return await this.handleApiResponse<GenericAPIResponse>(
      axios.post<GenericAPIResponse>(
        `/api/dask-jobs/${job.wbShortId ?? 'shared'}/jobs/${job.id}/enabled`,
        {
          enabled: !!enabled,
        },
        await axiosOptions()
      )
    );
  }

  async deleteJob(job: JobListItem): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.delete<{ ok: boolean }>(`/api/dask-jobs/${job.wbShortId ?? 'shared'}/jobs/${job.id}`, await axiosOptions())
    );
  }

  async getJobConfig(job: JobListItem) {
    return await checkAxiosResponse(
      axios.get<{ ok: boolean; config: ConfigSchemaFormModel['config']; configSchema: ConfigSchemaFormModel['configSchema'] }>(
        `/api/zoe/app-store/config/${job.id}`,
        await axiosOptions()
      )
    );
  }

  async triggerJob(jobId: string, data: unknown): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(axios.post<{ ok: boolean }>(`/api/dask-jobs/trigger`, { jobId, data }, await axiosOptions()));
  }

  async interruptExecution(executionId: string): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(axios.post<{ ok: boolean }>(`/api/dask-jobs/interrupt/${executionId}`, {}, await axiosOptions()));
  }
}
