import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Button, MenuProps } from 'antd';
import { CloseCircleOutlined, DesktopOutlined } from '@ant-design/icons';

import { WindowIcon } from '@heroicons/react/24/outline';

import { INSTANCE_ITEM_KEY_PREFIX, INSTANCES_ITEM_KEY } from '../../../side-nav/SideNav';

import { InstanceListModel } from '../models/instance-list';
import { WbInstance } from '../models/instance';

import { InstanceListDelegate } from './InstanceList';

import sharedStyles from '../../../side-nav/SideNav.shared.module.css';

function InstancesNav({ caption }: { caption: string }) {
  return (
    <div data-testid="side-nav-applications">
      <div className="sidebar-nav-item-title-icon-container">
        <Link to={'/instances/'}>{caption}</Link>
      </div>
    </div>
  );
}

function InstancesNavItem({ model, delegate }: { model: WbInstance; delegate: InstanceListDelegate }) {
  const path = `/instances/${model.id}`;

  const onCloseItem = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      evt.preventDefault();

      delegate.onSetInstanceIsOpen(model.id, false);
    },
    [delegate, model.id]
  );

  return (
    <div>
      <div className={sharedStyles.navLabel}>
        <Link to={path}>{model.name}</Link>
        {/*<div className={`${styles.serviceActivation} sidebar-nav-item-service-status`}>{model.status}</div>*/}
        <Button icon={<CloseCircleOutlined />} type="link" onClick={onCloseItem} />
      </div>
    </div>
  );
}

function getInstancesNavItems(model: InstanceListModel, delegate: InstanceListDelegate, isCollapsed: boolean) {
  const items = model.instances
    .filter(({ isOpenInsideUI }) => isOpenInsideUI)
    .map((service) => ({
      label: <InstancesNavItem model={service} delegate={delegate} />,
      icon: <WindowIcon height={16} width={16} />,
      key: `${INSTANCE_ITEM_KEY_PREFIX}${service.id}`,
      className: sharedStyles.fullHeightNavItem,
    })) as Required<MenuProps>['items'];

  if (isCollapsed) {
    items.splice(
      0,
      0,
      {
        label: <span>Show All Instances</span>,
        icon: <DesktopOutlined />,
        key: `${INSTANCE_ITEM_KEY_PREFIX}_`,
        className: '',
      },
      {
        type: 'divider',
        dashed: true,
      }
    );

    if (items.length === 2) {
      items.push({
        label: (
          <span className={sharedStyles.disabledNavItem}>
            <i>No Open Instances</i>
          </span>
        ),
        disabled: true,
        icon: <WindowIcon height={16} width={16} />,
        key: '',
        className: '',
      });
    }
  }

  return items;
}

export function getInstancesNav({
  caption,
  model,
  delegate,
  isCollapsed,
}: {
  caption: string;
  model: InstanceListModel;
  delegate: InstanceListDelegate;
  isCollapsed: boolean;
}): Required<MenuProps>['items'][number] {
  return {
    label: <InstancesNav caption={caption} />,
    key: INSTANCES_ITEM_KEY,
    icon: <DesktopOutlined />,
    children: getInstancesNavItems(model, delegate, isCollapsed),
    expandIcon: null,
  };
}
