import { ErrorNotification } from '../../../services/error-notification';
import { JobExecution, JobListItem, JobsApi } from '../jobs-api';
import { ModuleInstall } from '../../modules/models/module-install';
import { EditJobModel } from './job-edit';
import { TriggerJobModel } from './trigger-job';
import { LogsDialogModel } from '../../logs-dialog/models/logs-dialog-model';

export interface JobDetail {
  state: 'loading' | 'ready';
  jobId: string;
  executions: JobExecution[];
}

export interface JobExecutionToInterrupt {
  state: 'ready' | 'interrupting';
  execution: JobExecution;
}

export interface JobsList {
  state: 'loading' | 'ready';
  jobs: JobListItem[];
  jobDetail: JobDetail | undefined;
  jobExecutionLogs: LogsDialogModel | undefined;
  install: ModuleInstall | undefined;
  editJob: EditJobModel | undefined;
  triggerJob: TriggerJobModel | undefined;
  jobExecutionToInterrupt: JobExecutionToInterrupt | undefined;
}

export function asJobsListDetaileUpdate(model: () => JobsList, update: (_: JobsList) => void) {
  return (jobDetail: JobDetail | undefined) => update({ ...model(), jobDetail });
}

export function asEditJobUpdate(model: () => JobsList, update: (_: JobsList) => void) {
  return (editJob: EditJobModel | undefined) => update({ ...model(), editJob });
}

export function asTriggerJobUpdate(model: () => JobsList, update: (_: JobsList) => void) {
  return (triggerJob: TriggerJobModel | undefined) => update({ ...model(), triggerJob });
}

export function initialJobsList(): JobsList {
  return {
    state: 'loading',
    jobs: [],
    jobDetail: undefined,
    jobExecutionLogs: undefined,
    install: undefined,
    editJob: undefined,
    triggerJob: undefined,
    jobExecutionToInterrupt: undefined,
  };
}

export function updateJobsListItems(model: JobsList, jobs: JobListItem[]): JobsList {
  return { ...model, state: 'ready', jobs };
}

export async function loadJobDetail(
  api: JobsApi,
  model: JobsList,
  update: (_: JobDetail) => void,
  jobId: string
): Promise<ErrorNotification | undefined> {
  if (model.jobDetail?.jobId === jobId) {
    return;
  }

  update({ state: 'loading', jobId, executions: [] });

  const executions = await api.jobExecutions(jobId);
  if (!executions) {
    return { message: 'Error loading details.' };
  }

  update({ state: 'ready', jobId, executions });
}

export function updateJobExecutions(model: JobsList, update: (_: JobDetail) => void, executions: JobExecution[]) {
  if (model.jobDetail) {
    update({ ...model.jobDetail, executions });
  }
}

export async function loadExecutionLogs(
  api: JobsApi,
  model: () => JobsList,
  update: (_: JobsList) => void,
  executionId: string
): Promise<ErrorNotification | undefined> {
  if (!model().jobDetail) {
    return;
  }

  update({ ...model(), jobExecutionLogs: { state: 'loading', logs: '', id: executionId } });

  const res = await api.logs(executionId);

  const newModel = model();

  if (res === undefined) {
    update({ ...newModel, jobExecutionLogs: undefined });
    return { message: 'Error while loading logs.' };
  }

  if (newModel.jobExecutionLogs?.id === executionId) {
    update({ ...newModel, jobExecutionLogs: { state: 'ready', logs: res.logs, id: executionId } });
  }
}

export function closeExecutionLogs(model: JobsList): JobsList {
  return { ...model, jobExecutionLogs: undefined };
}

export function getDefaultJobId(model: JobsList): string | undefined {
  if (model.state === 'ready' && model.jobs.length > 0) {
    return model.jobs[0].id;
  }

  return undefined;
}

export function setJobExecutionToInterrupt(model: JobsList, execution: JobExecution | undefined): JobsList {
  return { ...model, jobExecutionToInterrupt: execution ? { state: 'ready', execution } : undefined };
}

export async function interruptJobExecution(
  api: JobsApi,
  model: () => JobsList,
  update: (_: JobsList) => void
): Promise<ErrorNotification | undefined> {
  const execution = model().jobExecutionToInterrupt?.execution;

  if (!execution) {
    return;
  }

  update({ ...model(), jobExecutionToInterrupt: { state: 'interrupting', execution } });

  const res = await api.interruptExecution(execution.id);

  const newModel = model();

  if (res === undefined) {
    update({ ...newModel, jobExecutionToInterrupt: { state: 'ready', execution } });
    return { message: 'Error while interrupting execution.' };
  }

  if (newModel.jobExecutionToInterrupt?.execution.id === execution.id) {
    update({ ...newModel, jobExecutionToInterrupt: undefined });
  }
}
