/* eslint-disable react/prop-types */
import React, { useMemo, useState, useCallback, ChangeEvent } from 'react';
import { Select, Input } from 'antd';

import classes from './TimeSpanControl.module.css';
import { DataQueryTimespan } from '../../../../types';

type TimeSpanControlParams = {
  value?: { value: number; timespan: string };
  onChange?: (a: unknown) => void;
  timespans: DataQueryTimespan[];
  disabled: boolean;
  id?: string;
};

const DEFAULT_PROPS = {
  onChange: () => {},
};

export default function TimeSpanControl({ value, onChange = DEFAULT_PROPS.onChange, timespans, disabled, id = '' }: TimeSpanControlParams) {
  const selectedTimespan = useMemo(() => value?.timespan || timespans[0]?.ms, [value, timespans]);

  const selectedValue = useMemo(() => value?.value, [value]);

  const handleValueChange = useCallback(
    ({ target: { value: e } }: ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        value: e,
      });
    },
    [value]
  );

  const handleTimespanChange = useCallback(
    (e: string) => {
      const max = timespans.find(({ ms }) => ms === e)?.max;

      onChange({
        ...value,
        timespan: e,
        value: Math.min(max ?? 0, value?.value ?? 0),
      });
    },
    [value]
  );

  const options = timespans.map((timespan) => ({
    key: timespan.ms,
    label: timespan.label,
    value: timespan.ms,
  }));

  const maximum = useMemo(() => timespans.find(({ ms }) => ms === selectedTimespan)?.max, [selectedTimespan]);

  const selectAfter = (
    <Select
      id="intervalUnit"
      aria-label="Interval unit"
      disabled={disabled}
      options={options}
      value={selectedTimespan}
      onChange={handleTimespanChange}
    />
  );

  return (
    <Input
      className={classes.standardInput}
      disabled={disabled}
      max={maximum}
      min={0}
      addonAfter={selectAfter}
      type="number"
      data-testid="data-query-time-span"
      value={selectedValue}
      id={id}
      onChange={handleValueChange}
    />
  );
}
