import React from 'react';
import AntdColumn, { ColumnProps } from 'antd/lib/table/Column';

import { Input, Space, Button, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import classes from './WithFilter.module.css';
import { DataQueryFilter } from '../../../../types';

type DataQueryFilterDetails = {
  id: string;
  type: string;
  data: { value: string; label: string }[];
  label: string;
  required: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FilterEnhancedColumnParams = ColumnProps<any> & {
  extraFilters?: DataQueryFilterDetails[];
  dataIndex: number;
  hasCustomFilter?: boolean;
};

const { Option } = Select;

const composeExtraFilter = (
  details: DataQueryFilterDetails,
  selections: DataQueryFilter,
  setSelectedFilters: (filters: DataQueryFilter[]) => void
) => {
  const { id, type, data } = details;

  switch (type) {
    case 'select':
      return (
        <Select
          className={classes.extraFilters}
          placeholder={`Select ${details.label}`}
          value={selections[id]}
          onChange={(newValue) => setSelectedFilters([{ ...selections, [id]: newValue }])}
        >
          {data.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      );

    default:
      console.error('[withFilter] Unsupported filter. Please implement first...');
      return null;
  }
};

const DEFAULT_PROPS = {
  extraFilters: [],
};

// eslint-disable-next-line default-param-last
const withFilter = (
  Column: typeof AntdColumn,
  activeFilters: DataQueryFilter = {},
  onAddFilter: (selection: DataQueryFilter, dataKey: number) => void,
  onClearFilter: (dataKey: string) => void
) => {
  function EnhancedColumn({
    extraFilters = DEFAULT_PROPS.extraFilters,
    hasCustomFilter = false,
    dataIndex,
    ...rest
  }: FilterEnhancedColumnParams) {
    const props = { extraFilters, hasCustomFilter, dataIndex, ...rest };

    const handleSearch = (selections: DataQueryFilter[], confirm: () => void, dataKey: number) => {
      onAddFilter(selections[0], dataKey);
      confirm();
    };

    const handleClear = (dataKey: string, clearFilters: () => void) => {
      onClearFilter(dataKey);
      clearFilters();
    };

    // eslint-disable-next-line no-unused-vars
    const filterDropdown = ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: (value: DataQueryFilter[]) => void;
      selectedKeys: DataQueryFilter[];
      confirm: () => void;
      clearFilters: () => void;
    }) => {
      const selections = selectedKeys[0] ?? {};

      return (
        <div className={classes.filterDropdown}>
          {extraFilters.map((filter) => (
            <div key={filter.label}>
              {filter.label}: {composeExtraFilter(filter, selections, setSelectedKeys)}
            </div>
          ))}
          <Input
            className={classes.searchInput}
            placeholder="Search"
            value={selections.value}
            onChange={(e) => setSelectedKeys([{ ...selections, value: e.target.value }])}
            onPressEnter={confirm}
          />
          <Space>
            <Button
              className={classes.smallButton}
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button className={classes.smallButton} onClick={() => handleClear(dataIndex.toString(), clearFilters)} size="small">
              Clear
            </Button>
          </Space>
        </div>
      );
    };

    const equalsOrStartsWith = (record: string, value: string) => {
      if (!Number.isNaN(Number(record)) && !Number.isNaN(Number(value))) {
        return Number(record) === Number(value);
      }

      return record.toLowerCase().startsWith(value.toLowerCase());
    };

    const onFilter = ({ value, ...rest }: DataQueryFilter, record: string[]) => {
      const recordToCheck = (record[dataIndex] || '').toString().toLowerCase();
      const hasCustomValue = recordToCheck.includes(':');

      if (!hasCustomValue) {
        return equalsOrStartsWith(recordToCheck, value);
      }

      let customPasses = true;
      const [prefix, recordValue] = recordToCheck.split(': ');

      for (const [customFilter, customValue] of Object.entries(rest)) {
        switch (customFilter) {
          case 'pre':
            customPasses = equalsOrStartsWith(prefix, customValue);
            break;
          default:
            console.log('[withFilter] Unimplemented filter method');
            break;
        }
      }

      return customPasses && equalsOrStartsWith(recordValue, value);
    };

    // Note: if 'activeFilters' is defined we want to take over managing the filters state.
    // So we don't want 'filteredValue' to be null/undefined because that will make antd manage active filters.
    const activeFilterValue = activeFilters ? (activeFilters[dataIndex] && [activeFilters[dataIndex]]) || [] : null;

    // hasCustomFilter: disables antd's client-side filtering (with onFilter callback)
    return (
      <Column
        {...props}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(!hasCustomFilter && { onFilter: onFilter as any })}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filterDropdown={filterDropdown as any}
        filteredValue={activeFilterValue}
      />
    );
  }

  return EnhancedColumn;
};

export default withFilter;
