/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';

import DomToImage from 'dom-to-image';
import sanitizeFilename from 'sanitize-filename';

import { Button, Dropdown, Spin } from 'antd';
import { CheckOutlined, DashOutlined, DownOutlined, DownloadOutlined } from '@ant-design/icons';

import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

import flatten from 'lodash/flatten';
import startCase from 'lodash/startCase';

import { createNumeralFormatter } from '../../../../../../_shared/graphComponents/graph-label';
import GraphTabledTooltipContent from '../../../../../../_shared/graphComponents/GraphTabledTooltipContent';

import { SettingsConfigContext } from '../../../../../../context/settings-config-context';
import ErrorComponent from '../../../../../../components/Error';

import classes from './DataGraph.module.css';
import * as styles from './data-graph.styles';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { DataQueryGraphDataPoint } from '../../../../../types';
import { getColorByKey } from '../../../../../../_shared/antColors/ant-colors';

export type xAxisColumnDisplayTypeEnum = 'varchar' | 'double' | 'number' | 'time' | 'date' | 'datetime' | 'dateticks';

type DataGraphParams = {
  products?: string[];
  dataPoints?: Record<string, unknown>;
  data?: DataQueryGraphDataPoint[];
  dataLoading?: boolean;
  shouldShowTime?: boolean;
  ignoreProductName?: boolean;
  onConfigure?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  tooltipLabelColumn?: string;
  xAxisColumn?: string;
  xAxisColumnType?: 'number' | 'category';
  xAxisColumnDisplayType?: xAxisColumnDisplayTypeEnum;
  xAxisLabelColumn?: string;
  columnNameModifier?: (name: string) => string;
  dataError?: unknown;
};

const decimalFormatter = createNumeralFormatter('0,0.00');

function formatDateXAxisLabel(value: string) {
  const dateDayjs = dayjs(value, 'YYYYMMDDHHmmss');
  return (dateDayjs.isValid() ? dateDayjs : dayjs(value)).format('MM/DD/YYYY');
}

function formatTimeXAxisLabel(value: string | number) {
  const valueStr = typeof value === 'number' ? value.toString() : value;
  const paddedValueStr = valueStr.padStart(6, '0');
  return dayjs(paddedValueStr).format('HH:mm:ss.SSS');
}

function formatDateTicksXAxisLabel(value: string | number) {
  const valueNumber = typeof value === 'number' ? value : parseInt(value);
  // dateTicks are send as microseconds from start of day.
  const miliseconds = valueNumber / 1000;
  // Since we only care about time, current date is ok to use to get formatted time string.
  return dayjs().startOf('day').add(miliseconds, 'millisecond').format('HH:mm:ss.SSS');
}

function formatDateTimeXAxisLabel(value: string) {
  return dayjs(value, 'YYYYMMDDHHmmss').format('MM/DD/YYYY - HH:mm:ss');
}

function formatValueBasedOnType(value: string, xAxisColumnDisplayType: xAxisColumnDisplayTypeEnum) {
  if (xAxisColumnDisplayType === 'date') {
    return formatDateXAxisLabel(value.toString());
  } else if (xAxisColumnDisplayType === 'time') {
    return formatTimeXAxisLabel(value.toString());
  } else if (xAxisColumnDisplayType === 'datetime') {
    return formatDateTimeXAxisLabel(value.toString());
  } else if (xAxisColumnDisplayType === 'dateticks') {
    return formatDateTicksXAxisLabel(value);
  } else {
    return value;
  }
}

function capitalizeOrEmpty(labelProp: string | undefined) {
  return labelProp ? labelProp.substring(0, 1).toUpperCase() + labelProp.substring(1) : '';
}

function capitalizeOrEmptyLabelPropWithFormattedValue(
  labelProp: string | undefined,
  value: string,
  xAxisColumnDisplayType: xAxisColumnDisplayTypeEnum
) {
  const capitalizedLabelProp = capitalizeOrEmpty(labelProp);
  return capitalizedLabelProp ? capitalizedLabelProp + ': ' + formatValueBasedOnType(value, xAxisColumnDisplayType) : value;
}

function tooltipLabelFormatter(
  value: number,
  payload: Payload<string | number | (string | number)[], string | number>[],
  xAxisColumnDisplayType: xAxisColumnDisplayTypeEnum,
  labelProp?: string
) {
  const label = payload?.length && payload[0]?.payload[labelProp ?? ''];
  if (label) {
    return capitalizeOrEmptyLabelPropWithFormattedValue(labelProp, value.toString(), xAxisColumnDisplayType);
  }

  if (!Number.isFinite(value)) {
    return value;
  }

  return capitalizeOrEmptyLabelPropWithFormattedValue(labelProp, value.toString(), xAxisColumnDisplayType);
}

const findErrors = (allErrors: unknown[], { product, error }: { product: string; error: unknown }) => [
  ...allErrors.concat(...[error ? `[${product}] - ${error as string}` : []]),
];

const DEFAULT_PROPS = {
  products: [],
  dataPoints: {},
  data: [],
  columnNameModifier: (name: string) => startCase(name),
};

export default function DataGraph({
  products = DEFAULT_PROPS.products,
  dataPoints = DEFAULT_PROPS.dataPoints,
  data = DEFAULT_PROPS.data,
  dataLoading = false,
  ignoreProductName = false,
  onConfigure = undefined,
  tooltipLabelColumn,
  xAxisColumn = 'dateTicks',
  xAxisColumnType = 'number',
  xAxisColumnDisplayType = 'date',
  xAxisLabelColumn,
  columnNameModifier = DEFAULT_PROPS.columnNameModifier,
}: DataGraphParams) {
  xAxisLabelColumn = xAxisLabelColumn || capitalizeOrEmpty(xAxisColumn);
  tooltipLabelColumn = xAxisLabelColumn ? xAxisLabelColumn.toLowerCase() : '';

  const { appConfig, updateAppConfig } = useContext(SettingsConfigContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const graphRef = useRef<any>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [graphData, setGraphData] = useState<{ [key: string]: string }[]>([]);
  const [imageGraph, setImageGraph] = useState<{ url?: string; name?: string }>({});
  const [errors, setError] = useState<unknown[]>([]);
  const allDataPoints = useMemo(() => [...Object.keys(dataPoints || {})], [dataPoints]);

  const productDataPointMix = useMemo(
    () => flatten(products.map((product) => allDataPoints.map((dataPoint) => `${product}_${dataPoint}`))),
    [products, allDataPoints]
  );

  const [visibleDataPoints, setVisibleDataPoints] = useState<string[]>(allDataPoints);

  useEffect(() => {
    if (data && data.length) {
      setError(data.reduce(findErrors, []));
    }
  }, [data]);

  useEffect(() => {
    if (imageGraph.url && linkRef.current) {
      linkRef.current.click();
      setImageGraph({});
    }
  }, [imageGraph, linkRef]);

  useEffect(() => {
    const maybeDataPoints = appConfig?.visualisations?.[allDataPoints.join('_')];
    if (maybeDataPoints) {
      setVisibleDataPoints(maybeDataPoints);
      return;
    }

    setVisibleDataPoints(allDataPoints);
  }, [allDataPoints, appConfig]);

  const handleMenuItemClicked = useCallback(
    ({ key, domEvent }: { key: string; domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> }) => {
      domEvent.preventDefault();
      domEvent.stopPropagation();

      const newVisibleDataPoints = visibleDataPoints.includes(key)
        ? visibleDataPoints.filter((item) => item !== key)
        : [...visibleDataPoints, key];
      updateAppConfig({
        visualisations: {
          ...appConfig.visualisations,
          [allDataPoints.join('_')]: newVisibleDataPoints,
        },
      });
      setVisibleDataPoints(newVisibleDataPoints);
    },
    [visibleDataPoints, setVisibleDataPoints, updateAppConfig, allDataPoints, appConfig.visualisations]
  );

  const downloadGraph = async () => {
    try {
      if (!graphRef.current) {
        return;
      }
      const { container: svgContainer } = graphRef.current;
      const pngImage = await DomToImage.toPng(svgContainer);
      const name = sanitizeFilename(`${products.join('_')}__${Object.keys(dataPoints).join('_')}`);

      setImageGraph({
        url: pngImage,
        name: `${name}.png`,
      });
    } catch (error) {
      console.log(error);
      setError((currentErrors) => [...currentErrors, 'Current Visualization cannot be downloaded']);
    }
  };

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const dataByDate: { [key: string]: { [key: string]: string } } = {};

    // FIXME this should not be done in DataGraph as it couples the component with a specific data shape
    // Suggestion: move this to GenericDataGraph and rename that to ProductsDataGraph.
    products.forEach((candidateProduct) => {
      const dataForProduct = data.find(({ product }) => product === candidateProduct);
      if (!dataForProduct || !Array.isArray(dataForProduct.rows)) {
        console.warn(`[graph] no data for product: ${candidateProduct}`);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataForProduct.rows.forEach((row: any) => {
        const output =
          dataByDate[row[xAxisColumn]] ||
          (dataByDate[row[xAxisColumn]] = { [xAxisColumn]: row[xAxisColumn], [tooltipLabelColumn!]: row[tooltipLabelColumn!] });

        allDataPoints.forEach((visibleLine) => {
          const key = `${candidateProduct}_${visibleLine}`;
          output[key] = row[visibleLine];
        });
      });
    });

    let chartData = Object.values(dataByDate);
    try {
      chartData = chartData.sort((a, b) => parseFloat(a[xAxisColumn]) - parseFloat(b[xAxisColumn]));
    } catch (error) {
      console.error('Could not sort chart data, ', error); //here should arrive only numeric or date (as number) types
    }
    setGraphData(chartData);
  }, [data, allDataPoints, xAxisColumn, products, tooltipLabelColumn]);

  const menuItems = useMemo(
    () =>
      allDataPoints.map((dataPoint) => ({
        key: dataPoint,
        icon: visibleDataPoints.includes(dataPoint) ? <CheckOutlined /> : <DashOutlined />,
        label: `Show '${dataPoints[dataPoint] as string}'`,
      })),
    [dataPoints, allDataPoints, visibleDataPoints]
  );
  const menu = { onClick: handleMenuItemClicked, items: menuItems };

  return (
    <Spin spinning={dataLoading}>
      {
        // NOTE: this is a hidden link used for the download chart feature
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        imageGraph.url && <a href={imageGraph.url} download={imageGraph.name} ref={linkRef} target="_blank" rel="noopener noreferrer" />
      }
      <ErrorComponent errors={errors} />
      <div className={classes.buttons}>
        <Dropdown menu={menu} placement="bottomRight" trigger={['click']}>
          <Button style={styles.dropdownButton} icon={<DownOutlined />} />
        </Dropdown>
        <Button disabled={dataLoading || !graphData?.length} icon={<DownloadOutlined />} onClick={downloadGraph} style={styles.leftButton}>
          Download
        </Button>
        {onConfigure && (
          <Button onClick={onConfigure} style={styles.leftButton}>
            Configure
          </Button>
        )}
      </div>
      <ResponsiveContainer height={700} width="100%" debounce={200}>
        <LineChart data={graphData} style={styles.lineChart} ref={graphRef} margin={{ left: 30 }}>
          {productDataPointMix.map((productLine) => {
            const [product, key] = productLine.split('_');
            if (!visibleDataPoints.includes(key)) {
              return undefined;
            }

            const colour = getColorByKey(productLine);
            return (
              <Line
                key={productLine}
                dataKey={productLine}
                name={ignoreProductName ? columnNameModifier(key) : `${product.toUpperCase()} - ${columnNameModifier(key)}`}
                type="linear"
                legendType="square"
                connectNulls={false}
                stroke={colour}
                dot={false}
                activeDot={{ stroke: 'white', strokeWidth: 1, r: 3 }}
                isAnimationActive={false}
              />
            );
          })}
          <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
          <XAxis
            dataKey={xAxisColumn}
            label={xAxisLabelColumn}
            domain={['dataMin', 'dataMax+1']}
            type={xAxisColumnType}
            allowDataOverflow
            height={100}
            scale={xAxisColumnType === 'number' ? 'linear' : 'auto'}
            style={styles.axisStyles}
            // tick={<CustomizedAxisTick shouldShowTime={shouldShowTime} />}
            tickCount={10}
            tickFormatter={(v) => formatValueBasedOnType(v, xAxisColumnDisplayType)}
          />
          <YAxis
            domain={['dataMin', 'dataMax+1']}
            type="number"
            label={{ value: allDataPoints ? capitalizeOrEmpty(allDataPoints.join(', ')) : '', dx: -30, angle: -90 }}
            allowDataOverflow
            scale="linear"
            style={styles.axisStyles}
            tickCount={20}
            padding={{ top: 10, bottom: 10 }}
            orientation="left"
          />
          <Tooltip
            itemStyle={styles.tooltipStyles}
            labelFormatter={(value, payload) => tooltipLabelFormatter(value, payload, xAxisColumnDisplayType, tooltipLabelColumn)}
            allowEscapeViewBox={{ x: false, y: true }}
            content={<GraphTabledTooltipContent formatter={decimalFormatter} />}
          />
          <Legend verticalAlign="top" />
        </LineChart>
      </ResponsiveContainer>
    </Spin>
  );
}
