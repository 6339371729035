import { ErrorNotification } from '../../../services/error-notification';
import { WbApi } from '../workbench-api';

export interface TempAccess {
  state: 'ready' | 'saving';
  shortId: string;
}

export function initialTempAccess(): TempAccess {
  return { state: 'ready', shortId: '' };
}

export function tempAccessUpdateShortId(model: TempAccess | undefined, shortId: string): TempAccess | undefined {
  if (!model) {
    return undefined;
  }

  return { ...model, shortId };
}

export async function tempAccessSave(
  api: WbApi,
  model: TempAccess | undefined,
  refresh: () => Promise<void>,
  update: (_: TempAccess | undefined) => void
): Promise<ErrorNotification | undefined> {
  if (!model?.shortId) {
    return;
  }

  update({ ...model, state: 'saving' });

  const res = await api.createTempAccessRequest(model.shortId);
  if (res === undefined) {
    update({ ...model, state: 'ready' });
    return { message: 'Error sending request' };
  }

  try {
    await refresh();
  } finally {
    update(undefined);
  }
}
