export const EVENT_NAME = 'changed';

export class ValueBox<T> extends EventTarget {
  public static readonly EventName = EVENT_NAME;

  constructor(private targetValue: T) {
    super();
  }

  set value(value: T) {
    this.targetValue = value;
    this.dispatchEvent(new Event(ValueBox.EventName));
  }

  get value(): T {
    return this.targetValue;
  }
}
