import React, { useCallback } from 'react';

import { Space, Form, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { ArrowRightOutlined } from '@ant-design/icons';
import PreciseTimePicker from '../../../../../_shared/preciseTimePicker/PreciseTimePicker';

import { AMERICAN_DATE_FORMAT } from '../../../../../utils/midas-constants';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

import classes from './SingleDateAndTimeRangeControl.module.css';

dayjs.extend(weekday);
dayjs.extend(localeData);

type SingleDateAndTimeRangeControlTime = {
  timeString: string;
  timestamp?: number;
};

type SingleDateAndTimeRangeControlParams = {
  value?: {
    date?: dayjs.Dayjs | null;
    startTime?: SingleDateAndTimeRangeControlTime;
    endTime?: SingleDateAndTimeRangeControlTime;
  };
  onChange?: (a: unknown) => void;
};

const validator = ({ hourIsSet, americanPeriod }: { hourIsSet: boolean; americanPeriod: string }) => hourIsSet && americanPeriod !== '_M';

const DEFAULT_PROPS = {
  onChange: () => {},
  value: {
    date: null,
    startTime: { timeString: '09:30:00' },
    endTime: { timeString: '16:00:00' },
  },
  valueEndTime: {},
};

function SingleDateAndTimeRangeControl({
  onChange = DEFAULT_PROPS.onChange,
  value = DEFAULT_PROPS.value,
}: SingleDateAndTimeRangeControlParams) {
  const handleDateValueChanged = (date: dayjs.Dayjs) => onChange({ ...value, date });

  const handleTime = useCallback(
    (time: SingleDateAndTimeRangeControlTime, timeKey: 'startTime' | 'endTime') => {
      // prevent infinite loops caused by antd form updates
      if ((value?.[timeKey]?.timeString === time?.timeString && value?.[timeKey]?.timestamp !== undefined) || !time.timeString) {
        return;
      }
      setTimeout(() => onChange({ ...value, [timeKey]: time }), 0);
    },
    [value, onChange]
  );

  const handleStartTime = (startTime: SingleDateAndTimeRangeControlTime) => handleTime(startTime, 'startTime');
  const handleEndTime = (endTime: SingleDateAndTimeRangeControlTime) => handleTime(endTime, 'endTime');

  return (
    <Space className={classes.dateTimePickerSpace} align="start">
      <DatePicker
        showTime={false}
        value={value?.date}
        onChange={handleDateValueChanged}
        format={AMERICAN_DATE_FORMAT}
        aria-label="Date picker"
      />
      <div className={classes.timePickers}>
        <div className={classes.timePickerWidth}>
          <PreciseTimePicker
            time={value?.startTime?.timeString}
            onTimeChange={handleStartTime}
            placeholder={value?.startTime?.timeString ? '' : 'Start time'}
            validator={validator}
            withMicroseconds={false}
            autofillMissingFields
            aria-label="Start time picker"
          />
        </div>
        <ArrowRightOutlined style={{ display: 'block' }} className={classes.arrowLineHeight} />
        <div className={classes.timePickerWidth}>
          <PreciseTimePicker
            time={value?.endTime?.timeString}
            onTimeChange={handleEndTime}
            placeholder={value?.endTime?.timeString ? '' : 'End time'}
            validator={validator}
            withMicroseconds={false}
            autofillMissingFields
            aria-label="End time picker"
          />
        </div>
      </div>
    </Space>
  );
}

export function SingleDateAndTimeRangeFormControl() {
  return (
    <Form.Item
      shouldUpdate
      label="Date/Time"
      name="dateAndTimeRange"
      data-testid="data-query-date-and-time-range"
      required
      rules={[
        {
          async validator(rule, { date, startTime, endTime }) {
            if (!date) {
              return Promise.reject('Please select a date.');
            }

            if (!startTime?.isValid || !endTime?.isValid) {
              return Promise.reject('Please select a valid start and end time.');
            }

            if (startTime.microTimestamp >= endTime.microTimestamp) {
              return Promise.reject('End time must be greater than start time.');
            }
          },
        },
      ]}
      labelAlign="left"
    >
      <SingleDateAndTimeRangeControl />
    </Form.Item>
  );
}

export default SingleDateAndTimeRangeControl;
