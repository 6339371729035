import { wsIsEmbedded, wsOpenUrl } from '../workspaces/utils';

export function openUrl(url: string) {
  if (wsIsEmbedded()) {
    wsOpenUrl(url);
    return;
  }

  window.open(url, '_blank')?.focus();
}
