import React, { useMemo } from 'react';

import { Statistic, Row, Col, Spin, Table } from 'antd';

import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

import useWebDataLoader from '../../hooks/use-web-data-loader';
import usePausingWebUpdater from './utils/use-pausing-web-updater';

import { createCommonDateFormatter } from '../../utils/text-utils';

import { initColumnsForWorkflowControl } from './utils/CreateColumns';

import { WF_RUNNING_STATE_URL } from '../../utils/midas-constants';

import classes from './WorkflowInfoControl.module.css';
import { DpActivityWorkflow } from '../../pages/types';

const dateFormatter = createCommonDateFormatter();

export default function WorkflowInfoControl({ uuid }: { uuid: string }) {
  const requestUrl = useMemo(() => {
    const url = new URL(WF_RUNNING_STATE_URL);
    url.searchParams.set('jobId', uuid);

    return url;
  }, [uuid]);

  const [webData, runUpdate] = useWebDataLoader<DpActivityWorkflow[]>({ url: requestUrl });

  usePausingWebUpdater({
    runUpdate,
    delay: 5000,
  });

  const tableColumns = useMemo(() => initColumnsForWorkflowControl(), []);

  const workflowInfo = useMemo(() => webData?.data?.[0], [webData]);

  // const currentStep =
  //   workflowInfo?.currentStep && workflowInfo?.steps?.find(({ stepId }) => stepId === workflowInfo?.currentStep);

  return (
    <Spin spinning={webData.loading}>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic title="Current Task Status" value={startCase(toLower(workflowInfo?.currentStatus || '(Unknown)'))} />
        </Col>
        <Col span={8}>
          <Statistic title="Current Pipeline" value={workflowInfo?.currentJobName || 'None'} />
        </Col>
        <Col span={8}>
          <Statistic title="Started At" value={dateFormatter(workflowInfo?.createdAt)} />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <div className={`ant-statistic-title ${classes.header}`}>Pipeline Task Status</div>
          <Table bordered size="middle" columns={tableColumns} dataSource={workflowInfo?.steps || []} rowKey="stepId" pagination={false} />
        </Col>
      </Row>
    </Spin>
  );
}
