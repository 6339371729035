import { GenerateApiKeyModel } from './generate-api-key';
import { AddGitlabApiKeyModel } from './add-gitlab-api-key';
import { SecondarySidebarState } from '../../../secondary-sidebar/SecondarySidebar';
import { UserPreferencesModel } from './user-preferences';
import { SignedInUser } from '../../../services/auth';
import { UsersApi } from '../../users/users-api';
import { ErrorNotification } from '../../../services/error-notification';

export const USERNAME_ITEM_KEY = 'username';

export const EMAIL_ITEM_KEY = 'email';

export const LOGOUT_ITEM_KEY = 'logout';

export const API_GEN_ITEM_KEY = 'generateApi';

export const ADD_GITLAB_API_KEY = 'addGitlabApiKey';

export const USER_PREFERENCES_KEY = 'userPreferences';

export const FLAG_ENABLE_WB_INSIDE_ZOE = 'ENABLE-INTEGRATED-WB';

export const FLAG_ENABLE_NEW_FEATURES = 'ENABLE-NEW-FEATURES';

export const FLAG_ENABLE_VISUALISATION_UI = 'ENABLE-VISUALISATION-UI';

export interface UserInfoModel {
  loading: boolean;
  user?: SignedInUser;
  sidebarCollapsed: boolean | undefined;
  notificationsCollapsed: SecondarySidebarState | undefined;
  isMidasPeer: boolean;
  workbenchAllowed: boolean;
  isAdmin: boolean;
  generateApiKey: GenerateApiKeyModel | undefined;
  addGitlabApiKey: AddGitlabApiKeyModel | undefined;
  userPreferences: UserPreferencesModel | undefined;
  featureFlags: string[];
}

export function initialUserInfo(): UserInfoModel {
  return {
    loading: true,
    user: undefined,
    sidebarCollapsed: false,
    notificationsCollapsed: SecondarySidebarState.Collapsed,
    generateApiKey: undefined,
    addGitlabApiKey: undefined,
    userPreferences: undefined,
    isMidasPeer: false,
    workbenchAllowed: false,
    isAdmin: false,
    featureFlags: [],
  };
}

export async function checkInUser(
  api: UsersApi,
  getModel: () => UserInfoModel,
  update: (_: UserInfoModel) => void
): Promise<ErrorNotification | undefined> {
  try {
    const res = await api.checkIn();

    if (!res?.ok) {
      return { message: 'Failed to check in' };
    }

    update({ ...getModel(), loading: false, featureFlags: res.featureFlags, workbenchAllowed: res.workbenchAllowed, isAdmin: res.isAdmin });
  } catch (error) {
    return { message: 'Failed to check in' };
  }
}
