import { FormInstance } from 'antd';
import isNil from 'lodash/isNil';
import dayjs, { Dayjs } from 'dayjs';
import { DataQueryConfig } from '../../../types';
import { isDayjs } from '../../../../utils/config-utils';

type DataQueryFormDateField = {
  date: Dayjs;
  startTime?: string;
  endTime?: string;
};

export const preventEnterKeyDefault = (e: React.KeyboardEvent<HTMLFormElement>) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};

export const isValidDateRange = (dateRange?: Dayjs[]) => {
  if (!dateRange || dateRange.length !== 2) {
    return false;
  }
  return dateRange.every((d) => isDayjs(d));
};

export const isValidDateTime = (dateTime?: DataQueryFormDateField) => {
  const isValidDate = !isNil(dateTime) && isDayjs(dateTime.date) && dateTime.date.isValid();
  return isValidDate && !isNil(dateTime.startTime) && !isNil(dateTime.endTime);
};

export const isValidDateField = (dateFieldName?: string, dateField?: string | Dayjs | Dayjs[] | DataQueryFormDateField) => {
  switch (dateFieldName) {
    case 'dateRange':
      return isValidDateRange(dateField as Dayjs[]);
    case 'dateAndTimeRange':
      return isValidDateTime(dateField as DataQueryFormDateField);
    case 'date':
    default:
      return !isNil(dateField) && (isDayjs(dateField) || dayjs(dateField as string).isValid());
  }
};

const isValidFeedAndDate = (dateFieldName: string, dateField: string | Dayjs | Dayjs[] | DataQueryFormDateField, feed: string) => {
  return isValidDateField(dateFieldName, dateField) && !isNil(feed);
};

export const shouldDisableProducts = ({
  formInstance,
  dateFieldName,
  includeFeed = true,
}: {
  formInstance: FormInstance;
  dateFieldName: string;
  includeFeed: boolean;
}) => {
  const dateField = formInstance.getFieldValue(dateFieldName);
  return includeFeed
    ? !isValidFeedAndDate(dateFieldName, dateField, formInstance.getFieldValue('feed'))
    : !isValidDateField(dateFieldName, dateField);
};

export const shouldChangesDisableProducts = ({
  formInstance,
  changedValues,
  dateFieldName,
  includeFeed = true,
}: {
  formInstance: FormInstance;
  changedValues: DataQueryConfig;
  dateFieldName: string;
  includeFeed?: boolean;
}) => {
  if (Object.prototype.hasOwnProperty.call(changedValues, 'feed') || Object.prototype.hasOwnProperty.call(changedValues, dateFieldName)) {
    return shouldDisableProducts({ formInstance, dateFieldName, includeFeed });
  }
  return false;
};
