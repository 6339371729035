import { WebViewIpcClientBase } from './web-view-ipc-client-base';

type VsCodeWebApi = {
  postMessage(message: any): void;
};

export class WebViewIpcClientIFrame extends WebViewIpcClientBase {
  constructor(protected target?: any, protected sessionId: string = '') {
    super(target, sessionId);
  }

  override postMessage(message: any) {
    window.parent.postMessage(message, '*');
  }

  public static createClientProxy<T>(sessionId = ''): T {
    const instantiatedClient = new WebViewIpcClientIFrame(undefined, sessionId);
    instantiatedClient.startListening();
    return instantiatedClient.createProxy() as T;
  }
}
