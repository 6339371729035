import React, { useCallback, useMemo, useState } from 'react';

import defer from 'lodash/defer';

import { Collapse, CollapseProps, Table } from 'antd';

import { initFileUploadColumns } from './utils/CreateColumns';

import useWebDataLoader from '../../hooks/use-web-data-loader';

import { convertPropsToJson } from '../../utils/json-utils';

import usePausingWebUpdater from './utils/use-pausing-web-updater';
import { DataQueryFormItemMetaAccordionControls, DpActivityJobStatusFile } from '../../pages/types';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SorterCallback, SorterFactory } from '../../pages/data-query/components/outputs/_shared/table-utils';
import { showErrorNotification } from '../../../_shared/views/Notifications';
import { DpFileExchangeApi, dpFxApiPath } from '../../../api/dp-file-exchange-api';

const DEFAULT_DELETE_CONFIG = Object.freeze({ id: undefined, busy: false, error: undefined } as {
  id?: string;
  busy: boolean;
  error: unknown;
});

type DpActivityFilesParams = {
  tablesSize: SizeType;
  conditionallyHandleShowRelatedItemsInfo: boolean | ((id: string) => void);
  handleTableChangeCallbackFactory: SorterCallback;
  accordionActiveKeys: DataQueryFormItemMetaAccordionControls;
  onAccordionActiveKeysChanged: (key: string, openControls: string | string[]) => void;
  sorterFactory?: (tableName: string) => SorterFactory | undefined;
  showActiveOnly?: boolean;
  showDeletedFiles?: boolean;
  selectedWorkflowId?: string | string[];
};

const DEFAULT_PROPS = {
  sorterFactory: () => undefined,
};

export default function DpActivityFiles({
  tablesSize,
  sorterFactory = DEFAULT_PROPS.sorterFactory,
  conditionallyHandleShowRelatedItemsInfo,
  handleTableChangeCallbackFactory,
  accordionActiveKeys,
  onAccordionActiveKeysChanged,
  showActiveOnly = false,
  showDeletedFiles = false,
  selectedWorkflowId,
}: DpActivityFilesParams) {
  const dpFxApi = new DpFileExchangeApi();
  const requestUrl = useMemo(() => {
    const url = dpFxApi.buildGetFilesUrl({ workflowId: selectedWorkflowId, showAll: !showActiveOnly, showDeleted: showDeletedFiles });

    return url;
  }, [showActiveOnly, showDeletedFiles, selectedWorkflowId]);

  const [webData, runUpdate] = useWebDataLoader<DpActivityJobStatusFile[]>({ url: requestUrl });

  usePausingWebUpdater({
    selectedWorkflowId,
    runUpdate,
  });

  const [deleteConfig, setDeleteConfig] = useState(DEFAULT_DELETE_CONFIG);

  const handleUploadFileDelete = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (id: string) => {
      if (deleteConfig.id === id) {
        return;
      }

      setDeleteConfig({ id, busy: true, error: undefined });
      defer(async () => {
        try {
          await dpFxApi.deleteFiles({ uploadId: id });
          setDeleteConfig(DEFAULT_DELETE_CONFIG);
          // eslint-disable-next-line @typescript-eslint/await-thenable
          await runUpdate();
        } catch (error: unknown) {
          console.error('[DpActivityFiles] failed to delete the specified file...', error);
          setDeleteConfig({ busy: false, error, id: undefined });
          showErrorNotification({
            message: 'Unable to delete the specified file...',
            description: (error as Error).message,
          });
        }
      });
    },
    [setDeleteConfig, deleteConfig, runUpdate]
  );

  const handleFileUploadDownload = useCallback(async (selectedId: string) => {
    try {
      const response = await dpFxApi.getDownloadPresignedUrl({ uploadId: selectedId });
      if (!response.ok || !response.data?.preSignedUrl) {
        console.error('[DpActivityFiles] failed to generate download URL...', response.error);
      } else {
        window.location.href = response.data.preSignedUrl;
      }
    } catch (error: unknown) {
      console.error('[DpActivityFiles] failed to generate download URL...', error);
      showErrorNotification({
        message: 'Unable to generate a download URL for the selected file...',
        description: (error as Error).message,
      });
    }
  }, []);

  const columnsForFileUpload = useMemo(
    () =>
      initFileUploadColumns(
        handleUploadFileDelete,
        handleFileUploadDownload,
        conditionallyHandleShowRelatedItemsInfo,
        deleteConfig.id,
        sorterFactory('columnsForFileUpload')
      ),
    [handleUploadFileDelete, handleFileUploadDownload, conditionallyHandleShowRelatedItemsInfo, deleteConfig.id, sorterFactory]
  );

  const tableData = useMemo(() => {
    if (webData?.data) {
      return webData.data.map((file) => convertPropsToJson(file, 'formData'));
    }
    return [];
  }, [webData.data]);

  const collapseItems: CollapseProps['items'] = [
    {
      key: 'files',
      label: 'My Files',
      children: (
        <Table
          columns={columnsForFileUpload}
          dataSource={tableData}
          loading={webData.loading}
          rowKey="id"
          size={tablesSize}
          scroll={{ x: true }}
          pagination={{ hideOnSinglePage: true }}
          onChange={handleTableChangeCallbackFactory('columnsForFileUpload')}
        />
      ),
    },
  ];

  return (
    <Collapse activeKey={accordionActiveKeys.files} onChange={(e) => onAccordionActiveKeysChanged('files', e)} items={collapseItems} />
  );
}
