import { useState, useRef } from 'react';
import { DataQueryConfigCursor, DataQueryProductRowOutput } from '../../../../types';
import { AMERICA_NY_TIMEZONE } from '../../../../../utils/midas-constants';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type CursorDateFormat = 'YYYYMMDD' | 'YYYY-MM-DD hh:mm:ss' | 'YYYY-MM-DD hh:mm:ss.SSS+';

function getIsoStringDate(dateValue: string, format?: CursorDateFormat) {
  if (format === 'YYYYMMDD') {
    const isoDate = `${dateValue.substring(0, 4)}-${dateValue.substring(4, 6)}-${dateValue.substring(6, 8)}`;
    return isoDate;
  }
  if (format === 'YYYY-MM-DD hh:mm:ss' || format === 'YYYY-MM-DD hh:mm:ss.SSS+') {
    let isoDate = dayjs.tz(dateValue, AMERICA_NY_TIMEZONE).toISOString();
    if (format === 'YYYY-MM-DD hh:mm:ss.SSS+') {
      //        drop the xxxZ part                         add the fractional part unchanged
      isoDate = isoDate.substring(0, isoDate.length - 4) + dateValue.split('.')[1] + 'Z';
    }
    return isoDate;
  }
  return dateValue;
}

export default function useCursorPaginationState() {
  /* Use stack to keep track of which evaluated keys have been previously seen */
  const [prevEvaluatedKeys, setPrevEvaluatedKeys] = useState<DataQueryConfigCursor[]>([]);
  /* Keep track of the current evaluated key */
  const [currentEvaluatedKey, setCurrentEvaluatedKey] = useState<DataQueryConfigCursor | null>(null);

  // Cursor used to get current page data
  const [requestCursor, setRequestCursor] = useState<DataQueryConfigCursor | null>(null);
  // Cursor to get next page data
  const dataCursor = useRef<DataQueryConfigCursor | null>(null);

  const popEvaluatedKey = () => {
    const prevEvaluatedKey = prevEvaluatedKeys.pop();
    setCurrentEvaluatedKey(prevEvaluatedKey!);
    return prevEvaluatedKey;
  };

  const pushEvaluatedKey = (evaluatedKey: DataQueryConfigCursor) => {
    setPrevEvaluatedKeys([...prevEvaluatedKeys, currentEvaluatedKey!]);
    setCurrentEvaluatedKey(evaluatedKey);
    return evaluatedKey;
  };

  const resetEvaluatedKeys = () => {
    setPrevEvaluatedKeys([]);
    setCurrentEvaluatedKey(null);
    setRequestCursor(null);
  };

  return {
    requestCursor,
    cursorPages: prevEvaluatedKeys.length,
    resetCursors: resetEvaluatedKeys,
    updateCursor: (data: DataQueryProductRowOutput[], dateProperty: keyof DataQueryProductRowOutput, format?: CursorDateFormat) => {
      const [lastDataItem] = (data || []).slice(-1);
      dataCursor.current = lastDataItem && {
        date: getIsoStringDate(lastDataItem[dateProperty] as string, format),
        sequence: lastDataItem.seq as any, // Potential type issue, not sure if string or number
      };
    },
    setCursorBack: () => {
      const cursor = popEvaluatedKey();
      setRequestCursor(cursor!);
      return cursor;
    },
    setCursorForward: () => {
      const cursor = pushEvaluatedKey(dataCursor.current!);
      setRequestCursor(cursor);
      return cursor;
    },
  };
}
