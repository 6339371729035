export const DipUploadedDataQuery = 'DipUploadedDataQuery';

export const BookJoinDataQuery = 'BookJoinDataQuery';

export const ProductInfo = 'ProductInfoForm';

export const DailyQuery = 'DailyQueryForm';

export const IntervalQuery = 'IntervalQueryForm';

export const TicksQuery = 'TicksQueryForm';

export const BookQuery = 'BookQueryForm';

export const OrderBook = 'OrderBookForm';
