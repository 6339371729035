import { PeriodicRequest } from '../../services/periodic-request';
import { UsageApi } from './usage-api';
import { UsageModel } from './models/usage-model';
import { UsageWarningScreenDelegate } from './views/UsageWarningScreen';

export class UsageWarningController implements UsageWarningScreenDelegate {
  private api = new UsageApi();

  private disposeCallbacks: (() => void)[] = [];

  private request: PeriodicRequest<unknown>;

  private update = (model: UsageModel) => {
    this.updateViewState(model);
  };

  constructor(private updateViewState: (_: UsageModel) => void) {
    this.request = new PeriodicRequest({
      interval: 60 * 1000,

      onPeriodicRequest: async () => await this.api.getUsageLevel(),

      onPeriodicRequestResult: (value: UsageModel | undefined) => {
        if (value) {
          this.update(value);
        }
      },
    });
    this.disposeCallbacks.push(this.request.start());
  }

  dispose() {
    for (const cb of this.disposeCallbacks) {
      cb();
    }
    this.disposeCallbacks = [];
  }

  async onAcknowledged(): Promise<void> {
    await this.api.acknowledge();
    await this.request.refresh();
  }
}
