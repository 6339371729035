import { ErrorNotification } from '../../../services/error-notification';
import { ConfigSchemaFormModel } from '../../../_shared/models/config-schema-form-model';
import { NotificationMessage } from '../../../_shared/views/Notifications';
import { JobListItem, JobsApi } from '../jobs-api';

export interface EditJobModel {
  job: JobListItem;
  state: 'loading' | 'ready' | 'saving';
  error: string | undefined;
  configSchema: ConfigSchemaFormModel['configSchema'] | undefined;
  config: ConfigSchemaFormModel['config'] | undefined;
}

export function jobEditAsConfigSchemaFormModel(model: EditJobModel): ConfigSchemaFormModel {
  return { configSchema: model.configSchema, config: model.config };
}

export async function loadEditJob(
  api: JobsApi,
  job: JobListItem,
  getModel: () => EditJobModel | undefined,
  update: (_: EditJobModel) => void
): Promise<ErrorNotification | undefined> {
  update({ state: 'loading', job, configSchema: undefined, config: undefined, error: undefined });

  const result = await api.getJobConfig(job);
  if (result && !result.ok) {
    return { message: 'Error loading config.' };
  }

  if (getModel()?.job.id !== job.id) {
    // changed while we were loading data, bail out
    return;
  }

  update({ state: 'ready', job, configSchema: result?.configSchema, config: result?.config, error: undefined });
}

export async function updateJob(
  api: JobsApi,
  getModel: () => EditJobModel | undefined,
  refresh: () => Promise<void>,
  update: (_: EditJobModel | undefined) => void
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  if (!model) {
    return model;
  }

  update({ ...model, state: 'saving' });

  const result = await api.updateJobConfig(model);

  if (getModel()?.job.id !== model.job.id) {
    // changed while saving, bail out
    return;
  }

  if (!result?.ok) {
    update({ ...model, state: 'ready', error: result?.error });
    return { message: 'Error updating job' };
  }

  try {
    await refresh();
  } finally {
    update(undefined);
  }
}

export async function toggleJobEnabled(
  api: JobsApi,
  job: JobListItem,
  refresh: () => Promise<void>
): Promise<ErrorNotification | undefined> {
  const result = await api.updateJobEnabled(job, !job.enabled);

  if (!result?.ok) {
    return { message: 'Error updating job' };
  }

  await refresh();
}

export async function deleteJob(api: JobsApi, model: JobListItem, refresh: () => Promise<void>): Promise<NotificationMessage | undefined> {
  const result = await api.deleteJob(model);

  if (!result?.ok) {
    return { type: 'error', message: 'Error deleting job' };
  }

  await refresh();
  return { type: 'info', message: 'Removing job' };
}
