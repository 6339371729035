import { useEffect, useRef } from 'react';
import { DataQueryConfig } from '../pages/types';

export const usePrevious = (value: DataQueryConfig | undefined) => {
  const ref = useRef<DataQueryConfig | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
