import React, { useMemo } from 'react';

import { Tabs, Typography, Select } from 'antd';

import OrderBookHourBreakdownGraph from './OrderBookHourBreakdownGraph';
import OrderBookLevelBreakdownGraph from './OrderBookLevelBreakdownGraph';

import classes from './OrderBookControls.module.css';
import { DataQueryConfig, DataQueryProductRow } from '../../../../../types';
import { GetUrlForQuery } from '../../hooks/use-data-requester';

type OrderBookGraphControlParams = {
  data?: DataQueryProductRow[];
  config: DataQueryConfig;
  products?: string[];
  product?: string;
  dataLoading?: boolean;
  onSelectProduct?: (value: unknown, option: unknown) => void;
  getUrlForQuery: GetUrlForQuery;
  onConfigChanged?: (config: DataQueryConfig) => void;
};

const colors = {
  ask: '#E8382A',
  bid: '#20B239',
  trades: '#313A75',
  volume: '#E8B12A',
};

export default function OrderBookGraphControl({
  data = [],
  config,
  dataLoading = false,
  products = [],
  product = undefined,
  onSelectProduct = () => {},
  getUrlForQuery,
  onConfigChanged = () => {},
}: OrderBookGraphControlParams) {
  const productOptions = useMemo(() => {
    return products.map((p) => ({ value: p, label: p }));
  }, [products]);

  const tabs = [
    {
      key: '1',
      label: 'Minute Breakdown',
      children: (
        <OrderBookHourBreakdownGraph
          config={config}
          getUrlForQuery={getUrlForQuery}
          colors={colors}
          product={product!}
          onConfigChanged={onConfigChanged}
        />
      ),
    },
    {
      key: '2',
      label: 'Levels',
      children: <OrderBookLevelBreakdownGraph data={data} dataLoading={dataLoading} colors={colors} product={product!} />,
    },
  ];

  return (
    <>
      <div className={classes.graphControlHeader}>
        <label className="ant-typography" htmlFor="showResultsFor">
          Show results for:&nbsp;
        </label>
        <Select
          id="showResultsFor"
          className={classes.graphControlProductSelect}
          disabled={productOptions.length === 0 || !onSelectProduct}
          placeholder={productOptions.length > 0 ? 'Select a product' : 'No results available'}
          onChange={onSelectProduct}
          options={productOptions}
          value={product}
        />
      </div>
      <Tabs defaultActiveKey="1" items={tabs} />
    </>
  );
}
