import { CSSProperties } from 'react';

export const axisStyles: CSSProperties = { fontSize: '10px' };

export const tooltipStyles: CSSProperties = { fontSize: '10pt' };

export const lineChart: CSSProperties = { userSelect: 'none' };

export const dropdownButton: CSSProperties = { margin: '2px' };

export const leftButton: CSSProperties = { margin: '2px', marginLeft: '16px' };
