import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import { WbApi } from '../workbench-api';
import { WbInstance } from './instance';

export interface InstanceStalePause {
  state: 'ready' | 'saving' | 'loading';
  id?: string;
  scheduledDays: number;
  alertsEnabled: boolean;
}

export interface InstanceDailyScheduledPause {
  state: 'ready' | 'saving' | 'loading' | 'loaded';
  dateError?: string | undefined;
  instanceId?: string;
  timezone: string;
  isPausedOnWeekends: boolean;
  fromTime: string | null;
  toTime: string | null;
}

export const initialDailyScheduleStatus = (
  instanceId?: string,
  state: 'ready' | 'saving' | 'loading' | 'loaded' = 'loading'
): InstanceDailyScheduledPause => ({
  instanceId: instanceId,
  state: state,
  timezone: dayjs.tz.guess(),
  fromTime: null,
  toTime: null,
  isPausedOnWeekends: false,
});

export async function loadDailyScheduledPause(
  api: WbApi,
  instance: WbInstance,
  update: (_: InstanceDailyScheduledPause) => void
): Promise<void> {
  update(initialDailyScheduleStatus(instance.id));
  const dailyInstanceScheduledInstance = await api.getDailyScheduledInstancePause(instance.shortId);

  if (!dailyInstanceScheduledInstance) {
    update(initialDailyScheduleStatus(instance.id, 'ready'));
    return;
  }

  update({
    instanceId: dailyInstanceScheduledInstance.instanceId,
    state: 'loaded',
    timezone: dailyInstanceScheduledInstance.timezone,
    fromTime: dailyInstanceScheduledInstance.fromTime,
    toTime: dailyInstanceScheduledInstance.toTime,
    isPausedOnWeekends: dailyInstanceScheduledInstance.isPausedOnWeekends,
  });
}
