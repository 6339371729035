import React from 'react';
import { Space } from 'antd';
import {
  CheckCircleTwoTone,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  WarningTwoTone,
  SyncOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons';
import { DipConstants } from '../../../pages/workflow/workflows/bookJoinWorkflow/_shared/constants';

const FileProcessingStates = {
  ENQUEUED: 'enqueued',
  IDLE: 'idle',
  RUNNING: 'running',
  FAILED: 'failed',
  COMPLETE: 'complete',
};

export default function ColumnStatusRenderer(status: string) {
  switch (status) {
    case DipConstants.FILE_DOWNLOAD:
      return (
        <Space>
          <CloudDownloadOutlined />
          File Downloaded
        </Space>
      );
    case DipConstants.FILE_DELETED:
      return (
        <Space>
          <DeleteOutlined />
          File Deleted
        </Space>
      );
    case DipConstants.FILE_COLUMNS_PARSE_FAILURE:
      return (
        <Space>
          <WarningTwoTone twoToneColor="red" />
          Column Parse Failure!
        </Space>
      );
    case DipConstants.FILE_UPLOADED:
      return (
        <Space>
          <CloudUploadOutlined />
          File Uploaded
        </Space>
      );
    case DipConstants.FILE_UPLOADING:
      return (
        <Space>
          <SyncOutlined spin />
          File Uploading
        </Space>
      );
    case DipConstants.CREATED_IDLE:
      return (
        <Space>
          <PauseCircleOutlined />
          Created; Idle
        </Space>
      );
    case DipConstants.DIP_FAILED:
      return (
        <Space>
          <WarningTwoTone twoToneColor="red" />
          Pipeline Failed!
        </Space>
      );
    case DipConstants.DIP_STARTED:
    case DipConstants.DIP_RUNNING:
      return (
        <Space>
          <SyncOutlined spin />
          Pipeline Running...
        </Space>
      );
    case DipConstants.DIP_SUCCEEDED:
      return (
        <Space>
          <CheckCircleTwoTone />
          Pipeline Succeeded
        </Space>
      );
    case DipConstants.FILE_COLUMNS_PARSED:
      return (
        <Space>
          <CheckCircleTwoTone />
          Columns Parsed
        </Space>
      );
    case DipConstants.FILE_GENERATED:
      return (
        <Space>
          <CheckCircleTwoTone />
          File Generated!
        </Space>
      );
    case DipConstants.PROCESSING_STARTED:
      return (
        <Space>
          <SyncOutlined spin />
          Processing Started...
        </Space>
      );
    case DipConstants.WORKFLOW_SCHEDULED:
      return (
        <Space>
          <CheckCircleTwoTone />
          Uploaded; Job Created
        </Space>
      );
    case FileProcessingStates.IDLE:
      return (
        <Space>
          <PauseCircleOutlined />
          Created; Idle
        </Space>
      );
    case FileProcessingStates.ENQUEUED:
      return (
        <Space>
          <PauseCircleOutlined />
          Enqueued
        </Space>
      );
    case FileProcessingStates.RUNNING:
      return (
        <Space>
          <SyncOutlined spin />
          Running
        </Space>
      );
    case FileProcessingStates.FAILED:
      return (
        <Space>
          <WarningTwoTone twoToneColor="red" />
          Processing Failed!
        </Space>
      );
    case FileProcessingStates.COMPLETE:
      return (
        <Space>
          <CheckCircleTwoTone />
          Processing Complete
        </Space>
      );
    default:
      return <Space>{status}</Space>;
  }
}
