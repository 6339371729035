/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import { Divider, Progress, Space, Badge, Popover } from 'antd';
import { CheckCircleTwoTone, PauseCircleOutlined, SyncOutlined, WarningTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';

import classes from './EnhancedStatusRenderer.module.css';
import sharedClasses from '../DpActivity.module.css';
import { DpActivityWorkflow, DpActivityWorkflowStep } from '../../../pages/types';

// linked to values in "FeFbdInternalWf.js" which sit outside this source tree.
export const ProcessingStates = {
  IDLE: 'IDLE',
  RUNNING: 'RUNNING',
  FAILED: 'FAILED',
  COMPLETE: 'COMPLETE',
  ABANDONED: 'ABANDONED',
};

function ErrorDetails({ data }: { data: string[] }) {
  const details: { message: string; renderKey: string }[] = useMemo(() => {
    let parsedMessages: { message: string; renderKey: string }[];

    try {
      parsedMessages = data.map((message) => ({ ...JSON.parse(message), renderKey: uuid() }));
    } catch (error) {
      console.error('[enhancedStatusRenderer] Unable to parse messages', error);
      parsedMessages = data.map((message) => ({ message, renderKey: uuid() }));
    }

    return parsedMessages;
  }, [data]);

  return (
    <ul>
      {details.map(({ message, renderKey }) => (
        <li key={renderKey}>{message}</li>
      ))}
    </ul>
  );
}

function DefaultOutput(status: string, details: string[] = []) {
  switch (status) {
    case ProcessingStates.COMPLETE:
      return (
        <Space>
          <CheckCircleTwoTone />
          Succeeded
        </Space>
      );
    case ProcessingStates.FAILED:
      return (
        <Space>
          <WarningTwoTone twoToneColor="red" />
          {details.length ? (
            <Popover title="Error Details" content={<ErrorDetails data={details} />} trigger="click" placement="bottom">
              <span>Processing Failed</span>
              <Divider type="vertical" />
              <span className={sharedClasses.infoText}>Errors</span>
              <Badge count={details.length} />
            </Popover>
          ) : (
            <>Processing Failed!</>
          )}
        </Space>
      );
    case ProcessingStates.IDLE:
      return (
        <Space>
          <PauseCircleOutlined />
          Enqueued
        </Space>
      );
    case ProcessingStates.RUNNING:
      return (
        <Space>
          <SyncOutlined spin />
          <div>Running...</div>
        </Space>
      );
    case ProcessingStates.ABANDONED:
      return (
        <Space>
          <CloseCircleOutlined />
          Abandoned
        </Space>
      );
    default:
      return status;
  }
}

export default function EnhancedStatusRenderer(status: string, row: DpActivityWorkflow | DpActivityWorkflowStep) {
  let runningStep: DpActivityWorkflowStep | undefined;

  if ('steps' in row && row.steps) {
    const { steps, currentStep } = row;
    if (!currentStep) {
      const details = row.stepOutputData?.errors || [];
      return DefaultOutput(status, details);
    }

    runningStep = steps.find(({ stepId }) => stepId === currentStep);
    if (!runningStep) {
      return DefaultOutput(status);
    }
  }

  let details;

  try {
    details = JSON.parse((row as DpActivityWorkflowStep).outputData)?.errors || [];
  } catch (error) {
    console.error('[enhancedStatusRenderer] Unable to parse messages', error);
    details = [];
  }

  const { message, percentage, processingStatus } = runningStep || (row as DpActivityWorkflowStep);
  return (
    <Space>
      {DefaultOutput(status, details)}
      {processingStatus !== ProcessingStates.FAILED && percentage >= 0 && (
        <>
          <Divider type="vertical" />
          <Progress percent={Math.round(percentage * 100)} className={classes.progressWidth} status="active" />
        </>
      )}
      {message && (
        <>
          <Divider type="vertical" />
          <div>{message}</div>
        </>
      )}
    </Space>
  );
}
