import React from 'react';

import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

import styles from './Spinner.module.css';

export function Spinner(props: SpinProps) {
  return (
    <div className={styles.spinnerContainer}>
      <Spin {...props} />
    </div>
  );
}
