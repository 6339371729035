import { createContext, useCallback, useEffect, useState } from 'react';

import { ValueBox } from '../utils/value-box';

export type AppTheme = 'light' | 'dark';

interface AppThemeContextValue {
  theme: AppTheme;
  toggleTheme: () => void;
}

export const AppThemeContext = createContext<AppThemeContextValue>({
  theme: 'light',
  toggleTheme: () => undefined,
});

export const themeBox = new ValueBox<AppTheme>('light');

themeBox.addEventListener(ValueBox.EventName, () => {
  localStorage.setItem('theme', themeBox.value);
});

// recolour the meta components used to hint to user agents what colour
// chromes should be.
export const recolourDesktopTheme = (colour: string) => {
  const metas = document.getElementsByTagName('head')[0];
  for (let i = 0; i <= metas.children.length; i++) {
    const item = metas.children.item(i);
    if (!item || item.getAttribute('name') !== 'theme-color') {
      continue;
    }

    item.setAttribute('content', colour);
  }
};

export function useThemeContextValue(): AppThemeContextValue {
  const [theme, setTheme] = useState<AppTheme>(localStorage.getItem('theme') === 'dark' ? 'dark' : 'light');

  const toggleTheme = useCallback(() => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  }, [theme]);

  useEffect(() => {
    const handler = () => {
      setTheme(themeBox.value);
    };

    themeBox.addEventListener(ValueBox.EventName, handler);
    return () => {
      themeBox.removeEventListener(ValueBox.EventName, handler);
    };
  });

  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.remove('light');
      document.body.classList.add('dark');
      recolourDesktopTheme('rgb(20, 20, 20)');
    } else {
      document.body.classList.remove('dark');
      document.body.classList.add('light');
      recolourDesktopTheme('rgb(248, 248, 248)');
    }
  }, [theme]);

  return { toggleTheme, theme };
}
