import * as antColors from '@ant-design/colors';

type MapEntry = {
  key: string;
  value: string;
};

const map: MapEntry[] = [];

export const antChartColors = (() => {
  const output: string[] = [];

  for (let i = 5; i < 10; i++) {
    [antColors.blue, antColors.purple, antColors.cyan, antColors.magenta, antColors.red, antColors.volcano].forEach((arr) =>
      output.push(arr[i])
    );
  }

  return output;
})();

/**
 * Gets a colour from a permanent map by key; ensures colours are not re-used.
 */
export const getColorByKey = (key: string): string => {
  const entry = map.find(({ key: sought }) => sought === key);
  if (entry) {
    return entry.value;
  }

  const colour = antChartColors[map.length];
  map.push({
    key,
    value: colour,
  });

  return colour;
};
