import { WbAppType, WbServiceStatus } from '@mst-fe/shared';
import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';
import axios from 'axios';
import { axiosOptions } from '../../services/requests';

export interface DashboardData {
  id: string;
  name: string;
}

export interface DashboardDetailWidgetPosition {
  colStart: number;
  nCols: number;
  rowStart: number;
  nRows: number;
}

export interface DashboardDetailWidgetData extends DashboardDetailWidgetPosition {
  id: string;
  name: string | null | undefined;
  serviceId: string | null | undefined;
  serviceStatus: WbServiceStatus | null | undefined;
  serviceUrl: string | undefined;
}

export interface DashboardDetailData {
  id: string;
  name: string;
  widgets: DashboardDetailWidgetData[];
}

export interface CreateDashboardRequest {
  name: string;
}

export interface UpdateDashboardWidget {
  id: string;
  colStart: number;
  nCols: number;
  rowStart: number;
  nRows: number;
  serviceId: string | null | undefined;
}

export interface UpdateDashboardRequest {
  name?: string;
  widgets?: UpdateDashboardWidget[];
}

export interface UserService {
  id: string;
  name: string;
  url: string | undefined;
  status: WbServiceStatus;
  type: WbAppType;
}

export class DashboardApi {
  async getDashboards() {
    return await checkAxiosResponse(axios.get<DashboardData[]>('/api/zoe/dashboard/', await axiosOptions()));
  }

  async getDashboardDetail(id: string) {
    return await checkAxiosResponse(axios.get<DashboardDetailData>(`/api/zoe/dashboard/${id}`, await axiosOptions()));
  }

  async createDashboard(data: CreateDashboardRequest): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(axios.post<{ ok: boolean }>(`/api/zoe/dashboard/`, data, await axiosOptions()));
  }

  async updateDashboard(id: string, data: UpdateDashboardRequest): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(axios.put<{ ok: boolean }>(`/api/zoe/dashboard/${id}`, data, await axiosOptions()));
  }

  async deleteDashboard(id: string): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(axios.delete<{ ok: boolean }>(`/api/zoe/dashboard/${id}`, await axiosOptions()));
  }

  async getDashboardServices(dashboardId: string): Promise<UserService[] | undefined> {
    return await checkAxiosResponse(axios.get<UserService[]>(`/api/zoe/dashboard/${dashboardId}/services`, await axiosOptions()));
  }

  async getUserServices(): Promise<UserService[] | undefined> {
    return await checkAxiosResponse(axios.get<UserService[]>(`/api/zoe/dashboard/user-services`, await axiosOptions()));
  }
}
