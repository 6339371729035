import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { axiosOptions } from './requests';

function getBaseAuthUrl(): string {
  return `${window.location.hostname === 'localhost' ? 'http://localhost:3001' : ''}/api/authorization${
    window.CONFIG.isMidasPeer ? '/midas' : ''
  }`;
}

export interface PluggableAuth {
  getAccessToken: () => Promise<string>;
  getUserSubject: () => Promise<string>;
}

let pluggable: PluggableAuth | undefined;

export function setPluggableAuth(auth: PluggableAuth) {
  pluggable = auth;
}

export function getAccessTokenFromCookie(): string | undefined {
  for (const cookie of document.cookie.split(';')) {
    const [name, value] = cookie.split('=').map((v) => v.trim());

    if (name === window.CONFIG.mstZoeAuthCookie) {
      return value;
    }
  }

  return undefined;
}

export async function getAccessToken(): Promise<string | undefined> {
  if (pluggable) {
    return await pluggable.getAccessToken();
  }

  return getAccessTokenFromCookie();
}

export async function getUserSubject(): Promise<string | undefined> {
  if (pluggable) {
    return await pluggable.getUserSubject();
  }

  return decodeAccessToken(getAccessTokenFromCookie())?.sub;
}

function decodeAccessToken(token: string | undefined): { sub: string; exp: number } | undefined {
  if (!token) {
    return undefined;
  }

  try {
    return jwtDecode(token);
  } catch (_) {
    return undefined;
  }
}

function willTokenExpireAfter(token: string | undefined, marginSeconds: number): boolean {
  const decoded = decodeAccessToken(token);

  if (!decoded) {
    return true;
  }

  return Date.now() + marginSeconds * 1000 >= decoded.exp * 1000;
}

export interface SignedInUser {
  attributes: {
    name: string;
    email: string;
  };
}

export function signOut() {
  window.location.href = `${getBaseAuthUrl()}/logout`;
}

export function handleLoginFlow(): SignedInUser | undefined {
  const accessToken = getAccessTokenFromCookie();
  if (!accessToken || willTokenExpireAfter(accessToken, -10)) {
    location.href = `${getBaseAuthUrl()}/login?callback=${encodeURIComponent(location.href)}`;
    return undefined;
  }

  let refreshing = false;
  setInterval(async () => {
    if (!refreshing && willTokenExpireAfter(getAccessTokenFromCookie(), 2 * 60)) {
      try {
        refreshing = true;
        // ignore call results
        await axios.post(`${getBaseAuthUrl()}/refresh`, {}, { ...(await axiosOptions()), timeout: 10 * 1000 });
      } finally {
        refreshing = false;
      }
    }
  }, 1000);

  const attrs: { name: string; email: string } = jwtDecode(accessToken);

  return {
    attributes: {
      name: attrs.name,
      email: attrs.email,
    },
  };
}
