import { ErrorNotification } from '../../../services/error-notification';
import { SearchApi, ResultsFromSource } from '../search-api';

export interface GlobalSearchModel {
  searchTerm: string;
  // Search results are grouped into the source of the result e.g. Applications, Jobs, Instances etc.
  searchResults: ResultsFromSource[];
  status: 'ready' | 'loading';
}

export function initialGlobalSearchResults(): GlobalSearchModel {
  return { searchTerm: '', searchResults: [], status: 'loading' };
}

export async function searchGlobal(
  api: SearchApi,
  getModel: () => GlobalSearchModel | undefined,
  update: (model: GlobalSearchModel) => void
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  if (!model || model.searchTerm === '') {
    return;
  }

  const trimmedTerm = model.searchTerm.trim();
  if (!trimmedTerm || trimmedTerm === '') {
    update({ ...model, searchTerm: '' });
    return;
  }

  const options = await api.getAllOptionsForTerm(trimmedTerm);

  if (!options) {
    update({ ...model, searchResults: [], status: 'loading' });
    return { message: 'Error searching.' };
  }

  update({ ...model, searchResults: options, status: 'ready' });
}
