import React, { useState } from 'react';

import { Form, Button, FormInstance } from 'antd';

import { dpProductName } from '../../../../utils/server-client-shared';

import SelectDpTableControl from './controls/SelectDpTableControl';
import DownloadButton from './controls/DownloadButton';

import { DP_GET_TABLE_DATA_URL, FORM_LAYOUT_LABEL } from '../../../../utils/midas-constants';

import classes from './shared.module.css';
import { DataQueryConfig, DataQueryForm } from '../../../types';
import { preventEnterKeyDefault } from './data-query-form-utils';

export const getUrlForQuery = (query: DataQueryConfig) => {
  const { dpTableName } = query;
  if (!dpTableName) {
    return undefined;
  }

  return `${DP_GET_TABLE_DATA_URL}${dpTableName}/downloadTearoff`;
};

const DEFAULT_PROPS = {
  onFinish: () => {},
};

export default function DipDataForm({
  formInstance,
  onFinish = DEFAULT_PROPS.onFinish,
}: {
  formInstance: FormInstance;
  onFinish: (value: unknown) => void;
}) {
  const [dipDataFormValid, setDipDataFormValid] = useState(false);

  const setFormValidity = async () => {
    try {
      await formInstance.validateFields();
      setDipDataFormValid(true);
    } catch (errorInfo: unknown) {
      setDipDataFormValid((errorInfo as { errorFields: unknown[] }).errorFields?.length === 0);
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleValueChange = async (changedValues: DataQueryForm) => {
    setTimeout(async () => await setFormValidity());
  };

  return (
    <Form
      form={formInstance}
      layout="horizontal"
      labelCol={FORM_LAYOUT_LABEL}
      onFinish={onFinish}
      labelWrap
      onValuesChange={async (changedValues) => {
        await handleValueChange(changedValues);
      }}
      onKeyDown={preventEnterKeyDefault}
    >
      <Form.Item
        label="Table"
        name="dpTableName"
        trigger="onChange"
        valuePropName="value"
        rules={[{ required: true, message: 'Please select a table.' }]}
        labelAlign="left"
      >
        <SelectDpTableControl />
      </Form.Item>
      <Form.Item>
        <Button className={classes.applyButtonContainer} htmlType="submit" type="primary" disabled={!dipDataFormValid}>
          Run Query
        </Button>
        <DownloadButton
          customProducts={[dpProductName]}
          downloadQuery={getUrlForQuery}
          allowedFormats={['csv']}
          formInstance={formInstance}
          isFormValid={dipDataFormValid}
        />
      </Form.Item>
    </Form>
  );
}
