import { useCallback, useEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';

import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';
import axios from 'axios';

import { ROOT_SERVER_URL } from '../../../../../utils/midas-constants';
import { usePrevious } from '../../../../../hooks/use-previous';
import { DataQueryConfig, DataQueryProductRow, DataQueryProductRowOutput } from '../../../../types';
import { getAccessTokenFromCookie } from '../../../../../../services/auth';
import { message, notification } from 'antd';

export type GetUrlForQuery = (
  config: DataQueryConfig,
  selectedItem?: string,
  downloadType?: string,
  useWS?: boolean
) => string | false | (string | false)[] | undefined;

type useDataRequesterParams = {
  config?: DataQueryConfig;
  getUrlForQuery: GetUrlForQuery;
  clearBeforeLoad?: boolean;
  useWS?: boolean;
};

const keyEnhancer =
  (key: number) =>
  (row: DataQueryProductRowOutput, i: number): DataQueryProductRowOutput => ({
    ...row,
    key: row.key! + i + key,
  });

const getThroughWebSocket = async (url: string) => {
  return new Promise((resolve, reject) => {
    const socket = new WebSocket(url);

    const resultBlob: Blob[] = [];

    socket.onopen = function (_event) {
      socket.send(JSON.stringify({ authorization: `Bearer ${getAccessTokenFromCookie()}` }));
    };

    socket.onmessage = function (event) {
      // If response gets too big for memory we could write to filesystem instead (Cache API or IndexedDB API)
      resultBlob.push(event.data);
    };

    socket.onclose = async function (event) {
      if (event.wasClean) {
        if (resultBlob.length < 1) {
          resolve({ output: [] });
          return;
        }

        let result = '';
        for (let i = 0; i < resultBlob.length; i++) {
          const current = await resultBlob[i].text();
          result += current;
        }

        try {
          const parsedResult = JSON.parse(result);
          resolve(parsedResult);
        } catch (e) {
          console.error('[WS error] unable to parse result.');
          reject(undefined);
        }
      } else {
        console.error(`[WS close] Connection died code=${event.code} reason=${event.reason}`);
        reject(undefined);
      }
    };

    socket.onerror = function (error) {
      console.error('[WS error] onerror event.', error);
      reject(undefined);
    };
  });
};

const isPromise = (promise: any) => !!promise && typeof promise.then === 'function';

export function withErr(action: string, callback: any) {
  const handleError = (error: any) => {
    console.error('[handle] failed to execute action: ', action, '...', error);
    notification.error({
      message: `Failed to successfully ${action}`,
      description: `Error message returned from server: ${error?.message ?? '<none>'}`,
    });
  };

  try {
    const response = callback();
    if (isPromise(response)) {
      response.catch(handleError);
    }
    return response;
  } catch (error) {
    handleError(error);
    return undefined;
  }
}

export default function useDataRequester({ config, getUrlForQuery, clearBeforeLoad = false, useWS = false }: useDataRequesterParams) {
  const previousConfig = usePrevious(config);

  const [processing, setProcessing] = useState<{ busy: boolean; error?: string | object }>({ busy: false, error: undefined });
  const [downloadedData, setDownloadedData] = useState<DataQueryProductRow[]>([]);

  useEffect(() => {
    if (!config) {
      console.warn('[useDataRequester] got an empty config.');
      return;
    }

    if (previousConfig && isEqual(previousConfig, config)) {
      // config has not changed
      return;
    }

    setProcessing({ busy: true });

    if (clearBeforeLoad) {
      setDownloadedData([]);
    }

    const addKey = keyEnhancer(Math.random());

    const normalizeData = (data: DataQueryProductRowOutput[]): DataQueryProductRowOutput[] => {
      return data.map(addKey);
    };

    void (async () => {
      try {
        const url = getUrlForQuery(config, undefined, undefined, useWS);
        if (!url || (Array.isArray(url) && (url.length === 0 || url.some((u) => u === false)))) {
          setProcessing({ busy: false });
          return;
        }
        const data = await withErr('download data', () => {
          const authToken = getAccessTokenFromCookie();
          if (Array.isArray(url)) {
            return Promise.all(
              url.map((u) => {
                if (!u) throw new Error('Could not get products since an empty url was provided');
                const definedU: string = u;

                return useWS
                  ? getThroughWebSocket(u)
                  : checkAxiosResponse(
                      axios.get(definedU, { headers: { Authorization: `Bearer ${authToken}` }, validateStatus: null }),
                      'Error while getting products from urls'
                    );
              })
            );
          }
          const definedUrl: string = url;
          return useWS
            ? getThroughWebSocket(url)
            : checkAxiosResponse(
                axios.get(definedUrl, { headers: { Authorization: `Bearer ${authToken}` }, validateStatus: null }),
                'Error while getting products from url'
              );
        });

        if (!data || data.indexOf(undefined) >= 0) {
          throw new Error('Error while getting products');
        }

        setDownloadedData(
          Array.isArray(url)
            ? (data as DataQueryProductRow[]).map(({ output, ...rest }) => ({ ...rest, output: normalizeData(output) }))
            : // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (normalizeData(data as any[]) as any[]) // This case should never be executed
        );
        setProcessing({ busy: false });
      } catch (error: unknown) {
        console.error('[useDataRequester] failed to request data...', error);
        setProcessing({ busy: false, error: error as object });
      }
    })();
  }, [config, clearBeforeLoad]);

  const handleDownloadData = useCallback(
    (selectedItem: string) => {
      if (!config) {
        return;
      }

      void (async () => {
        try {
          const url = getUrlForQuery(config, selectedItem);
          if (!url) throw new Error('Could not get products since an empty url was provided');
          const definedUrl: string = url as string;
          const authToken = getAccessTokenFromCookie();
          const content = await checkAxiosResponse(
            axios.get(definedUrl, { headers: { Authorization: `Bearer ${authToken}` }, validateStatus: null }),
            'Error while downloading data'
          );
          void message.success('Data download started!');
          window.location.href = ROOT_SERVER_URL + content;
        } catch (error) {
          void message.error('Failed to download data.');
          console.error('[useDataRequester] failed to generate download URL...', error);
        }
      })();
    },
    [config, getUrlForQuery]
  );

  return { processing, downloadedData, handleDownloadData };
}
