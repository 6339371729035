import React, { useCallback, useContext, useMemo, useState } from 'react';

import { SizeType } from 'antd/es/config-provider/SizeContext';
import { SorterCallback, SorterFactory } from '../../pages/data-query/components/outputs/_shared/table-utils';
import { DataQueryFormItemMetaAccordionControls } from '../../pages/types';
import { Collapse, CollapseProps, Table } from 'antd';
import useWebDataLoader from '../../hooks/use-web-data-loader';
import { UserDataLakeApi, UserDefinedTableOverview } from '../../api/user-data-lake-api';
import { showErrorNotification } from '../../../_shared/views/Notifications';
import { initUDTColumns } from './utils/CreateColumns';
import { LinkedError } from '@mst-fe/shared/dist/errors/linked-error';
import usePausingWebUpdater from './utils/use-pausing-web-updater';
import { CreateUserTableWorkflowContext } from '../../pages/workflow/workflows/create-user-table/create-user-table-workflow-context';
import ModifyUDTPermissionsDialog from './ModifyUDTPermissionsDialog';

type UserDefinedTablesProps = {
  tablesSize: SizeType;
  handleTableChangeCallbackFactory: SorterCallback;
  accordionActiveKeys: DataQueryFormItemMetaAccordionControls;
  onAccordionActiveKeysChanged: (key: string, openControls: string | string[]) => void;
  sorterFactory?: (tableName: string) => SorterFactory | undefined;
  includePublicData?: boolean;
};

const DEFAULT_PROPS = {
  sorterFactory: () => undefined,
};

export default function UserDefinedTablesView({
  tablesSize,
  sorterFactory = DEFAULT_PROPS.sorterFactory,
  handleTableChangeCallbackFactory,
  accordionActiveKeys,
  onAccordionActiveKeysChanged,
  includePublicData = false,
}: UserDefinedTablesProps) {
  const context = useContext(CreateUserTableWorkflowContext);
  const userDataLakeApi = useMemo(() => new UserDataLakeApi(), []);
  const [permissionsModalState, setPermissionsModalState] = useState<{ open: boolean; tableId: string; disabled: boolean }>({
    open: false,
    tableId: '',
    disabled: true,
  });

  const requestUrl = useMemo(() => {
    const url = userDataLakeApi.buildListTablesUrl({ includePublicData });

    return url;
  }, [includePublicData, userDataLakeApi]);

  const [webData, runUpdate] = useWebDataLoader<{ tables: UserDefinedTableOverview[] }>({ url: requestUrl });

  usePausingWebUpdater({
    selectedWorkflowId: context.uuid,
    runUpdate,
  });

  const handleTableDelete = useCallback(
    async (tableId: string) => {
      try {
        await userDataLakeApi.deleteTable({ id: tableId });
        runUpdate();
      } catch (error: unknown) {
        console.error('[DpActivity] failed to delete table...', error);
        showErrorNotification({
          message: 'Unable to delete the specified table...',
          description: error instanceof LinkedError ? error.message : undefined,
        });
      }
    },
    [runUpdate, userDataLakeApi]
  );

  const handleModifyPermissionsClick = (tableId: string, canShare: boolean) => {
    setPermissionsModalState({ open: true, tableId, disabled: !canShare });
  };

  const columnsForTableData = useMemo(
    () => initUDTColumns(handleTableDelete, handleModifyPermissionsClick, sorterFactory?.('columnsForTableData')),
    [handleTableDelete, sorterFactory]
  );

  const collapseItems: CollapseProps['items'] = [
    {
      key: 'tables',
      label: 'My User-Defined Tables',
      children: (
        <Table
          columns={columnsForTableData}
          dataSource={webData.data?.tables}
          loading={webData.loading}
          rowKey="id"
          size={tablesSize}
          scroll={{ x: true }}
          pagination={{ hideOnSinglePage: true }}
          onChange={handleTableChangeCallbackFactory('columnsForTableData')}
        />
      ),
    },
  ];

  return (
    <>
      <Collapse activeKey={accordionActiveKeys.tables} onChange={(e) => onAccordionActiveKeysChanged('tables', e)} items={collapseItems} />
      <ModifyUDTPermissionsDialog modalState={permissionsModalState} setModalState={setPermissionsModalState} runUpdate={runUpdate} />
    </>
  );
}
