import { createContext } from 'react';
import { DataQueryFormItemMeta } from '../pages/types';

export type DataQueryPageContextType = {
  configItemId?: string;
  updateMetaConfig: (delta: DataQueryFormItemMeta) => void;
  metaData?: DataQueryFormItemMeta;
};

const dataQueryPageContext: DataQueryPageContextType = {
  configItemId: undefined,
  updateMetaConfig: () => {},
  metaData: {},
};

export const DataQueryPageContext = createContext<DataQueryPageContextType>(dataQueryPageContext);
