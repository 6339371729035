import React from 'react';
import { Link } from 'react-router-dom';

import styles from './SidebarNameHeader.module.css';

function SidebarNameHeader({
  className,
  captionText = 'LSEG',
  captionTextLabel = 'LSEG',
  subHeaderText,
  subHeaderTextLabel,
  linkTarget,
}: {
  className: string;
  captionText: React.ReactNode | string;
  captionTextLabel?: string;
  subHeaderText?: React.ReactNode | string;
  subHeaderTextLabel?: string;
  linkTarget?: string;
}) {
  const core = (
    <div className={styles.headerContainer}>
      <h1 className={styles.subHeaderText} aria-label={captionTextLabel}>
        {captionText}{' '}
      </h1>
      {subHeaderText && (
        <h1 className={styles.headerText} aria-label={subHeaderTextLabel}>
          {subHeaderText}
        </h1>
      )}
    </div>
  );

  return linkTarget ? (
    <Link to={linkTarget} className={className}>
      {core}
    </Link>
  ) : (
    <div className={className}>{core}</div>
  );
}

export default SidebarNameHeader;
