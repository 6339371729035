import { useEffect, useMemo } from 'react';
import useWindowSize from './use-window-size';

export default function useResponsive() {
  const windowSize = useWindowSize();

  const isMobile = useMemo(() => windowSize.width < 640, [windowSize.width]);

  useEffect(() => {
    if (isMobile) {
      document.body.classList.remove('mobile');
      document.body.classList.add('mobile');
    } else {
      document.body.classList.remove('mobile');
    }
  }, [isMobile]);

  return { windowSize, isMobile };
}
