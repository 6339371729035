import { WebViewIpcClientBase } from './web-view-ipc-client-base';

type VsCodeWebApi = {
  postMessage(message: any): void;
};

export class WebViewIpcClientVsCode extends WebViewIpcClientBase {
  constructor(protected vsCodeApi: VsCodeWebApi, protected target?: any, protected sessionId: string = '') {
    super(target, sessionId);
  }

  override postMessage(message: any) {
    this.vsCodeApi.postMessage(message);
  }

  public static createClientProxy<T>(vsCodeApi: any, sessionId = ''): T {
    const instantiatedClient = new WebViewIpcClientVsCode(vsCodeApi, undefined, sessionId);
    instantiatedClient.startListening();
    return instantiatedClient.createProxy() as T;
  }
}
