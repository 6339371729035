import numeral from 'numeral';
import dayjs from 'dayjs';

export function DateTickFormatter(dataValue: number): string | number {
  if (!Number.isFinite(dataValue)) {
    return dataValue;
  }

  const date = dayjs.unix(dataValue);
  return date.utc().format('YYYY-MM-DD');
}

export function ValueTickFormatter(dataValue: number): string | number {
  if (!Number.isFinite(dataValue)) {
    return dataValue;
  }

  return dataValue.toLocaleString();
}

export function ValueTickNumericFormatter(dataValue: number): string | number {
  if (!Number.isFinite(dataValue)) {
    return dataValue;
  }

  return numeral(dataValue).format('0.0a');
}

export function TooltipContentFormatter(dataValue: number): string | number {
  if (!Number.isFinite(dataValue)) {
    return dataValue;
  }

  return dataValue.toLocaleString();
}

export function TooltipLabelFormatter(dataValue: number): string | number {
  if (!Number.isFinite(dataValue)) {
    return dataValue;
  }

  const date = dayjs.unix(dataValue);
  return date.utc().format('dddd,  DD-MMM-YYYY');
}

export const createNumeralFormatter =
  (format = '0,0.00') =>
  (dataValue: number): string | number => {
    if (!Number.isFinite(dataValue)) {
      return dataValue;
    }

    return numeral(dataValue).format(format);
  };
