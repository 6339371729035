import { useState, useEffect } from 'react';

const BREAKPOINTS = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

function getSize() {
  const width = window.innerWidth;
  return {
    height: window.innerHeight,
    width,
    isBelowXs: width < BREAKPOINTS.xs,
    isAboveXs: width >= BREAKPOINTS.xs,
    isBelowSm: width < BREAKPOINTS.sm,
    isAboveSm: width >= BREAKPOINTS.sm,
    isBelowMd: width < BREAKPOINTS.md,
    isAboveMd: width >= BREAKPOINTS.md,
    isBelowLg: width < BREAKPOINTS.lg,
    isAboveLg: width >= BREAKPOINTS.lg,
    isBelowXl: width < BREAKPOINTS.xl,
    isAboveXl: width >= BREAKPOINTS.xl,
    isBelowXxl: width < BREAKPOINTS.xxl,
    isAboveXxl: width >= BREAKPOINTS.xxl,
  };
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => setWindowSize(getSize());
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;
