import React, { ReactNode, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import getUserGuidePath from '../../../utils/user-guide';

import styles from './InfoDialog.module.css';

export interface InfoDialogDelegate {
  closeDialog(): void;
}

export function InfoDialog({
  model,
  delegate,
}: {
  model: { title: string; content: ReactNode; docsSection?: string; docsPath?: string; isLarge?: boolean };
  delegate: InfoDialogDelegate;
}) {
  const docsUrl = getUserGuidePath(model.docsPath);
  const closeDialog = useCallback(() => delegate.closeDialog(), [delegate]);

  useEffect(() => {
    function handlePressEscape(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        closeDialog();
      }
    }

    window.addEventListener('keydown', handlePressEscape, true);
    return () => {
      window.removeEventListener('keydown', handlePressEscape, true);
    };
  }, [closeDialog]);

  return (
    <Modal
      data-testid="info-dialog"
      title={model.title ?? 'Info'}
      open
      closable={false}
      maskClosable={false}
      onCancel={closeDialog}
      footer={[
        <Button key="close" onClick={closeDialog}>
          Close
        </Button>,
      ]}
      width={model.isLarge ? window.innerWidth * 0.7 : undefined}
      className={model.isLarge ? styles.isLarge : ''}
    >
      <div className={styles.bodyContent}>
        {model.content}
        <p>
          {model.docsSection ? (
            <>
              For further information, please refer to section <i>&quot;{model.docsSection}&quot;</i> of the{' '}
              <Link to={docsUrl}>
                documentation <ExportOutlined />
              </Link>
              .
            </>
          ) : (
            <>
              For further information, go to the{' '}
              <Link to={docsUrl}>
                documentation <ExportOutlined />
              </Link>
              .
            </>
          )}
        </p>
      </div>
    </Modal>
  );
}
