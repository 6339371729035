import { AptSearchResult, WbApi } from '../workbench-api';

export interface InstallPackages {
  packageSearchTerm: string;
  packageSearchResults: AptSearchResult[];
  selectedPackages: string[];
  existingPackages?: string[];
  isLoading: boolean;
}

export function installPackagesEmpty(): InstallPackages {
  return { packageSearchResults: [], packageSearchTerm: '', selectedPackages: [], isLoading: false };
}

export async function installPackagesSearch(
  api: WbApi,
  getModel: () => InstallPackages | undefined,
  update: (_: InstallPackages) => void,
  term: string
): Promise<void> {
  let model = getModel();
  if (!model) {
    return;
  }

  update({ ...model, packageSearchTerm: term });
  const results = await api.searchPackages(term);
  if (!results) {
    // ignore the error
    return;
  }

  model = getModel();
  if (!model) {
    return;
  }

  // remove already selected or installed packages from results
  const alreadyInstalled = new Set(model.selectedPackages.concat(model.existingPackages ?? []));
  const packageSearchResults = results.filter((r) => !alreadyInstalled.has(r.name));

  update({ ...model, packageSearchResults, isLoading: false });
}

export function installPackagesSetSelected(model: InstallPackages | undefined, selectedPackages: string[]): InstallPackages | undefined {
  if (!model) {
    return model;
  }

  return { ...model, selectedPackages, packageSearchResults: [], packageSearchTerm: '' };
}
