import React from 'react';
import { Link } from 'react-router-dom';
import { Button, MenuProps } from 'antd';
import { ShoppingCartOutlined, ShoppingOutlined } from '@ant-design/icons';

import { CogIcon } from '@heroicons/react/24/outline';

import { JobsList } from '../models/jobs-list';

import { JobListItem } from '../jobs-api';

import { JOBS_ITEM_KEY, JOBS_ITEM_KEY_PREFIX } from '../../../side-nav/SideNav';

import styles from './JobsNav.module.css';
import sharedStyles from '../../../side-nav/SideNav.shared.module.css';

function JobsNavItem({ model }: { model: JobListItem }) {
  const path = `/jobs/${model.id}`;

  return (
    <div className={`${sharedStyles.navLabel} sidebar-nav-item-title-icon-container`}>
      <Link to={path}>{model.name}</Link>
      <div className={`${styles.jobActivation} sidebar-nav-item-service-status`}>{model.enabled ? 'ENABLED' : 'DISABLED'}</div>
    </div>
  );
}

function JobsNav() {
  return (
    <div data-testid="side-nav-jobs">
      <div className="sidebar-nav-item-title-icon-container">
        <div>Jobs</div>
      </div>
    </div>
  );
}

function getJobsNavItems(model: JobsList) {
  return model.jobs.length > 0
    ? model.jobs.map((job) => ({
        label: <JobsNavItem model={job} />,
        icon: <CogIcon height={16} width={16} />,
        key: `${JOBS_ITEM_KEY_PREFIX}${job.id}`,
        className: sharedStyles.fullHeightNavItem,
        title: job.name,
      }))
    : [
        {
          label: model.state === 'loading' ? 'Loading…' : 'No installed jobs',
          key: `${JOBS_ITEM_KEY_PREFIX}empty`,
          disabled: true,
        },
      ];
}

export function getJobsNav({ model }: { model: JobsList }): Required<MenuProps>['items'][number] {
  return {
    label: <JobsNav />,
    key: JOBS_ITEM_KEY,
    icon: <ShoppingOutlined />,
    children: getJobsNavItems(model),
  };
}
