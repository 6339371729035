import React from 'react';
import dayjs from 'dayjs';
import AntdColumn, { ColumnProps } from 'antd/lib/table/Column';
import { CompareFn } from 'antd/lib/table/interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SorterEnhancedColumnParams = ColumnProps<any> & {
  dataIndex: string;
  hasCustomSorter?: boolean;
  title?: string;
  key?: string;
};

type SorterData = {
  [key: string]: number;
};

const knownDateColumns = ['date', 'dateticks', 'datetime', 'time', 'timestamp'];

const defaultSort = (a: number, b: number) => {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};

const withSorter = (Column: typeof AntdColumn) => {
  function EnhancedColumn({ hasCustomSorter = false, ...rest }: SorterEnhancedColumnParams) {
    const props = { hasCustomSorter, ...rest };

    const handleSort: CompareFn<SorterData> = (a: SorterData, b: SorterData) => {
      try {
        const { dataIndex } = props;

        if (knownDateColumns.includes(dataIndex.toLowerCase()) && dayjs(a[dataIndex]).isValid()) {
          return dayjs(a[dataIndex]).diff(b[dataIndex]);
        }

        return defaultSort(a[dataIndex], b[dataIndex]);
      } catch (error) {
        console.log('[withSorter] Could not sort table', error);
        return 0;
      }
    };

    return <Column {...props} sorter={!hasCustomSorter ? handleSort : true} />;
  }

  return EnhancedColumn;
};

export default withSorter;
