import { NotificationListControlDelegate } from './views/NotificationsListControl';
import { NotificationsApi } from './notifications-api';
import {
  createSetNotificationRead,
  initialNotificationsListModel,
  NotificationsListModel,
  updateNotificationList,
} from './models/notifications-list';
import { loadNotifications, NotificationItem } from './models/notification';
import { PeriodicRequest } from '../../../services/periodic-request';
import { handleError } from '../../../services/error-notification';
import { showNotification } from '../../../_shared/views/Notifications';
import { SHORT_POLLING_INTERVAL } from '../../../constants';

export class NotificationController implements NotificationListControlDelegate {
  private orgApi = new NotificationsApi();

  private model = initialNotificationsListModel();

  private notificationsPeriodicRequest: PeriodicRequest<unknown>;

  private disposeCallbacks: (() => void)[] = [];

  private notificationCallbacks: ((notification: NotificationItem) => Promise<void>)[] = [];

  constructor(private updateViewState: (_: NotificationsListModel) => void) {
    this.notificationsPeriodicRequest = new PeriodicRequest<NotificationItem[] | undefined>({
      interval: SHORT_POLLING_INTERVAL,

      onPeriodicRequest: async () => await loadNotifications(this.orgApi, () => this.model.notifications),

      onPeriodicRequestResult: (value) => {
        if (value !== undefined) {
          this.update(updateNotificationList(this.model, value));
        }
      },
    });
    this.disposeCallbacks.push(this.notificationsPeriodicRequest.start());
  }

  private update = (model: NotificationsListModel) => {
    const existingModel = this.model;
    this.model = model;
    this.updateViewState(this.model);
    this.triggerNotifications(existingModel, model);
  };

  dispose() {
    for (const cb of this.disposeCallbacks) {
      cb();
    }
    this.disposeCallbacks = [];
  }

  async onSetNotificationRead(notificationId?: number): Promise<void> {
    const newModel = await createSetNotificationRead(this.model, this.orgApi, handleError, notificationId ?? undefined);
    this.update(newModel);
  }

  private triggerNotifications(existingModel: NotificationsListModel, newModel: NotificationsListModel) {
    if (existingModel.loading && !newModel.loading) {
      return;
    }

    const ids = existingModel.notifications.map(({ id }) => id);
    const newAlerts = newModel.notifications.filter(({ id }) => !ids.includes(id));
    if (newAlerts.length === 0) {
      return;
    }

    for (const alert of newAlerts) {
      const onClick = alert.referencedEntity
        ? (event?: MouseEvent) => {
            event?.stopPropagation();
            event?.preventDefault();

            void this.onNotificationClicked?.(alert.id);
          }
        : undefined;

      showNotification({
        type: 'info',
        key: `notification_${alert.id}`,
        message: alert.title,
        description: alert.description,
        onClick,
      });
    }
  }

  async onNotificationClicked(notificationId: number): Promise<void> {
    const notification = this.model.notifications.find(({ id }) => id === notificationId);
    if (!notification || this.notificationCallbacks.length === 0) {
      return;
    }

    await this.notificationCallbacks[0](notification);
  }

  onNotificationInvoked(notificationHandler: ((notification: NotificationItem) => Promise<void>) | undefined): void {
    this.notificationCallbacks.length = 0;

    if (notificationHandler) {
      this.notificationCallbacks.push(notificationHandler);
    }
  }
}
