import flattenDeep from 'lodash/flattenDeep';
import compact from 'lodash/compact';
import dayjs from 'dayjs';
import { DataQueryConfigQuery } from '../pages/types';

export const convertToBookJoinQuery = (input: DataQueryConfigQuery[], queryType?: string) => {
  return input.map(({ group, feed, value, level, time, offset }) => {
    const bookQueryFormExtraInputs = [group.toLowerCase(), '[', feed, '].', value, ','];
    const output = [
      queryType === 'BookQueryForm' ? [...bookQueryFormExtraInputs] : [],
      group.toLowerCase(),
      '[',
      feed,
      '].',
      value,
      level ? [level] : [],
      time ? ['{', time.replace(/(\._+)+| /g, ''), '}'] : [],
      offset ? ['{', offset >= 0 ? '+' : '', offset, '}'] : [],
    ];
    return compact(flattenDeep(output)).join('');
  });
};

export const timestampToDateTime = (timestamp: number) => {
  if (!timestamp) {
    return '';
  }

  const stringTimeStamp = timestamp.toString();
  const dateAndTime = dayjs(Number(stringTimeStamp.substring(0, 13))).format('YYYYMMDD HH:mm:ss');
  const precision = stringTimeStamp.substring(13);

  return `${dateAndTime}.${precision}`;
};
