import React, { useEffect } from 'react';

import { Form, Input, DatePicker } from 'antd';

import { PRODUCT_COLUMNS_TO_SEARCH } from '../_shared/advanced-search-constants';
import { AMERICAN_DATE_FORMAT } from '../../utils/midas-constants';
import { AdvancedProductSearchCriteria } from './AdvancedProductSearchForm';
import { FormInstance } from 'antd/lib';

type ProductDataFormFieldsParams = {
  formInstance: FormInstance;
  resetProducts: () => void;
  setSearchCriteria: (searchCriteria: AdvancedProductSearchCriteria[]) => void;
  knownColumns?: { name: string }[];
};

export default function ProductDataFormFields({
  formInstance,
  resetProducts,
  setSearchCriteria,
  knownColumns,
}: ProductDataFormFieldsParams) {
  const searchDate = Form.useWatch('productSearchDate', formInstance);
  const searchFragment = Form.useWatch('productSearchText', formInstance);

  useEffect(() => {
    if (!searchDate || !searchFragment || !knownColumns) {
      return;
    }

    // Make sure available columns includes the columns we need to search against
    const filteredColumnsToSearch = PRODUCT_COLUMNS_TO_SEARCH.filter(({ column }) => knownColumns.some(({ name }) => name === column));

    if (filteredColumnsToSearch.length !== PRODUCT_COLUMNS_TO_SEARCH.length) {
      console.warn('[ProductDataForm] Known columns do not align with columns to search', {
        available: filteredColumnsToSearch.map(({ column }) => column).join(','),
        intended: PRODUCT_COLUMNS_TO_SEARCH.map(({ column }) => column).join(','),
      });
    }

    setSearchCriteria([
      {
        field: 'date',
        type: 'date',
        value: searchDate,
      },
      ...filteredColumnsToSearch.map(({ column: field, searchMethod }) => ({
        field,
        value: searchFragment,
        searchMethod,
      })),
      {
        field: 'correlation',
        value: `date+,(${filteredColumnsToSearch.map(({ column }) => column).join('|,')})`,
      },
    ]);
  }, [searchDate, searchFragment, setSearchCriteria, knownColumns]);

  useEffect(() => {
    if (!searchFragment?.trim()) {
      resetProducts();
    }
  }, [searchFragment, resetProducts]);

  return (
    <>
      <Form.Item
        label="Product"
        name="productSearchText"
        rules={[{ required: true, message: 'Enter a product name or ticker!' }]}
        labelAlign="left"
      >
        <Input placeholder="Enter a product name or ticker" />
      </Form.Item>
      <Form.Item label="Date" labelAlign="left" name="productSearchDate" rules={[{ required: true, message: 'Enter a search date' }]}>
        <DatePicker format={AMERICAN_DATE_FORMAT} aria-label="Date picker" />
      </Form.Item>
    </>
  );
}
