import Modal from 'antd/lib/modal/Modal';
import React, { useCallback } from 'react';
import { useController } from '../../../hooks/use-controller';
import { UsageWarningController } from '../usage-warning-controller';
import { initialUsageModel, UsageModel } from '../models/usage-model';

export interface UsageWarningScreenDelegate {
  onAcknowledged(): void;
}

const levelToMessage = {
  NOTIFY_WARN: 'You are close to reaching your allowed monthly resource usage.',
  NOTIFY_CRITICAL:
    'You are critically close to reaching your allowed monthly resource usage. If you reach your allowed monthly usage, your resources will be stopped, but your stored data will remain safe.',
  NOTIFY_OVERAGE: 'You have reached your allowed monthly resource usage. Overage charges will be applied.',
  SHUTDOWN:
    'You have reached your allowed monthly resource usage. Your resources have been temporarily stopped, but your stored data remains safe. You will be able to re-start your resources next month.',
};

function UsageWarningModalView({ model, delegate }: { model: UsageModel; delegate: UsageWarningScreenDelegate }) {
  const {
    supportEmailAddress,
    branding: { instanceName },
  } = window.CONFIG;

  const onClose = useCallback(() => delegate.onAcknowledged(), [delegate]);

  if (!model.level) {
    return null;
  }

  return (
    <Modal title="Usage Warning" maskClosable={false} open onOk={onClose} onCancel={onClose}>
      <p>{levelToMessage[model.level]}</p>
      <p>
        Please contact our support team, at{' '}
        <a href={`mailto:${supportEmailAddress}?subject=${instanceName}%20Usage%20Quota`}>{supportEmailAddress}</a> for further assistance
        or to request a quota increase.
      </p>
    </Modal>
  );
}

export function UsageWarningScreen() {
  const { model, delegate } = useController(initialUsageModel, UsageWarningController);
  return delegate ? <UsageWarningModalView model={model} delegate={delegate} /> : null;
}
