import React, { useState, useEffect } from 'react';
import { Button, Form, FormInstance } from 'antd';

import SelectEquityControl from './controls/SelectEquityControl';
import MultipleSwitchesControl from './controls/MultipleSwitchesControl';
import { SingleDateAndTimeRangeFormControl } from './controls/SingleDateAndTimeRangeControl';
import DownloadButton from './controls/DownloadButton';
import { preventEnterKeyDefault, shouldChangesDisableProducts, shouldDisableProducts } from './data-query-form-utils';
import { FORM_LAYOUT_LABEL } from '../../../../utils/midas-constants';

import { getUrlForQuery } from '../outputs/TicksOutput';

import classes from './shared.module.css';
import { DataQueryConfig } from '../../../types';

type TicksFormParams = {
  config: DataQueryConfig;
  formInstance: FormInstance;
  onFinish: (values: unknown) => void;
  onDownload: (selectedItem: string) => void;
};

type TicksMessages = {
  [group: string]: string[] | TicksGroup;
};

type TicksGroup = {
  messages: string[];
  feeds: { [feed: string]: string[] };
};

const DEPTH_GROUP_MESSAGES = ['ADD', 'MOD', 'TRD', 'IMB'];

const SIP_AND_TAQ = {
  messages: ['QUO', 'STA', 'TRD'],
  feeds: {
    CTA: ['QUO', 'STA'],
    CTS: ['TRD'],
    UTDF: ['TRD'],
    UQDF: ['QUO', 'STA'],
  },
};

// Conventions:
// - If all feeds under a group share same messages with the group,
//      an array with available messages is returned by the group
// - If excel doesn't specify messages for specific group/feed,
//      empty array is returned which corresponds to all available messages
const AVAILABLE_MESSAGES: TicksMessages = {
  direct: {
    messages: [...DEPTH_GROUP_MESSAGES, 'QUO'],
    feeds: {
      IEX: ['QUO', 'TRD'],
      IEXDEEP: DEPTH_GROUP_MESSAGES,
      PSX: DEPTH_GROUP_MESSAGES,
      ARCA: DEPTH_GROUP_MESSAGES,
      BATS: DEPTH_GROUP_MESSAGES,
      EDGA: DEPTH_GROUP_MESSAGES,
      EDGX: DEPTH_GROUP_MESSAGES,
      CHX: DEPTH_GROUP_MESSAGES,
      INET: DEPTH_GROUP_MESSAGES,
      ULTRA: [],
      AMEX: DEPTH_GROUP_MESSAGES,
      NYSE: DEPTH_GROUP_MESSAGES,
      BX: DEPTH_GROUP_MESSAGES,
      NSX: DEPTH_GROUP_MESSAGES,
      BATSY: DEPTH_GROUP_MESSAGES,
      MIAX: DEPTH_GROUP_MESSAGES,
      MEMX: DEPTH_GROUP_MESSAGES,
    },
  },
  sip: SIP_AND_TAQ,
  taq: SIP_AND_TAQ,
  futures: DEPTH_GROUP_MESSAGES,
  fi: [],
  crypto: [],
  cme: [],
};

const extractMessagesFromFeed = (feed = '') => {
  const [group, maybeFeed] = feed.split('/') as [keyof typeof AVAILABLE_MESSAGES, string];

  const groupMessages = AVAILABLE_MESSAGES[group];

  if (!groupMessages) {
    return [];
  }

  if (Array.isArray(groupMessages)) {
    return groupMessages;
  }

  const feedMessages = (AVAILABLE_MESSAGES[group] as TicksGroup).feeds?.[maybeFeed];

  if (!feedMessages) {
    return groupMessages.messages;
  }

  return feedMessages;
};

const DATE_FIELD_NAME = 'dateAndTimeRange';

const DEFAULT_PROPS = {
  onFinish: () => {},
};

export default function TicksForm({ config, formInstance, onFinish = DEFAULT_PROPS.onFinish }: TicksFormParams) {
  const formValue = Form.useWatch([], formInstance);

  const [availableMessages, setAvailableMessages] = useState<string[]>([]);
  const [disableProducts, setDisableProducts] = useState(false);
  const [tickFormValid, setTickFormValid] = useState(false);

  const setFormValidity = async () => {
    try {
      await formInstance.validateFields({ validateOnly: true });
      setTickFormValid(true);
    } catch (errorInfo: unknown) {
      setTickFormValid((errorInfo as { errorFields: unknown[] }).errorFields?.length === 0);
    }
  };

  const handleValueChange = (changedValues: { feed: unknown }) => {
    if (changedValues.feed) {
      formInstance.setFieldsValue({
        messages: ['ALL'],
      });
    }
  };

  useEffect(() => {
    if (!formValue) return;

    const shouldDisable = shouldChangesDisableProducts({
      formInstance,
      changedValues: formValue,
      dateFieldName: DATE_FIELD_NAME,
    });

    setDisableProducts(shouldDisable);

    if (formValue.feed) {
      const feedMessages = extractMessagesFromFeed(formValue.feed);
      setAvailableMessages(feedMessages);
    }
    setTimeout(async () => await setFormValidity());
  }, [formValue]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/require-await
    const fetchData = async () => {
      const shouldDisable = shouldDisableProducts({ formInstance, dateFieldName: DATE_FIELD_NAME, includeFeed: false });
      setDisableProducts(shouldDisable);

      setAvailableMessages(extractMessagesFromFeed(config.feed));

      setTimeout(async () => await setFormValidity());
    };

    void fetchData();
  }, [config]);

  return (
    <Form
      form={formInstance}
      layout="horizontal"
      labelCol={FORM_LAYOUT_LABEL}
      onFinish={onFinish}
      onValuesChange={handleValueChange}
      labelWrap
      onKeyDown={preventEnterKeyDefault}
    >
      <SingleDateAndTimeRangeFormControl />
      <SelectEquityControl config={config} disabled={disableProducts} dateFieldName={DATE_FIELD_NAME} form={formInstance} />

      <Form.Item
        dependencies={['feed']}
        label="Messages"
        name="messages"
        rules={[{ required: true, message: 'Please select at least one message type.' }]}
        help="The message types to show. Select ALL to view all results, even those without a message type."
        labelAlign="left"
      >
        <MultipleSwitchesControl
          showAll
          allDisablesOthers={false}
          values={['ADD', 'MOD', 'TRD', 'QUO', 'IMB', 'STA', 'LVL']}
          availableValues={availableMessages}
        />
      </Form.Item>
      <Form.Item>
        <Button className={classes.applyButtonContainer} htmlType="submit" type="primary" disabled={!tickFormValid}>
          Run Query
        </Button>
        <DownloadButton downloadQuery={getUrlForQuery} isFormValid={tickFormValid} formInstance={formInstance} />
      </Form.Item>
    </Form>
  );
}
