import { WbInstanceStatus } from '../../instances/workbench-api';
import { SharePermissions, WbServiceData, WbServiceStatus, WbServiceType } from '../services-api';

export interface WbService {
  id: string;
  serverId: string;
  serverName: string;
  name: string;
  statusOverride: WbServiceStatus | undefined;
  type: WbServiceType;
  status: WbServiceStatus;
  instanceStatus?: WbInstanceStatus;
  url: string;
  shortId: string;
  wbName: string;
  ownedByUser: boolean;
  isDefaultServer: boolean;
  isPublishedByYou: boolean;
  isSharedToOthers: boolean;
  isInstalledFromStore: boolean;
  ownerInfo?: {
    displayName: string;
    emailAddress: string;
  };
  documentationUrl?: string | undefined;
  publishedModuleId?: string;
  permissions?: SharePermissions;
  logo?: string;
}

export function serviceFromData(existingData: WbService[], data: WbServiceData): WbService {
  const matchingRecord = existingData.find((d) => d.id === data.id);
  return {
    ...data,
    statusOverride: matchingRecord?.statusOverride,
  };
}

export function canDeployService(service: WbService): boolean {
  return service.status === 'RUNNING' || service.status === 'STOPPED' || service.status === 'FAILED';
}

export function canStopService(service: WbService): boolean {
  return service.status === 'RUNNING' || service.status === 'FAILED';
}

export function canDeleteService(service: WbService): boolean {
  return service.status === 'STOPPED';
}

export function canShareService(service: WbService): boolean {
  return service.status === 'RUNNING';
}
