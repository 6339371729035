import React, { useCallback, useContext, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { AutoComplete } from 'antd';
import { useNavigate } from 'react-router-dom';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';

import { GlobalSearchModel } from '../models/global-search';
import { getInstanceUrl } from '../../shared/instance-mapping';
import { AppThemeContext } from '../../../hooks/use-theme';

import { SearchResult } from '../search-api';

import styles from './GlobalSearchView.module.css';

export interface GlobalSearchViewDelegate {
  onGlobalSearch(): Promise<void>;

  onSearchTermChange(term: string): void;

  clearSearchResults(): void;
}

export interface AutoCompleteOption {
  key: string;
  value: string;
  href: string | { externalLink: string };
  label?: unknown;
}

function renderSourceTitle(title: string) {
  return <span className={styles.globalSearchResultGroupTitle}>{title}</span>;
}

function renderItem(type: string, item: SearchResult): AutoCompleteOption {
  let href: string | { externalLink: string };

  switch (type) {
    case 'instances':
      href = { externalLink: getInstanceUrl(item.id) };
      break;
    case 'resources':
      href = `/resources#resource-${item.id}`;
      break;
    case 'dashboards':
      href = `/dashboard/${item.id}`;
      break;
    case 'tasks':
      href = `/jobs/${item.id}`;
      break;
    case 'applications':
      href = `/applications/${item.id}`;
      break;
    default:
      href = '';
      break;
  }

  // Add a `label` if you want the appearance to be something other than the 'value'
  return {
    key: `${type}-${item.id}`,
    href,
    value: item.title,
  };
}

function mapSearchResultsToAutoCompleteGroups(model: GlobalSearchModel) {
  const { searchResults, status } = model;
  if (!searchResults.length && status === 'ready') {
    return [{ label: 'No results matching the search term' }];
  }

  return searchResults.map((source) => ({
    label: renderSourceTitle(source.label),
    title: source.label,
    options: source.searchResults.map((searchResult) => renderItem(source.label, searchResult)),
  }));
}

export function GlobalSearchView({ model, delegate }: { model: GlobalSearchModel; delegate: GlobalSearchViewDelegate }) {
  const navigate = useNavigate();
  const onSelect = useCallback(
    (value: string, option: AutoCompleteOption) => {
      delegate.clearSearchResults();
      if (typeof option.href === 'string') {
        navigate(option.href);
      } else {
        window.open(option.href.externalLink, option.key);
      }
    },
    [navigate, delegate]
  );
  const onSearch = useCallback(() => delegate.onGlobalSearch(), [delegate]);
  const onChange = useCallback((value: string) => delegate.onSearchTermChange(value), [delegate]);
  const debouncedOnSearch = useMemo(() => debounce(onSearch, 500), [onSearch]);
  const options = mapSearchResultsToAutoCompleteGroups(model);
  const { theme } = useContext(AppThemeContext);
  const style = {
    border: `1px solid ${theme === 'light' ? '#595959' : 'white'}`, // was rgb(171,173,178)
    backgroundColor: theme === 'light' ? 'white' : 'var(--gray-12)',
  };

  return (
    <AutoComplete
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore ts thinks because of onSelect that options are AutoCompleteOption...
      options={options}
      maxLength={40}
      className={styles.globalSearch}
      onSearch={debouncedOnSearch}
      popupClassName={styles.globalSearch}
      onSelect={onSelect}
      suffixIcon={<SearchOutlined />}
      listHeight={800}
      value={model.searchTerm}
      onChange={onChange}
      placeholder={'Search...'}
      aria-label="Search in the website"
      id="globalSearch"
      style={style}
    ></AutoComplete>
  );
}
