export const joinWithAmpersand = (values: string[]) => {
  if (!values || values.length === 0) {
    return '';
  }
  if (values.length === 1) {
    return values[0];
  }

  const v = [...values];
  const end = v.pop();
  return `${v.join(', ')} & ${end}`;
};

export const humanFileSize = (bytes: number, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const createCommonDateFormatter =
  (emptyText = 'Missing', errorText = 'Bad Date') =>
  (date?: string) => {
    if (!date) {
      return emptyText;
    }

    const parsedDate = new Date(date);
    if (!parsedDate || Number.isNaN(parsedDate)) {
      return errorText;
    }
    // example output: 12/17/20, 5:05 AM
    return new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(parsedDate);
  };
