import React, { useCallback, useEffect, useState } from 'react';

import { Button, Form, Select, Space, Spin } from 'antd';
import debounce from 'lodash/debounce';
import defer from 'lodash/defer';
import unique from 'lodash/uniq';

import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';
import axios from 'axios';

import { ADVANCED_SEARCH_DISABLED_FEED_FAMILIES, PRODUCTS_QUERY_URL } from '../../../../../utils/midas-constants';
import AdvancedProductSearchModal from '../../../../../modals/AdvancedProductSearchModal';
import * as QueryTypes from '../../query-types';
import SelectFeedControl from './SelectFeedControl';
import SearchPlaceholder, { notFoundContent } from '../../../../../components/SearchPlaceholder';

import { DataQueryConfig, DataQueryProducts, DataQueryFeed } from '../../../../types';
import { FormInstance } from 'antd/es/form/Form';
import { Dayjs } from 'dayjs';
import { getAccessTokenFromCookie } from '../../../../../../services/auth';

type SelectEquityControlParams = {
  config?: DataQueryConfig;
  disabled?: boolean;
  dateFieldName?: string;
  form: FormInstance;
  excludeFeedFamilies?: string[];
  showSelectFeedControl?: boolean;
  selectFamily?: boolean;
  selectFeed?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDateByFieldName = (dateFieldName: string, dateField: any): Dayjs => {
  switch (dateFieldName) {
    case 'date':
      return dateField;
    case 'dateRange':
      return dateField.length && dateField[0];
    case 'dateAndTimeRange':
      return dateField?.date;
    default:
      return dateField;
  }
};

export default function SelectEquityControl({
  config,
  disabled,
  dateFieldName = '',
  form,
  excludeFeedFamilies = [],
  showSelectFeedControl = true,
  selectFamily = true,
  selectFeed = true,
}: SelectEquityControlParams) {
  const [advancedSearchDisabled, setAdvancedSearchDisabled] = useState(true);
  const [products, setProducts] = useState<DataQueryProducts>({ list: [], busy: false, error: undefined });
  const [searchModalVisible, setSearchModalVisible] = useState(false);

  const getProductsForDate = useCallback(async (source: 'twxm' | 'lake', formSources: string, queryText: string, date: Dayjs) => {
    // Note: MIDAS API only supports get products for a single date
    const authToken = getAccessTokenFromCookie();
    if (!authToken) throw new Error('Could not get products since an empty auth token was provided');

    const response: string[] | undefined =
      queryText && date
        ? await checkAxiosResponse(
            axios.post(
              PRODUCTS_QUERY_URL,
              { source, type: formSources, date: date.toString(), filter: queryText },
              { headers: { Authorization: `Bearer ${authToken}` }, validateStatus: null }
            ),
            'Error while getting products'
          )
        : [];

    if (!response) {
      throw new Error('Error while getting products');
    }

    // mapping list of products to what the view expects
    const result = response.map((p: string) => ({
      key: p,
      value: p,
    }));

    return result;
  }, []);

  const handleLoadProducts = useCallback(
    debounce((queryText) => {
      setProducts({ ...products, busy: true, error: undefined });

      // TODO: fix this later (quick first pass for speed), but when we don't want to show the feed control (e.g. for
      // Daily queries, check to see if the product is in the direct feed).
      const feedSource = form.getFieldValue('source');
      const feedFamily = showSelectFeedControl ? form.getFieldValue('feed') : 'direct';
      defer(async () => {
        try {
          const startDate = getDateByFieldName(dateFieldName, form.getFieldValue(dateFieldName));
          const list = await getProductsForDate(feedSource, feedFamily, queryText, startDate);

          setProducts({ list, busy: false, error: undefined });
        } catch (error: unknown) {
          console.error('[SelectEquityControl] failed to load products from server...', error);
          setProducts({ list: [], busy: false, error });
        }
      });
    }, 250),
    [products, setProducts, form, config]
  );

  const handleSelectItem = useCallback(() => {
    // force a close of the selected dropdown.
    // the  rc-select/antd control is very opinionated on how it should work, so we hack around that
    // by forcing an event to be raised which fakes a click outside the dropdown.

    // see, we're not hacky programmers here.

    // https://github.com/react-component/select/blob/master/src/hooks/useSelectTriggerControl.ts
    setTimeout(() => {
      const mouseDownEvent = document.createEvent('MouseEvents');
      const target = document.getElementsByTagName('body').item(0)!;
      mouseDownEvent.initMouseEvent('mousedown', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, target);
      target.dispatchEvent(mouseDownEvent);
    }, 50);
  }, []);

  useEffect(() => {
    if (!config) {
      return;
    }

    const { feed, queryType } = config;
    if (queryType === QueryTypes.DailyQuery) {
      setAdvancedSearchDisabled(false);
    } else if (!feed) {
      setAdvancedSearchDisabled(true);
    } else {
      const feedFamily = feed.split('/')?.[0];
      setAdvancedSearchDisabled(ADVANCED_SEARCH_DISABLED_FEED_FAMILIES.includes(feedFamily));
    }
  }, [config]);

  const onSearchComplete = (addedProducts: string[]) => {
    setSearchModalVisible(false);

    const currentProducts = form.getFieldValue('products');
    const uniqueProducts = unique([...currentProducts, ...addedProducts]);
    form.setFieldValue('products', uniqueProducts);

    // TODO: check to see if products are within selected feed.
  };

  const onSelectFamilyOrFeed = (value: string, { feedFamily }: DataQueryFeed) => {
    setAdvancedSearchDisabled(ADVANCED_SEARCH_DISABLED_FEED_FAMILIES.includes(feedFamily!));
  };

  const onToggleModalVisible = () => {
    setSearchModalVisible((visible) => !visible);
  };

  // If we add the modal toggle button to the same Form.Item as the Select below,
  // the form fields will not bind correctly!
  // See the following: https://ant.design/components/form/#components-form-demo-complex-form-control
  return (
    <>
      <SelectFeedControl
        form={form}
        onSelectFamilyOrFeed={onSelectFamilyOrFeed}
        showSelectFeedControl={showSelectFeedControl}
        selectFamily={selectFamily}
        selectFeed={selectFeed}
        excludeFamilies={excludeFeedFamilies}
      />
      <Form.Item htmlFor="productsSearch" required={true} label="Products" labelAlign="left">
        <Space.Compact className="w-full">
          <Form.Item noStyle>
            <Button disabled={advancedSearchDisabled} onClick={onToggleModalVisible} style={{ flex: '0 0 145px' }}>
              Advanced Search
            </Button>
          </Form.Item>
          <Form.Item
            noStyle
            dependencies={['feed']}
            name="products"
            data-testid="data-query-products"
            trigger="onChange"
            valuePropName="value"
            extra={disabled && `You need to first select a date ${showSelectFeedControl ? 'and feed ' : ''}to be able to fill in products.`}
            rules={[
              { required: true, message: 'Please select one or more products.' },
              () => ({
                async validator(rule, value) {
                  if (!value || value.length === 0 || config?.queryType === QueryTypes.TicksQuery) {
                    return;
                  }

                  if (value.length > 5) {
                    // eslint-disable-next-line
                    return Promise.reject(`You cannot choose more than 5 products, please remove at least ${value.length - 5} products.`);
                  }
                },
              }),
            ]}
          >
            <Select
              id="productsSearch"
              aria-label="Products"
              loading={products.busy}
              mode="multiple"
              disabled={disabled}
              onSearch={handleLoadProducts}
              placeholder={<SearchPlaceholder text="Please enter an equity by name or symbol..." />}
              notFoundContent={products.busy ? <Spin size="small" /> : products.list.length ? null : notFoundContent()}
              onSelect={handleSelectItem}
            >
              {products.list.map((tickerOption) => (
                <Select.Option key={tickerOption.value} value={tickerOption.value} data-testid={'products-menu-item-' + tickerOption.value}>
                  {tickerOption.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Space.Compact>
      </Form.Item>
      <AdvancedProductSearchModal onSearchCancel={onToggleModalVisible} onSearchComplete={onSearchComplete} visible={searchModalVisible} />
    </>
  );
}
