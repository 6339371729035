import React from 'react';

import { Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import styles from './Error.module.css';

type ErrorParams = {
  errors?: unknown;
  caption?: string;
  className?: string;
};

const DEFAULT_PROPS: { errors: unknown[] } = {
  errors: [],
};

export default function ErrorComponent({ errors = DEFAULT_PROPS.errors, caption = 'Error', className }: ErrorParams) {
  if (!errors || (Array.isArray(errors) && errors.length === 0)) {
    return null;
  }

  const rawErrors = Array.isArray(errors) ? errors : [errors];
  const allErrors = rawErrors.filter((error) => error);
  if (allErrors.length === 0) {
    return null;
  }
  const typedErrors = allErrors as { error?: string | { message: string }; message?: string }[];

  return (
    <Space className={`${styles.errorContainer} ${className}`}>
      <ExclamationCircleOutlined />
      {typedErrors.map((error, index) => {
        const errorDescription =
          (error?.error as { message: string })?.message ||
          error?.error ||
          error.message ||
          error ||
          'Failed to retrieve data from server.';
        return (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`server-error-${index}`}>
            <>
              <strong>{caption}: </strong>
              {errorDescription}
            </>
          </span>
        );
      })}
    </Space>
  );
}
