import { v4 } from 'uuid';

import { getToJson } from './io';

import { WF_WIZARD_SERVICES_URL } from './midas-constants';

import routes from '../../utils/routes';

import { ISettingsConfigContext } from '../context/settings-config-context';
import { IHistoryContext } from '../context/history-context';
import { getAccessTokenFromCookie } from '../../services/auth';

export const cloneWorkflow = async (settingsConfigContext: ISettingsConfigContext, historyContext: IHistoryContext, selectedId: string) => {
  try {
    const token = getAccessTokenFromCookie();
    const url = new URL(WF_WIZARD_SERVICES_URL);
    url.searchParams.append('id', selectedId);

    const existingContent = await getToJson(url.toString(), token);
    const {
      wizardRequest: { configuration },
    } = existingContent;

    const uuid = v4();

    settingsConfigContext.updateAppConfig((appConfig) => {
      if (appConfig.tabs!.length === 0) {
        console.error('[cloneWorkflow] tabs collection is empty!');
      }

      return {
        ...appConfig,
        tabs: [
          ...appConfig.tabs!,
          {
            config: { type: 'BookJoinWorkflow' },
            name: 'WF: Data Ingestion',
            type: 'workflow',
            uuid,
          },
        ],
        workflows: [
          ...(appConfig.workflows || []),
          {
            uuid,
            processState: {
              ...configuration,
              current: 3,
            },
          },
        ],
      };
    });

    setTimeout(() => {
      const newRoute = `${routes.Workflow}/${uuid}`;
      historyContext.go(newRoute);
    }, 1);
  } catch (error) {
    console.error('[cloneWorkflow] failed to clone...', error);
  }
};
