import { DataQueryConfig } from '../../../../types';
import { BookJoinDataQuery, DipUploadedDataQuery } from '../../query-types';

export const getTableName = (query: DataQueryConfig) => {
  const { queryType, bookJoinTableName, dpTableName } = query;

  if (queryType === DipUploadedDataQuery) {
    return dpTableName;
  }
  if (queryType === BookJoinDataQuery) {
    return bookJoinTableName;
  }
  return undefined;
};
