/* eslint-disable @typescript-eslint/ban-types */
import { createContext } from 'react';
import { DataQueryFormItem } from '../pages/types';

type IQueryConfigContext = {
  searchConfigs?: DataQueryFormItem[];
  previousSearches?: DataQueryFormItem[];
  removePreviousSearchConfig: (deletedSearchId: string, searchType: string) => void;
  selectPreviousSearchConfig: (candidate: DataQueryFormItem, searchType: string, routeType: string) => void;
  updateSearchConfig: (id: string, delta: string[]) => DataQueryFormItem | undefined;
  setSearchConfigs: (newSearchConfigs: DataQueryFormItem[] | Function, deletedSearchConfig?: DataQueryFormItem) => void;
  updateMetaConfig: (id: string, delta: DataQueryFormItem | Function) => void;
  loading?: boolean;
};

const queryConfigContext: IQueryConfigContext = {
  searchConfigs: [
    {
      name: 'Untitled',
      uuid: '',
      config: {
        products: [],
      },
    },
  ],
  previousSearches: [],
  removePreviousSearchConfig: () => {},
  selectPreviousSearchConfig: () => {},
  updateSearchConfig: () => undefined,
  setSearchConfigs: () => {},
  updateMetaConfig: () => {},
  loading: true,
};

export const QueryConfigContext = createContext(queryConfigContext);
