/* eslint-disable react/prop-types */
import React, { useState, useMemo, useCallback } from 'react';

import { Spin, Select, Table, Button } from 'antd';
import { ResponsiveContainer, CartesianGrid, Tooltip, BarChart, Bar, XAxis, YAxis, Rectangle, LabelList, TooltipProps } from 'recharts';

import numeral from 'numeral';

import { CloseOutlined } from '@ant-design/icons';

import classes from './OrderBookControls.module.css';
import { DataQueryProductRow, DataQueryProductRowOutputItem } from '../../../../../types';

type OrderBookGraphColors = { [color: string]: string };

type OrderBookLevelBreakdownGraphParams = {
  data: DataQueryProductRow[];
  dataLoading: boolean;
  colors: OrderBookGraphColors;
  product: string;
};

const { Option } = Select;

const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

const breakdownTableColumns = [
  {
    title: 'Exchange',
    dataIndex: 'exchange',
  },
  {
    title: 'OID',
    dataIndex: 'oid',
  },
  {
    title: 'Size',
    dataIndex: 'size',
  },
];

function CustomTooltip({
  active,
  payload,
  label,
  colors,
}: TooltipProps<string | number | (string | number)[], string | number> & { colors: OrderBookGraphColors }) {
  if (!active || !payload?.length) {
    return null;
  }

  const [
    {
      payload: { priceType },
    },
  ] = payload;

  return (
    <div className={classes.customTooltip}>
      <div className={classes.tooltipHeader} style={{ color: colors[priceType] }}>
        {capitalizeFirstLetter(priceType)}: ${label}
      </div>
      <ul className={classes.tooltipList}>
        {payload.map(({ name, value }) => (
          <li key={name}>
            {name}: {numeral(value).format('0,0')}
          </li>
        ))}
      </ul>
    </div>
  );
}

const CustomBar = (selectedBar: DataQueryProductRowOutputItem | null, colors: OrderBookGraphColors) =>
  function CustomBar(props: { priceType: string; payload: { index: number } }) {
    const { priceType } = props;
    const isSelected = selectedBar?.index === props.payload.index;

    return <Rectangle {...props} fill={colors[priceType]} stroke={isSelected ? '#000' : '#fff'} strokeWidth={isSelected ? 2 : 1} />;
  };

export default function OrderBookLevelBreakdownGraph({ data, dataLoading, colors, product }: OrderBookLevelBreakdownGraphParams) {
  const [exchange, setExchange] = useState('');
  const [selectedBarDetails, setBar] = useState<DataQueryProductRowOutputItem | null>(null);

  const normalizedData = useMemo(() => {
    if (!data || !data.length) {
      return { all: [], exchanges: [] };
    }

    const details = data.find(({ product: pr }) => pr === product);

    if (!details?.output) {
      console.warn('[OrderBookLevelBreakdownGraph] No output data returned...');
      return { all: [], exchanges: [] };
    }

    const groupedData = details.output.reduce<{ all: DataQueryProductRowOutputItem[]; exchanges: Set<string> }>(
      (acc, current) => {
        const { all, exchanges } = current;

        exchanges!.forEach((exc) => acc.exchanges.add(exc));

        const allFiltered = exchange ? all!.filter((details) => details.breakdown.some(({ exchange: exc }) => exc === exchange)) : all;

        acc.all.push(...allFiltered!);

        return acc;
      },
      {
        all: [],
        exchanges: new Set(),
      }
    );

    return {
      all: groupedData.all.sort((a, b) => b.price - a.price),
      exchanges: [...groupedData.exchanges].sort((a, b) => {
        if (a < b) {
          return -1;
        }

        if (b > a) {
          return 1;
        }

        return 0;
      }),
    };
  }, [data, exchange, product]);

  const exchangesToRender = useMemo(() => {
    if (!normalizedData.exchanges.length) {
      return [];
    }

    if (exchange === '') {
      return normalizedData.exchanges;
    }

    return [exchange];
  }, [normalizedData, exchange]);

  const CustomFormattedBar = useMemo(() => CustomBar(selectedBarDetails, colors), [selectedBarDetails]);

  const handleBarClick = useCallback(
    (data: DataQueryProductRowOutputItem) => {
      const { breakdown } = data;

      setBar({
        ...data,
        breakdown: exchange ? breakdown.filter(({ exchange: exc }) => exc === exchange) : breakdown,
      });
    },
    [exchange]
  );

  const handleBreakDownClose = useCallback(() => {
    setBar(null);
  }, []);

  const handleExchangeSelection = useCallback((newExchange: string) => {
    setBar(null);
    setExchange(newExchange);
  }, []);

  const getLabel = useCallback(
    (data: DataQueryProductRowOutputItem) => {
      const size =
        exchange === '' ? data.size : data.breakdown.reduce((acc, curr) => acc + (curr.exchange === exchange ? curr.size : 0), 0);

      return numeral(size).format('0,0');
    },
    [exchange]
  );

  return (
    <Spin spinning={dataLoading}>
      <div className={classes.tabPaneContent}>
        <div className="grow">
          Exchanges:&nbsp;
          <Select className={classes.exchangeSelector} value={exchange} onSelect={handleExchangeSelection}>
            <Option value="">All</Option>
            {normalizedData.exchanges.map((exchange) => (
              <Option key={exchange}>{exchange}</Option>
            ))}
          </Select>
          <ResponsiveContainer width="100%" height={900}>
            <BarChart layout="vertical" data={normalizedData.all} margin={{ bottom: 30, right: 50 }}>
              <YAxis
                dataKey="price"
                allowDecimals
                type="category"
                label={{ value: 'Price', angle: -90, position: 'insideLeft' }}
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12 }}
                interval={0}
              />
              <XAxis type="number" label={{ value: 'Size', position: 'bottom' }} axisLine={false} />
              <CartesianGrid horizontal={false} />
              <Tooltip content={<CustomTooltip colors={colors} />} />
              {exchangesToRender.map((exchange, index) => (
                <Bar
                  key={exchange}
                  stackId="index"
                  isAnimationActive={false}
                  cursor="pointer"
                  dataKey={exchange}
                  barSize={16}
                  shape={CustomFormattedBar}
                  onClick={handleBarClick}
                >
                  {index === exchangesToRender.length - 1 ? <LabelList position="right" valueAccessor={getLabel} /> : null}
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
        {selectedBarDetails ? (
          <div className={classes.tabPaneBarAreaTable}>
            <Button
              className={classes.tableAreaCloseButton}
              type="primary"
              icon={<CloseOutlined />}
              onClick={handleBreakDownClose}
              size="small"
              danger
            />
            <div className={classes.breakdownTableTitle}>
              {capitalizeFirstLetter(selectedBarDetails.priceType)}: ${selectedBarDetails.price}
            </div>
            <Table
              columns={breakdownTableColumns}
              rowKey="oid"
              dataSource={selectedBarDetails.breakdown}
              pagination={{ hideOnSinglePage: true }}
              size="small"
            />
          </div>
        ) : null}
      </div>
    </Spin>
  );
}
