/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';

import { Modal, Form, TreeSelect, InputNumber, Select } from 'antd';
import PreciseTimePicker from '../../../../../_shared/preciseTimePicker/PreciseTimePicker';
import { DataQueryConfigQuery, WorkflowQueryOptions } from '../../../../types';

type QueryModalTime = {
  hours?: number;
  timeString?: string;
  milliseconds?: number;
  microseconds?: number;
  isValid?: boolean;
};

type QueryModalParams = {
  editing: {
    index: number | null;
    data?: DataQueryConfigQuery;
    done: () => void;
  };
  isVisible: boolean;
  closeModal: () => void;
  queryOptions: WorkflowQueryOptions;
  setBookJoinData: (fn: (queries: DataQueryConfigQuery[]) => DataQueryConfigQuery[]) => void;
};

const INITIAL_FIELD_VALUES: {
  value?: string;
  time?: QueryModalTime | null;
  offset?: number | null;
  level?: number | null;
} = {
  value: '',
  time: null,
  offset: null,
  level: null,
};

const combineGroupAndFeed = (group?: string, feed?: string) => {
  if (feed && feed !== 'all') {
    return `${group}/${feed}`;
  }

  return group;
};

const getTimeObject = (timeInfo?: QueryModalTime) => {
  const { hours, timeString = '', milliseconds, microseconds, isValid } = timeInfo || {};
  const getNormalizedTimeString = () => {
    // hour and mili/micro seconds are split by space '14:37:35 .123 .456' or '14:37:35 .123456' or '14:37:35 .123 .___'.
    const timeParts = timeString.split(' ');
    const normalizedTime = timeParts[0];
    let precisionTimes = timeParts.slice(1, timeParts.length);
    // when timeString '14:37:35 .123456' this happens when time was not changed.
    if (precisionTimes.length === 1) {
      return `${normalizedTime}${precisionTimes[0]}`;
    }
    precisionTimes = precisionTimes.map((pT) => pT.replaceAll('.', ''));
    // parse correct precision string for mili and micro seconds.
    const microsecondsString = Number.isNaN(microseconds) ? '000' : precisionTimes[1];
    const precisionString = Number.isNaN(milliseconds) ? '' : `.${precisionTimes[0]}${microsecondsString}`;

    return `${normalizedTime}${precisionString}`;
  };
  const time = timeString ? getNormalizedTimeString() : '';

  return {
    time,
    timeString: timeString.substring(0, 9) + time.substring(8, 15),
    isValid,
  };
};

function QueryModal({ editing, isVisible, closeModal, queryOptions: { feedsAndGroupsTree, values }, setBookJoinData }: QueryModalParams) {
  const [form] = Form.useForm();
  const isEdit = editing.index !== null;
  const editingData = editing.data;
  const [customValidatedFields, setFields] = useState(INITIAL_FIELD_VALUES);
  const [valueSupportsLevel, setValueSupport] = useState(false);

  const timeChangeHandler = (time: QueryModalTime) => {
    setFields((fields) => ({ ...fields, time }));
  };

  const handleValueChange = (value: string, _index: unknown) => {
    setFields({
      ...customValidatedFields,
      ...(customValidatedFields.level && { level: null }),
      value,
    });
  };

  const validateFormAndClose = async () => {
    const { groupAndFeed, ...restFormValues } = await form.validateFields();
    const { time, timeString, isValid } = getTimeObject(customValidatedFields?.time || {});
    if (timeString && !isValid) {
      return;
    }

    const [group, feed = 'all'] = groupAndFeed.split('/');
    const normalizedFormValues = {
      group,
      feed,
      ...restFormValues,
      ...customValidatedFields,
      time,
      timeString,
    };

    setBookJoinData((queries) => {
      if (isEdit) {
        return Object.assign([], queries, { [editing.index!]: normalizedFormValues });
      }

      return [...queries, normalizedFormValues];
    });

    closeModal();

    editing.done();
    form.resetFields();
    setFields(INITIAL_FIELD_VALUES);
  };

  // align Form values when edit
  useEffect(() => {
    const commonValuesToRefresh = {
      value: editingData?.value,
      level: editingData?.level,
      offset: editingData?.offset,
      time: { timeString: editingData?.timeString, isValid: true },
    };

    form.setFieldsValue({
      groupAndFeed: editingData ? combineGroupAndFeed(editingData.group, editingData.feed) : undefined,
      ...commonValuesToRefresh,
    });

    setFields(commonValuesToRefresh);
  }, [editingData, form]);

  useEffect(() => {
    setValueSupport(values.supportLevelValues.includes(customValidatedFields.value!));
  }, [customValidatedFields.value, values.supportLevelValues]);

  return (
    <Modal
      title="Add Query Item"
      open={isVisible}
      onOk={validateFormAndClose}
      onCancel={() => {
        closeModal();

        editing.done();
        form.resetFields();
        setFields(INITIAL_FIELD_VALUES);
      }}
      maskClosable={false}
      okText={isEdit ? 'Done' : 'Add'}
      // FIXES:
      // "Warning: Instance created by `useForm` is not connected to any Form element. Forget to pass `form` prop?"
      // https://ant.design/components/form/#Why-is-there-a-form-warning-when-used-in-Modal
      forceRender
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Group/Feed"
          name="groupAndFeed"
          rules={[{ required: true, message: 'Please select a group and optionally a feed.' }]}
        >
          <TreeSelect placeholder="Please select a group and optionally a feed" treeData={feedsAndGroupsTree} allowClear />
        </Form.Item>
        <Form.Item label="Value" name="value" rules={[{ required: true, message: 'Please select a value type.' }]}>
          <Select placeholder="Please select a value type" options={values.options} onChange={handleValueChange} allowClear />
        </Form.Item>
        <Form.Item label="Level" extra={!valueSupportsLevel && <>The selected value does not support a level</>} name="level">
          <InputNumber
            placeholder="Level"
            min={1}
            max={10}
            onChange={(level: number | null) => setFields((fields) => ({ ...fields, level }))}
            disabled={!valueSupportsLevel}
          />
        </Form.Item>
        <Form.Item
          label="Time"
          extra={typeof customValidatedFields.offset === 'number' && <>Time not available when Offset is defined</>}
          name="time"
        >
          <PreciseTimePicker
            placeholder={customValidatedFields?.time?.timeString || customValidatedFields?.offset?.toString() ? '' : 'Time'}
            time={customValidatedFields?.time?.timeString || ''}
            onTimeChange={timeChangeHandler}
            disabled={typeof customValidatedFields.offset === 'number'}
            autofillMissingFields
          />
        </Form.Item>
        <Form.Item
          label="Offset"
          extra={customValidatedFields?.time?.timeString && <>Offset not available when Time is defined</>}
          name="offset"
        >
          <InputNumber
            placeholder="Offset"
            onChange={(offset: number | null) => setFields((fields) => ({ ...fields, offset }))}
            disabled={!!customValidatedFields?.time?.timeString}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default QueryModal;
