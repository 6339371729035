import { MutableRefObject, createContext } from 'react';
import { To } from 'react-router-dom';

export type IHistoryContext = {
  go: (value: To) => void;
  eventHandler: MutableRefObject<((value: To) => void) | null | undefined> | null;
};

const historyContext: IHistoryContext = {
  go: () => {
    throw new Error('not implemented');
  },
  eventHandler: null,
};

export const HistoryContext = createContext(historyContext);
