import React, { useMemo } from 'react';

import { Table } from 'antd';

import PagingDataTable from '../paging/PagingDataTable';
import { DataQueryProductRow, DataQueryProductRowOutput, DataQueryProductServerInfo } from '../../../../../types';

type OrderBookGridControlParams = {
  data?: DataQueryProductRow[];
  products?: string[];
  product?: string;
  dataLoading?: boolean;
  dataError?: string | object;
  onSelectProduct?: (value: string, option: { value: string; label: string } | { value: string; label: string }[]) => void;
  productServerInfo?: DataQueryProductServerInfo;
  onDownload?: (selectedItem: string) => void;
};

const columns = [
  <Table.Column key="exchange" title="Exchange" dataIndex="exchange" />,
  <Table.Column key="bidPrice" title="Best Bid Price" dataIndex="bidPrice" />,
  <Table.Column key="bidSize" title="Best Bid Size" dataIndex="bidSize" />,
  <Table.Column key="askPrice" title="Best Ask Price" dataIndex="askPrice" />,
  <Table.Column key="askSize" title="Best Ask Size" dataIndex="askSize" />,
];

const DEFAULT_PROPS = {
  data: [],
  products: [],
  onSelectProduct: () => {},
  onDownload: () => {},
};

export default function OrderBookGridControl({
  data = DEFAULT_PROPS.data,
  dataLoading = false,
  dataError = undefined,
  products = DEFAULT_PROPS.products,
  product = undefined,
  onSelectProduct = DEFAULT_PROPS.onSelectProduct,
  productServerInfo = undefined,
  onDownload = DEFAULT_PROPS.onDownload,
}: OrderBookGridControlParams) {
  const normalizedData: { key: string; exchange: string; [key: string]: string | number }[] = useMemo(() => {
    if (!data || !data.length) {
      return [];
    }

    const details = data.find(({ product: pr }) => pr === product);

    if (!details?.output) {
      console.warn('[OrderBookGridControl] No output data returned...');
      return [];
    }

    const groupedData = details.output.reduce<{ [exchange: string]: { key: string; exchange: string; [key: string]: string | number } }>(
      (acc, current) => {
        const { priceType, dataPerExchange } = current;

        for (const [exchange, info] of Object.entries(dataPerExchange!)) {
          if (!acc[exchange]) {
            acc[exchange] = {
              key: exchange,
              exchange,
            };
          }

          acc[exchange][`${priceType}Price`] = info.price;
          acc[exchange][`${priceType}Size`] = info.size;
        }

        return acc;
      },
      {}
    );

    return Object.values(groupedData).sort((a, b) => {
      if (a.exchange < b.exchange) {
        return -1;
      }

      if (a.exchange > b.exchange) {
        return 1;
      }

      return 0;
    });
  }, [data, product]);

  return (
    <PagingDataTable
      data={normalizedData}
      tableColumns={columns}
      product={product}
      products={products}
      dataLoading={dataLoading}
      dataError={dataError}
      onSelectProduct={onSelectProduct}
      productServerInfo={productServerInfo}
      onDownload={onDownload}
    />
  );
}
