import axios from 'axios';
import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';
import { axiosOptions } from '../../services/requests';

export interface SearchResult {
  id: string;
  type: string;
  title: string;
}

export interface ResultsFromSource {
  label: string;
  searchResults: SearchResult[];
}

export class SearchApi {
  async getAllOptionsForTerm(term: string): Promise<ResultsFromSource[] | undefined> {
    return await checkAxiosResponse(axios.get<ResultsFromSource[]>(`/api/zoe/search?term=${term}`, await axiosOptions()));
  }
}
