import React from 'react';
import { LoginOutlined } from '@ant-design/icons';
import { EXT_APPS_KEY } from '../../../side-nav/SideNav';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { EXT_APPS_LIST } from '../models/ext-apps-list';

import sharedStyles from '../../../side-nav/SideNav.shared.module.css';

export function getExtAppURL(id: string) {
  const app = EXT_APPS_LIST.find((app) => app.id === id);
  if (!app) return undefined;

  const isDev = window.CONFIG.mstEnvironment === 'development' || window.CONFIG.mstEnvironment === 'shared-dev';
  return isDev ? app.devUrl : app.url;
}

function getExtAppsNavItems(): ItemType[] {
  return EXT_APPS_LIST.map((app) => ({
    icon: <LoginOutlined />,
    key: `${EXT_APPS_KEY}/${app.id}`,
    label: (
      <div className={sharedStyles.subtitledNavLabel} data-testid={`side-nav-${app.id}`} title={app.subtitle}>
        <div>{app.name}</div>
        <div>{app.subtitle}</div>
      </div>
    ),
  }));
}

export function getExtAppsNav(): ItemType {
  return {
    key: EXT_APPS_KEY,
    label: 'All Apps',
    icon: <LoginOutlined />,
    children: getExtAppsNavItems(),
  };
}
