// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertPropsToJson = (object: any, properties: string | string[]) => {
  (Array.isArray(properties) ? properties : [properties]).forEach((key) => {
    try {
      if (object[key] && typeof object[key] === 'string') {
        object[key] = JSON.parse(object[key] as string);
      }
    } catch (e) {
      // Ignored
    }
  });
  return object;
};
