import { ErrorNotification } from '../../../services/error-notification';
import { WbApi } from '../workbench-api';
import { InstallPackages } from './install-packages';
import { WbInstance } from './instance';

export interface InstallPackageState {
  pendingInstallationIds: string[];
}

export interface InstanceInstallPackages {
  state: 'loading' | 'ready' | 'saving';
  instance: WbInstance;
}

export interface InstanceInstallModel {
  install: InstanceInstallPackages | undefined;
  packages: InstallPackages | undefined;
  state?: InstallPackageState;
}

export async function loadInstallPackages(wbApi: WbApi, instance: WbInstance, update: (_: InstanceInstallModel) => void): Promise<void> {
  update({
    install: { state: 'loading', instance },
    packages: { packageSearchTerm: '', packageSearchResults: [], selectedPackages: [], isLoading: false },
  });

  const packageInstallations = (await wbApi.getInstancePackages(instance.shortId)) ?? [];

  const packages = packageInstallations.flatMap((pkg) => pkg[window.CONFIG.isMidasPeer ? 'condaPackages' : 'aptPackages']);
  // packages can be duplicated (each installation request is a different record)
  const existingPackages = Array.from(new Set(packages)).sort();

  update({
    install: {
      state: 'ready',
      instance,
    },
    packages: {
      packageSearchTerm: '',
      packageSearchResults: [],
      existingPackages,
      selectedPackages: [],
      isLoading: false,
    },
  });
}

export async function installPackages(
  wbApi: WbApi,
  getModel: () => InstanceInstallModel,
  update: (_: InstanceInstallModel) => void
): Promise<ErrorNotification | undefined> {
  let model = getModel();
  if (!model.install || !model.packages) {
    return;
  }

  update({ ...model, install: { ...model.install, state: 'saving' } });

  const packages = window.CONFIG.isMidasPeer
    ? { condaPackages: model.packages.selectedPackages, aptPackages: [] }
    : { aptPackages: model.packages.selectedPackages, condaPackages: [] };

  const res = await wbApi.installPackages({ instanceId: model.install.instance.id, ...packages });
  model = getModel();
  if (!model.install || !model.packages) {
    return;
  }
  if (!res?.ok) {
    update({ ...model, install: { ...model.install, state: 'ready' } });

    return { message: 'Error installing packages' };
  }

  update({
    install: undefined,
    packages: undefined,
    state: { pendingInstallationIds: (model.state?.pendingInstallationIds ?? []).concat([res.installationId]) },
  });
}

export interface InstallationNotification {
  type: 'success' | 'error';
  message: string;
}
