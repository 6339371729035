import React, { Component } from 'react';

import classNames from 'classnames';

import { Alert } from 'antd';

import classes from './ErrorBoundary.module.css';

type ErrorBoundaryParams = {
  children: JSX.Element;
  componentName: string;
  bordered?: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryParams, { hasError: boolean }> {
  static defaultProps = {
    bordered: false,
  };

  constructor(props: ErrorBoundaryParams) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown) {
    console.log(error);
  }

  render() {
    const { hasError } = this.state;
    const { children, componentName, bordered } = this.props;
    const containerCN = classNames(classes.errorBoundary, {
      [classes.borderedMessage]: bordered,
    });

    if (hasError) {
      return (
        <div className={containerCN}>
          <Alert className={classes.errorAlert} message="Error" description={`Unable to load ${componentName}`} type="error" showIcon />
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
