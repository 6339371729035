enum ZoeRoute {
  Home = '/',
  Dashboard = '/dashboard',
  Applications = '/applications',
  PublishedManifests = '/published-manifests',
  Jobs = '/jobs',
  Resources = '/resources',
  DataVisualisation = '/visualisation',
  Data = '/data',
  Help = '/help',
  Workflow = '/workflow',
  DpState = '/my-data',
  Usage = '/usage',
  Documentation = '/documentation',
  FileExplorer = '/file-explorer',
  Peer = '/peer',
  Instances = '/instances',
  Notifications = '/notifications',
  Monitoring = '/monitoring',
  CancelUserResourcesDeletion = '/cancel-user-resources-deletion',
}

export enum MyRoute {
  FileExplorer = '/fileExchange',
  Instances = '/workbench',
}

export default ZoeRoute;
