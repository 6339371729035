import React, { useCallback, useContext, useMemo } from 'react';

import { Collapse, CollapseProps, Table } from 'antd';

import { SettingsConfigContext } from '../../context/settings-config-context';

import useWebDataLoader from '../../hooks/use-web-data-loader';
import usePausingWebUpdater from './utils/use-pausing-web-updater';

import { initTableDataColumns } from './utils/CreateColumns';

import routes from '../../../utils/routes';
import { DataQueryFormItem, DataQueryFormItemMetaAccordionControls, DpActivityJobStatusTable } from '../../pages/types';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SorterCallback, SorterFactory } from '../../pages/data-query/components/outputs/_shared/table-utils';
import { DpFileExchangeApi } from '../../../api/dp-file-exchange-api';
import { showErrorNotification } from '../../../_shared/views/Notifications';

type DpActivityAvailableDataParams = {
  tablesSize: SizeType;
  conditionallyHandleShowRelatedItemsInfo: boolean | ((id: string) => void);
  handleTableChangeCallbackFactory: SorterCallback;
  accordionActiveKeys: DataQueryFormItemMetaAccordionControls;
  onAccordionActiveKeysChanged: (key: string, openControls: string | string[]) => void;
  sorterFactory?: (tableName: string) => SorterFactory | undefined;
  showActiveOnly?: boolean;
  showDeletedFiles?: boolean;
  includePublicData?: boolean;
  selectedWorkflowId?: string | string[];
};

const DEFAULT_PROPS = {
  sorterFactory: () => undefined,
};

export default function DpActivityAvailableData({
  tablesSize,
  sorterFactory = DEFAULT_PROPS.sorterFactory,
  conditionallyHandleShowRelatedItemsInfo,
  handleTableChangeCallbackFactory,
  accordionActiveKeys,
  onAccordionActiveKeysChanged,
  showActiveOnly = false,
  showDeletedFiles = false,
  includePublicData = false,
  selectedWorkflowId,
}: DpActivityAvailableDataParams) {
  const settingsConfigContext = useContext(SettingsConfigContext);
  const dpFileExchangeApi = new DpFileExchangeApi();

  const { createTab, closeDataTab } = settingsConfigContext;

  const requestUrl = useMemo(() => {
    const url = dpFileExchangeApi.buildGetTablesUrl({ workflowId: selectedWorkflowId, includePublicData });

    return url;
  }, [showActiveOnly, showDeletedFiles, includePublicData, selectedWorkflowId]);

  const [webData, runUpdate] = useWebDataLoader<DpActivityJobStatusTable[]>({ url: requestUrl });

  usePausingWebUpdater({
    selectedWorkflowId,
    runUpdate,
  });

  const handleTableDelete = useCallback(
    async (tableId: string) => {
      try {
        await dpFileExchangeApi.deleteTable(tableId);
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await runUpdate();
        await closeDataTab?.(tableId);
      } catch (error: unknown) {
        console.error('[DpActivity] failed to delete table...', error);
        showErrorNotification({
          message: 'Unable to delete the specified table...',
          description: (error as Error).message,
        });
      }
    },
    [runUpdate, closeDataTab]
  );

  const handleViewItemClicked = useCallback(
    (record: { id: string }) => {
      const newItem: DataQueryFormItem = {
        name: 'DIP Data Sources',
        type: 'data',
        config: {
          products: [],
          queryType: 'DipUploadedDataQuery',
          dpTableName: record.id,
        },
        readOnly: true,
        meta: { accordionActiveKeys: ['resultPanel', 'vizPanel'] },
      };

      void createTab(routes.Data, newItem);
    },
    [createTab]
  );

  const columnsForTableData = useMemo(
    () =>
      initTableDataColumns(
        handleTableDelete,
        handleViewItemClicked,
        conditionallyHandleShowRelatedItemsInfo,
        sorterFactory?.('columnsForTableData')
      ),
    [handleTableDelete, conditionallyHandleShowRelatedItemsInfo, sorterFactory]
  );

  const collapseItems: CollapseProps['items'] = [
    {
      key: 'data',
      label: 'My Available Data',
      children: (
        <Table
          columns={columnsForTableData}
          dataSource={webData.data}
          loading={webData.loading}
          rowKey="id"
          size={tablesSize}
          scroll={{ x: true }}
          pagination={{ hideOnSinglePage: true }}
          onChange={handleTableChangeCallbackFactory('columnsForTableData')}
        />
      ),
    },
  ];

  return <Collapse activeKey={accordionActiveKeys.data} onChange={(e) => onAccordionActiveKeysChanged('data', e)} items={collapseItems} />;
}
