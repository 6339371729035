import React from 'react';

import AdvancedProductSearchForm, { AdvancedProductSearchFormInitialValues } from '../../../../modals/components/AdvancedProductSearchForm';

import { Form, FormInstance } from 'antd';
import { FORM_LAYOUT_LABEL } from '../../../../utils/midas-constants';
import { preventEnterKeyDefault } from './data-query-form-utils';

const defaultOnFinish = () => {};

export default function ProductInfoForm({
  formInstance,
  onFinish = defaultOnFinish,
}: {
  formInstance: FormInstance;
  onFinish?: () => void;
}) {
  return (
    <Form
      form={formInstance}
      labelCol={FORM_LAYOUT_LABEL}
      initialValues={AdvancedProductSearchFormInitialValues}
      layout="horizontal"
      onFinish={onFinish}
      onKeyDown={preventEnterKeyDefault}
    >
      <AdvancedProductSearchForm formInstance={formInstance} productInfoForm embedded />
    </Form>
  );
}
