import { DocumentationApi } from '../documentation-api';

export interface Documentation {
  id: string;
  url: string;
  label: string;
}

export interface DocumentationModel {
  loading: boolean;
  documentation: Documentation[];
}

export type DocumentationType = 'midas-landing-page' | 'midas-user-guide' | 'thw-user-guide';

export function initialDocumentation(): DocumentationModel {
  return {
    loading: true,
    documentation: [],
  };
}

export async function loadDocumentation(api: DocumentationApi, update: (_: DocumentationModel) => void): Promise<void> {
  const documentation = await api.getAllDocumentationLinks();
  if (!documentation) {
    return;
  }

  update({ loading: false, documentation });
}
