/* eslint-disable check-file/filename-naming-convention */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { handleLoginFlow, setPluggableAuth, SignedInUser, signOut } from './services/auth';
import { wbIsInsideWorkspaces, wsGetAuthData, wsGetToken, wsInit, wsIsEmbedded } from './workspaces/utils';
import { WorkspaceAuthenticatedApp } from './WorkspaceAuthenticatedApp';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './fonts/fonts.css';
import './colour-palette.css';
import './workspaces/workspace-styles.css';


if (process.env.NODE_ENV !== 'production') {
  void import('@axe-core/react').then((axe) => {
    void axe.default(React, ReactDOM, 1000);
  });
}

window.CONFIG = { ...window.CONFIG, featureFlags: { ...window.CONFIG.featureFlags, wbInsideZoe: true } };

async function createInstance(user: SignedInUser | undefined) {
  document.title = window.CONFIG.branding.longName;

  let Root: (a: { user: SignedInUser; signOut: () => void }) => JSX.Element;

  if (window.CONFIG?.isMidasPeer) {
    const { MidasAuthenticatedApp } = await import('./MidasAuthenticatedApp');
    Root = MidasAuthenticatedApp;
  } else {
    const app = await import('./App');
    Root = app.default;
  }

  const rootElem = document.getElementById('root');
  if (!rootElem) {
    return;
  }

  const root = ReactDOM.createRoot(rootElem);
  root.render(
    <React.StrictMode>
      <Root signOut={signOut} user={user!} />
    </React.StrictMode>
  );
}

function createDelegatedInstance(userData: SignedInUser) {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <WorkspaceAuthenticatedApp user={userData} />
    </React.StrictMode>
  );
}

const addWorkspaceClass = () => {
  document.getElementsByTagName('html')[0].classList.add('inside-workspaces');
};

const isEmbedded = wsIsEmbedded();
console.info('[wsb] embedded status: ', isEmbedded);

// this doesn't work with the way LSEG are using OpenFin, because predictability OpenFin is very opinionated.
//. console.info('[wsb]', wsIsOpenFin());

void (async () => {
  try {
    if (window.CONFIG.permitWorkspaceLogin && isEmbedded) {
      const isInsideWs = await wbIsInsideWorkspaces();
      if (isInsideWs) {
        window.CONFIG.isInsideWorkspaces = true;
        window.CONFIG.isMidasPeer = false;

        await wsInit();
        const userAuthData = wsGetAuthData();
        if (!userAuthData) {
          const body = document.getElementsByTagName('body')[0];
          body.appendChild(document.createTextNode('Failed to initialise connectivity to server!'));
          return;
        }

        setPluggableAuth({
          getAccessToken: () => Promise.resolve(wsGetToken()),
          getUserSubject: () => {
            const authData = wsGetAuthData();
            if (authData) {
              return Promise.resolve(authData.attributes.name || '(no user)');
            } else {
              return Promise.resolve('(no user)');
            }
          },
        });

        addWorkspaceClass();
        createDelegatedInstance(userAuthData);
        return;
      }
    }
  } catch (e) {
    console.error('[wsb] could not load WB inside WS; reverting to existing auth flow...');
  }

  const user = handleLoginFlow();
  if (!user) {
    // not logged in, user is being redirected, don't render the page
    return <></>;
  }

  await createInstance(user);
})();
