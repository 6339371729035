/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios from 'axios';

import { WowContextBridge } from './wow-context-bridge';

import { themeBox } from '../hooks/use-theme';

import { SignedInUser } from '../services/auth';

export type WsUserInfo = {
  AccountName: string;
  Address: string;
  CJR: string;
  City: string;
  Country: string;
  Email: string;
  FullUserName: string;
  Homesite: string;
  JobRole: string;
  LocationID: string;
  PreferredLanguage: string;
  UUID: string;
};

export type TokenResponse = {
  ok: boolean;
  token: string;
};

type MOVEMENT_COLOR_SCHEME_PREDEFINED = 'American' | 'Asian1' | 'Asian2' | 'European';
type MOVEMENT_COLOR_SCHEME_CUSTOM = 'Custom1';

interface IColorThemeInfo {
  theme: 'Charcoal' | 'Pearl';
  movementColorScheme: MOVEMENT_COLOR_SCHEME_PREDEFINED | MOVEMENT_COLOR_SCHEME_CUSTOM;
  colorConfig: {
    negative: string;
    neutral: string;
    positive: string;
    tickUp: string;
    tickDown: string;
    bidAndAsk: string;
  };
}

const WB_APP_ID = 'TICKHISTORYWORKBENCH';

let userCache = '';
let userInfo: WsUserInfo | undefined;

let launcher: any;
let context: any;

export async function wbIsInsideWorkspaces(): Promise<boolean> {
  return new Promise((resolve) => {
    if (!wsIsEmbedded()) {
      resolve(false);
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://workspace.ppe.refinitiv.com/Apps/RTKLoader/0.2933.0/rtk.js';
    script.addEventListener('load', () => {
      const hasLoader = (window as any).RTKLoader !== undefined;
      console.debug('[wsb] script loading: status: ', hasLoader);
      resolve(hasLoader);
    });
    script.addEventListener('error', (error) => {
      console.error('[wsb] script loading failed: ', error);
      resolve(false);
    });
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  });
}

export function wsIsEmbedded() {
  // eslint-disable-next-line no-restricted-globals
  return self !== top;
}

export async function wsUnsafeExchangeRtkUserInfoForToken(wsUserInfo: WsUserInfo, authZToken?: string | undefined) {
  const extractedInfo = {
    Email: wsUserInfo.Email,
    FullUserName: wsUserInfo.FullUserName,
    UUID: wsUserInfo.UUID,
  };
  const response = await axios.post<TokenResponse>('/api-ext/workspaces/get-token', extractedInfo, {
    headers: {
      Authorization: `Bearer ${authZToken}`,
    },
  });
  return response.data.token;
}

export function wsDefinitelyInside() {
  return userInfo !== undefined;
}

export function wsOpenUrl(url: string) {
  launcher.__byUrl(url, { target: 'tab' });
}

// DWT: doesn't work in our version of OpenFin (probably due to the Workspaces layer)
export async function wsIsOpenFin() {
  try {
    const finsProtocolResult = await (window as any)?.openfinDeployment.checkForFinsProtocol();
    console.info('openFin', finsProtocolResult);
  } catch (e) {
    console.info('openFin error', e);
  }
}

export async function wsInit() {
  console.info('[wsb] getting authentication from RTK...');

  const loader = (window as any).RTKLoader;
  const toolkit = await loader.init(
    {
      AppInstanceId: WB_APP_ID,
      Debug: {
        origin: 'https://workspace.ppe.refinitiv.com',
      },
    },
    ['User', 'AuthZ', 'Launch', 'ThemeAndColorState', 'Context']
  );

  launcher = toolkit.Launch;
  context = toolkit.Context;

  userInfo = await toolkit.User.getInfoAsync();

  const onChange = (data: IColorThemeInfo) => {
    themeBox.value = data.theme === 'Charcoal' ? 'dark' : 'light';
  };

  toolkit.ThemeAndColorState.subscribeChanges(onChange);

  const colourTheme = await toolkit.ThemeAndColorState.getColorTheme();
  onChange(colourTheme);

  console.info('[wsb] got user information...', userInfo?.Email);

  const tickTock = async () => {
    try {
      if (!userInfo) {
        userCache = '';
        return;
      }

      const authZAccessToken = await toolkit.AuthZ.getAccessToken(WB_APP_ID, []);
      try {
        const token2 = await toolkit.AuthZ.getAccessToken(WB_APP_ID, ['email', 'reutersuuid']);
        console.info('token2:', token2);
      } catch (err) {
        console.warn('token2: call fail: ', err);
      }
      userCache = await wsUnsafeExchangeRtkUserInfoForToken(userInfo, authZAccessToken);
      console.info('[wsb] freshened cache...', userCache);
    } catch (e) {
      console.error('[wsb] failed get latest data...', e);
    }
  };

  setInterval(() => void tickTock(), 60 * 5 * 1000);

  await tickTock();
}

export async function wsGetBridge(targetWindow: Window | undefined | null): Promise<WowContextBridge | undefined> {
  if (!wsIsEmbedded()) {
    console.warn('[wsb] not running in embedded mode!');
    return undefined;
  }

  if (!targetWindow) {
    console.error('[wsb] target window is not provided!');
    return undefined;
  }

  if (!context) {
    console.error('[wsb] no context; bridge has not been initialised yet!');
    return undefined;
  }

  const bridge = new WowContextBridge(context, targetWindow);
  await bridge.initialise();

  console.debug('[wsb] creating a bridge targetted at:', targetWindow);

  const handler = () => {
    bridge.dispose();
  };
  targetWindow.addEventListener('beforeunload', handler);
  return bridge;
}

export function wsGetToken(): string {
  return userCache;
}

export function wsGetAuthData(): SignedInUser | undefined {
  if (!userInfo) {
    return undefined;
  }

  return {
    attributes: {
      name: userInfo.FullUserName,
      email: userInfo.Email,
    },
  };
}
