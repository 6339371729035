import { ReactNode } from 'react';
import { WorkflowWizardState } from '../pages/types';

export type StepStateValues = 'process' | 'finish' | 'error';

export const STEP_STATE: { [key in 'Processing' | 'Complete' | 'Error']: StepStateValues } = {
  Processing: 'process',
  Complete: 'finish',
  Error: 'error',
};

export type StatusValues = 'wait' | StepStateValues | undefined;

export type WorkflowWizardContext =
  | {
      stepStatus: string;
      setStepStatus: (status: StepStateValues | undefined) => void;

      //similar to WizardProps
      setCurrentStep: (step: number | undefined) => void;
      currentStep: number;
      stepContentWrapper?: (content: string) => JSX.Element;

      //other
      previousStep: number;

      //percent and history
      stepPercent?: number;
      setStepPercent?: (percent: number | undefined) => void;
      stepHistory: any[];
    }
  | Record<string, never>;

export type WizardProps = {
  // similar to WorkflowWizardContext
  setCurrentStep?: (step: number | undefined) => void;
  currentStep?: number; //
  stepContentWrapper?: (content: string) => JSX.Element; //

  //other
  children: ReactNode;
  stepHeaders: JSX.Element[];
  onCurrentChange?: (step: number) => void;
  status?: StatusValues;
  verticalBreakpoint?: string;
  stepWrapper?: (content: JSX.Element) => JSX.Element;

  //percent and history
  percent?: number;
  initialStepHistory?: WorkflowWizardState[];
  onStepHistoryChange?: (history: any) => void;
};

export interface WizardStepProps {
  children: ReactNode;
  previousEnabled?: boolean;
  nextEnabled?: boolean;
  onPrevious?: (previousStep: number, setCurrentStep: (step: number) => void) => void;
  onNext?: (currentStep: number, setCurrentStep: (step: number) => void) => void;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  navigationButtons?: ReactNode;
}
