import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { InstanceListModel } from '../models/instance-list';
import { getInstanceUrl } from '../../shared/instance-mapping';
import { AppThemeContext } from '../../../hooks/use-theme';

import { InstanceListDelegate } from './InstanceList';

import styles from './WorkbenchInstanceFakeContainer.module.css';

/* A quick and dirty POC for WS integration.
 *
 * Due to the way IFRAMEs work with React, and the fact you still can't manually reposition them inside the DOM,
 * this control is used to store a set of IFRAMEs that are hidden when they're not selected.
 *
 * The following conditions must hold true:
 * - the parent container's node can't move once it is created
 * - child IFRAMEs nodes cannot be moved
 *
 * This should not end up in production.
 *
 */

export function WorkbenchInstanceFakeContainer({ model, delegate }: { model: InstanceListModel; delegate: InstanceListDelegate }) {
  const ref = useRef<HTMLDivElement | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const appTheme = useContext(AppThemeContext);

  const [activeId, setActiveId] = useState<string | undefined>();

  const modelRef = useRef<InstanceListModel>(model);
  const delegateRef = useRef<InstanceListDelegate>(delegate);

  // please consult the "docs/available-vscode-themes.json" file for the IDs of all VS Code themes
  const vsCodeThemeId = useMemo(() => {
    return appTheme.theme === 'light'
      ? 'vs vscode-theme-defaults-themes-light_modern-json'
      : 'vs-dark vscode-theme-defaults-themes-dark_modern-json';
  }, [appTheme]);

  useEffect(() => {
    modelRef.current = model;
    delegateRef.current = delegate;
  }, [model, delegate]);

  useEffect(() => {
    const listener = (evt: MessageEvent) => {
      const payload = typeof evt.data === 'string' ? JSON.parse(evt.data) : evt.data;
      const { action, isShuttingDown, uuid } = payload as {
        action: string;
        isShuttingDown: boolean | undefined;
        uuid: string;
      };

      if (action === 'SET_THEME_RESPONSE') {
        console.debug('[wifc] set theme response: ', JSON.stringify(evt.data));
        return;
      }

      if (action !== 'LIFECYCLE_CHANGED' || !isShuttingDown) {
        return;
      }

      // DWT: fabulous naming by yours truly. UUID here is actually the full address of the instance.
      const url = new URL(uuid);
      const instanceId = url.searchParams.get('embeddedUuid');

      if (!instanceId) {
        console.warn('[wifc] no instanceID provided in URL! URL: ', url);
        return;
      }

      console.info('[wifc] shutting down instance: ', instanceId);

      delegate.onSetInstanceIsOpen(instanceId, false);
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  }, [delegate]);

  const forEachFrame = (action: (id: string, frame: HTMLIFrameElement) => void) => {
    const children = ref.current?.children;
    if (!children) {
      return;
    }

    for (const child of children) {
      const id = child.getAttribute('data-wb-frame-id');
      if (!id) {
        // not an element we care about
        return;
      }

      action(id, child as HTMLIFrameElement);
    }
  };

  useEffect(() => {
    const themeId = vsCodeThemeId;
    forEachFrame((id, frame) => {
      frame.contentWindow?.postMessage({ action: 'SET_THEME', themeId }, '*');
    });
  }, [appTheme, vsCodeThemeId]);

  useEffect(() => {
    const ids = model?.instances?.filter(({ isOpenInsideUI }) => isOpenInsideUI)?.map(({ id }) => id);

    if (!ref.current) {
      return;
    }

    const seenIds = new Set<string>(ids);

    forEachFrame((id, child) => {
      if (!ids.includes(id)) {
        // remove DOM node
        ref.current?.removeChild(child);
        if (activeId === id) {
          navigate('/instances');
          return;
        }
      }

      seenIds.delete(id);
    });

    if (seenIds.size > 0) {
      for (const id of seenIds) {
        const instance = model?.instances?.find(({ id: instanceId }) => id === instanceId);
        if (!instance) {
          return;
        }

        const frameElement = document.createElement('iframe');
        frameElement.setAttribute('data-wb-frame-id', id);

        const url = new URL(getInstanceUrl(instance.shortId));
        url.searchParams.set('embeddedUuid', instance.id);
        url.searchParams.set('themeId', vsCodeThemeId);
        frameElement.src = url.toString();

        frameElement.classList.add(styles.frame);
        frameElement.classList.add(styles.hiddenFrame);

        ref.current?.appendChild(frameElement);
      }
    }
  }, [model?.instances, activeId, navigate, vsCodeThemeId]);

  React.useEffect(() => {
    const exploded = location.pathname?.trim()?.split('/');
    const [, path, id] = exploded;
    if (path !== 'instances') {
      setActiveId(undefined);
      return;
    }

    setActiveId(id);
  }, [location]);

  useEffect(() => {
    const layoutContainer = document.getElementsByClassName('ant-layout-content')[0];
    if (!activeId) {
      ref.current?.classList.add(styles.hiddenFrame);
    } else {
      ref.current?.classList.remove(styles.hiddenFrame);
    }

    let seenVisible = false;
    forEachFrame((id, child) => {
      const isVisible = id === activeId;
      if (isVisible) {
        seenVisible = true;

        child.classList.remove(styles.hiddenFrame);
        child.classList.add(styles.visibleFrame);
      } else {
        child.classList.remove(styles.visibleFrame);
        child.classList.add(styles.hiddenFrame);
      }
    });

    if (!seenVisible && activeId) {
      // basically, the user is trying to see a WB instance that's no longer available
      console.info('[test] the selected ID is not visible.', activeId);
    }
  }, [activeId]);

  useEffect(() => {
    // this effect should only fire once - and that's to forcibly open up a component if the URL is set.
    const exploded = location.pathname?.trim()?.split('/');
    const [, path, id] = exploded;
    if (path === 'instances' && id) {
      console.info('[wifc] should be showing: ', id);
      delegateRef.current?.onSetInstanceIsOpen(id, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={ref} id={'wb-inline-frame-container'} className={`${styles.container} ${styles.hiddenFrame}`}></div>;
}
