import { DashboardData } from '../dashboard-api';
import { DashboardUpsert } from './dashboard-upsert';
import { DashboardRemove } from './dashboard-remove';
import { DASHBOARDS_ITEM_KEY } from '../../../side-nav/SideNav';

export interface DashboardList {
  state: 'loading' | 'ready';
  openKeys: string[];
  dashboards: DashboardData[];
  create: DashboardUpsert | undefined;
  remove: DashboardRemove | undefined;
}

export function initialDashboardList(): DashboardList {
  return { state: 'loading', openKeys: [DASHBOARDS_ITEM_KEY], dashboards: [], create: undefined, remove: undefined };
}

export function updateDashboards(model: DashboardList, dashboards: DashboardData[]): DashboardList {
  return { ...model, state: 'ready', dashboards };
}

export function asDashboardCreateUpdate(model: () => DashboardList, update: (_: DashboardList) => void) {
  return (create: DashboardUpsert | undefined) => update({ ...model(), create });
}

export function asDashboardRemoveUpdate(model: () => DashboardList, update: (_: DashboardList) => void) {
  return (remove: DashboardRemove | undefined) => update({ ...model(), remove });
}
