import { STEP_STATE } from '../../../../../wizard/WizardCommonTypesAndConstants';

export const SHARING_OPTIONS_IDS = {
  Public: 'public',
  Private: 'private',
};

export const SOURCE_FILE_OPTIONS = {
  ExistingDataSet: 'select',
  UploadFile: 'upload',
  ExistingS3Data: 'selectS3File',
};

export const WIZARD_STAGES = {
  SelectDataSource: 0,
  SelectOrUploadFile: 1,
  PrepareDpMappings: 2,
  ChooseWorkflowType: 3,
  MapData: 4,
  CreateQuery: 5,
  SelectOutputLocation: 6,
  SelectOutputTableOrFileInfo: 7,
  Generate: 8,
  Complete: 9,
};

export const WIZARD_STATE_KEYS = {
  SelectedSource: 'fileKey',
};

export enum SOURCE_TYPES {
  UploadedFile = 'uploadedFile',
  ExistingData = 'selectedFile',
  ExistingS3Data = 'selectedS3File',
}

export enum UPLOADED_FILE_DATA {
  /**
   * configuration key for information about an uploaded S3 file; column lengths, etc.
   */
  UploadedDataInfo = 'uploadedDataInfo',
}

export enum MAPPING_STEP_KEYS {
  UploadName = 'uploadName',
  SharingOption = 'sharingOption',
  MappingData = 'mappingData',
}

export const DESTINATION_TYPES = {
  DownloadFile: 'generate',
  Store: 'store',
};

export const BOOK_JOIN_SOURCE_TYPES = {
  ManualValue: 'manualValue',
};

export const DIP_SPECIAL_CONVERSION_IDS = {
  Symbology_InetTicker: 'Source ticker is in INET symbology',
  Symbology_NyseTicker: 'Source ticker is in NYSE symbology',
  Symbology_Cusip: 'Source ticker is in CUSIP symbology',
};

export const DIP_DATETIME_CONVERSION_IDS = {
  DateTime_SourceEpoch: 'Source date/time in ticks since Epoch',
  DateTime_SourceSecondsSinceMidnight: 'Source date/time in seconds since midnight',
  DateTime_SourceMicroSecondsSinceMidnight: 'Source date/time in microseconds since midnight',
  DateTime_DateTimeValue: 'Source date/time split by space',
  DateTime_DateValue: 'Source date used only',
};

export const DIP_SPECIAL_CONVERSION = Object.values(DIP_SPECIAL_CONVERSION_IDS);

export const DIP_DATETIME_CONVERSION = Object.values(DIP_DATETIME_CONVERSION_IDS);

export const DIP_PRODUCT_SEPARATORS = [
  '(Do Not Expand Data)',
  'Separator: \\',
  'Separator: /',
  'Separator: |',
  'Separator: ,',
  'Separator: $',
  'Separator: #',
  'Separator: ;',
];

export const DIP_COLUMN_DATA_TYPES = {
  '': '(Ignore Column)',
  String: 'String',
  Numeric: 'Numeric',
  Date: 'Date',
  Time: 'Time',
  DateTime: 'Date/Time',
};

export default {
  STEP_STATE,
  WIZARD_STAGES,
  SHARING_OPTIONS_IDS,
  DESTINATION_TYPES,
  DIP_SPECIAL_CONVERSION_IDS,
  DIP_PRODUCT_SEPARATORS,
  BOOK_JOIN_SOURCE_TYPES,
  DIP_SPECIAL_CONVERSION,
  SOURCE_TYPES,
  UPLOADED_FILE_DATA,
  DIP_DATETIME_CONVERSION_IDS,
  DIP_DATETIME_CONVERSION,
  DIP_COLUMN_DATA_TYPES,
  SOURCE_FILE_OPTIONS,
  WIZARD_STATE_KEYS,
  MAPPING_STEP_KEYS,
};

/* keep in sync with DB table (swilgate_dip_job_status) */
export const DipConstants = {
  CREATED_IDLE: 'CREATED_IDLE', //'Job has been created but is idle.',
  DIP_FAILED: 'DIP_FAILED', //'The DIP job has failed.',
  DIP_STARTED: 'DIP_STARTED', //'The DIP job has commenced.',
  DIP_RUNNING: 'DIP_RUNNING', //'The DIP job is running/heartbeating.',
  DIP_SUCCEEDED: 'DIP_SUCCEEDED', //'The DIP job has succeeded.',
  FILE_UPLOADED: 'FILE_UPLOADED', //'The file has been uploaded successfully.',
  FILE_UPLOADING: 'FILE_UPLOADING', //'The file upload has been commenced (optional step; may not be seen with S3 upload)'
  FILE_DOWNLOAD: 'FILE_DOWNLOAD', //'The file is being downloaded by a user.'
  PROCESSING_STARTED: 'PROCESSING_STARTED', //'The file post-processing has commenced.'
  FILE_DELETED: 'FILE_DELETED', //'The file has been deleted by a user',
  FILE_COLUMNS_PARSED: 'FILE_COLUMNS_PARSED', //'The file's columns have been successfully parsed',
  FILE_COLUMNS_PARSE_FAILURE: 'FILE_COLUMNS_PARSE_FAILURE', //'The file's columns could not be successfully parsed',
  WORKFLOW_SCHEDULED: 'WORKFLOW_SCHEDULED', //'The workflow has been scheduled using the supplied file'
  FILE_GENERATED: 'FILE_GENERATED', //'The file has been successfully generated'
};

interface Format {
  format?: string;
  example?: string;
  notes?: string | null;
}

interface FormatGroup {
  key: string;
  title: string;
  list: Format[];
}

export const DATE_FORMATS = [
  { format: 'YYYYMMDD', example: '20230503', notes: null },
  { format: 'YYYY-MM-DD', example: '2023-05-03', notes: 'with allowed date separators / . space -' },
  { format: 'YYYY-M-D', example: '2023-5-3', notes: 'with allowed date separators / . space -' },
  { format: 'MM-DD-YYYY', example: '05-03-2023', notes: 'with allowed date separators / . space -' },
  { format: 'M-D-YYYY', example: '5-3-2023', notes: 'with allowed date separators / . space -' },
  { format: 'D-MMM-YYYY', example: '3-May-2023', notes: 'with allowed date separators / . space -' },
  { format: 'DD-MMM-YYYY', example: '03-May-2023', notes: 'with allowed date separators / . space -' },
];

export const DATETIME_FORMATS = [
  { format: 'YYYY-MM-DD HH:mm', example: '2023-10-19 15:16', notes: 'with allowed date separators / . -' },
  { format: 'YYYY-MM-DD HH:mm:ss', example: '2023-10-19 15:16:17', notes: 'with allowed date separators / . -' },
  { format: 'YYYY-MM-DD HH:mm:ss.SSS', example: '2023-10-19 15:16:17.123', notes: 'with allowed date separators / . -' },
  { format: 'YYYY-MM-DD HH:mm:ss.SSSSSS', example: '2023-10-19 15:16:17.123456', notes: 'with allowed date separators / . -' },
  { format: 'YYYY-MM-DDTHH:mm', example: '2023-10-19T15:16', notes: null },
  { format: 'YYYY-MM-DDTHH:mm:ss', example: '2023-05-03T15:16:17', notes: null },
  { format: 'YYYY-MM-DDTHH:mm:ss.SSS', example: '2023-05-03T15:16:17.123', notes: null },
  { format: 'YYYY-MM-DDTHH:mm:ss.SSSSSS', example: '2023-05-03T15:16:17.123456', notes: null },
];

export const TIME_FORMATS = [
  { format: 'HH:mm', example: '15:16' },
  { format: 'HH:mm:ss', example: '15:16:17' },
];

export const ALL_FORMATS: FormatGroup[] = [
  { key: 'DateTime', title: DIP_COLUMN_DATA_TYPES['DateTime'], list: DATETIME_FORMATS },
  { key: 'Date', title: DIP_COLUMN_DATA_TYPES['Date'], list: DATE_FORMATS },
  { key: 'Time', title: DIP_COLUMN_DATA_TYPES['Time'], list: TIME_FORMATS },
  {
    key: 'DateTime_SourceEpoch',
    title: DIP_DATETIME_CONVERSION_IDS['DateTime_SourceEpoch'],
    list: [{ format: 'Integer number', example: '1694077260', notes: '- will be formatted in a readable datetime value' }],
  },
  {
    key: 'DateTime_DateTimeValue',
    title: DIP_DATETIME_CONVERSION_IDS['DateTime_DateTimeValue'],
    list: [{ format: 'YYYY MM dd HH mm ss', example: '2023 10 19 15 16 17' }],
  },
  {
    key: 'DateTime_DateValue',
    title: DIP_DATETIME_CONVERSION_IDS['DateTime_DateValue'],
    list: [{ format: 'see date/time input formats', example: 'keeps only the date' }],
  },
];
