import { NotificationItem } from '../models/notification';
import { NotificationsListModel } from '../models/notifications-list';

import React, { useState } from 'react';
import { Badge, Button, List, Popover } from 'antd';
import { sanitize } from 'dompurify';

import { CloseCircleOutlined } from '@ant-design/icons';

import tinydate from 'tinydate';

import { EmptyBellIconComponent } from '../../../../_shared/IconControls';

import styles from './NotificationsListControl.module.css';

const stamp = tinydate('{HH}:{mm}:{ss} {DD}-{MMM}-{YY}', { MMM: (d) => d.toLocaleString('default', { month: 'short' }) });

export interface NotificationListControlDelegate {
  onSetNotificationRead(notificationId?: number): Promise<void>;

  onNotificationClicked(notificationId: number): Promise<void>;

  onNotificationInvoked(notificationHandler: (notification: NotificationItem) => Promise<void>): void;
}

export function NotificationsListControl({
  notificationsModel,
  notificationsDelegate,
  isCollapsed,
}: {
  notificationsModel: NotificationsListModel;
  notificationsDelegate: NotificationListControlDelegate | undefined;
  isCollapsed: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const hasNotifications = notificationsModel.notifications?.length > 0;

  // TODO: not sure how this could work, yet, as we don't know if the user has explicitly clicked open
  // useEffect(() => {
  //   if (isOpen && isCollapsed && notificationsModel.notifications.length === 0) {
  //     setIsOpen(false);
  //   }
  // }, [isCollapsed, isOpen, notificationsModel.notifications]);

  const renderItem = (item: NotificationItem) => {
    const sanitizedDescription = sanitize(item.description ?? '');

    return (
      <List.Item
        key={item.id}
        role={'button'}
        className={!item.referencedEntity ? styles.notificationItemDisabled : ''}
        onClick={
          item.referencedEntity
            ? (event) => {
                event.stopPropagation();
                event.preventDefault();

                void notificationsDelegate?.onNotificationClicked?.(item.id);
              }
            : undefined
        }
        actions={[
          <Button
            icon={<CloseCircleOutlined className={styles.clearIcon} />}
            key={`button_${item.id}`}
            type={'text'}
            onClick={async (event) => {
              event.stopPropagation();
              event.preventDefault();

              await notificationsDelegate?.onSetNotificationRead(item.id);
            }}
          />,
        ]}
      >
        <List.Item.Meta
          avatar={<EmptyBellIconComponent />}
          title={item.title}
          description={
            <>
              <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }}></div>
              <div className={styles.notificationDate}>{stamp(new Date(item.createdAt?.toString()))}</div>
            </>
          }
        />
      </List.Item>
    );
  };

  return (
    <>
      {!isCollapsed && (
        <div className={styles.notificationsMenu}>
          {!hasNotifications && <div className={styles.noNotifications}>No Notifications</div>}
          {hasNotifications && (
            <>
              <div className={styles.notificationsHeader}>
                <Button
                  className={styles.clearAllButton}
                  onClick={async (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    await notificationsDelegate?.onSetNotificationRead();
                  }}
                >
                  <CloseCircleOutlined className={styles.clearAllIcon} /> Clear all
                </Button>
              </div>
              <List
                className={styles.popupContainer}
                loading={notificationsModel.loading}
                dataSource={notificationsModel.notifications}
                renderItem={renderItem}
              />
            </>
          )}
        </div>
      )}
      {isCollapsed && (
        <Popover
          overlayClassName={styles.notificationPopup}
          placement={'leftTop'}
          trigger={['click']}
          open={isOpen}
          onOpenChange={(open) => setIsOpen(open)}
          content={
            <div className={styles.notificationsMenu}>
              {!hasNotifications && <div className={styles.noNotifications}>No Notifications</div>}
              {hasNotifications && (
                <>
                  <div className={styles.notificationsHeader}>
                    <Button
                      className={styles.clearAllButton}
                      onClick={async (event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        await notificationsDelegate?.onSetNotificationRead();
                      }}
                    >
                      <CloseCircleOutlined className={styles.clearAllIcon} /> Clear all
                    </Button>
                  </div>
                  <List
                    className={styles.popupContainer}
                    loading={notificationsModel.loading}
                    dataSource={notificationsModel.notifications}
                    renderItem={renderItem}
                  />
                </>
              )}
            </div>
          }
        >
          <div className={styles.collapsedButton}>
            {/* Badge color a11y bug: https://github.com/ant-design/ant-design/issues/49543 */}
            <Badge color="#e12f31" count={notificationsModel.notifications.length > 99 ? '99+' : notificationsModel.notifications.length}>
              <Button aria-label="Notifications" type="text" icon={<EmptyBellIconComponent />} />
            </Badge>
          </div>
        </Popover>
      )}
    </>
  );
}
