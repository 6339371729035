import axios from 'axios';

import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';

import { Documentation, DocumentationType } from './models/documentation-list';
import { axiosOptions, GenericAPIResponse } from '../../services/requests';

export const DOCUMENTATION_BASE_ENDPOINT = '/api-ext/maystreet-documentation';

export interface DocumentationSearchResult {
  refIndex: number;
  item: {
    id: number;
    path: string;
  };
  matches?: { indices: [number, number][]; key: string; value: string }[];
}

export class DocumentationApi {
  async getAllDocumentationLinks(): Promise<Documentation[] | undefined> {
    return await checkAxiosResponse(axios.get<Documentation[]>(`${DOCUMENTATION_BASE_ENDPOINT}/docs`, await axiosOptions()));
  }

  async getMarkdownPage(docType: DocumentationType, filePath: string): Promise<string | undefined> {
    return await checkAxiosResponse(axios.get<string>(`${DOCUMENTATION_BASE_ENDPOINT}/${docType}/${filePath}`, await axiosOptions()));
  }

  async searchMidasDocumentation(
    docType: DocumentationType,
    query: string,
    limit = -1
  ): Promise<GenericAPIResponse<DocumentationSearchResult[]> | undefined> {
    return await checkAxiosResponse(
      axios.get(`${DOCUMENTATION_BASE_ENDPOINT}/search/${docType}?query=${query}&limit=${limit}&matches=true`, await axiosOptions())
    );
  }
}
