import React, { useMemo } from 'react';

import { Table } from 'antd';

import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';

import PagingDataTable from './paging/PagingDataTable';

import withSorter from '../_shared/WithSorter';
import { DataQueryProductRow, DataQueryProductRowOutput, DataQueryProductServerInfo } from '../../../../types';

export type GenericDataTableParams = {
  tableColumns?: JSX.Element[];
  products?: string[];
  product?: string;
  dataLoading?: boolean;
  dataError?: string | object;
  dataIndex?: string;
  onSelectProduct?: (value: string, option: { value: string; label: string } | { value: string; label: string }[]) => void;
  productServerInfo?: DataQueryProductServerInfo;
  onDownload?: (selectedItem: string) => void;
  columnNameModifier?: (name: string) => string;
  data: DataQueryProductRow[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subTableRowRenderer?: (column: string) => (value: any) => string | number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultSubTableRowRenderer = () => (value: any) => {
  const isBoolean = typeof value === 'boolean';

  if (!isBoolean) {
    return value;
  }

  return value ? 'TRUE' : 'FALSE';
};

const DEFAULT_PROPS = {
  products: [],
  onSelectProduct: () => {},
  onDownload: () => {},
  tableColumns: [],
  columnNameModifier: (name: string) => startCase(toLower(name)),
};

export default function GenericDataTable({
  products = DEFAULT_PROPS.products,
  product = undefined,
  onSelectProduct = DEFAULT_PROPS.onSelectProduct,
  productServerInfo = undefined,
  dataLoading = false,
  dataError = undefined,
  onDownload = DEFAULT_PROPS.onDownload,
  tableColumns = DEFAULT_PROPS.tableColumns,
  columnNameModifier = DEFAULT_PROPS.columnNameModifier,
  subTableRowRenderer = defaultSubTableRowRenderer,
  data,
}: GenericDataTableParams) {
  const selectedData = useMemo(() => {
    if (!data || !data.length) {
      return [];
    }

    const productData = data.find((details) => details.product === product);

    if (!productData) {
      console.info('[GenericDataTable] Selected product not in results...');
      return [];
    }

    return productData.output;
  }, [product, data]);

  const actualTableColumns = useMemo(() => {
    if (tableColumns && tableColumns.length > 0) {
      return tableColumns.map(({ props, type: Column }) => withSorter(Column)({ key: props.dataIndex, ...props }));
    }

    if (selectedData.length === 0) {
      return [];
    }

    return Object.keys(selectedData[0])
      .filter(
        (fieldName) =>
          !['key', 'dateTicks'].includes(fieldName) && typeof selectedData[0][fieldName as keyof DataQueryProductRowOutput] !== 'object'
      )
      .map((fieldName) =>
        withSorter(Table.Column)({
          key: fieldName,
          title: columnNameModifier(fieldName),
          dataIndex: fieldName,
          render: defaultSubTableRowRenderer(),
        })
      );
  }, [tableColumns, selectedData, columnNameModifier]);

  return (
    <PagingDataTable
      tableColumns={actualTableColumns}
      products={products}
      data={selectedData}
      dataLoading={dataLoading}
      dataError={dataError}
      product={product}
      productServerInfo={productServerInfo}
      onSelectProduct={onSelectProduct}
      onDownload={onDownload}
      columnNameModifier={columnNameModifier}
      subTableRowRenderer={subTableRowRenderer}
    />
  );
}
