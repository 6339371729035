import React, { MouseEventHandler, useMemo, useState } from 'react';

import { Table } from 'antd';

import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';

import PagingDataTable, { PaginationType } from './paging/PagingDataTable';

import withSorter from '../_shared/WithSorter';
import { DEFAULT_PAGE_SIZE } from '../_shared/table-utils';
import { DataQueryTableColumn, DataQueryProductServerInfo, DataQueryProductRow, DataQueryProductRowOutput } from '../../../../types';

type CursorPaginationDataTableParams = {
  tableColumns?: DataQueryTableColumn[];
  columnNameModifier?: (name: string) => string;
  defaultPageSize?: number;
  onPageSizeChanged?: (previous: number, next: number) => void;
  data: DataQueryProductRow[];
  dataLoading?: boolean;
  dataError?: string | object;
  dataIndex?: string;
  onDownload?: (selectedItem: string) => void;
  products?: string[];
  product?: string;
  productServerInfo?: DataQueryProductServerInfo;
  onSelectProduct?: (value: string, option: { value: string; label: string } | { value: string; label: string }[]) => void;
  currentPage?: number;
  onCursorBack?: MouseEventHandler<HTMLElement>;
  onCursorNext?: MouseEventHandler<HTMLElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subTableRowRenderer?: () => (value: any) => string;
};

const defaultSubTableRowRenderer =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: any): string => {
    const isBoolean = typeof value === 'boolean';

    if (!isBoolean) {
      return value;
    }

    return value ? 'TRUE' : 'FALSE';
  };

const DEFAULT_PROPS = {
  products: [],
  onPageSizeChanged: () => {},
  onCursorBack: () => {},
  onCursorNext: () => {},
  onSelectProduct: () => {},
  onDownload: () => {},
  tableColumns: [],
  columnNameModifier: (name: string) => startCase(toLower(name)),
};

export default function CursorPaginationDataTable({
  products = DEFAULT_PROPS.products,
  product = undefined,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  onPageSizeChanged = DEFAULT_PROPS.onPageSizeChanged,
  currentPage = 0,
  onCursorBack = DEFAULT_PROPS.onCursorBack,
  onCursorNext = DEFAULT_PROPS.onCursorNext,
  onSelectProduct = DEFAULT_PROPS.onSelectProduct,
  productServerInfo = undefined,
  dataLoading = false,
  dataError = undefined,
  onDownload = DEFAULT_PROPS.onDownload,
  tableColumns = DEFAULT_PROPS.tableColumns,
  columnNameModifier = DEFAULT_PROPS.columnNameModifier,
  subTableRowRenderer = defaultSubTableRowRenderer,
  data,
}: CursorPaginationDataTableParams) {
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const selectedData = useMemo(() => {
    if (!data || !data.length) {
      return [];
    }

    const productData = data.find((details) => details.product === product);

    if (!productData) {
      console.info('[CursorPaginationDataTable] Selected product not in results...');
      return [];
    }

    return productData.output;
  }, [product, data]);

  const actualTableColumns = useMemo(() => {
    if (tableColumns && tableColumns.length > 0) {
      return tableColumns.map(({ props, type: Column }) => withSorter(Column)({ key: props.dataIndex, ...props }));
    }

    if (selectedData.length === 0) {
      return [];
    }

    return Object.keys(selectedData[0])
      .filter(
        (fieldName) =>
          !['key', 'dateTicks'].includes(fieldName) && typeof selectedData[0][fieldName as keyof DataQueryProductRowOutput] !== 'object'
      )
      .map((fieldName) =>
        withSorter(Table.Column)({
          key: fieldName,
          title: columnNameModifier(fieldName),
          dataIndex: fieldName,
          render: defaultSubTableRowRenderer(),
        })
      );
  }, [tableColumns, selectedData, columnNameModifier]);

  const handlePageSizeChanged = (previous: number, next: number) => {
    setPageSize(next);
    onPageSizeChanged(previous, next);
  };

  return (
    <PagingDataTable
      tableColumns={actualTableColumns}
      columnNameModifier={columnNameModifier}
      products={products}
      data={selectedData}
      dataLoading={dataLoading}
      dataError={dataError}
      onDownload={onDownload}
      product={product}
      onSelectProduct={onSelectProduct}
      productServerInfo={productServerInfo}
      paginationType={PaginationType.CURSOR}
      currentPage={currentPage}
      onCursorBack={onCursorBack}
      onCursorNext={onCursorNext}
      pageSize={pageSize}
      onPageSizeChanged={handlePageSizeChanged}
      subTableRowRenderer={subTableRowRenderer}
    />
  );
}
