export const ROOT_SERVER_URL = process.env.NODE_ENV === 'production' ? `${window.location.origin}/` : 'http://localhost:3001/';

export const WS_SERVER_URL =
  process.env.NODE_ENV === 'production'
    ? `${window.location.origin}/`.replace('https://', 'wss://')
    : `wss://websocket.wb.local.maystreet.com:3010/`;

export const getTypeaheadSearchUrl = (query: string) => `${ROOT_SERVER_URL}api/static/typeahead?filter=${encodeURIComponent(query)}`;

export const FEED_FAMILIES_URL = `${ROOT_SERVER_URL}api/static/feedsAndFamilies`;

export const DIP_CONFIG_URL = `${ROOT_SERVER_URL}api/static/dipFeeds`;

export const PRODUCTS_QUERY_URL = `${ROOT_SERVER_URL}api/querying/products`;

export const DP_TABLE_NAME_ALL_URL = `${ROOT_SERVER_URL}api/querying/dp/tables`;

export const DP_TABLE_NAME_BASE_URL = `${ROOT_SERVER_URL}api/querying/dp/tables?type=`;

export const DP_GET_TABLE_DATA_URL = `${ROOT_SERVER_URL}api/querying/dp/`;

export const REFERENCE_GET_TABLE_DATA_URL = `${ROOT_SERVER_URL}api/querying/reference/`;

export const S3_CALLBACK_URL = `${ROOT_SERVER_URL}api/static/s3-redirect`;

export const WF_SERVICES_URL = `${ROOT_SERVER_URL}api/services/dp/wf`;

export const WF_WIZARD_SERVICES_URL = `${ROOT_SERVER_URL}api/services/dp/wizard`;

export const WF_WIZARD_MAPPING_STEP_URL = `${ROOT_SERVER_URL}api/services/dp/wizard/workflowStep/createColumns`;

export const WF_RUNNING_STATE_URL = `${ROOT_SERVER_URL}api/services/dp/runningWorkflow`;

export const WF_DUMP_ALL_URL = `${ROOT_SERVER_URL}api/services/dp/auditWorkflow`;

export const USER_DATA_STORE_URL = `${ROOT_SERVER_URL}api/services/user-data-store`;

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const AMERICA_NY_TIMEZONE = 'America/New_York';

export const AMERICAN_DATE_FORMAT = 'MM/DD/YYYY';
// export const AMERICAN_DATE_TIME_FORMAT = 'YYYY/MM/DD hh:MM:SS a';

export const FORM_LAYOUT_LABEL = { xs: 24, sm: 8, md: 5, lg: 4, xxl: 2 };

export const FORM_LAYOUT_CONTROL = { xs: 24, sm: 5, md: 4, lg: 3, xxl: 2 };

export const FORM_LAYOUT_CONTROL_OFFSET = {
  xs: 24,
  sm: { offset: 5 },
  md: { offset: 5 },
  lg: { offset: 4 },
  xxl: { offset: 2 },
};

export const MODAL_FORM_LAYOUT_LABEL = { xs: 24, sm: 6, md: 4 };

export const MODAL_FORM_LAYOUT_CONTROL_OFFSET = {
  xs: 24,
  sm: { offset: 6 },
  md: { offset: 4 },
};

export const ADVANCED_SEARCH_DISABLED_FEED_FAMILIES = ['futures', 'fi'];

export const ACTIVITY_TAB_ID = '00112233-4455-6677-8899-aabbccddeeff';
