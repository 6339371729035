import isEqual from 'lodash/isEqual';

import { getUserSubject } from '../../../services/auth';
import { ErrorNotification } from '../../../services/error-notification';
import { WbApi, UserAssignmentSpecifier } from '../workbench-api';
import { UsersApi, User } from '../../users/users-api';
import { WbInstance } from './instance';
import { collapseUsers } from '../../users/utils';

export interface InstanceReassign {
  state: 'loading' | 'ready' | 'saving';
  instance: WbInstance;
  objectType: string;
  selectedUserSubject: { canAssignToAnyone: false; userId: string } | { canAssignToAnyone: true; email: string };
  users: User[];
}

export async function loadInstanceReassign(usersApi: UsersApi, instance: WbInstance, update: (_: InstanceReassign) => void): Promise<void> {
  const objectType = 'instance';

  update({ state: 'loading', objectType, instance, users: [], selectedUserSubject: { canAssignToAnyone: false, userId: '' } });

  const currentUserId = await getUserSubject();
  const reassignmentOptions = (await usersApi.getUsers()) || { allUserGroups: [], canAssignToAnyone: false };

  const users = collapseUsers(reassignmentOptions.allUserGroups);

  let selectedUserSubject: InstanceReassign['selectedUserSubject'];
  if (reassignmentOptions.canAssignToAnyone) {
    selectedUserSubject = { canAssignToAnyone: true, email: '' };
  } else {
    selectedUserSubject = { canAssignToAnyone: false, userId: currentUserId ?? '' };
  }
  update({
    state: 'ready',
    objectType,
    instance,
    users,
    selectedUserSubject,
  });
}

export function instanceReassignSetUser(
  model: InstanceReassign | undefined,
  userIdOrEmail: UserAssignmentSpecifier
): InstanceReassign | undefined {
  if (!model) {
    return model;
  }

  const result = { ...model };
  if (model.selectedUserSubject.canAssignToAnyone && 'email' in userIdOrEmail) {
    result.selectedUserSubject = { canAssignToAnyone: true, email: userIdOrEmail.email };
  } else if (!model.selectedUserSubject.canAssignToAnyone && 'userId' in userIdOrEmail) {
    result.selectedUserSubject = { canAssignToAnyone: false, userId: userIdOrEmail.userId };
  }

  return result;
}

export async function instanceReassignSave(
  wbApi: WbApi,
  model: InstanceReassign | undefined,
  refresh: () => Promise<void>,
  update: (_: InstanceReassign | undefined) => void
): Promise<ErrorNotification | undefined> {
  if (!model) {
    return;
  }

  update({ ...model, state: 'saving' });

  const currentUserSubject = await getUserSubject();
  if (!model.selectedUserSubject || isEqual(model.selectedUserSubject, { userId: currentUserSubject })) {
    // Close modal
    update(undefined);
    return;
  }

  const targetUser = model.selectedUserSubject;
  const targetUserArgument = targetUser.canAssignToAnyone ? { email: targetUser.email } : { userId: targetUser.userId };
  const res = await wbApi.reassignInstance({ id: model.instance.id, user: targetUserArgument });
  if (!res?.ok) {
    update({ ...model, state: 'ready' });
    return { message: 'Error reassigning instance' };
  }

  try {
    await refresh();
  } finally {
    update(undefined);
  }
}
