import { handleError } from '../../services/error-notification';
import { showNotification } from '../../_shared/views/Notifications';
import { UsersApi } from '../users/users-api';
import { AuthorisationApi } from './authorisation-api';
import { AddGitlabApiKeyModel, asAddGitlabApiKeyUpdate, loadAddGitlabApiKey, saveGitlabApiKey } from './models/add-gitlab-api-key';
import {
  asGenerateApiKeyUpdate,
  generateApiKey,
  GenerateApiKeyModel,
  loadGenerateApiKey,
  updateExpirationDays,
  updateState,
} from './models/generate-api-key';
import { checkInUser, FLAG_ENABLE_WB_INSIDE_ZOE, initialUserInfo, UserInfoModel } from './models/user-info';
import { UserInfoDelegate } from './views/UserInfo';
import { asUserPreferencesUpdate, loadUserPreferences, saveUserPreferences } from './models/user-preferences';
import { WbUserPreferenceRow } from '@mst-fe/shared';

export class UserInfoController implements UserInfoDelegate {
  private authApi = new AuthorisationApi();
  private usersApi = new UsersApi();

  private model = initialUserInfo();

  private getModel = (): UserInfoModel => this.model;

  private getAddGitlabApiKeyModel = (): AddGitlabApiKeyModel | undefined => this.model.addGitlabApiKey;

  private getGenerateApiKeyModel = (): GenerateApiKeyModel | undefined => this.model.generateApiKey;

  private update = (model: UserInfoModel) => {
    this.model = model;
    this.updateViewState(model);
  };

  private updateAddGitlabApiKey = asAddGitlabApiKeyUpdate(this.getModel, this.update);

  private updateGenerateApiKey = asGenerateApiKeyUpdate(this.getModel, this.update);

  private updateUserPreferences = asUserPreferencesUpdate(this.getModel, this.update);

  constructor(private updateViewState: (_: UserInfoModel) => void) {}

  onGitlabApiKeyChange(value: string): void {
    this.updateAddGitlabApiKey({ apiKey: value, loading: false });
  }

  onCancelAddGitlabApiKey(): void {
    this.updateAddGitlabApiKey(undefined);
  }

  onCloseGenerateApiKey(): void {
    this.updateGenerateApiKey(undefined);
  }

  onChangeExpirationDays(expirationDays: number): void {
    const generateApiKeyModel = this.getGenerateApiKeyModel();
    if (generateApiKeyModel) {
      this.updateGenerateApiKey(updateExpirationDays(generateApiKeyModel, expirationDays));
    }
  }

  onStartGenerateApiKey(): void {
    loadGenerateApiKey(this.updateGenerateApiKey);
  }

  onCopyGeneratedKey(): void {
    const generateApiKeyModel = this.getGenerateApiKeyModel();
    if (generateApiKeyModel) {
      this.updateGenerateApiKey(updateState(generateApiKeyModel, 'copied'));
    }
  }

  async onGenerateApiKey(): Promise<void> {
    const generateApiKeyModel = this.getGenerateApiKeyModel();
    if (generateApiKeyModel) {
      await generateApiKey(this.authApi, generateApiKeyModel, this.updateGenerateApiKey);
    }
  }

  async onConfirmAddGitlabApiKey(): Promise<void> {
    const result = await saveGitlabApiKey(this.usersApi, this.getAddGitlabApiKeyModel, this.updateAddGitlabApiKey);
    if (result) {
      showNotification(result);
    }
  }

  async onStartAddGitlabApiKey(): Promise<void> {
    handleError(await loadAddGitlabApiKey(this.usersApi, this.updateAddGitlabApiKey));
  }

  async onAppStartedWithUser(): Promise<void> {
    handleError(await checkInUser(this.usersApi, this.getModel, this.update));
  }

  /**
   * Force the feature flag to true to enable WiWoOF if we're
   * running inside Workspaces..
   */
  onAppStartedInsideWorkspaces(): void {
    const model = this.getModel();
    this.update({ ...model, featureFlags: [...model.featureFlags, FLAG_ENABLE_WB_INSIDE_ZOE] });
  }

  async onOpenUserPreferences(): Promise<void> {
    handleError(await loadUserPreferences(this.usersApi, this.updateUserPreferences));
  }

  private onEditUserPreference(preferences: Partial<WbUserPreferenceRow>): void {
    if (this.model.userPreferences) {
      this.updateUserPreferences({
        ...this.model.userPreferences,
        touched: true,
        preferences: { ...(this.model.userPreferences.preferences as WbUserPreferenceRow), ...preferences },
      });
    }
  }

  onEditStaleInstancesAlertsEnabled(staleInstancesAlertsEnabled: boolean): void {
    this.onEditUserPreference({ staleInstancesAlertsEnabled });
  }

  onCancelUserPreferences(): void {
    this.updateUserPreferences(undefined);
  }

  async onSaveUserPreferences(): Promise<void> {
    if (this.model.userPreferences?.touched && this.model.userPreferences.preferences) {
      handleError(await saveUserPreferences(this.usersApi, this.model.userPreferences.preferences));
    }
    this.onCancelUserPreferences();
  }
}
