import { AuthorisationApi } from '../authorisation-api';
import { UserInfoModel } from './user-info';

const SECONDS_IN_DAY = 86400;

export interface GenerateApiKeyModel {
  apiKey: string;
  expirationDays: number;
  state: 'ready' | 'generated' | 'copied';
  error: string | undefined;
}

export function initialApiKey(): GenerateApiKeyModel {
  return { apiKey: '', expirationDays: 1, state: 'ready', error: undefined };
}

export function asGenerateApiKeyUpdate(model: () => UserInfoModel, update: (_: UserInfoModel) => void) {
  return (generateApiKey: GenerateApiKeyModel | undefined) => update({ ...model(), generateApiKey });
}

export function updateExpirationDays(model: GenerateApiKeyModel, expirationDays: number): GenerateApiKeyModel {
  return { ...model, expirationDays };
}

export function updateApiKey(model: GenerateApiKeyModel, apiKey: string): GenerateApiKeyModel {
  return { ...model, apiKey, state: 'generated' };
}

export function updateState(model: GenerateApiKeyModel, state: 'ready' | 'generated' | 'copied'): GenerateApiKeyModel {
  return { ...model, state };
}

export function updateError(model: GenerateApiKeyModel, error: string): GenerateApiKeyModel {
  return { ...model, error };
}

export function loadGenerateApiKey(update: (_: GenerateApiKeyModel) => void): void {
  update({
    apiKey: '',
    expirationDays: 1,
    state: 'ready',
    error: undefined,
  });
}

export async function generateApiKey(
  api: AuthorisationApi,
  model: GenerateApiKeyModel,
  update: (_: GenerateApiKeyModel) => void
): Promise<void> {
  if (!model.expirationDays) {
    update(updateError(model, 'Expiration time required.'));
    return;
  }

  if (!window.CONFIG.isMidasPeer && model.expirationDays > 90) {
    update(updateError(model, 'Expiration days must be below 90.'));
    return;
  }

  const apiKey = await api.generateApiKey(model.expirationDays * SECONDS_IN_DAY);

  if (apiKey) {
    update(updateApiKey(model, apiKey));
  } else {
    update(updateError(model, 'An error occured while generating API key'));
  }
}
