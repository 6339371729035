import React, { useMemo } from 'react';

import { Typography } from 'antd';

import DataGraph, { xAxisColumnDisplayTypeEnum } from './graph/DataGraph';

import { sampleDataPoints } from '../_shared/GraphUtils';
import { DataQueryProductRow } from '../../../../types';

export type GenericDataGraphParams = {
  dataPoints?: Record<string, unknown>;
  data?: DataQueryProductRow[];
  dataLoading?: boolean;
  dataError?: string | object;
  products?: string[];
  columnNameModifier?: (name: string) => string;
  xAxisColumn?: string;
  xAxisColumnType?: 'number' | 'category';
  xAxisColumnDisplayType?: xAxisColumnDisplayTypeEnum;
  xAxisLabelColumn?: string;
};

export default function GenericDataGraph({
  products = [],
  data = [],
  dataPoints = undefined,
  dataLoading = false,
  dataError = undefined,
  columnNameModifier = undefined,
  xAxisColumn = undefined,
  xAxisColumnType = undefined,
  xAxisColumnDisplayType = undefined,
  xAxisLabelColumn = undefined,
}: GenericDataGraphParams) {
  const sampleGraphData = useMemo(() => {
    if (!data.length) {
      return [];
    }

    return sampleDataPoints(data, Object.keys(dataPoints!));
  }, [data, dataPoints]);

  const overflowedProductData = data?.find((productData) => productData.overflowed);

  return (
    <>
      {overflowedProductData && (
        <Typography.Text type="danger">{`The result of your query has too many rows, only the first ${overflowedProductData.lineCount} values are shown. You can try to reduce the time range or use the 'Download Data' button to download the entire dataset.`}</Typography.Text>
      )}
      <DataGraph
        dataPoints={dataPoints}
        products={products}
        data={sampleGraphData}
        dataLoading={dataLoading}
        dataError={dataError}
        columnNameModifier={columnNameModifier}
        xAxisColumn={xAxisColumn}
        xAxisColumnType={xAxisColumnType}
        xAxisColumnDisplayType={xAxisColumnDisplayType}
        xAxisLabelColumn={xAxisLabelColumn}
      />
    </>
  );
}
