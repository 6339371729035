import React from 'react';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import { TempAccessRequest } from '../workbench-api';
import { showNotification } from '../../../_shared/views/Notifications';

export interface TempAccessNotificationsDelegate {
  onTempAccessNotificationConfirm(notification: TempAccessRequest): void;
  onTempAccessNotificationDeny(notification: TempAccessRequest): void;
}

function notificationKey(model: TempAccessRequest): string {
  return `temp-access-request-${model.id}`;
}

export function showTempAccessNotifications({
  model,
  delegate,
}: {
  model: TempAccessRequest[];
  delegate: TempAccessNotificationsDelegate;
}) {
  if (!model || model.length === 0) {
    return null;
  }

  for (const n of model) {
    if (n.status !== 'REQUESTED') {
      continue;
    }

    const onConfirm = () => {
      delegate.onTempAccessNotificationConfirm(n);
    };

    const onDeny = () => {
      delegate.onTempAccessNotificationDeny(n);
    };

    const btn = (
      <div>
        <Button onClick={onDeny}>Deny</Button>
        <Button type="primary" style={{ marginLeft: '20px' }} onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    );
    showNotification({
      type: 'open',
      message: `Allow admin to access your ${n.shortId} instance?`,
      description: `After you grant access to the admin, you will lose your control of the instance until it's returned to you.`,
      btn,
      key: notificationKey(n),
      duration: 300,
      config: {
        placement: 'topRight',
        bottom: 50,
      },
    });
  }
}

export function hideTempAccessNotifications({ model }: { model: TempAccessRequest }) {
  notification.destroy(notificationKey(model));
}
