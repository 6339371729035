/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import flatten from 'lodash/flatten';

import { LinkedItemChangedPayload } from './wow-context-bridge';

export function extractChange(data: LinkedItemChangedPayload[], identifier: string): string[] | undefined {
  const filteredItems = (Array.isArray(data) ? data : [data])
    .map((row: any) => {
      const identifierList = row?.IdentifierList as string[];
      if (!identifierList || !identifierList.includes(identifier)) {
        return undefined;
      }

      const value = row?.[identifier];
      return (Array.isArray(value) ? value : [value]).map((a) => a.toString());
    })
    .filter((a) => a);

  // a user selecting nothing may need to be handled here, so we don't return an empty array.
  if (filteredItems.length === 0) {
    return undefined;
  }

  return flatten(filteredItems);
}

export function createWorkspaceRicChangeEvent(ric: string[] | string): Partial<LinkedItemChangedPayload> {
  return {
    FieldList: ['SelectionNS'],
    IdentifierList: ['ParameterList', 'RIC'],
    RIC: ric,
    ParameterList: '',
    SelectionNS: 'RIC',
  };
}
