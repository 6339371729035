import React, { useState } from 'react';

import uniq from 'lodash/uniq';
import get from 'lodash/get';
import compact from 'lodash/compact';

import DataGraph, { xAxisColumnDisplayTypeEnum } from '../graph/DataGraph';
import GraphControlPanel from './GraphControlPanel';

import useWebDataLoader from '../../../../../../hooks/use-web-data-loader';
import { anyEmpty } from '../../../../../../utils/config-utils';
import { dpProductName } from '../../../../../../utils/server-client-shared';

import { getTableName } from '../../_shared/DpUtils';

import { DP_GET_TABLE_DATA_URL } from '../../../../../../utils/midas-constants';

import classes from './DpDataGraph.module.css';
import { DataQueryConfig, DataQueryProductColumn, DataQueryProductRow } from '../../../../../types';

type DpDataGraphParams = {
  config: DataQueryConfig;
  onConfigChanged?: (config: DataQueryConfig) => void;
};

type DpDataGraphColumn = {
  name: string;
};

export const queryProvider = (config: DataQueryConfig, columnMetadata: DpDataGraphColumn[]) => {
  const tableName = getTableName(config);
  if (!tableName) {
    return {};
  }

  const availableColumns = columnMetadata.map(({ name }) => name);

  const { vizConfig: { visibleColumns: averageFor = [], indexColumn: against = undefined, groupByColumns: groupBy } = {} } = config;

  const shouldGenerate = compact([...averageFor, against, groupBy]).every((column) => availableColumns.includes(column));

  if (!shouldGenerate) {
    return {};
  }

  const url = new URL(`${DP_GET_TABLE_DATA_URL}${tableName}/visualise`);

  url.searchParams.append('averageFor', uniq([...averageFor, against]).toString());
  url.searchParams.append('against', against ? against.toString() : '');
  if (groupBy) {
    url.searchParams.append('groupBy', groupBy);
  }

  return !anyEmpty(url.searchParams, ['groupBy']) ? { url: url.toString() } : {};
};

const metadataQueryProvider = (config: DataQueryConfig) => {
  const tableName = getTableName(config);

  if (!tableName) {
    return {};
  }

  return {
    url: tableName && `${DP_GET_TABLE_DATA_URL}${tableName}/info`,
  };
};

const DEFAULT_PROPS = {
  onConfigChanged: () => {},
};

export default function DpDataGraph({ config, onConfigChanged = DEFAULT_PROPS.onConfigChanged }: DpDataGraphParams) {
  const [isCustomiseOpen, setCustomiseOpen] = useState(false);

  const [tableMetadata] = useWebDataLoader(metadataQueryProvider(config));
  const columnMetadata = get(tableMetadata, 'data[0].columnMetadata', []);
  const sampleData = get(tableMetadata, 'data[0].sampleData', []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [graphData] = useWebDataLoader<any>(queryProvider(config, columnMetadata));

  const { vizConfig: { visibleColumns, indexColumn } = {} } = config;

  const dataPoints = (visibleColumns || []).reduce((acc: Record<string, unknown>, columnName) => {
    acc[columnName] = columnName;
    return acc;
  }, {}) as DataQueryProductRow;

  function infereType(indexColumn?: string): xAxisColumnDisplayTypeEnum | undefined {
    if (!indexColumn) return undefined;
    const column = columnMetadata.find((column: DataQueryProductColumn) => column.name.toLowerCase() === indexColumn.toLowerCase());
    return column ? (column.fieldType as xAxisColumnDisplayTypeEnum) : undefined;
  }

  return (
    <div className={classes.drawerWrapper}>
      <GraphControlPanel
        isOpen={isCustomiseOpen}
        onClose={() => setCustomiseOpen(false)}
        columnMetadata={columnMetadata}
        config={config}
        onConfigChanged={onConfigChanged}
        sampleData={sampleData}
      />
      <DataGraph
        dataPoints={dataPoints}
        shouldShowTime={false}
        data={graphData.data}
        dataLoading={graphData.loading}
        products={[dpProductName]}
        ignoreProductName
        onConfigure={() => setCustomiseOpen(true)}
        xAxisColumn={indexColumn}
        xAxisColumnDisplayType={infereType(indexColumn)}
      />
    </div>
  );
}
