import { anyEmpty } from '../../../../../utils/config-utils';

export const buildUrl = (base: string, params: { [key: string]: unknown }, emptyIsValid: string[] = []) => {
  const url = new URL(base);

  for (const [name, value] of Object.entries(params)) {
    url.searchParams.append(name, String(value));
  }

  return !anyEmpty(url.searchParams, emptyIsValid) && url.toString();
};
