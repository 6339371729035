import React from 'react';

import { Col, Row, Switch } from 'antd';

import classes from './MultipleSwitchesControl.module.css';

type MultipleSwitchesControlParams = {
  value?: string[];
  onChange?: (values: string[]) => void;
  values?: string[];
  showAll?: boolean;
  disabled?: boolean;
  allDisablesOthers?: boolean;
  availableValues?: string[];
};

const switchColLayout = {
  xs: 12,
  sm: 8,
  md: 6,
  lg: 4,
  xl: 3,
  xxl: 2,
  xxxl: 1,
};

const DEFAULT_PROPS = {
  onChange: () => {},
  value: [],
  values: ['ON', 'OFF'],
  availableValues: [],
};

export default function MultipleSwitchesControl({
  onChange = DEFAULT_PROPS.onChange,
  value = DEFAULT_PROPS.value,
  values = DEFAULT_PROPS.values,
  showAll = false,
  disabled = false,
  allDisablesOthers = true,
  availableValues = DEFAULT_PROPS.availableValues,
}: MultipleSwitchesControlParams) {
  const createHandleSwitchChange = (name: string) => (checked: boolean) => {
    const setOfValues = new Set(value);

    if (checked) {
      setOfValues.add(name);
    } else {
      setOfValues.delete(name);
    }

    if (!allDisablesOthers) {
      if (name === 'ALL') {
        onChange(checked ? ['ALL'] : []);
        return;
      }
      setOfValues.delete('ALL');
    }
    onChange(Array.from(setOfValues.values()));
  };

  return (
    <Row>
      {values.map((key) => (
        <Col key={key} {...switchColLayout}>
          <Switch
            className={classes.radioItem}
            checked={value.includes(key)}
            disabled={
              disabled ||
              (allDisablesOthers && value.includes('ALL')) ||
              (availableValues.length && !availableValues.includes(key)) ||
              false
            }
            onChange={createHandleSwitchChange(key)}
            title={key}
          />
          {key}
        </Col>
      ))}
      {showAll && (
        <Col {...switchColLayout}>
          <Switch
            className={classes.radioItem}
            checked={value.includes('ALL')}
            disabled={disabled}
            onChange={createHandleSwitchChange('ALL')}
            title="all"
          />
          ALL
        </Col>
      )}
    </Row>
  );
}
