import React from 'react';
import { MonitorOutlined } from '@ant-design/icons';
import { FEEDS_MONITOR_KEY, STATUS_DASHBOARD, MONITORING_APPS_KEY_PREFIX, MONITORING_APPS_KEY } from '../../../side-nav/SideNav';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

function getMonitoringAppsNavItems(showStatusDashboard: boolean): ItemType[] {
  const items = [
    {
      label: 'Feeds Monitor',
      icon: <MonitorOutlined />,
      key: `${MONITORING_APPS_KEY_PREFIX}${FEEDS_MONITOR_KEY}`,
    },
  ];
  if (showStatusDashboard) {
    items.push({
      label: 'Status Dashboard',
      icon: <MonitorOutlined />,
      key: `${MONITORING_APPS_KEY_PREFIX}${STATUS_DASHBOARD}`,
    });
  }

  return items;
}

export function getMonitoringAppsNav(showStatusDashboard: boolean): ItemType {
  return {
    key: MONITORING_APPS_KEY,
    label: 'Monitoring Apps',
    icon: <MonitorOutlined />,
    children: getMonitoringAppsNavItems(showStatusDashboard),
  };
}
