import React from 'react';
import { Button, Layout, Popover } from 'antd';

import startCase from 'lodash/startCase';

import styles from './AppFooter.module.css';

const { Footer } = Layout;

export type VersionInfo = {
  version: string | undefined;
  branch: string | undefined;
  pipeline: string | undefined;
  namespace: string | undefined;
};

export function getContent(versionInfo: VersionInfo, type: string) {
  return Object.entries(versionInfo).map(([key, value]) => (
    <tr key={key}>
      <td>{startCase(key)}</td>
      <td>:</td>
      <td>{(value || 'Unknown').toString()}</td>
    </tr>
  ));
}

function AppFooter({ localVersionInfo }: { localVersionInfo: VersionInfo }) {
  const {
    supportEmailAddress,
    branding: { shortName },
  } = window.CONFIG;

  return (
    <Footer className="app-footer">
      <div className={'global-footer-text'}>
        &copy; {new Date().getFullYear()} LSEG PLC. All rights reserved. Authorized users only. |{'   '}
        <a href={`mailto:${supportEmailAddress}?subject=${shortName}%20Support%20Question`}>Support</a>
        {'   '}|{'   '}
        <a href="https://www.lseg.com/en/policies" target="_blank" rel="noreferrer">
          Copyright
        </a>
        {'   '}|{'   '}
        <Popover
          trigger={['click']}
          placement="topRight"
          title="Version Information"
          content={
            <table>
              <tbody>
                <tr>
                  <td colSpan={2} className={styles.headerText}>
                    Server Version
                  </td>
                </tr>
                {getContent(window.CONFIG.versionInfo, 'Server')}
                <tr>
                  <td colSpan={2} className={`${styles.headerText} ${styles.secondaryHeaderText}`}>
                    Client Version
                  </td>
                </tr>
                {getContent(localVersionInfo, 'Client')}
              </tbody>
            </table>
          }
        >
          <Button type={'link'}>Version Info</Button>
        </Popover>
      </div>
    </Footer>
  );
}

export default AppFooter;
