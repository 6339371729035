import React from 'react';
import { SearchOutlined } from '@ant-design/icons';

export default function SearchPlaceholder({ text = 'Please type to search for available values...' }: { text: string }) {
  return (
    <>
      <SearchOutlined />
      &nbsp;&nbsp;{text}
    </>
  );
}

// This is not a component since component cannot return undefined which is needed to trigger
// the default component behavior (when passed prop is undefined it works like no prop is passed)
export const notFoundContent = () =>
  (document.activeElement as HTMLInputElement)?.value ? undefined : (
    <label style={{ padding: '10px' }}>Start typing to add products...</label>
  );
