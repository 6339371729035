import { ErrorNotification } from '../../../services/error-notification';
import { DashboardApi } from '../dashboard-api';
import { DashboardDetail } from './dashboard-detail';

export interface DashboardUpsert {
  id: string | undefined;
  state: 'ready' | 'saving';
  name: string;
  nameError: string;
}

export function initialDashboardCreate(): DashboardUpsert {
  return { id: undefined, state: 'ready', name: '', nameError: '' };
}

export function initialDashboardUpsert(detail: DashboardDetail): DashboardUpsert {
  return { id: detail.id, state: 'ready', name: detail.name, nameError: '' };
}

export function updateDashboardName(model: DashboardUpsert | undefined, name: string): DashboardUpsert | undefined {
  if (!model) {
    return undefined;
  }

  return { ...model, name, nameError: '' };
}

export async function saveDashboardUpsert(
  api: DashboardApi,
  model: DashboardUpsert | undefined,
  refresh: () => Promise<void>,
  update: (_: DashboardUpsert | undefined) => void
): Promise<ErrorNotification | undefined> {
  if (!model) {
    return;
  }

  const nameToSave = model.name.trim();
  if (!nameToSave) {
    update({ ...model, nameError: 'This field is required.' });
    return;
  }

  update({ ...model, state: 'saving' });

  const res = model.id ? await api.updateDashboard(model.id, { name: nameToSave }) : await api.createDashboard({ name: nameToSave });
  if (!res?.ok) {
    update({ ...model, state: 'ready' });
    return { message: 'Error saving dashboard' };
  }

  try {
    await refresh();
  } finally {
    update(undefined);
  }
}
