import React, { useState, useEffect } from 'react';

import { Button, Form, InputNumber, FormInstance } from 'antd';

import SelectEquityControl from './controls/SelectEquityControl';
import { SingleDateAndTimeRangeFormControl } from './controls/SingleDateAndTimeRangeControl';
import DownloadButton from './controls/DownloadButton';
import { shouldDisableProducts, shouldChangesDisableProducts, preventEnterKeyDefault } from './data-query-form-utils';
import { getUrlForQuery } from '../outputs/IntervalOutput';

import { FORM_LAYOUT_LABEL } from '../../../../utils/midas-constants';
import { DataQueryConfig, DataQueryForm } from '../../../types';

import classes from './shared.module.css';

type IntervalFormParams = {
  config: DataQueryConfig;
  formInstance: FormInstance;
  onFinish?: (values: unknown) => void;
};

const DATE_FIELD_NAME = 'dateAndTimeRange';

const DEFAULT_PROPS = {
  onFinish: () => {},
};

export default function IntervalForm({ config, formInstance, onFinish = DEFAULT_PROPS.onFinish }: IntervalFormParams) {
  const [disableProducts, setDisableProducts] = useState(true);
  const [intervalFormValid, setIntervalFormValid] = useState(false);

  const setFormValidity = async () => {
    try {
      await formInstance.validateFields({ validateOnly: true });
      setIntervalFormValid(true);
    } catch (errorInfo: unknown) {
      setIntervalFormValid((errorInfo as { errorFields: unknown[] }).errorFields?.length === 0);
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const onValuesChange = async (changedValues: DataQueryForm) => {
    const shouldDisable = shouldChangesDisableProducts({
      formInstance,
      changedValues,
      dateFieldName: DATE_FIELD_NAME,
    });

    setDisableProducts(shouldDisable);

    setTimeout(async () => await setFormValidity());
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/require-await
    const fetchData = async () => {
      const shouldDisable = shouldDisableProducts({ formInstance, dateFieldName: DATE_FIELD_NAME, includeFeed: false });
      setDisableProducts(shouldDisable);

      setTimeout(async () => await setFormValidity());
    };

    void fetchData();
  }, [config]);

  return (
    <Form
      form={formInstance}
      layout="horizontal"
      labelCol={FORM_LAYOUT_LABEL}
      onFinish={onFinish}
      onValuesChange={async (changedValues) => {
        await onValuesChange(changedValues);
      }}
      labelWrap
      onKeyDown={preventEnterKeyDefault}
    >
      <SingleDateAndTimeRangeFormControl />
      <SelectEquityControl
        config={config}
        disabled={disableProducts}
        dateFieldName={DATE_FIELD_NAME}
        form={formInstance}
        excludeFeedFamilies={['fi', 'quotes', 'trades']}
      />

      <Form.Item
        label="Interval (sec)"
        name="interval"
        dependencies={['date']}
        className={classes.interval}
        rules={[
          { required: true, message: 'Please select an interval.' },
          ({ getFieldValue }) => ({
            async validator(rule, value) {
              if (!value) {
                return;
              }

              const dateTimeRangeField = getFieldValue('dateAndTimeRange');
              if (!dateTimeRangeField) {
                return;
              }

              const { startTime, endTime } = dateTimeRangeField;
              const totalTimeSeconds = (endTime.timestamp - startTime.timestamp) / 1000;

              if (value > totalTimeSeconds) {
                // eslint-disable-next-line
                return Promise.reject('Interval is greater than time between start and end date/time range.');
              }
            },
          }),
        ]}
      >
        <InputNumber min={1} max={61} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary" disabled={!intervalFormValid}>
          Run Query
        </Button>
        <DownloadButton downloadQuery={getUrlForQuery} formInstance={formInstance} isFormValid={intervalFormValid} />
      </Form.Item>
    </Form>
  );
}
