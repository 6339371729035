import React, { useCallback, useMemo, useState, useContext } from 'react';

import defer from 'lodash/defer';

import { Collapse, Table, Modal, CollapseProps } from 'antd';
import { getToJson, remove } from '../../utils/io';

import { HistoryContext } from '../../context/history-context';
import { SettingsConfigContext } from '../../context/settings-config-context';

import useWebDataLoader from '../../hooks/use-web-data-loader';

import { cloneWorkflow } from '../../utils/workflow-utilities';

import { initColumnsForJobsData } from './utils/CreateColumns';
import usePausingWebUpdater from './utils/use-pausing-web-updater';

import WorkflowInfoControl from './WorkflowInfoControl';

import { ROOT_SERVER_URL, WF_DUMP_ALL_URL, WF_RUNNING_STATE_URL, WF_SERVICES_URL } from '../../utils/midas-constants';
import { DataQueryFormItemMetaAccordionControls, DpActivityWorkflow } from '../../pages/types';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SorterCallback, SorterFactory } from '../../pages/data-query/components/outputs/_shared/table-utils';
import { getAccessTokenFromCookie } from '../../../services/auth';
import { showErrorNotification } from '../../../_shared/views/Notifications';

type DpActivityJobsParams = {
  tablesSize: SizeType;
  conditionallyHandleShowRelatedItemsInfo: boolean | ((id: string[]) => void);
  handleTableChangeCallbackFactory: SorterCallback;
  accordionActiveKeys: DataQueryFormItemMetaAccordionControls;
  onAccordionActiveKeysChanged: (key: string, openControls: string) => void;
  sorterFactory?: (tableName: string) => SorterFactory | undefined;
  showActiveOnly?: boolean;
  showDeletedFiles?: boolean;
  selectedWorkflowId?: string | string[];
};

export default function DpActivityJobs({
  tablesSize,
  sorterFactory = () => undefined,
  conditionallyHandleShowRelatedItemsInfo,
  handleTableChangeCallbackFactory,
  accordionActiveKeys,
  onAccordionActiveKeysChanged,
  showActiveOnly = false,
  selectedWorkflowId,
}: DpActivityJobsParams) {
  const historyContext = useContext(HistoryContext);
  const settingsConfigContext = useContext(SettingsConfigContext);

  const requestUrl = useMemo(() => {
    const url = new URL(WF_RUNNING_STATE_URL);
    url.searchParams.append('showAll', Boolean(!showActiveOnly).toString());

    if (selectedWorkflowId) {
      url.searchParams.set('jobId', selectedWorkflowId.toString());
    }

    return url;
  }, [showActiveOnly, selectedWorkflowId]);

  const [webData, runUpdate] = useWebDataLoader<DpActivityWorkflow[]>({ url: requestUrl });

  usePausingWebUpdater({
    selectedWorkflowId,
    runUpdate,
  });

  const [workflowModalInfoModalState, setWorkflowInfoModalState] = useState({
    open: false,
    selectedId: undefined,
  } as { open: boolean; selectedId?: string });

  const handleDeleteWorkflow = useCallback(
    (e: string) => {
      defer(async () => {
        try {
          const token = getAccessTokenFromCookie();

          const url = new URL(WF_SERVICES_URL);
          url.searchParams.set('jobId', e);
          await remove(url.toString(), token);

          // eslint-disable-next-line @typescript-eslint/await-thenable
          await runUpdate();
        } catch (error: unknown) {
          console.error('[DpActivityJobs] failed to delete the specified job...', error);
          showErrorNotification({
            message: 'Failed to delete the specified job...',
            description: (error as Error).message,
          });
        }
      });
    },
    [runUpdate]
  );

  const handleShowWorkflowInfo = useCallback(
    (selectedId: string) => {
      setWorkflowInfoModalState({
        open: true,
        selectedId,
      });
    },
    [setWorkflowInfoModalState]
  );

  const handleWorkflowItemClone = useCallback(
    (selectedId: string) => {
      void cloneWorkflow(settingsConfigContext, historyContext, selectedId);
    },
    [settingsConfigContext, historyContext]
  );

  const handleCreateAuditData = useCallback((id: string) => {
    void (async () => {
      try {
        const url = new URL(WF_DUMP_ALL_URL);
        url.searchParams.set('id', id);
        const response = await getToJson(url.toString(), getAccessTokenFromCookie());
        window.location.href = ROOT_SERVER_URL + response;
      } catch (error: unknown) {
        console.error('[DpActivityJobs] could not generate URL...', error);
        showErrorNotification({
          message: 'Failed to get the URL to download audit data...',
          description: (error as Error).message,
        });
      }
    })();
  }, []);

  const columnsForJobsData = useMemo(
    () =>
      initColumnsForJobsData(
        handleShowWorkflowInfo,
        handleWorkflowItemClone,
        handleDeleteWorkflow,
        conditionallyHandleShowRelatedItemsInfo,
        handleCreateAuditData,
        sorterFactory('columnsForJobsData')
      ),
    [
      handleShowWorkflowInfo,
      conditionallyHandleShowRelatedItemsInfo,
      handleDeleteWorkflow,
      handleWorkflowItemClone,
      sorterFactory,
      handleCreateAuditData,
    ]
  );

  const collapseItems: CollapseProps['items'] = [
    {
      key: 'active',
      label: 'Data Ingestion/Book Join Jobs',
      children: (
        <Table
          columns={columnsForJobsData}
          dataSource={webData.data}
          loading={webData.loading && !webData?.data}
          rowKey="id"
          size={tablesSize}
          scroll={{ x: true }}
          pagination={{ hideOnSinglePage: true }}
          onChange={handleTableChangeCallbackFactory('columnsForJobsData')}
        />
      ),
    },
  ];

  return (
    <>
      <Collapse
        activeKey={accordionActiveKeys.active}
        onChange={(e) => onAccordionActiveKeysChanged('active', e as string)}
        items={collapseItems}
      />
      <Modal
        open={workflowModalInfoModalState.open}
        onOk={() => setWorkflowInfoModalState({ open: false, selectedId: undefined })}
        closable={false}
        onCancel={() => setWorkflowInfoModalState({ open: false, selectedId: undefined })}
        cancelButtonProps={{ style: { display: 'none' } }}
        title="Workflow Job Information"
        width="75%"
        maskClosable={false}
        destroyOnClose
      >
        <WorkflowInfoControl uuid={workflowModalInfoModalState.selectedId!} />
      </Modal>
    </>
  );
}
