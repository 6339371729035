import { ErrorNotification } from '../../../services/error-notification';
import { DashboardApi } from '../dashboard-api';

export interface DashboardRemove {
  state: 'ready' | 'saving';
  dashboardId: string;
}

export function initialDashboardRemove(dashboardId: string): DashboardRemove {
  return { state: 'ready', dashboardId };
}

export async function saveDashboardRemove(
  api: DashboardApi,
  model: DashboardRemove,
  refresh: () => Promise<void>,
  update: (_: DashboardRemove | undefined) => void
): Promise<ErrorNotification | undefined> {
  update({ ...model, state: 'saving' });

  const res = await api.deleteDashboard(model.dashboardId);
  if (!res?.ok) {
    update({ ...model, state: 'ready' });
    return { message: 'Error deleting dashboard.' };
  }

  try {
    await refresh();
  } finally {
    update(undefined);
  }
}
