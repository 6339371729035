import { DocumentationApi } from './documentation-api';
import { DocumentationModel, loadDocumentation } from './models/documentation-list';

export class DocumentationController {
  private api = new DocumentationApi();

  constructor(private updateViewState: (_: DocumentationModel) => void) {
    void this.load();
  }

  private update = (model: DocumentationModel) => {
    this.updateViewState(model);
  };

  private async load() {
    await loadDocumentation(this.api, this.update);
  }
}
