import React from 'react';

import GenericOutputContainer from './controls/GenericOutputContainer';
import DataRetrievingDataTable from './controls/DataRetrievingDataTable';
import useIndexDataRequester from './hooks/use-index-data-requester';

import { dpProductName } from '../../../../utils/server-client-shared';

import { anyEmpty } from '../../../../utils/config-utils';
import { getTableName } from './_shared/DpUtils';

import { DP_GET_TABLE_DATA_URL } from '../../../../utils/midas-constants';
import DpDataGraph from './controls/dp/DpDataGraph';
import { GetUrlForQuery } from './hooks/use-data-requester';
import { DataQueryConfig } from '../../../types';

const graphDataPoints = {
  high: 'High',
  low: 'Low',
  open: 'Open',
  close: 'Close',
  vol: 'Volume',
};

export const getUrlForQuery: GetUrlForQuery = (query: DataQueryConfig) => {
  const tableName = getTableName(query);
  if (!tableName) {
    return undefined;
  }

  return `${DP_GET_TABLE_DATA_URL}${tableName}/info`;
};

const getUrlForTableQuery = (query: DataQueryConfig) => {
  const tableName = getTableName(query);
  if (!tableName) {
    return undefined;
  }

  const { start, end } = query;

  const url = new URL(`${DP_GET_TABLE_DATA_URL}${tableName}/get`);

  url.searchParams.append('startRow', String(start));
  url.searchParams.append('rowCount', String(end! - start!));

  return !anyEmpty(url.searchParams) && url.toString();
};

export const getUrlForGraphQuery = (query: DataQueryConfig) => {
  const tableName = getTableName(query);
  if (!tableName) {
    return undefined;
  }

  const { lineCount } = query;

  const url = new URL(`${DP_GET_TABLE_DATA_URL}/${tableName}/visualise`);

  url.searchParams.append('lineCount', String(lineCount));

  return url.toString();
};

/**
 * In the world of DiP, the 'product' is always hardwired to the output product, which is 'Uploaded Data'.
 */
export default function DipDataOutput({
  config,
  onConfigChanged,
}: {
  config?: DataQueryConfig;
  onConfigChanged?: (config: DataQueryConfig) => void;
}) {
  const getDownloadUrlForQuery: GetUrlForQuery = (query: DataQueryConfig) => {
    const tableName = getTableName(query);
    if (!tableName) {
      return undefined;
    }

    return `${DP_GET_TABLE_DATA_URL}${tableName}/downloadTearoff`;
  };

  const { pageIndexData, downloadData } = useIndexDataRequester({ config, getUrlForQuery, getDownloadUrlForQuery });

  return (
    <GenericOutputContainer
      dataPoints={graphDataPoints}
      config={config}
      getUrlForTableQuery={getUrlForTableQuery}
      graphControl={DpDataGraph}
      gridControl={DataRetrievingDataTable}
      tableColumns={[]}
      data={pageIndexData.data || []}
      dataLoading={pageIndexData.busy}
      dataError={pageIndexData.error}
      products={[dpProductName]}
      product={dpProductName}
      onDownload={downloadData}
      onConfigChanged={onConfigChanged}
    />
  );
}
