import React, { useState, useEffect } from 'react';

import { Table, Tooltip } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

import GenericOutputContainer from './controls/GenericOutputContainer';
import CursorPaginationDataTable from './controls/CursorPaginationDataTable';
import useProductDataRequester from './hooks/use-product-data-requester';
import useDataRequester, { GetUrlForQuery } from './hooks/use-data-requester';
import useCursorPaginationState from './hooks/use-cursor-pagination-state';

import { timestampToDateTime } from '../../../../_shared/normalizers';
import { buildUrl } from './_shared/misc-utils';
import { DEFAULT_PAGE_SIZE } from './_shared/table-utils';
import { MAX_GRAPH_RESULTS } from './_shared/GraphUtils';

import { DATE_FORMAT, ROOT_SERVER_URL, WS_SERVER_URL } from '../../../../utils/midas-constants';
import { DataQueryConfig } from '../../../types';

const DAILY_QUERY_URL = `${ROOT_SERVER_URL}api/querying/daily`;
const DAILY_QUERY_WS_URL = `${WS_SERVER_URL}api/querying/daily`;

function TitleWithTooltip({ title, tooltip }: { title: string; tooltip: string }) {
  return (
    <>
      <Tooltip placement="bottom" title={tooltip}>
        <InfoCircleTwoTone twoToneColor="#bcbcbc" />
      </Tooltip>{' '}
      {title}
    </>
  );
}

const tableColumns = [
  <Table.Column title="Date" dataIndex="date" key="date" />,
  <Table.Column title="Product" dataIndex="product" key="product" />,
  <Table.Column title="High" dataIndex="high" key="high" />,
  <Table.Column title="Low" dataIndex="low" key="low" />,
  <Table.Column title="Open" dataIndex="open" key="open" />,
  <Table.Column title="Close" dataIndex="close" key="close" />,
  <Table.Column
    title={<TitleWithTooltip tooltip="Factor representing splits (excuding dividends)" title="R Split" />}
    dataIndex="rsplit"
    key="rsplit"
  />,
  <Table.Column
    title={
      <TitleWithTooltip tooltip="Factor representing dividends and split adjustments (equals price divided by close)" title="C Split" />
    }
    dataIndex="csplit"
    key="csplit"
  />,
  <Table.Column title="Price" dataIndex="prc" key="prc" />,
  <Table.Column title="Volume" dataIndex="vol" key="vol" />,
];

const subTableRowRenderer = (column: string) => (value: number) => {
  if (column.match(/timestamp/)) {
    return timestampToDateTime(value);
  }

  return value;
};

export const graphDataPoints = {
  high: 'High',
  low: 'Low',
  open: 'Open',
  close: 'Close',
  vol: 'Volume',
};

export const getUrlForQuery: GetUrlForQuery = (query, selectedProductForDownload, incomingDownloadType = 'text/csv', useWS = false) => {
  let actualStartDate = query.dateRange?.[0]?.format(DATE_FORMAT);
  const actualEndDate = query.dateRange?.[1]?.format(DATE_FORMAT);

  if (query.cursorKey && !selectedProductForDownload) {
    actualStartDate = query.cursorKey.date;
  }

  const sharedParams = {
    download: (selectedProductForDownload !== undefined).toString(),
    startDate: actualStartDate,
    endDate: actualEndDate,
    source: query.source,
    ...(query.feed && { feed: query.feed }),
  };

  if (selectedProductForDownload) {
    const downloadType = incomingDownloadType;

    return buildUrl(DAILY_QUERY_URL, {
      ...sharedParams,
      product: selectedProductForDownload,
      downloadType,
    });
  }

  const dailyUrl = useWS ? DAILY_QUERY_WS_URL : DAILY_QUERY_URL;
  return query.products?.map((product) =>
    buildUrl(dailyUrl, {
      ...sharedParams,
      product,
      limit: query.pageSize || DEFAULT_PAGE_SIZE,
    })
  );
};

const getUrlForGraphQuery = (query: DataQueryConfig) => {
  const actualStartDate = query.dateRange?.[0]?.format(DATE_FORMAT);
  const actualEndDate = query.dateRange?.[1]?.format(DATE_FORMAT);

  const sharedParams = {
    startDate: actualStartDate,
    endDate: actualEndDate,
    limit: MAX_GRAPH_RESULTS,
    source: query.source,
    ...(query.feed && { feed: query.feed }),
  };

  return query.products?.map((product: string) =>
    buildUrl(DAILY_QUERY_URL, {
      ...sharedParams,
      product,
    })
  );
};

const DEFAULT_PROPS = {
  onConfigChanged: () => {},
};

export default function DailyOutput({
  config,
  onConfigChanged = DEFAULT_PROPS.onConfigChanged,
}: {
  config?: DataQueryConfig;
  onConfigChanged?: (config: DataQueryConfig) => void;
}) {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { downloadedData, setProduct, product, handleDownloadData, processing } = useProductDataRequester({
    config,
    getUrlForQuery,
  });

  const { processing: graphProcessing, downloadedData: graphData } = useDataRequester({
    config,
    getUrlForQuery: getUrlForGraphQuery,
  });

  const { requestCursor, cursorPages, resetCursors, setCursorBack, setCursorForward, updateCursor } = useCursorPaginationState();

  useEffect(() => {
    onConfigChanged({
      ...config,
      pageSize,
      cursorKey: requestCursor,
      currentPage: cursorPages,
    });
  }, [requestCursor]);

  useEffect(() => {
    resetCursors();

    onConfigChanged({
      ...config,
      pageSize,
      cursorKey: null,
      currentPage: 0,
    });
  }, [pageSize, product, config?.dateRange]);

  useEffect(() => {
    const productData = downloadedData.find((item) => item.product === product);
    if (productData?.output) {
      updateCursor(productData.output, 'date', 'YYYYMMDD');
    }
  }, [downloadedData, product]);

  const handlePreviousPage = () => {
    setCursorBack();
  };

  const handleNextPage = () => {
    setCursorForward();
  };

  const handlePageSizeChanged = (_previous: number, next: number) => setPageSize(next);

  return (
    <GenericOutputContainer
      graphControl={undefined}
      onChangedFilters={undefined}
      config={config}
      gridControl={CursorPaginationDataTable}
      data={downloadedData || []}
      dataLoading={processing.busy}
      dataError={processing.error}
      products={config?.products || []}
      product={product}
      onSelectProduct={setProduct}
      onDownload={handleDownloadData}
      subTableRowRenderer={subTableRowRenderer}
      dataPoints={graphDataPoints}
      graphData={graphData}
      graphDataLoading={graphProcessing.busy}
      xAxisColumnType="category"
      xAxisLabelColumn="date"
      xAxisColumn="date"
      onCursorBack={handlePreviousPage}
      onCursorNext={handleNextPage}
      onPageSizeChanged={handlePageSizeChanged}
      tableColumns={tableColumns}
    />
  );
}
