import React, { useState, useEffect, useCallback } from 'react';

import { Spin, Modal, Typography } from 'antd';
import {
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Bar,
  XAxis,
  YAxis,
  Line,
  ComposedChart,
  Area,
  Brush,
  ReferenceLine,
  TooltipProps,
} from 'recharts';
import { Dayjs } from 'dayjs';

import numeral from 'numeral';

import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';
import axios from 'axios';

import classes from './OrderBookControls.module.css';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { DataQueryConfig } from '../../../../../types';
import { GetUrlForQuery } from '../../hooks/use-data-requester';
import { getAccessTokenFromCookie } from '../../../../../../../services/auth';

type OrderBookHourBreakdownGraphParams = {
  config: DataQueryConfig;
  getUrlForQuery: GetUrlForQuery;
  colors: {
    ask: string;
    bid: string;
    trades: string;
    volume: string;
  };
  product: string;
  onConfigChanged: (config: DataQueryConfig) => void;
};

type OrderBookHourBreakdownGraphModalState = {
  visible: boolean;
  time: string;
  products: string[];
  sources?: string;
  date?: string;
  oldTime?: string;
};

interface OrderBookGraphData {
  trades?: number;
  time: string;
  [key: string]: string | number | undefined;
}

const { Text } = Typography;

function CustomTooltip({ active, payload }: TooltipProps<string | number | (string | number)[], string | number>) {
  if (!active || !payload?.length) {
    return null;
  }

  const [
    {
      payload: { time },
    },
  ] = payload;

  const nameMap: {
    [key: string]: {
      name?: string;
      formatter: (val: string | number | (string | number)[] | undefined) => string | number | (string | number)[] | undefined;
    };
  } = {
    'direct.bid': {
      name: 'Bid',
      formatter: (v) => v,
    },
    'direct.ask': {
      name: 'Ask',
      formatter: (v) => v,
    },
    'direct.volume': {
      name: 'Volume',
      formatter: (v) => numeral(v).format('0,0'),
    },
    Trades: {
      formatter: (v) => numeral(v).format('0,0'),
    },
  };

  return (
    <div className={classes.customTooltip}>
      <div className={classes.tooltipHeader}>{time}</div>
      <ul className={classes.tooltipList}>
        {payload.map(({ name, value, stroke: color }) => (
          <li key={name} style={{ color }}>
            {nameMap[name as string]?.name || name}: <b>{nameMap[name as string].formatter(value)}</b>
          </li>
        ))}
      </ul>
    </div>
  );
}

const INITIAL_MODAL_STATE: OrderBookHourBreakdownGraphModalState = { visible: false, time: '', products: [], sources: '', date: '' };

export default function OrderBookHourBreakdownGraph({
  config,
  getUrlForQuery,
  colors,
  product,
  onConfigChanged,
}: OrderBookHourBreakdownGraphParams) {
  const token = getAccessTokenFromCookie();
  const [processing, setProcessing] = useState<{ busy: boolean; error?: unknown }>({ busy: false, error: undefined });
  const [graphData, setGraphData] = useState<OrderBookGraphData[]>([]);
  const [modalDetails, setModalDetails] = useState<OrderBookHourBreakdownGraphModalState>(INITIAL_MODAL_STATE);
  const [brushIndexes, setBrushIndexes] = useState({ startIndex: 0, endIndex: 0, trimmedTime: '' });

  const xAxisFormatter = (fullTime: string) => {
    if (!fullTime?.length) {
      return fullTime;
    }
    const [hour, minute] = fullTime.split(':');

    return `${hour}:${minute}`;
  };

  const brushFormatter = (index: number) => graphData[index].time;

  const numberAbbreviation = (number: number) => numeral(number).format('0 a');

  const handleChartClick = useCallback(
    (data: CategoricalChartState) => {
      if (!data || !data.activeLabel) {
        return;
      }

      const { activeLabel: time } = data;
      const { products, date: rawDate, sources, time: oldTime } = config;

      setModalDetails({
        visible: true,
        time,
        products: products!,
        date: (rawDate as Dayjs)?.format('YYYY-MM-DD'),
        sources,
        oldTime,
      });
    },
    [config]
  );

  const handleModalOk = useCallback(() => {
    onConfigChanged({
      ...config,
      time: modalDetails.time,
    });

    setModalDetails(INITIAL_MODAL_STATE);
  }, [config, modalDetails, onConfigChanged]);

  const handleModalCancel = useCallback(() => {
    setModalDetails(INITIAL_MODAL_STATE);
  }, []);

  useEffect(() => {
    setProcessing({ busy: true });

    void (async () => {
      try {
        const url = getUrlForQuery(config, product);

        if (!url) {
          setProcessing({ busy: false });
          return;
        }
        const definedUrl: string = url as string;

        const allData: { output: OrderBookGraphData[] } | undefined = await checkAxiosResponse(
          axios.get(definedUrl, { headers: { Authorization: `Bearer ${token}` }, validateStatus: null }),
          'Error while getting chart data'
        );

        if (!allData) {
          throw new Error('Error while getting chart data');
        }

        const calculateTrades = (line: OrderBookGraphData, index: number, array: OrderBookGraphData[]) => {
          if (index === 0) {
            return { ...line, trades: 0 };
          }

          return { ...line, trades: (line['direct.volume'] as number) - (array[index - 1]['direct.volume'] as number) };
        };

        const [selectedHour, selectedMinutes] = config.time!.split(':');
        const trimmedTime = `${selectedHour}:${selectedMinutes}:00`;
        const selectedTimeIndex = allData.output.findIndex(({ time }: { time: string }) => time === trimmedTime);

        setBrushIndexes({
          startIndex: Math.max(0, selectedTimeIndex - 30),
          endIndex: Math.min(420, selectedTimeIndex + 30), // 9:00 - 16:00 = 7 hours * 60 minutes = 420
          trimmedTime,
        });
        setGraphData(allData.output.map(calculateTrades));
        setProcessing({ busy: false });
      } catch (error) {
        console.error('[OrderBookHourBreakdownGraph] failed to retrieve data from sources...', error);
        setProcessing({ busy: false });
      }
    })();
  }, [setProcessing, setGraphData, config, getUrlForQuery, product, token]);

  return (
    <>
      <Spin spinning={processing.busy}>
        <ResponsiveContainer width="100%" height={600}>
          <ComposedChart
            data={graphData}
            margin={{
              top: 50,
              bottom: 50,
            }}
            onClick={handleChartClick}
            {...{ cursor: 'pointer' }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="time" tickFormatter={xAxisFormatter} tick={{ fontSize: 12 }} />
            <YAxis
              yAxisId="price"
              dataKey="direct.ask"
              allowDecimals
              domain={['dataMin', 'dataMax']}
              axisLine={{ stroke: 'none' }}
              padding={{ bottom: 300 }}
              label={{ position: 'top', offset: 30, value: 'Price' }}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              yAxisId="volume"
              orientation="right"
              dataKey="direct.volume"
              tickFormatter={numberAbbreviation}
              domain={['dataMin', 'dataMax']}
              axisLine={{ stroke: 'none' }}
              padding={{ top: 300 }}
              label={{ position: 'bottom', offset: -170, value: 'Volume' }}
              tick={{ fontSize: 12, fill: colors.volume }}
            />
            <YAxis
              yAxisId="trades"
              dataKey="trades"
              tickFormatter={numberAbbreviation}
              axisLine={{ stroke: 'none' }}
              padding={{ top: 300 }}
              label={{ position: 'bottom', offset: -170, value: 'Trades', left: 20 }}
              tick={{ fontSize: 12, fill: colors.trades }}
            />
            <Area type="monotone" dataKey="direct.volume" stroke="#e69500" fill={colors.volume} yAxisId="volume" />
            <Line type="monotone" dataKey="direct.ask" stroke={colors.ask} yAxisId="price" />
            <Line type="monotone" dataKey="direct.bid" stroke={colors.bid} yAxisId="price" />
            <Bar yAxisId="trades" dataKey="trades" name="Trades" fill={colors.trades} stroke={colors.trades} />
            <Tooltip content={<CustomTooltip />} />
            <ReferenceLine
              x={brushIndexes.trimmedTime}
              stroke="black"
              label={{ value: brushIndexes.trimmedTime, position: 'top' }}
              yAxisId="price"
              strokeDasharray="3 3"
            />
            {graphData.length ? (
              <Brush
                startIndex={brushIndexes.startIndex}
                endIndex={brushIndexes.endIndex}
                tickFormatter={brushFormatter}
                stroke="#1890ff"
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </Spin>
      <Modal title="Query new order book viewer?" onOk={handleModalOk} onCancel={handleModalCancel} open={modalDetails.visible}>
        <p>Are you sure you want to query for a new order book viewer for:</p>
        <ul>
          <li>
            Feed: <Text strong>{modalDetails.sources}</Text>
          </li>
          <li>
            {modalDetails.products.length > 1 ? 'Products' : 'Product'}: <Text strong>{modalDetails.products.join(', ')}</Text>
          </li>
          <li>
            Date: <Text strong>{modalDetails.date}</Text>
          </li>
          <li>
            Time: <Text strong>{modalDetails.time}</Text>
            <Text type="secondary" strong>
              <i> (currently selected {modalDetails.oldTime})</i>
            </Text>
          </li>
        </ul>
      </Modal>
    </>
  );
}
