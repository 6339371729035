import React, { useState, useEffect } from 'react';

import { Switch, Select, Spin, Space } from 'antd';
import defer from 'lodash/defer';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';
import axios from 'axios';

import { DP_TABLE_NAME_ALL_URL } from '../../../../../utils/midas-constants';

import classes from './SelectDpTableControl.module.css';
import { getAccessTokenFromCookie } from '../../../../../../services/auth';

interface Dictionary<T> {
  [index: string]: T;
}

type SelectDpTableControlParams = {
  type?: string;
  formElementName?: string;
  onClick?: () => void;
};

type SelectDpTableControlTable = {
  list: Dictionary<
    {
      tableName: string;
      displayName: string;
      createdBy: string;
      createdAtText: string;
      createdAtMs: string;
    }[]
  >;
  busy: boolean;
  error: unknown;
};

export default function SelectDpTableControl({
  type,
  formElementName: _formElementName = 'tableName',
  onClick,
  ...rest
}: SelectDpTableControlParams) {
  const [isPublic, setIsPublic] = useState(false);
  const [tables, setTables] = useState<SelectDpTableControlTable>({
    list: {},
    busy: true,
    error: undefined,
  });

  useEffect(() => {
    setTables((s) => ({ ...s, busy: true }));

    defer(async () => {
      try {
        const shouldIncludePublic = String(isPublic);
        const url = new URL(DP_TABLE_NAME_ALL_URL);
        url.searchParams.set('includePublic', shouldIncludePublic);
        const response = await checkAxiosResponse(
          axios.get(url.toString(), { headers: { Authorization: `Bearer ${getAccessTokenFromCookie()}` }, validateStatus: null }),
          'Error while getting tables'
        );
        if (!response) {
          throw new Error('Error while getting tables');
        }
        const { rows, includePublic } = response;

        // could be a synchronisation issue due to the adhoc nature of these calls
        // ensure we still have the correct result set.
        if (includePublic !== shouldIncludePublic) {
          return;
        }

        const list = groupBy(rows, ({ type, isPublic }) => `${type.toUpperCase()} - ${isPublic ? 'PUBLIC' : 'MY'}`);
        setTables({ list, busy: false, error: undefined });
      } catch (error) {
        console.error('[SelectDIPDataControl] failed to load tables from server...', error);
        setTables({ list: {}, busy: false, error });
      }
    });
  }, [type, isPublic]);

  const keys: (keyof SelectDpTableControlTable['list'])[] = Object.keys(tables?.list || {});

  return (
    <Space direction="vertical" className="w-full">
      <Select
        onClick={onClick}
        notFoundContent={tables.busy ? <Spin size="small" /> : null}
        placeholder="Please choose a table..."
        loading={tables.busy}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          if (!option?.children || option.options) {
            return false;
          }

          return option.children.some((child) => child.toLowerCase().indexOf(input.toLowerCase()) >= 0);
        }}
        {...rest}
      >
        {keys.map((key) => {
          const values = orderBy(tables.list[key], ['displayName', 'createdAtMs'], ['asc', 'desc']);
          return (
            <Select.OptGroup key={key} title={key}>
              {values.map(({ tableName, displayName, createdBy, createdAtText }) => (
                <Select.Option key={tableName} value={tableName}>
                  {displayName} ({createdBy}) ({createdAtText})
                </Select.Option>
              ))}
            </Select.OptGroup>
          );
        })}
      </Select>
      <Space className="w-full">
        <Switch
          id="includePublicItems"
          aria-label="Include Public Items?"
          onChange={setIsPublic}
          checked={isPublic}
          disabled={tables.busy}
        />
        <label htmlFor="includePublicItems" className={tables.busy ? classes.disabled : ''}>
          Include Public Items
        </label>
      </Space>
    </Space>
  );
}
