import React, { useState, useEffect, ReactNode } from 'react';

import { Table, Button, Select } from 'antd';

import QueryModal from '../modules/QueryModal';

import useCacheOrFetch from '../hooks/use-cache-or-fetch';

import classes from './BookJoinForm.module.css';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { DataQueryConfigQuery, WorkflowQueryOptions } from '../../../../types';

type BookJoinFormColumn = {
  title: string;
  key: string;
  dataIndex?: string;
  fixed?: 'left' | 'right' | boolean;
  width?: number;
  render?: (text: string, record: unknown, index: number) => ReactNode;
};

export type BookJoinFormParams = {
  label?: string;
  tableSize?: SizeType;
  onDataChange?: ({ source, queries }: { source: 'twxm' | 'lake'; queries: DataQueryConfigQuery[] }) => void;
  userData?: {
    queries: DataQueryConfigQuery[];
  };
  options?: WorkflowQueryOptions;
  source?: 'twxm' | 'lake';
  allowSourceSelection?: boolean;
};

const initColumns = (
  setBookJoinData: (fn: (queries: DataQueryConfigQuery[]) => DataQueryConfigQuery[]) => void,
  setEditDataIndex: (index: number) => void
): BookJoinFormColumn[] => [
  { title: 'Group', dataIndex: 'group', key: 'group' },
  { title: 'Feed', dataIndex: 'feed', key: 'feed' },
  { title: 'Value', dataIndex: 'value', key: 'value' },
  { title: 'Level', dataIndex: 'level', key: 'level' },
  { title: 'Time', dataIndex: 'timeString', key: 'timeString' },
  { title: 'Offset', dataIndex: 'offset', key: 'offset' },
  {
    title: 'Actions',
    key: 'actions',
    fixed: 'right',
    width: 200,
    render: (text, record, index) => {
      return (
        <div className="flex">
          <Button type="link" onClick={() => setEditDataIndex(index)}>
            Edit
          </Button>
          <Button danger type="link" onClick={() => setBookJoinData((queries) => queries.filter((d, i) => i !== index))}>
            Delete
          </Button>
        </div>
      );
    },
  },
];

const defaultProps: Required<Pick<BookJoinFormParams, 'userData' | 'options'>> = {
  userData: {
    queries: [],
  },
  options: {
    feedsAndGroupsTree: [],
    values: {
      options: [],
      supportLevelValues: [],
    },
    loading: true,
  },
};

function BookJoinForm({
  label = 'Queries:',
  tableSize = 'large',
  onDataChange = undefined,
  userData = defaultProps.userData,
  options = defaultProps.options,
  source = 'twxm',
  allowSourceSelection = false,
}: BookJoinFormParams) {
  const [feedSource, setFeedSource] = useState(source);
  const [modalVisible, setModalVisibility] = useState(false);
  const [bookJoinData, setBookJoinData] = useState(userData.queries);
  const [editingDataIndex, setEditDataIndex] = useState<number | null>(null);

  // userData/options can be provided either from parent or a cache hook
  const { feedsAndGroupsTree, values, loading } = useCacheOrFetch(feedSource, options);

  useEffect(() => {
    if (editingDataIndex !== null) {
      setModalVisibility(true);
    }
  }, [editingDataIndex]);

  useEffect(() => void setFeedSource(source), [source]);

  useEffect(() => void onDataChange?.({ source, queries: bookJoinData }), [bookJoinData]);

  function onSourceChange(value: 'twxm' | 'lake') {
    setFeedSource(value);
    setBookJoinData([]);
    onDataChange?.({ source: value, queries: [] });
  }

  return (
    <div>
      <div className={classes.queriesTable}>
        <span>{label}</span>
        <Table
          bordered
          size={tableSize}
          rowKey={({ group, feed, value }) => `${group}_${feed}_${value}`}
          columns={initColumns(setBookJoinData, setEditDataIndex)}
          dataSource={bookJoinData}
          pagination={{ hideOnSinglePage: true }}
          scroll={{ x: true }}
        />
      </div>
      <div className="flex justify-end">
        {allowSourceSelection && (
          <Select
            style={{ width: '145px', marginRight: '8px' }}
            aria-label="Feed source"
            defaultValue={source}
            onChange={onSourceChange}
            options={[
              { value: 'twxm', label: 'Twxm feeds' },
              { value: 'lake', label: 'Data lake feeds' },
            ]}
          ></Select>
        )}
        <Button type="primary" loading={loading} disabled={loading} onClick={() => setModalVisibility(true)}>
          Add Query
        </Button>
      </div>
      <QueryModal
        editing={{
          index: editingDataIndex,
          data: editingDataIndex !== null ? bookJoinData[editingDataIndex] : undefined,
          done: () => setEditDataIndex(null),
        }}
        isVisible={modalVisible}
        closeModal={() => setModalVisibility(false)}
        queryOptions={{ feedsAndGroupsTree, values }}
        setBookJoinData={setBookJoinData}
      />
    </div>
  );
}

export default BookJoinForm;
