import { useEffect } from 'react';

export default function usePausingWebUpdater({
  selectedWorkflowId,
  runUpdate,
  delay = 10000,
}: {
  selectedWorkflowId?: string | string[];
  runUpdate: () => void;
  delay?: number;
}) {
  useEffect(() => {
    if (selectedWorkflowId) {
      return;
    }

    const id = setInterval(runUpdate, delay);
    return () => {
      clearInterval(id);
    };
  }, [selectedWorkflowId, runUpdate, delay]);
}
