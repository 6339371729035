import React from 'react';

import { Button, Layout } from 'antd';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

import SidebarNameHeader from '../_shared/SidebarNameHeader';

import { NotificationsListModel } from './controls/notifications/models/notifications-list';
import { NotificationListControlDelegate, NotificationsListControl } from './controls/notifications/views/NotificationsListControl';
import { SidebarButtons } from './controls/sidebar-buttons/SidebarButtons';

export enum SecondarySidebarState {
  Hidden,
  Collapsed,
  Visible,
}

export const classNameFromState = (state: SecondarySidebarState): string => {
  switch (state) {
    case SecondarySidebarState.Collapsed:
      return 'secondary-sidebar-collapsed';
    case SecondarySidebarState.Hidden:
      return 'secondary-sidebar-hidden';
    case SecondarySidebarState.Visible:
      return 'secondary-sidebar-visible';
  }
};

export function SecondarySidebar({
  notificationsModel,
  notificationsDelegate,
  sidebarCollapsed,
  onSidebarCollapsedChanged,
}: {
  notificationsModel: NotificationsListModel;
  notificationsDelegate: NotificationListControlDelegate | undefined;
  sidebarCollapsed: boolean;
  onSidebarCollapsedChanged: (isCollapsed: boolean) => void;
}) {
  const handleSidebarCollapseToggle = () => {
    onSidebarCollapsedChanged?.(!sidebarCollapsed);
  };

  return (
    <Layout.Sider collapsed={sidebarCollapsed} className="web-sider" width={256} aria-label="Alerts sidebar">
      <SidebarNameHeader captionText={'ALERTS'} className={'secondary-app-header-container'} />
      <div className="web-side-menu">
        <NotificationsListControl
          isCollapsed={sidebarCollapsed}
          notificationsModel={notificationsModel}
          notificationsDelegate={notificationsDelegate}
        />
      </div>
      <div className="side-menu-bottom">
        <SidebarButtons sidebarCollapsed={sidebarCollapsed}>
          <Button
            type={'text'}
            aria-label="Toggle notifications sidebar"
            onClick={handleSidebarCollapseToggle}
            icon={sidebarCollapsed ? <ArrowLeftIcon height={18} width={18} /> : <ArrowRightIcon height={18} width={18} />}
          />
        </SidebarButtons>
      </div>
    </Layout.Sider>
  );
}
