/**
 * Have to supply this because the default Rechart tooltip doesn't allow you to format
 * the label with a callback.
 *
 * Sigh
 */
import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';

import isArray from 'lodash/isArray';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import sortBy from 'lodash/sortBy';

export const isANumber = (value: any): boolean => isNumber(value) && !isNaN(value);

export const isNumOrStr = (value: any): boolean => isANumber(value) || isString(value);

function defaultFormatter(value: any): any {
  return isArray(value) && isNumOrStr(value[0]) && isNumOrStr(value[1]) ? value.join(' ~ ') : value;
}

interface Entry {
  type: string;
  color?: string;
  name: string;
  value: any;
  unit?: string;
  formatter?: (value: any, name: string, entry: Entry, index: number, payload: Entry[]) => any;
}

interface GraphTabledTooltipContentProps {
  payload?: Entry[];
  formatter?: (value: any, name: string, entry: Entry, index: number, payload: Entry[]) => any;
  itemStyle?: React.CSSProperties;
  itemSorter?: (a: Entry, b: Entry) => number;
  wrapperClassName?: string;
  contentStyle?: React.CSSProperties;
  labelClassName?: string;
  labelStyle?: React.CSSProperties;
  label?: ReactNode;
  labelFormatter?: (label: ReactNode, payload?: Entry[]) => ReactNode;
}

class GraphTabledTooltipContent extends PureComponent<GraphTabledTooltipContentProps> {
  renderContent() {
    const { payload, formatter, itemStyle, itemSorter } = this.props;

    if (payload && payload.length) {
      const listStyle: React.CSSProperties = { padding: 0, margin: 0 };

      const sortedPayload = itemSorter ? sortBy(payload, itemSorter) : payload;

      const items = (sortedPayload as Entry[]).map((entry: Entry, i: number) => {
        if (entry.type === 'none') {
          return null;
        }

        const finalItemStyle: React.CSSProperties = {
          paddingTop: 4,
          paddingBottom: 4,
          color: entry.color || '#000',
          ...itemStyle,
        };

        const finalFormatter = entry.formatter || formatter || defaultFormatter;
        let { name, value } = entry;
        if (finalFormatter) {
          const formatted = finalFormatter(value, name, entry, i, payload);
          if (Array.isArray(formatted)) {
            [value, name] = formatted;
          } else {
            value = formatted;
          }
        }
        return (
          <tr key={`tooltip-item-${i}`} style={finalItemStyle}>
            <td>{name}</td>
            <td>:</td>
            <td>
              <span>{value}</span>
              <span>{entry.unit || ''}</span>
            </td>
          </tr>
        );
      });

      return (
        <table className="recharts-tooltip-item-list" style={listStyle}>
          <tbody>{items}</tbody>
        </table>
      );
    }

    return null;
  }

  render() {
    const { wrapperClassName, contentStyle, labelClassName, labelStyle, label, labelFormatter, payload } = this.props;
    const finalStyle: React.CSSProperties = {
      margin: 0,
      padding: 10,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      whiteSpace: 'nowrap',
      ...contentStyle,
    };

    const finalLabelStyle: React.CSSProperties = {
      margin: 0,
      ...labelStyle,
    };

    const hasLabel = !isNil(label);
    let finalLabel: ReactNode = hasLabel ? label : '';
    const wrapperCN = classNames('recharts-default-tooltip', wrapperClassName);
    const labelCN = classNames('recharts-tooltip-label', labelClassName);

    if (hasLabel && labelFormatter) {
      const formattedLabel = labelFormatter(label, payload);
      if (!isNil(formattedLabel)) {
        finalLabel = formattedLabel;
      }
    }

    const renderLabel = (label: ReactNode) => {
      if (React.isValidElement(label)) {
        return label;
      } else if (typeof label === 'string' || typeof label === 'number' || typeof label === 'boolean') {
        return label;
      } else {
        return '';
      }
    };

    return (
      <div className={wrapperCN} style={finalStyle}>
        <p className={labelCN} style={finalLabelStyle}>
          {renderLabel(finalLabel)}
        </p>
        {this.renderContent()}
      </div>
    );
  }
}

export default GraphTabledTooltipContent;
