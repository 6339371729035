import { WbUserPreferenceRow } from '@mst-fe/shared';
import { ErrorNotification } from '../../../services/error-notification';
import { UsersApi } from '../../users/users-api';
import { UserInfoModel } from './user-info';

export interface UserPreferencesModel {
  loading: boolean;
  touched: boolean;
  preferences?: WbUserPreferenceRow;
}

export function initialUserPreferences(): UserPreferencesModel {
  return { loading: true, touched: false, preferences: undefined };
}

export function asUserPreferencesUpdate(model: () => UserInfoModel, update: (_: UserInfoModel) => void) {
  return (userPreferences: UserPreferencesModel | undefined) => update({ ...model(), userPreferences });
}

export async function loadUserPreferences(
  api: UsersApi,
  update: (_: UserPreferencesModel) => void
): Promise<ErrorNotification | undefined> {
  update(initialUserPreferences());
  const ERROR_MESSAGE = 'Error retrieving the user preferences';
  try {
    const res = await api.getUserPreferences();
    if (!res?.ok) {
      return { message: ERROR_MESSAGE };
    }
    update({
      loading: false,
      touched: false,
      preferences: res.body,
    });
  } catch (error) {
    return { message: ERROR_MESSAGE };
  }
}

export async function saveUserPreferences(api: UsersApi, preferences: WbUserPreferenceRow): Promise<ErrorNotification | undefined> {
  const ERROR_MESSAGE = 'Error saving the user preferences';
  try {
    const res = await api.saveUserPreferences(preferences);
    if (!res?.ok) {
      return { message: ERROR_MESSAGE };
    }
  } catch (error) {
    return { message: ERROR_MESSAGE };
  }
}
