import React, { useState, useEffect } from 'react';
import { Form, Button, Select, FormInstance } from 'antd';

import SelectEquityControl from './controls/SelectEquityControl';
import DownloadButton from './controls/DownloadButton';
import TimeSpanControl from './controls/TimeSpanControl';
import { SingleDateAndTimeRangeFormControl } from './controls/SingleDateAndTimeRangeControl';

import BookJoinForm from '../../../workflow/workflows/bookJoinWorkflow/forms/BookJoinForm';
import { getUrlForQuery } from '../outputs/BookQueryOutput';
import { FORM_LAYOUT_LABEL } from '../../../../utils/midas-constants';
import { preventEnterKeyDefault, shouldChangesDisableProducts, shouldDisableProducts } from './data-query-form-utils';

import classes from './shared.module.css';
import { DataQueryConfig, DataQueryConfigQuery, DataQueryTimespan } from '../../../types';

type BookQueryFormParams = {
  config: DataQueryConfig;
  formInstance: FormInstance;
  onFinish: (value: unknown) => void;
};

const QUERY_TYPES = [
  { value: 'time', label: 'BBO per interval provided' },
  { value: 'bbo_price', label: 'BBO based on price changes only' },
  { value: 'bbo_all', label: 'BBO based on quantity or price changes' },
  { value: 'locked', label: 'Best bid equals the best ask' },
  { value: 'crossed', label: 'Best bid higher than the best ask' },
  { value: 'trade', label: 'Trade occurrence' },
];

const QUERY_INTERVAL_REQUIRED = QUERY_TYPES.find((query) => query.value === 'time')?.label;

const TIMESPANS: DataQueryTimespan[] = [
  {
    ms: '',
    label: 'μs',
    max: 99999,
  },
  {
    ms: 's',
    label: 's',
    max: 59,
  },
  {
    ms: 'm',
    label: 'm',
    max: 59,
  },
  {
    ms: 'h',
    label: 'h',
    max: 23,
  },
];

const DATE_FIELD_NAME = 'dateAndTimeRange';

const DEFAULT_PROPS = {
  onFinish: () => {},
};

export default function BookQueryForm({ config, formInstance, onFinish = DEFAULT_PROPS.onFinish }: BookQueryFormParams) {
  const formValue = Form.useWatch([], formInstance);

  const [intervalAvailable, setIntervalAvailability] = useState(false);
  const [disableProducts, setDisableProducts] = useState(false);
  const [bookFormValid, setBookFormValid] = useState(false);

  const setFormValidity = async () => {
    try {
      await formInstance.validateFields({ validateOnly: true });
      setBookFormValid(true);
    } catch (errorInfo: unknown) {
      setBookFormValid((errorInfo as { errorFields: unknown[] }).errorFields?.length === 0);
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleQueryChange = async ({ queries }: { queries: DataQueryConfigQuery[] }) => {
    formInstance.setFieldsValue({ queries });
    setTimeout(async () => await setFormValidity());
  };

  useEffect(() => {
    if (!formValue) return;

    const shouldDisable = shouldChangesDisableProducts({
      formInstance,
      changedValues: formValue,
      dateFieldName: DATE_FIELD_NAME,
    });
    setDisableProducts(shouldDisable);

    if (formValue.event) {
      setIntervalAvailability(formValue.event === 'time');
    }

    setTimeout(async () => await setFormValidity());
  }, [formValue]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/require-await
    const fetchData = async () => {
      const shouldDisable = shouldDisableProducts({ formInstance, dateFieldName: DATE_FIELD_NAME, includeFeed: false });
      setDisableProducts(shouldDisable);

      setIntervalAvailability(config.event === 'time');

      setTimeout(async () => await setFormValidity());
    };
    void fetchData();
  }, [config]);

  return (
    <Form
      form={formInstance}
      layout="horizontal"
      labelCol={FORM_LAYOUT_LABEL}
      onFinish={onFinish}
      labelWrap
      onKeyDown={preventEnterKeyDefault}
    >
      <SingleDateAndTimeRangeFormControl />
      <SelectEquityControl
        config={config}
        disabled={disableProducts}
        dateFieldName={DATE_FIELD_NAME}
        form={formInstance}
        excludeFeedFamilies={['fi', 'quotes', 'trades']}
      />
      <Form.Item
        label="Book Query Type"
        name="event"
        rules={[{ required: true, message: 'Please select Book Query type.' }]}
        labelAlign="left"
      >
        <Select id="queryBookType" aria-label="Query Book type" placeholder="Please select Book Query type.">
          {QUERY_TYPES.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Interval"
        htmlFor="intervalAndUnit"
        name="intervalAndUnit"
        dependencies={['event']}
        required={formInstance.getFieldValue('event') === 'time'}
        {...(!intervalAvailable && {
          help: (
            <>
              Interval is only available for Book Query Type: <i>{QUERY_INTERVAL_REQUIRED}</i>
            </>
          ),
        })}
        rules={[
          ({ getFieldValue }) => ({
            async validator(rule, value) {
              const currentEvent = getFieldValue('event');

              if (currentEvent === 'time' && !value) {
                return Promise.reject(Error('Please provide interval.'));
              }

              return Promise.resolve();
            },
          }),
        ]}
        labelAlign="left"
      >
        <TimeSpanControl disabled={!intervalAvailable} timespans={TIMESPANS} value={undefined} onChange={undefined} />
      </Form.Item>
      <Form.Item label="Queries" name="queries" rules={[{ required: true, message: 'Please create queries.' }]} labelAlign="left">
        <BookJoinForm
          label=""
          source={formValue?.source}
          onDataChange={async (changedValues) => {
            await handleQueryChange(changedValues);
          }}
          tableSize="small"
          userData={{ queries: config.queries || [] }}
        />
      </Form.Item>
      <Form.Item>
        <Button className={classes.applyButtonContainer} htmlType="submit" type="primary" disabled={!bookFormValid}>
          Run Query
        </Button>
        <DownloadButton downloadQuery={getUrlForQuery} formInstance={formInstance} isFormValid={bookFormValid} />
      </Form.Item>
    </Form>
  );
}
