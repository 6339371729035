import React, { useState, useEffect } from 'react';

import { Table } from 'antd';

import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';

import GenericOutputContainer from './controls/GenericOutputContainer';
import CursorPaginationDataTable from './controls/CursorPaginationDataTable';
import useProductDataRequester from './hooks/use-product-data-requester';
import useDataRequester from './hooks/use-data-requester';
import useCursorPaginationState from './hooks/use-cursor-pagination-state';

import { buildUrl } from './_shared/misc-utils';

import { timestampToDateTime } from '../../../../_shared/normalizers';
import { coerceDateTimesToDatesTimes, isDayjs } from '../../../../utils/config-utils';

import { DATE_FORMAT, ROOT_SERVER_URL, WS_SERVER_URL } from '../../../../utils/midas-constants';
import { DEFAULT_PAGE_SIZE } from './_shared/table-utils';
import { DataQueryConfig } from '../../../types';

const INTERVAL_QUERY_URL = `${ROOT_SERVER_URL}api/querying/interval`;
const INTERVAL_QUERY_WS_URL = `${WS_SERVER_URL}api/querying/interval`;

const tableColumns = [
  <Table.Column title="Date" dataIndex="datetime" key="datetime" />,
  <Table.Column title="Product" dataIndex="product" key="product" />,
  <Table.Column title="Bid" dataIndex="bid" key="bid" />,
  <Table.Column title="Ask" dataIndex="ask" key="ask" />,
  <Table.Column title="A Size" dataIndex="asize" key="asize" />,
  <Table.Column title="B Size" dataIndex="bsize" key="bsize" />,
  <Table.Column title="Last Price" dataIndex="last_price" key="last_price" />,
  <Table.Column title="Last Size" dataIndex="last_size" key="last_size" />,
  <Table.Column title="Last SRO" dataIndex="last_sro" key="last_sro" />,
  <Table.Column title="Last Time" dataIndex="last_time" key="last_time" />,
  <Table.Column title="Volume" dataIndex="volume" key="volume" />,
];

const subTableRowRenderer = (column: string) => (value: number) => {
  if (column === 'buy') {
    return value ? 'Buy' : 'Sell';
  }

  if (column.match(/timestamp/)) {
    return timestampToDateTime(value);
  }

  return value;
};

const columnNameModifier = (name: string) => {
  if (name === 'buy') {
    return 'Side';
  }

  return startCase(toLower(name));
};

export const graphDataPoints = {
  bid: 'Bid',
  ask: 'Ask',
  asize: 'A Size',
  bsize: 'B Size',
  volume: 'Volume',
};

export const getUrlForQuery = (
  query: DataQueryConfig,
  selectedProductForDownload?: string,
  incomingDownloadType = 'text/csv',
  useWS = false
) => {
  const { startDate, endDate } = coerceDateTimesToDatesTimes(query.dateAndTimeRange);

  let actualStartDate = startDate?.toISOString();
  const actualEndDate = endDate?.toISOString();

  if (query.cursorKey && !selectedProductForDownload) {
    actualStartDate = query.cursorKey.date;
  }

  const sharedParams = {
    download: (selectedProductForDownload !== undefined).toString(),
    source: query.source,
    feed: query.feed,
    interval: query.interval,
    startDate: actualStartDate,
    endDate: actualEndDate,
  };

  if (selectedProductForDownload) {
    const downloadType = incomingDownloadType;

    return buildUrl(INTERVAL_QUERY_URL, {
      ...sharedParams,
      product: selectedProductForDownload,
      downloadType,
    });
  }
  const intervalUrl = useWS ? INTERVAL_QUERY_WS_URL : INTERVAL_QUERY_URL;

  return query.products?.map((product) =>
    buildUrl(intervalUrl, {
      ...sharedParams,
      product,
      limit: query.pageSize || DEFAULT_PAGE_SIZE,
    })
  );
};

const getUrlForGraphQuery = (query: DataQueryConfig) => {
  const { startDate, endDate } = coerceDateTimesToDatesTimes(query.dateAndTimeRange);

  const actualStartDate = startDate?.toISOString();
  const actualEndDate = endDate?.toISOString();

  const queryParams = {
    source: query.source,
    feed: query.feed,
    interval: query.interval,
    startDate: actualStartDate,
    endDate: actualEndDate,
    limit: 1000, // custom limit as interval query seems particulary slow
  };

  return query.products?.map((product: string) =>
    buildUrl(INTERVAL_QUERY_URL, {
      ...queryParams,
      product,
    })
  );
};

const DEFAULT_PROPS = {
  onConfigChanged: () => {},
};

export default function IntervalOutput({
  config,
  onConfigChanged = DEFAULT_PROPS.onConfigChanged,
}: {
  config: DataQueryConfig;
  onConfigChanged?: (config: DataQueryConfig) => void;
}) {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { downloadedData, setProduct, product, handleDownloadData, processing } = useProductDataRequester({
    config,
    getUrlForQuery,
  });

  const { processing: graphProcessing, downloadedData: graphData } = useDataRequester({
    config,
    getUrlForQuery: getUrlForGraphQuery,
  });

  const { requestCursor, cursorPages, resetCursors, setCursorBack, setCursorForward, updateCursor } = useCursorPaginationState();

  useEffect(() => {
    onConfigChanged({
      ...config,
      pageSize,
      cursorKey: requestCursor,
      currentPage: cursorPages,
    });
  }, [requestCursor]);

  useEffect(() => {
    resetCursors();

    onConfigChanged({
      ...config,
      pageSize,
      cursorKey: null,
      currentPage: 0,
    });
  }, [pageSize, product, config.dateAndTimeRange]);

  useEffect(() => {
    const productData = downloadedData.find((item) => item.product === product);
    if (productData?.output) {
      updateCursor(productData.output, 'datetime', 'YYYY-MM-DD hh:mm:ss');
    }
  }, [downloadedData, product]);

  const handlePreviousPage = () => {
    setCursorBack();
  };

  const handleNextPage = () => {
    setCursorForward();
  };

  const handlePageSizeChanged = (_previous: number, next: number) => setPageSize(next);

  return (
    <GenericOutputContainer
      config={config}
      gridControl={CursorPaginationDataTable}
      data={downloadedData || []}
      dataLoading={processing.busy}
      dataError={processing.error}
      products={config?.products || []}
      product={product}
      onSelectProduct={setProduct}
      onDownload={handleDownloadData}
      columnNameModifier={columnNameModifier}
      subTableRowRenderer={subTableRowRenderer}
      dataPoints={graphDataPoints}
      graphData={graphData}
      graphDataLoading={graphProcessing.busy}
      xAxisColumnType="category"
      xAxisColumnDisplayType="time"
      xAxisLabelColumn={isDayjs(config.dateAndTimeRange?.date) ? config.dateAndTimeRange?.date.format(DATE_FORMAT) : 'datetime'}
      xAxisColumn="datetime"
      onCursorBack={handlePreviousPage}
      onCursorNext={handleNextPage}
      onPageSizeChanged={handlePageSizeChanged}
      graphControl={undefined}
      onChangedFilters={undefined}
      onConfigChanged={undefined}
      getUrlForGraphQuery={undefined}
      tableColumns={tableColumns}
    />
  );
}
