import React, { useState, useEffect } from 'react';

import { Button, Form, FormInstance, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import SelectEquityControl from './controls/SelectEquityControl';
import DownloadButton from './controls/DownloadButton';
import { getUrlForQuery } from '../outputs/DailyOutput';
import { AMERICAN_DATE_FORMAT, FORM_LAYOUT_LABEL } from '../../../../utils/midas-constants';
import { shouldDisableProducts, shouldChangesDisableProducts, preventEnterKeyDefault } from './data-query-form-utils';

import classes from './shared.module.css';
import { DataQueryConfig } from '../../../types';

type DailyFormParams = {
  config: DataQueryConfig;
  formInstance: FormInstance;
  onFinish: (value: unknown) => void;
};

export const DATE_FIELD_NAME = 'dateRange';

const DEFAULT_PROPS = {
  onFinish: () => {},
};

const ONE_HOUR_MS = 1000 * 3600;
const ONE_DAY_MS = ONE_HOUR_MS * 24;

export default function DailyForm({ config, formInstance, onFinish = DEFAULT_PROPS.onFinish }: DailyFormParams) {
  const formValue = Form.useWatch([], formInstance);

  const [disableProducts, setDisableProducts] = useState(true);
  const [dailyFormValid, setDailyFormValid] = useState(false);

  const setFormValidity = async () => {
    try {
      await formInstance.validateFields({ validateOnly: true });
      setDailyFormValid(true);
    } catch (errorInfo: unknown) {
      setDailyFormValid((errorInfo as { errorFields: unknown[] }).errorFields?.length === 0);
    }
  };

  // when this is set to DayJs type ts is moaning about rangepicker Dayjs being different tahn imported one...
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const disabledDate = (current: any) => {
    if ((current && current > dayjs()) || current.day() === 0 || current.day() === 6) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!formValue) return;

    const shouldDisable = shouldChangesDisableProducts({
      formInstance,
      changedValues: formValue,
      dateFieldName: DATE_FIELD_NAME,
      includeFeed: false,
    });
    setDisableProducts(shouldDisable);

    setTimeout(async () => await setFormValidity());
  }, [formValue]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/require-await
    const fetchData = async () => {
      const shouldDisable = shouldDisableProducts({ formInstance, dateFieldName: DATE_FIELD_NAME, includeFeed: false });
      setDisableProducts(shouldDisable);

      setTimeout(async () => await setFormValidity());
    };

    void fetchData();
  }, [config]);

  return (
    <Form
      form={formInstance}
      layout="horizontal"
      labelCol={FORM_LAYOUT_LABEL}
      onFinish={onFinish}
      labelWrap
      onKeyDown={preventEnterKeyDefault}
    >
      <Form.Item
        label="Date"
        name={DATE_FIELD_NAME}
        labelAlign="left"
        rules={[
          {
            required: true,
            message: 'Please select a date range',
          },
          () => ({
            async validator(_rule, value: [Dayjs, Dayjs]) {
              if (!value || !value[0] || !value[1]) {
                return;
              }
              const startDate = value[0];

              if (startDate.day() === 0 || startDate.day() === 6) {
                return Promise.reject(Error('Starting date cannot be a weekend.'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <DatePicker.RangePicker disabledDate={disabledDate} format={AMERICAN_DATE_FORMAT} showTime={false} />
      </Form.Item>
      <SelectEquityControl
        config={config}
        disabled={disableProducts}
        dateFieldName={DATE_FIELD_NAME}
        form={formInstance}
        showSelectFeedControl={false}
      />
      <Form.Item>
        <Button className={classes.applyButtonContainer} htmlType="submit" type="primary" disabled={!dailyFormValid}>
          Run Query
        </Button>
        <DownloadButton downloadQuery={getUrlForQuery} formInstance={formInstance} isFormValid={dailyFormValid} />
      </Form.Item>
    </Form>
  );
}
