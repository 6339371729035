import React, { useState, useEffect } from 'react';

import GenericOutputContainer from './controls/GenericOutputContainer';
import TicksSingleItemOutput from './controls/TicksSingleItemOutput';

import useProductDataRequester from './hooks/use-product-data-requester';
import useCursorPaginationState from './hooks/use-cursor-pagination-state';

import { buildUrl } from './_shared/misc-utils';

import { coerceDateTimesToDatesTimes } from '../../../../utils/config-utils';

import { ROOT_SERVER_URL, WS_SERVER_URL } from '../../../../utils/midas-constants';
import { DEFAULT_PAGE_SIZE } from './_shared/table-utils';
import { DataQueryConfig, DataQueryFilter } from '../../../types';

const TICKS_QUERY_URL = `${ROOT_SERVER_URL}api/querying/ticks`;
const TICKS_QUERY_WS_URL = `${WS_SERVER_URL}api/querying/ticks`;

export const getUrlForQuery = (
  query: DataQueryConfig,
  selectedProductForDownload?: string,
  incomingDownloadType = 'text/csv',
  useWS = false
) => {
  const { startDate, endDate } = coerceDateTimesToDatesTimes(query.dateAndTimeRange);

  let actualStartDate = startDate?.toISOString();
  const actualEndDate = endDate?.toISOString();

  if (query.cursorKey && !selectedProductForDownload) {
    actualStartDate = query.cursorKey.date;
  }

  const sharedParams = {
    download: (selectedProductForDownload !== undefined).toString(),
    source: query.source,
    feed: query.feed,
    startDate: actualStartDate,
    endDate: actualEndDate,
    messages: query?.messages?.includes('ALL') ? [] : (query?.messages || []).join(','),
  };

  if (selectedProductForDownload) {
    const downloadType = incomingDownloadType;

    return buildUrl(
      TICKS_QUERY_URL,
      {
        ...sharedParams,
        product: selectedProductForDownload,
        downloadType,
      },
      ['messages']
    );
  }

  const filters = query.filters && JSON.stringify(query.filters);
  const cursor = query.cursorKey && JSON.stringify(query.cursorKey);

  const queryUrl = useWS ? TICKS_QUERY_WS_URL : TICKS_QUERY_URL;

  return query.products?.map((product) =>
    buildUrl(
      queryUrl,
      {
        ...sharedParams,
        ...(filters ? { filters } : {}),
        ...(cursor ? { cursor } : {}),
        product,
        limit: query.pageSize || DEFAULT_PAGE_SIZE,
      },
      ['messages']
    )
  );
};

const DEFAULT_PROPS = {
  onConfigChanged: () => {},
};

export default function TicksOutput({
  config,
  onConfigChanged = DEFAULT_PROPS.onConfigChanged,
}: {
  config: DataQueryConfig;
  onConfigChanged?: (config: DataQueryConfig) => void;
}) {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { downloadedData, setProduct, product, handleDownloadData, processing } = useProductDataRequester({
    config,
    getUrlForQuery,
  });

  const { requestCursor, cursorPages, resetCursors, setCursorBack, setCursorForward, updateCursor } = useCursorPaginationState();

  useEffect(() => {
    onConfigChanged({
      ...config,
      pageSize,
      cursorKey: requestCursor,
      currentPage: cursorPages,
    });
  }, [requestCursor]);

  useEffect(() => {
    resetCursors();

    onConfigChanged({
      ...config,
      pageSize,
      cursorKey: null,
      currentPage: 0,
    });
  }, [pageSize, product, JSON.stringify(config.dateAndTimeRange)]);

  useEffect(() => {
    const productData = downloadedData.find((item) => item.product === product);
    if (productData?.output) {
      updateCursor(productData.output, 'datetime', 'YYYY-MM-DD hh:mm:ss.SSS+');
    }
  }, [downloadedData, product]);

  const handlePreviousPage = () => {
    setCursorBack();
  };

  const handleNextPage = () => {
    setCursorForward();
  };

  const handlePageSizeChanged = (_previous: number, next: number) => setPageSize(next);

  const handleChangedFilters = (filters: DataQueryFilter) => {
    resetCursors();

    onConfigChanged({
      ...config,
      filters,
      cursorKey: null,
      currentPage: 0,
    });
  };

  return (
    <GenericOutputContainer
      dataPoints={null}
      config={config}
      data={downloadedData || []}
      dataLoading={processing.busy}
      dataError={processing.error}
      products={config?.products || []}
      product={product}
      onSelectProduct={setProduct}
      onDownload={handleDownloadData}
      onChangedFilters={handleChangedFilters}
      onCursorBack={handlePreviousPage}
      onCursorNext={handleNextPage}
      onPageSizeChanged={handlePageSizeChanged}
      gridControl={TicksSingleItemOutput}
    />
  );
}
