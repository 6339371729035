import axios from 'axios';
import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';
import { axiosOptions } from '../../services/requests';

export class AuthorisationApi {
  async generateApiKey(expirationSeconds: number): Promise<string | undefined> {
    return (
      await checkAxiosResponse(
        axios.post<{ apiKey: string }>('/api/authorization/generate-api-key', { expirationSeconds }, await axiosOptions()),
        'Error generating API key'
      )
    )?.apiKey;
  }
}
