import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { DashboardRemove } from '../models/dashboard-remove';

export interface DashboardRemoveDialogDelegate {
  onDashboardRemoveSelected(remove: boolean): void;
}

export function DashboardRemoveDialog({ model, delegate }: { model: DashboardRemove; delegate: DashboardRemoveDialogDelegate }) {
  const onOk = useCallback(() => delegate.onDashboardRemoveSelected(true), [delegate]);
  const onCancel = useCallback(() => delegate.onDashboardRemoveSelected(false), [delegate]);

  return (
    <Modal
      title="Remove Dashboard?"
      maskClosable={false}
      open
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{ disabled: model.state === 'saving' }}
      cancelButtonProps={{ disabled: model.state === 'saving' }}
      data-testid="remove-dashboard-modal"
    >
      Are you sure you want to remove the selected dashboard?
    </Modal>
  );
}
