import { useState, useEffect } from 'react';

import useDataRequester, { GetUrlForQuery } from './use-data-requester';
import { DataQueryConfig } from '../../../../types';

type useProductDataRequesterParams = {
  config?: DataQueryConfig;
  getUrlForQuery: GetUrlForQuery;
};

export default function useProductDataRequester({ config, getUrlForQuery }: useProductDataRequesterParams) {
  const { processing, downloadedData, handleDownloadData } = useDataRequester({
    config,
    getUrlForQuery,
    useWS: true,
  });
  const [product, setProduct] = useState('');

  useEffect(() => {
    if (!config || !config.products || config.products.length === 0 || (product && config.products.includes(product))) {
      return;
    }

    setProduct(config.products[0]);
  }, [config, product]);

  return {
    downloadedData,
    setProduct,
    product,
    processing,
    handleDownloadData,
  };
}
