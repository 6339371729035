import EventEmitter from 'events';
import { useEffect, useRef, useCallback } from 'react';

interface Auth {
  getTokenSilently(): Promise<string>;
}

interface ErrorEvent {
  url: string;
  response: Response;
}

const management = {
  events: new EventEmitter(),
  _halted: false, // Initialize _halted to false
  halt: () => {
    management._halted = true;
  },
  isHalted: () => {
    return management._halted;
  },
  neverResolve: new Promise<never>(() => {}),
};

export const getManagement = () => management;

const emitError = (url: string, response: Response) => {
  if (!management.events.listeners('error').length) {
    return;
  }

  management.events.emit('error', {
    url,
    response: {
      status: response.status,
      statusText: response.statusText,
    },
  } as ErrorEvent);
};

const TYPES = ['get', 'patch', 'remove', 'put', 'post'] as const;
type RequestType = typeof TYPES[number];

const unassignedAbortControllers: Record<RequestType, AbortController> = TYPES.reduce(
  (obj, type) => ({
    ...obj,
    [type]: new AbortController(),
  }),
  {} as Record<RequestType, AbortController>
);

const abortControllers: Record<string, AbortController> = {};

const getCancelSignal = (cancellationToken: string | undefined, type: RequestType): AbortSignal => {
  if (!cancellationToken) {
    return unassignedAbortControllers[type].signal;
  }

  if (!abortControllers[cancellationToken]) {
    abortControllers[cancellationToken] = new AbortController();
  }

  return abortControllers[cancellationToken].signal;
};

const getAuthHeader = async (auth: string | Auth | undefined): Promise<Record<string, string>> => {
  if (!auth) {
    return {};
  }

  if (typeof auth === 'string') {
    return {
      Authorization: `Bearer ${auth}`,
    };
  }

  const authToken = await auth.getTokenSilently();
  return {
    Authorization: `Bearer ${authToken}`,
  };
};

// POST
export const post = async (
  url: string,
  data: any,
  auth: string | Auth | undefined,
  cancellationToken: string | undefined = undefined,
  options: { parseValidationError?: boolean } | undefined = undefined
): Promise<Response> => {
  const signal = getCancelSignal(cancellationToken, 'post');

  if (management.isHalted()) {
    return management.neverResolve as unknown as Response;
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      ...(await getAuthHeader(auth)),
    },
    body: typeof data === 'string' ? data : JSON.stringify(data),
    signal,
  });

  if (options?.parseValidationError && response.status === 400) {
    return await response.json();
  }

  if (!response.ok) {
    emitError(url, response);
    throw new Error(`Expected a successful response code, but got: ${response.statusText} (${response.status})`);
  }

  return response;
};

export const postToJson = async (
  url: string,
  data: any,
  auth: string | Auth | undefined,
  cancellationToken: string | undefined = undefined
): Promise<any> => (await post(url, data, auth, cancellationToken)).json();

// GET
export const get = async (
  url: string,
  auth: string | Auth | undefined,
  headers: Record<string, string> = {},
  cancellationToken: string | undefined = undefined
): Promise<Response> => {
  const signal = getCancelSignal(cancellationToken, 'get');

  if (management.isHalted()) {
    return management.neverResolve as unknown as Response;
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      ...headers,
      ...(await getAuthHeader(auth)),
    },
    signal,
  });

  if (!response.ok) {
    emitError(url, response);
    throw new Error(`Expected a successful response code, but got: ${response.statusText} (${response.status})`);
  }

  return response;
};

export const getToJson = async (
  url: string,
  auth: string | Auth | undefined,
  headers: Record<string, string> = {},
  cancellationToken: string | undefined = undefined
): Promise<any> => (await get(url, auth, headers, cancellationToken)).json();

// REMOVE
export const remove = async (
  url: string,
  auth: string | Auth | undefined,
  cancellationToken: string | undefined = undefined
): Promise<Response> => {
  const signal = getCancelSignal(cancellationToken, 'remove');

  if (management.isHalted()) {
    return management.neverResolve as unknown as Response;
  }

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      ...(await getAuthHeader(auth)),
    },
    signal,
  });

  if (!response.ok) {
    emitError(url, response);
    throw new Error(`Expected a successful response code, but got: ${response.statusText} (${response.status})`);
  }

  return response;
};

export const removeToJson = async (
  url: string,
  auth: string | Auth | undefined,
  cancellationToken: string | undefined = undefined
): Promise<any> => (await remove(url, auth, cancellationToken)).json();
