import React, { CSSProperties, ReactNode } from 'react';

import styles from './PaddedContainer.module.css';

// Allowing PaddedContainer to receive unknown props so that, for instance, data-testid can be passed.
type PaddedContainerProps = {
  className?: string | undefined;
  children: ReactNode | undefined;
  style?: CSSProperties | undefined;
  [prop: string]: unknown;
};

export function PaddedContainer(props: PaddedContainerProps) {
  const { className, style, children, ...rest } = props;
  const mergedClassNames = className ? `${styles.container} ${className}` : styles.container;
  return (
    <div className={mergedClassNames} style={style} {...rest}>
      {children}
    </div>
  );
}
