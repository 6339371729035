import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Table } from 'antd';

import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';

import useDataRequester, { GetUrlForQuery } from '../hooks/use-data-requester';
import PagingDataTable from './paging/PagingDataTable';

import withSorter from '../_shared/WithSorter';
import { DataQueryConfig, DataQueryTableColumn, DataQueryProductServerInfo } from '../../../../types';

type DataRetrievingDataTableParams = {
  config?: DataQueryConfig;
  tableColumns?: DataQueryTableColumn[];
  products?: string[];
  product?: string;
  dataLoading?: boolean;
  dataError?: string | object;
  dataIndex?: string;
  onSelectProduct?: (value: string, option: { value: string; label: string } | { value: string; label: string }[]) => void;
  productServerInfo?: DataQueryProductServerInfo;
  onDownload?: (selectedItem: string) => void;
  getUrlForQuery: GetUrlForQuery;
  defaultPageSize?: number;
  columnNameModifier?: (name: string) => string;
};

const DEFAULT_PROPS = {
  config: {
    products: [],
    feed: undefined,
  },
  products: [],
  onSelectProduct: () => {},
  onDownload: () => {},
  tableColumns: [],
  columnNameModifier: (name: string) => startCase(toLower(name)),
};

export default function DataRetrievingDataTable({
  config = DEFAULT_PROPS.config,
  products = DEFAULT_PROPS.products,
  product = undefined,
  onSelectProduct = DEFAULT_PROPS.onSelectProduct,
  productServerInfo = undefined,
  dataLoading = false,
  dataError = undefined,
  onDownload = DEFAULT_PROPS.onDownload,
  getUrlForQuery,
  tableColumns = DEFAULT_PROPS.tableColumns,
  defaultPageSize = 50,
  columnNameModifier = DEFAULT_PROPS.columnNameModifier,
}: DataRetrievingDataTableParams) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handlePageSizeChanged = useCallback((existingSize: number, newSize: number) => setPageSize(newSize), [setPageSize]);

  useEffect(() => setPage(1), [config, product]);

  const childConfig = useMemo(
    () => ({
      ...config,
      uuid: productServerInfo?.uuid,
      start: (page - 1) * pageSize,
      end: page * pageSize,
    }),
    [config, productServerInfo, page, pageSize]
  );

  const { processing, downloadedData } = useDataRequester({ config: childConfig, getUrlForQuery });

  const actualTableColumns = useMemo(() => {
    if (tableColumns && tableColumns.length > 0) {
      return tableColumns.map(({ props, type: Column }) => withSorter(Column)({ key: props.dataIndex, ...props }));
    }

    if (downloadedData.length === 0) {
      return [];
    }

    return Object.keys(downloadedData[0])
      .filter((fieldName) => !['key', 'dateTicks'].includes(fieldName))
      .map((fieldName) =>
        withSorter(Table.Column)({
          key: fieldName,
          title: columnNameModifier(fieldName),
          dataIndex: fieldName,
        })
      );
  }, [tableColumns, downloadedData]);

  return (
    <PagingDataTable
      tableColumns={actualTableColumns}
      products={products}
      currentPage={page}
      pageSize={pageSize}
      data={downloadedData}
      dataLoading={processing.busy || dataLoading}
      dataError={processing.error || dataError}
      product={product}
      productServerInfo={productServerInfo}
      onPageChanged={setPage}
      onPageSizeChanged={handlePageSizeChanged}
      onSelectProduct={onSelectProduct}
      onDownload={onDownload}
    />
  );
}
