/* eslint-disable no-param-reassign */
import { MutableRefObject, useEffect } from 'react';

import { useNavigate, To } from 'react-router-dom';

export default function HistoryProxy({ eventHandler }: { eventHandler: MutableRefObject<((value: To) => void) | null | undefined> }) {
  const navigate = useNavigate();

  useEffect(() => {
    eventHandler.current = (url) => {
      navigate(url);
    };
    return () => {
      eventHandler.current = null;
    };
  }, [eventHandler, navigate]);

  return null;
}
