import React from 'react';
import { useController } from '../../../hooks/use-controller';
import { GlobalSearchController } from '../global-search-controller';
import { initialGlobalSearchResults } from '../models/global-search';
import { GlobalSearchView } from './GlobalSearchView';

export function GlobalSearch() {
  const { model, delegate } = useController(initialGlobalSearchResults, GlobalSearchController);
  return (delegate && <GlobalSearchView model={model} delegate={delegate} />) ?? null;
}
