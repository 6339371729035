import { useEffect, useState, useCallback, useMemo } from 'react';

import { getToJson, postToJson, get, post } from '../utils/io';
import { getAccessTokenFromCookie } from '../../services/auth';

type useWebDataLoaderParams = {
  url?: string | URL;
  content?: string;
  isPlain?: boolean;
};

type WebDataLoaderData<T = unknown> = {
  loading: boolean;
  error: unknown;
  data: T | undefined;
};

export default function useWebDataLoader<T = unknown>({
  url = '',
  content,
  isPlain = false,
}: useWebDataLoaderParams): [WebDataLoaderData<T>, () => (() => void) | undefined] {
  const fns: {
    get: (url: string | URL, token: string | null) => Promise<T>;
    post: (url: string | URL, content: string, token: string | null) => Promise<T>;
  } = useMemo(
    () => ({
      get: (isPlain ? async (_url: string, token: string) => (await get(_url, token)).text() : getToJson) as unknown as (
        url: string | URL,
        token: string | null
      ) => Promise<T>,
      post: (isPlain
        ? async (_url: string, _content: string, token: string) => (await post(_url, _content, token)).text()
        : postToJson) as unknown as (url: string | URL, content: string, token: string | null) => Promise<T>,
    }),
    [isPlain]
  );

  const [data, setData] = useState<WebDataLoaderData<T>>({
    loading: false,
    error: null,
    data: undefined,
  });

  const runUpdate = useCallback(() => {
    let cancelStateUpdate = false;
    if (!url) {
      setData((d) => ({ ...d, loading: false, data: undefined }));
      return;
    }

    setData((d) => ({ ...d, loading: true }));
    void (async () => {
      try {
        if (!url) {
          setData((d) => ({ ...d, loading: false }));
          return;
        }

        const authToken = getAccessTokenFromCookie()!;

        const isPost = content !== undefined;
        const loadedData = isPost ? await fns.post(url, content, authToken) : await fns.get(url, authToken);

        if (cancelStateUpdate) {
          return;
        }

        setData({
          loading: false,
          error: null,
          data: loadedData,
        });
      } catch (err: unknown) {
        console.error('[useDataLoader] failed to load data...', err);
        setData({ error: err, loading: false, data: [] as T });
      }
    })();

    return () => {
      cancelStateUpdate = true;
    };
  }, [fns, url, content]);

  useEffect(runUpdate, [runUpdate, url, content]);

  return [data, runUpdate];
}
