import { handleError } from '../../services/error-notification';
import { PeriodicRequest } from '../../services/periodic-request';
import { DashboardApi, DashboardData } from './dashboard-api';
import { initialDashboardCreate, saveDashboardUpsert, updateDashboardName } from './models/dashboard-upsert';
import {
  asDashboardCreateUpdate,
  asDashboardRemoveUpdate,
  DashboardList,
  initialDashboardList,
  updateDashboards,
} from './models/dashboard-list';
import { initialDashboardRemove, saveDashboardRemove } from './models/dashboard-remove';
import { DashboardNavDelegate } from './views/DashboardNav';
import { DashboardDetailParentController } from './dashboard-detail-controller';
import { LONG_POLLING_INTERVAL } from '../../constants';

export class DashboardController implements DashboardNavDelegate, DashboardDetailParentController {
  private api = new DashboardApi();

  private model = initialDashboardList();

  private dashboardPeriodicRequest: PeriodicRequest<DashboardData[] | undefined>;

  private disposeCallbacks: (() => void)[] = [];

  constructor(private updateViewState: (_: DashboardList) => void) {
    this.dashboardPeriodicRequest = new PeriodicRequest({
      interval: LONG_POLLING_INTERVAL,

      onPeriodicRequest: async () => {
        return await this.api.getDashboards();
      },

      onPeriodicRequestResult: (value: DashboardData[] | undefined) => {
        if (value) {
          this.update(updateDashboards(this.model, value));
        }
      },
    });
  }

  start() {
    if (this.disposeCallbacks.length === 0) {
      this.disposeCallbacks.push(this.dashboardPeriodicRequest.start());
    }
  }

  dispose() {
    for (const cb of this.disposeCallbacks) {
      cb();
    }
    this.disposeCallbacks = [];
  }

  private getModel = (): DashboardList => this.model;

  private update = (model: DashboardList) => {
    this.model = model;
    this.updateViewState(model);
  };

  private updateCreate = asDashboardCreateUpdate(this.getModel, this.update);

  private updateRemove = asDashboardRemoveUpdate(this.getModel, this.update);

  onDashboardCreate(): void {
    this.updateCreate(initialDashboardCreate());
  }

  onDashboardNameChanged(name: string): void {
    this.updateCreate(updateDashboardName(this.model.create, name));
  }

  async onDashboardUpsertDone(): Promise<void> {
    handleError(await saveDashboardUpsert(this.api, this.model.create, this.dashboardPeriodicRequest.refresh, this.updateCreate));
  }

  onDashboardUpsertCancelled(): void {
    this.updateCreate(undefined);
  }

  onDashboardRemove(dashboardId: string): void {
    this.updateRemove(initialDashboardRemove(dashboardId));
  }

  async onDashboardRemoveSelected(remove: boolean): Promise<void> {
    if (!this.model.remove) {
      return;
    }

    if (remove) {
      handleError(await saveDashboardRemove(this.api, this.model.remove, this.dashboardPeriodicRequest.refresh, this.updateRemove));
    } else {
      this.updateRemove(undefined);
    }
  }

  async onDashboardUpdated(): Promise<void> {
    await this.dashboardPeriodicRequest.refresh();
  }
}
