/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import { Form, FormInstance, Select, Space, TreeSelect } from 'antd';

import startCase from 'lodash/startCase';
import defer from 'lodash/defer';

import axios from 'axios';

import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';

import { FEED_FAMILIES_URL } from '../../../../../utils/midas-constants';
import { DataQueryFeed } from '../../../../types';
import { getAccessTokenFromCookie } from '../../../../../../services/auth';

const ALWAYS_EXCLUDED_FEED_FAMILIES = ['cme'];

type SelectFeedControlParams = {
  form: FormInstance;
  excludeFamilies: string[];
  onSelectFamilyOrFeed: (value: string, option: DataQueryFeed) => void;
  showSelectFeedControl: boolean;
  selectFamily: boolean;
  selectFeed: boolean;
};

type SelectFeedControlFeeds = {
  tree: { twxm?: DataQueryFeed[]; lake?: DataQueryFeed[] };
  busy: boolean;
  error: unknown;
};

const DEFAULT_PROPS = {
  incomingExcludedFamilies: [],
  onSelectFamilyOrFeed: () => {},
};

export default function SelectFeedControl({
  form,
  excludeFamilies: incomingExcludedFamilies = DEFAULT_PROPS.incomingExcludedFamilies,
  onSelectFamilyOrFeed = DEFAULT_PROPS.onSelectFamilyOrFeed,
  showSelectFeedControl = true,
  selectFamily = true,
  selectFeed = true,
}: SelectFeedControlParams) {
  const token = getAccessTokenFromCookie();

  const source = Form.useWatch<'twxm' | 'lake'>('source', form);

  function onSourceChange() {
    form.setFieldValue('feed', undefined);
    form.setFieldValue('products', undefined);
  }

  const [feeds, setFeeds] = useState<SelectFeedControlFeeds>({ tree: {}, busy: false, error: undefined });

  useEffect(() => {
    setFeeds((s) => ({ ...s, busy: true, error: undefined }));

    defer(async () => {
      try {
        const rawTree = await checkAxiosResponse(
          axios.get<{
            twxm: { [feed_family: string]: string[] };
            lake: { [feed_family: string]: string[] };
          }>(FEED_FAMILIES_URL, {
            headers: { Authorization: `Bearer ${token}` },
            validateStatus: null,
          }),
          'Error while getting feeds'
        );
        if (!rawTree) {
          throw new Error('Error while getting feeds');
        }

        const excludeFamilies = new Set([...incomingExcludedFamilies, ...ALWAYS_EXCLUDED_FEED_FAMILIES]);

        const tree: { twxm: DataQueryFeed[]; lake: DataQueryFeed[] } = { twxm: [], lake: [] };

        for (const source of ['twxm', 'lake'] as const) {
          for (const [feed_family, feeds] of Object.entries(rawTree[source])) {
            if (excludeFamilies.has(feed_family)) {
              continue;
            }

            tree[source].push({
              key: feed_family,
              value: feed_family,
              title: startCase(feed_family),
              feedFamily: feed_family,
              selectable: selectFamily,
              children: feeds.sort().map((feed) => ({
                key: `${feed_family}/${feed}`,
                value: `${feed_family}/${feed}`,
                title: feed,
                feedFamily: feed_family,
                selectable: selectFeed,
              })),
            });
          }
        }

        setFeeds({ tree, busy: false, error: undefined });
      } catch (error: unknown) {
        console.error('[SelectFeedControl] failed to load sources from server..', error);
        setFeeds({ tree: {}, busy: false, error });
      }
    });
  }, []);

  return (
    <Form.Item
      label="Feed"
      labelAlign="left"
      required
      help={feeds.error ? 'Failed to load feeds. Please try again later.' : undefined}
      validateStatus={feeds.error ? 'warning' : undefined}
    >
      <Space.Compact className="w-full">
        <Form.Item noStyle name="source" initialValue="twxm">
          <Select
            style={{ flex: '0 0 145px' }}
            aria-label="Feed source"
            loading={feeds.busy}
            options={[
              { value: 'twxm', label: 'TWXM feeds' },
              { value: 'lake', label: 'Data Lake feeds' },
            ]}
            onSelect={onSourceChange}
            data-testid="data-query-feed-source"
          ></Select>
        </Form.Item>
        {showSelectFeedControl && (
          <Form.Item
            noStyle
            name="feed"
            rules={[{ required: true, message: 'Please select a feed.' }]}
            trigger="onChange"
            valuePropName="value"
            data-testid="data-query-feed"
          >
            <TreeSelect
              aria-label="Feed"
              allowClear
              loading={feeds.busy}
              onSelect={onSelectFamilyOrFeed}
              placeholder="Please select a feed source"
              treeData={feeds.tree[source]}
              data-testid="data-query-feed-tree"
            />
          </Form.Item>
        )}
      </Space.Compact>
    </Form.Item>
  );
}
