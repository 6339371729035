import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

export function formatDateTime(time: string): string {
  if (!time) {
    return 'N/A';
  }

  const date = new Date(time);
  return date.toLocaleString();
}

export function formatTime(time: string): string {
  if (!time) {
    return 'N/A';
  }

  const date = new Date(time);
  return date.toLocaleTimeString();
}

export function titleCase(value: string) {
  return startCase(toLower(value));
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) {
    return '0 bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function formatArray(arr: string[]) {
  return arr.join(', ');
}
