import { ErrorNotification } from '../../../services/error-notification';
import { JobsApi } from '../jobs-api';

export interface TriggerJobModel {
  state: 'ready' | 'saving';
  jobId: string;
  payload: string;
  payloadError: string;
}

export function initialTriggerJobModel(jobId: string): TriggerJobModel {
  return {
    state: 'ready',
    jobId,
    payload: '',
    payloadError: '',
  };
}

function getPayloadError(payload: string): string {
  const msg = 'Job payload must be a valid JSON object';

  try {
    const res = JSON.parse(payload);
    if (typeof res !== 'object' || Array.isArray(res)) {
      return msg;
    }
  } catch {
    return msg;
  }

  return '';
}

export function editTriggerJobPayload(model: TriggerJobModel, payload: string): TriggerJobModel {
  return {
    ...model,
    payload,
    payloadError: getPayloadError(payload),
  };
}

export async function saveTriggerJob(
  api: JobsApi,
  getModel: () => TriggerJobModel | undefined,
  refresh: () => Promise<void>,
  update: (_: TriggerJobModel | undefined) => void
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  if (!model) {
    return model;
  }

  if (model.payloadError) {
    return;
  }

  update({ ...model, state: 'saving' });

  const result = await api.triggerJob(model.jobId, JSON.parse(model.payload || '{}'));

  if (getModel()?.jobId !== model.jobId) {
    // changed while saving, bail out
    return;
  }

  if (!result?.ok) {
    update({ ...model, state: 'ready' });
    return { message: 'Error triggering job' };
  }

  try {
    await refresh();
  } finally {
    update(undefined);
  }
}
