/* eslint-disable @typescript-eslint/unbound-method */
import { WebViewIpcBase } from './web-view-ipc-base';

export interface IWebViewIpcClient {
  invoke(name: string, ...parameters: any): Promise<any>;
}

export abstract class WebViewIpcClientBase extends WebViewIpcBase implements IWebViewIpcClient {
  protected constructor(protected target?: any, protected sessionId: string = '') {
    super(target, sessionId);

    this.webHandleMessage = this.webHandleMessage.bind(this);
  }

  override startListening() {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('message', this.webHandleMessage);
  }

  override stopListening() {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.removeEventListener('message', this.webHandleMessage);
  }

  override fire(callback: () => void) {
    setTimeout(callback, 0);
  }

  protected async webHandleMessage(e: any) {
    return super.handleMessage(e.data);
  }

  abstract postMessage(message: any): void;
}
