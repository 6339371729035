import React, { useMemo, useRef, useState, useEffect, useCallback, MouseEventHandler } from 'react';

import { DownloadOutlined, InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Pagination, Select, Space, Table, Typography, Input, message, InputRef, TableProps, Tooltip } from 'antd';
import { ColumnType, Key, TablePaginationConfig } from 'antd/lib/table/interface';

import flatten from 'lodash/flatten';
import compact from 'lodash/compact';

import MidasError from '../../../../../../components/Error';
import { DEFAULT_PAGE_SIZE } from '../../_shared/table-utils';
import CursorPagination from './CursorPagination';

import classes from './PagingDataTable.module.css';
import { DataQueryProductServerInfo } from '../../../../../types';

type PagingDataTableParams = {
  products?: string[];
  product?: string;
  tableColumns?: JSX.Element[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any>[];
  dataLoading?: boolean;
  dataError?: { error?: string } | string;
  onDownload?: (selectedItem: string) => void;
  paginationType?: string;
  pageSize?: number;
  onPageSizeChanged?: (current: number, size: number) => void;
  currentPage?: number;
  onPageChanged?: (page: number, pageSize?: number) => void;
  onCursorBack?: MouseEventHandler<HTMLElement>;
  onCursorNext?: MouseEventHandler<HTMLElement>;
  onSelectProduct?: (value: string, option: { value: string; label: string } | { value: string; label: string }[]) => void;
  productServerInfo?: DataQueryProductServerInfo;
  columnNameModifier?: (name: string) => string | undefined;
  subTableRowRenderer?: (column: string) => ((value: unknown) => string | number) | undefined;
};

type PagingDataTableRow = {
  [key: string]: unknown[];
};

export const PaginationType = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
  CURSOR: 'cursor',
};

const DEFAULT_PROPS = {
  products: [],
  data: [],
  productServerInfo: {},
  tableColumns: [],
  onPageSizeChanged: () => {},
  onCursorBack: () => {},
  onCursorNext: () => {},
  onPageChanged: () => {},
  onDownload: () => {
    throw new Error('Function "onDownload" not implemented...');
  },
  onSelectProduct: () => {},
  columnNameModifier: () => undefined,
  subTableRowRenderer: () => undefined,
};

export default function PagingDataTable({
  products = DEFAULT_PROPS.products,
  product = '',
  data = DEFAULT_PROPS.data,
  dataLoading = false,
  dataError,
  productServerInfo = DEFAULT_PROPS.productServerInfo,
  tableColumns = DEFAULT_PROPS.tableColumns,
  paginationType = PaginationType.DEFAULT,
  pageSize = DEFAULT_PAGE_SIZE,
  onPageSizeChanged = DEFAULT_PROPS.onPageSizeChanged,
  onCursorBack = DEFAULT_PROPS.onCursorBack,
  onCursorNext = DEFAULT_PROPS.onCursorNext,
  currentPage = 0,
  onPageChanged = DEFAULT_PROPS.onPageChanged,
  onDownload = DEFAULT_PROPS.onDownload,
  onSelectProduct = DEFAULT_PROPS.onSelectProduct,
  columnNameModifier = DEFAULT_PROPS.columnNameModifier,
  subTableRowRenderer = DEFAULT_PROPS.subTableRowRenderer,
}: PagingDataTableParams) {
  const productOptions = useMemo(() => {
    return products.map((p) => ({ value: p, label: p }));
  }, [products]);
  const urlInputRef = useRef<InputRef>(null);
  const [urlVisible, setUrlVisibility] = useState(false);

  const copyUrlToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(urlInputRef.current!.input!.value);
      void message.success('URL copied successfully on clipboard!');
    } catch (error) {
      console.debug('[PagingDataTable] Error on router URL copy from input attempt..', error);
      void message.error('Unable to copy router URL. Please copy it manually.');
      setUrlVisibility(true);
    }
  };

  const errors = compact(
    flatten<{ error: string }>([
      productServerInfo?.error ? [{ error: productServerInfo?.error }] : [],
      dataError ? [{ error: (typeof dataError === 'object' && dataError.error) || (dataError as string) }] : [],
      data.filter(({ error }) => error) as { error: string }[],
      productServerInfo?.overflowed && paginationType !== PaginationType.CURSOR
        ? [
            {
              error: `Too many rows, only the first ${productServerInfo?.lineCount} row(s) are shown. Use the 'Download Data' button to download the entire dataset.`,
            },
          ]
        : [],
    ])
  );

  // overflow isn't really an error...
  const shouldEnable = data.length > 0 && (errors.length === 0 || (errors.length === 1 && productServerInfo.overflowed));

  const rowExpandable = (record: PagingDataTableRow) =>
    Object.keys(record).some(
      (key) =>
        (!Array.isArray(record[key]) && typeof record[key] === 'object' && record[key] !== null) ||
        (Array.isArray(record[key]) && record[key].length)
    );

  const expandedRowRender = (record: PagingDataTableRow) => {
    const arrayColumns = Object.keys(record).filter((key) => typeof record[key] === 'object');
    // build columns
    const columnsPerEntry = arrayColumns.reduce<ColumnType<PagingDataTableRow>>((data, key) => {
      if (record[key] === null || (Array.isArray(record[key]) && !record[key].length)) {
        return data;
      }

      const sampleRow = Array.isArray(record[key]) ? record[key][0] : record[key];

      return {
        ...data,
        [key]: sampleRow
          ? Object.keys(sampleRow).map((column) => ({
              key: column,
              title: columnNameModifier(column),
              dataIndex: column,
              render: subTableRowRenderer(column),
            }))
          : [],
      };
    }, {});

    return (
      <div className={classes.subTablesContainer}>
        {Object.entries(columnsPerEntry).map(([entity, columns]) => (
          <div key={entity} className={classes.subTable}>
            <Typography.Title level={2} className={classes.subTableTitle}>
              {columnNameModifier(entity)}
            </Typography.Title>
            <Table
              bordered
              columns={columns}
              rowKey={() => Math.random()}
              dataSource={(Array.isArray(record[entity]) ? record[entity] : [record[entity]]) as PagingDataTableRow[]}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>
        ))}
      </div>
    );
  };

  const tableFooter = () => {
    return (
      <Pagination
        pageSize={pageSize}
        current={currentPage}
        total={productServerInfo?.lineCount}
        onChange={onPageChanged}
        onShowSizeChange={onPageSizeChanged}
      />
    );
  };

  const tableHeader = () => {
    return (
      <Space className={classes.header} direction="vertical">
        <Space className={classes.tableHeaderTools}>
          <label className="ant-typography" htmlFor="showResultsForPagingDataTable">
            Show results for:
          </label>
          <Select
            id="showResultsForPagingDataTable"
            className={classes.dataCombo}
            disabled={productOptions.length === 0 || !onSelectProduct}
            placeholder={productOptions.length > 0 ? 'Select a product' : 'No results available'}
            onChange={onSelectProduct}
            options={productOptions}
            value={product}
          />
          <Button icon={<DownloadOutlined />} disabled={!shouldEnable} onClick={() => onDownload(product)}>
            Download Grid Data
          </Button>
          {productServerInfo?.requestUrl && (
            <>
              <Button icon={<LinkOutlined />} disabled={urlVisible} onClick={copyUrlToClipboard}>
                Get Data URL
                <Tooltip title="Returns a URL with the query you have defined. This can be used to retrieve same data programmatically">
                  <InfoCircleOutlined />
                </Tooltip>
              </Button>
              <Input {...(!urlVisible ? { style: { display: 'none ' } } : {})} ref={urlInputRef} value={productServerInfo.requestUrl} />
            </>
          )}
        </Space>
        <MidasError errors={errors} />
      </Space>
    );
  };

  const cursorPagination = () => {
    return (
      <CursorPagination
        isFirstPage={currentPage === 0}
        isLastPage={data.length < pageSize}
        onBack={onCursorBack}
        onNext={onCursorNext}
        onShowSizeChange={(current, newSize) => {
          onPageSizeChanged(current, newSize);
        }}
      />
    );
  };

  const paginationByType = useCallback(
    (
      type: string
    ): {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      footer?: TableProps<any>['footer'];
      pagination: TablePaginationConfig;
    } => {
      switch (type) {
        case PaginationType.CURSOR:
          return {
            footer: cursorPagination,
            pagination: {
              pageSize,
              position: ['none', 'none'],
            },
          };
        case PaginationType.CUSTOM:
          return {
            footer: tableFooter,
            pagination: {
              pageSize,
              position: ['none', 'none'],
            },
          };
        default:
          return {
            pagination: {
              position: ['bottomLeft'],
              pageSize,
              onChange: (page: number, newPageSize: number) => {
                onPageChanged(page);
                onPageSizeChanged(pageSize, newPageSize);
              },
            },
          };
      }
    },
    [paginationType, data, pageSize]
  );

  useEffect(() => {
    // if copy to clipboard fails, we show input and mark the content
    if (urlVisible && urlInputRef.current) {
      urlInputRef.current.focus({
        cursor: 'all',
      });
    }
  }, [urlVisible, urlInputRef]);

  return (
    <Table
      className={classes.pagingDataTable}
      dataSource={data}
      data-testid="data-query-table"
      loading={dataLoading}
      // eslint-disable-next-line no-unused-vars
      onChange={(pagination, _filters, _sorter, _extra) => {
        // TODO maybe i can replace these by just using onChange in parent
        onPageChanged(pagination.current!);
        onPageSizeChanged(pageSize, pagination.pageSize!);
      }}
      expandable={{ expandedRowRender, rowExpandable, showExpandColumn: !!data.length }}
      rowKey={(row) => row.key}
      title={tableHeader}
      size="small"
      {...paginationByType(paginationType)}
    >
      {tableColumns}
    </Table>
  );
}
