import React, { useMemo, useCallback, useState, useEffect } from 'react';

import startCase from 'lodash/startCase';

import GenericOutputContainer from './controls/GenericOutputContainer';
import CursorPaginationDataTable from './controls/CursorPaginationDataTable';

import useProductDataRequester from './hooks/use-product-data-requester';
import useDataRequester from './hooks/use-data-requester';
import useCursorPaginationState from './hooks/use-cursor-pagination-state';

import { buildUrl } from './_shared/misc-utils';
import { DEFAULT_PAGE_SIZE } from './_shared/table-utils';
import { MAX_GRAPH_RESULTS } from './_shared/GraphUtils';

import { coerceDateTimesToDatesTimes, isDayjs } from '../../../../utils/config-utils';
import { convertToBookJoinQuery } from '../../../../_shared/normalizers';
import { DataQueryConfig, DataQueryProductRow } from '../../../types';
import { DATE_FORMAT, ROOT_SERVER_URL, WS_SERVER_URL } from '../../../../utils/midas-constants';

const BOOKQUERY_QUERY_URL = `${ROOT_SERVER_URL}api/querying/bookquery`;
const BOOKQUERY_QUERY_WS_URL = `${WS_SERVER_URL}api/querying/bookquery`;
const staticKeys = ['datetime', 'product'];

export const getUrlForQuery = (query: DataQueryConfig, selectedProductForDownload?: string, downloadType = 'text/csv', useWS = false) => {
  const { startDate, endDate } = coerceDateTimesToDatesTimes(query.dateAndTimeRange);

  let actualStartDate = startDate?.toISOString();
  const actualEndDate = endDate?.toISOString();

  if (query.cursorKey && !selectedProductForDownload) {
    actualStartDate = query.cursorKey.date;
  }

  const sharedParams = {
    download: (selectedProductForDownload !== undefined).toString(),
    source: query.source,
    feed: query.feed,
    startDate: actualStartDate,
    endDate: actualEndDate,
    event: query.event,
    interval: `${query.intervalAndUnit?.value}${query.intervalAndUnit?.timespan || ''}`,
    query: query.queries && convertToBookJoinQuery(query.queries, query.queryType),
    limit: query.pageSize || DEFAULT_PAGE_SIZE,
  };

  if (selectedProductForDownload) {
    return buildUrl(BOOKQUERY_QUERY_URL, {
      ...sharedParams,
      product: selectedProductForDownload,
      downloadType,
    });
  }
  const bookqueryUrl = useWS ? BOOKQUERY_QUERY_WS_URL : BOOKQUERY_QUERY_URL;
  return query.products?.map((product) =>
    buildUrl(bookqueryUrl, {
      ...sharedParams,
      product,
    })
  );
};

const getUrlForGraphQuery = (query: DataQueryConfig) => {
  const { startDate, endDate } = coerceDateTimesToDatesTimes(query.dateAndTimeRange);

  const actualStartDate = startDate?.toISOString();
  const actualEndDate = endDate?.toISOString();

  const queryParams = {
    source: query.source,
    feed: query.feed,
    startDate: actualStartDate,
    endDate: actualEndDate,
    event: query.event,
    interval: `${query.intervalAndUnit?.value}${query.intervalAndUnit?.timespan || ''}`,
    query: query.queries && convertToBookJoinQuery(query.queries, query.queryType),
    limit: MAX_GRAPH_RESULTS,
  };

  return query.products?.map((product) =>
    buildUrl(BOOKQUERY_QUERY_URL, {
      ...queryParams,
      product,
    })
  );
};

const DEFAULT_PROPS = {
  onConfigChanged: () => {},
};

export default function BookQueryOutput({
  config,
  onConfigChanged = DEFAULT_PROPS.onConfigChanged,
}: {
  config?: DataQueryConfig;
  onConfigChanged?: (config: DataQueryConfig) => void;
}) {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { requestCursor, cursorPages, resetCursors, setCursorBack, setCursorForward, updateCursor } = useCursorPaginationState();

  const { downloadedData, setProduct, product, handleDownloadData, processing } = useProductDataRequester({
    config,
    getUrlForQuery,
  });

  const { processing: graphProcessing, downloadedData: graphData } = useDataRequester({
    config,
    getUrlForQuery: getUrlForGraphQuery,
  });

  useEffect(() => {
    onConfigChanged({
      ...config,
      pageSize,
      cursorKey: requestCursor,
      currentPage: cursorPages,
    });
  }, [requestCursor]);

  useEffect(() => {
    resetCursors();

    onConfigChanged({
      ...config,
      pageSize,
      cursorKey: null,
      currentPage: 0,
    });
  }, [pageSize, product, config?.dateAndTimeRange]);

  useEffect(() => {
    const productData = downloadedData.find((item) => item.product === product);
    if (productData?.output) {
      updateCursor(productData.output, 'datetime', 'YYYY-MM-DD hh:mm:ss');
    }
  }, [downloadedData, product]);

  const graphDataPoints = useMemo(() => {
    if (!config?.queries) {
      return {};
    }

    return convertToBookJoinQuery(config.queries).reduce<{ [key: string]: string }>(
      (allPoints, point: string) => ({
        ...allPoints,
        [point]: point, // TODO: discuss value
      }),
      {}
    );
  }, [config]);

  const columnNameModifier = useCallback((name: string) => {
    if (staticKeys.includes(name)) {
      return startCase(name);
    }

    try {
      const [groupAndFeed, valueAndOffset] = name.split('.');
      const [group, feed] = groupAndFeed.split('[').map((str: string) => str.replace(/[^a-zA-Z]/g, ''));
      // eslint-disable-next-line no-useless-escape
      const [valueAndLevel, offset] = valueAndOffset.split('{').map((str: string) => str.replace(/[^a-zA-Z0-9:\-\+.]/g, ''));
      const maybeLevel = Number(valueAndLevel.charAt(valueAndLevel.length - 1));
      let value = valueAndLevel;

      const hasLevel = !Number.isNaN(maybeLevel);

      if (hasLevel) {
        value = valueAndLevel.slice(0, -1);
      }

      return `Group: ${startCase(group)}, Feed: ${feed}, Value: ${value}${hasLevel ? `, Level: ${maybeLevel}` : ''}${
        offset ? `, Offset: ${offset}` : ''
      }`;
    } catch (error) {
      console.error('[BookQueryOutput] Unable to generate column name for: ', name);
      return name;
    }
  }, []);

  const handlePreviousPage = () => {
    setCursorBack();
  };

  const handleNextPage = () => {
    setCursorForward();
  };

  const handlePageSizeChanged = (_previous: number, next: number) => setPageSize(next);

  return (
    <GenericOutputContainer
      config={config}
      gridControl={CursorPaginationDataTable}
      tableColumns={[]}
      columnNameModifier={columnNameModifier}
      data={downloadedData || []}
      dataLoading={processing.busy}
      dataError={processing.error}
      products={config?.products || []}
      product={product}
      onDownload={handleDownloadData}
      onSelectProduct={setProduct}
      onCursorBack={handlePreviousPage}
      onCursorNext={handleNextPage}
      onPageSizeChanged={handlePageSizeChanged}
      dataPoints={graphDataPoints}
      graphData={graphData}
      graphDataLoading={graphProcessing.busy}
      xAxisLabelColumn={isDayjs(config?.dateAndTimeRange?.date) ? config?.dateAndTimeRange?.date.format(DATE_FORMAT) : 'dateTicks'}
      xAxisColumn="dateTicks"
      graphControl={undefined}
      onChangedFilters={undefined}
      xAxisColumnType={undefined}
      xAxisColumnDisplayType="dateticks"
    />
  );
}
