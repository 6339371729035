import React from 'react';
import isFunction from 'lodash/isFunction';

export function renderEntity(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DefaultElement: (...params: any) => JSX.Element,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: React.JSXElementConstructor<any> | undefined,
  props: object
) {
  if (React.isValidElement(content)) {
    return React.cloneElement(content, props);
  }
  if (isFunction(content)) {
    return React.createElement(content, props);
  }

  return <DefaultElement {...props} />;
}
