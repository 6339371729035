import React, { MouseEventHandler, useMemo, useState } from 'react';

import { Table, Select } from 'antd';

import withFilter from '../_shared/WithFilter';
import withSorter from '../_shared/WithSorter';
import { DEFAULT_PAGE_SIZE } from '../_shared/table-utils';

import PagingDataTable, { PaginationType } from './paging/PagingDataTable';
import classes from './TicksSingleItemOutput.module.css';
import {
  DataQueryConfig,
  DataQueryFilter,
  DataQueryProductRow,
  DataQueryProductRowOutput,
  DataQueryProductServerInfo,
} from '../../../../types';

type TicksSingleItemOutputParams = {
  config?: DataQueryConfig;
  data?: DataQueryProductRow[];
  dataIndex?: string;
  dataLoading?: boolean;
  dataError?: string | object;
  products?: string[];
  product?: string;
  onSelectProduct?: (value: string, option: { value: string; label: string } | { value: string; label: string }[]) => void;
  productServerInfo?: DataQueryProductServerInfo;
  onDownload?: (selectedItem: string) => void;
  defaultPageSize?: number;
  onPageSizeChanged?: (previous: number, next: number) => void;
  currentPage?: number;
  onCursorBack?: MouseEventHandler<HTMLElement>;
  onCursorNext?: MouseEventHandler<HTMLElement>;
  onChangedFilters?: (filters: DataQueryFilter) => void;
};

/* eslint-disable react/jsx-key */
const sharedColumns = [
  <Table.Column title="Date/Time" dataIndex="datetime" />,
  <Table.Column title="Message Type" dataIndex="mtype" />,
  <Table.Column title="Micros" dataIndex="micros" />,
  <Table.Column title="Sequence Number" dataIndex="seq" />,
  <Table.Column title="Delta" dataIndex="delta" />,
  <Table.Column title="Source" dataIndex="source" />,
  <Table.Column title="Symbol" dataIndex="symbol" />,
];

// we alias product to instrument.
const tableColumns = [
  ...sharedColumns,
  <Table.Column title="Field 1" dataIndex="f1" />,
  <Table.Column title="Field 2" dataIndex="f2" />,
  <Table.Column title="Field 3" dataIndex="f3" />,
  <Table.Column title="Field 4" dataIndex="f4" />,
  <Table.Column title="Field 5" dataIndex="f5" />,
  <Table.Column title="Field 6" dataIndex="f6" />,
  <Table.Column title="Field 7" dataIndex="f7" />,
  <Table.Column title="Field 8" dataIndex="f8" />,
  <Table.Column title="Field 9" dataIndex="f9" />,
  <Table.Column title="Field 10" dataIndex="f10" />,
  <Table.Column title="Field 11" dataIndex="f11" />,
  <Table.Column title="Field 12" dataIndex="f12" />,
];

const tableColumnsPerMessage = {
  ADD: [
    ...sharedColumns,
    <Table.Column title="Order ID" dataIndex="oid" />,
    <Table.Column title="Shares" dataIndex="shares" />,
    <Table.Column title="Price" dataIndex="price" />,
    <Table.Column title="Side" dataIndex="side" />,
    <Table.Column title="Flags" dataIndex="flags" />,
    <Table.Column title="MP ID" dataIndex="mpid" />,
    <Table.Column title="Extra" dataIndex="extra" />,
  ],
  MOD: [
    ...sharedColumns,
    <Table.Column title="Order ID" dataIndex="oid" />,
    <Table.Column title="Shares" dataIndex="shares" />,
    <Table.Column title="Price" dataIndex="price" />,
    <Table.Column title="Side" dataIndex="side" />,
    <Table.Column title="Extra" dataIndex="extra" />,
  ],
  TRD: [
    ...sharedColumns,
    <Table.Column title="Order ID" dataIndex="oid" />,
    <Table.Column title="Shares" dataIndex="shares" />,
    <Table.Column title="Price" dataIndex="price" />,
    <Table.Column title="Side" dataIndex="side" />,
    <Table.Column title="Flags" dataIndex="flags" />,
    <Table.Column title="MP ID" dataIndex="mpid_exid" />,
    <Table.Column title="Mid SC" dataIndex="mid_sc" />,
    <Table.Column title="Extra" dataIndex="extra" />,
  ],
  QUO: [
    ...sharedColumns,
    <Table.Column title="Buy Order ID" dataIndex="boid" />,
    <Table.Column title="Buy Qty" dataIndex="bsz" />,
    <Table.Column title="Buy Price" dataIndex="bprice" />,
    <Table.Column title="Buy Flags" dataIndex="bflags" />,
    <Table.Column title="BMP ID" dataIndex="bmpid" />,
    <Table.Column title="Sell Order ID" dataIndex="aoid" />,
    <Table.Column title="Sell Qty" dataIndex="asz" />,
    <Table.Column title="Sell Price" dataIndex="aprice" />,
    <Table.Column title="Sell Flags" dataIndex="aflags" />,
    <Table.Column title="AMP ID" dataIndex="ampid" />,
    <Table.Column title="QC" dataIndex="qc" />,
    <Table.Column title="Extra" dataIndex="extra" />,
  ],
  IMB: [
    ...sharedColumns,
    <Table.Column title="Empty" dataIndex="empty" />,
    <Table.Column title="Extra" dataIndex="extra" />,
    <Table.Column title="Far Price" dataIndex="far" />,
    <Table.Column title="Near Price" dataIndex="near" />,
    <Table.Column title="Ref Price" dataIndex="ref" />,
    <Table.Column title="Flags" dataIndex="flags" />,
    <Table.Column title="Imb Dir" dataIndex="idire" />,
    <Table.Column title="Imb Shares" dataIndex="ishares" />,
    <Table.Column title="Paired Shares" dataIndex="pshares" />,
    <Table.Column title="Price Var Ind" dataIndex="pvi" />,
  ],
  STA: [
    ...sharedColumns,
    <Table.Column title="Event" dataIndex="event" />,
    <Table.Column title="Value" dataIndex="value" />,
    <Table.Column title="Empty" dataIndex="empty" />,
    <Table.Column title="LULD Ind Code" dataIndex="luld_ic" />,
    <Table.Column title="LL Price Band" dataIndex="luld_lo" />,
    <Table.Column title="UL Price Band" dataIndex="luld_hi" />,
    <Table.Column title="Extra" dataIndex="extra" />,
  ],
  LVL: [
    ...sharedColumns,
    <Table.Column title="Order ID" dataIndex="oid" />,
    <Table.Column title="Shares" dataIndex="shares" />,
    <Table.Column title="Price" dataIndex="price" />,
    <Table.Column title="Side" dataIndex="side" />,
    <Table.Column title="Flags" dataIndex="flags" />,
    <Table.Column title="MP ID" dataIndex="mpid" />,
    <Table.Column title="Diff" dataIndex="diff" />,
    <Table.Column title="Num Orders" dataIndex="nord" />,
    <Table.Column title="Link 1" dataIndex="link1" />,
    <Table.Column title="Link 2" dataIndex="link2" />,
    <Table.Column title="Link 3" dataIndex="link3" />,
    <Table.Column title="Extra" dataIndex="extra" />,
  ],
};
/* eslint-enable react/jsx-key */

const fieldMap = {
  add: {
    f1: 'OrderID',
    f2: 'Size',
    f3: 'Price',
    f4: 'Side',
    f5: 'Flags',
    f6: 'MP ID',
    f7: 'Extra',
  },
  mod: {
    f1: 'OrderID',
    f2: 'Size',
    f3: 'Price',
    f4: 'Side',
    f5: 'Extra',
  },
  trd: {
    f1: 'OrderID',
    f2: 'Size',
    f3: 'Price',
    f4: 'Side',
    f5: 'Flags',
    f6: 'MP ID',
    f7: 'Mid SC',
    f8: 'Extra',
  },
  lvl: {
    f1: 'OrderID',
    f2: 'Size',
    f3: 'Price',
    f4: 'Side',
    f5: 'Flags',
    f6: 'MP ID',
    f7: 'Diff',
    f8: 'Num Orders',
    f9: 'Link 1',
    f10: 'Link 2',
    f11: 'Link 3',
    f12: 'Extra',
  },
  quo: {
    f1: 'Buy Order ID',
    f2: 'Buy Qty',
    f3: 'Buy Price',
    f4: 'Buy Flags',
    f5: 'BMP ID',
    f6: 'Sell Order ID',
    f7: 'Sell Qty',
    f8: 'Sell Price',
    f9: 'Sell Flags',
    f10: 'AMP ID',
    f11: 'QC',
    f12: 'Extra',
  },
  imb: {
    f1: 'Paired Shares',
    f2: 'Imb Shares',
    f3: 'Imb Dir',
    f4: 'Far Price',
    f5: 'Near Price',
    f6: 'Ref Price',
    f7: 'Price Var Ind',
    f8: 'Flags',
  },
  sta: {
    f1: 'Event',
    f2: 'Value',
    f3: '-',
    f4: 'LULD Ind Code',
    f5: 'LL Price Band',
    f6: 'UL Price Band',
    f7: 'TS1 Delta',
    f8: 'TS2 Delta',
  },
};

const messagesPerField = Object.entries(fieldMap).reduce<{ [key: string]: { label: string; value: string }[] }>(
  (data, [message, fields]) => {
    const fieldKeys = Object.keys(fields);

    for (const key of fieldKeys as (keyof typeof fields)[]) {
      if (!data[key]) {
        // eslint-disable-next-line no-param-reassign
        data[key] = [];
      }

      const sameLabelIndex = data[key].findIndex(({ label }) => label === fields[key]);
      if (sameLabelIndex !== -1) {
        // eslint-disable-next-line no-param-reassign
        data[key][sameLabelIndex].value += `|${message}`;
        continue;
      }

      data[key].push({ value: message, label: fields[key] });
    }

    return data;
  },
  {}
);

const getColumnLabelByKey = (key: string, message: string) => {
  return messagesPerField[key]?.find((item) => item.value === message)?.label;
};

const buildFilterTagLabel = (key: string, filter: DataQueryFilter) => {
  const columnLabel = getColumnLabelByKey(key, filter.pre) ?? filter.pre;
  const preLabel = filter.pre ? `(${columnLabel})` : '';
  const filterValue = filter.value ? filter.value : '[all]';
  return {
    label: `${key} ${preLabel}: ${filterValue}`,
    value: key,
  };
};

export default function TicksSingleItemOutput({
  config = {
    products: [],
    feed: undefined,
    messages: undefined,
    filters: {},
  },
  data = undefined,
  dataLoading = false,
  dataError = undefined,
  products = [],
  product = undefined,
  onSelectProduct = () => {},
  productServerInfo = undefined,
  onDownload = () => {},
  defaultPageSize = DEFAULT_PAGE_SIZE,
  onPageSizeChanged = () => {},
  currentPage = 0,
  onCursorBack = () => {},
  onCursorNext = () => {},
  onChangedFilters = () => {},
}: TicksSingleItemOutputParams) {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [filters, setFilters] = useState(config.filters ?? {});
  const selectedMessage = useMemo(
    () => (config?.messages?.length === 1 ? (config?.messages[0] as keyof typeof tableColumnsPerMessage) : null),
    [JSON.stringify(config)]
  ); // single message selected

  const mappedData = useMemo(() => {
    if (!data || !data.length) {
      return [];
    }

    const productData = data.find((details) => details.product === product);

    if (!productData) {
      return [];
    }

    return productData.output.map((row) =>
      Object.keys(row).reduce<{ [key: string]: unknown }>((acc, cur) => {
        const field = fieldMap[row.mtype as keyof typeof fieldMap];
        const messageType = field?.[cur as keyof typeof field];

        if (messageType) {
          acc[cur] = `${messageType}: ${row[cur as keyof DataQueryProductRowOutput] as string}`;
        } else {
          acc[cur] = row[cur as keyof DataQueryProductRowOutput];
        }
        return acc;
      }, {})
    );
  }, [product, data]);

  const handleFiltering = (filter: DataQueryFilter, property: number) => {
    const updatedFilters = { ...filters, [property]: filter };
    setFilters(updatedFilters);

    onChangedFilters(updatedFilters);
  };

  const handleClearFilter = (property: string) => {
    // eslint-disable-next-line no-unused-vars
    const { [property]: _, ...updatedFilters } = filters;
    setFilters(updatedFilters);

    onChangedFilters(updatedFilters);
  };

  const columns = (selectedMessage && tableColumnsPerMessage[selectedMessage] ? tableColumnsPerMessage[selectedMessage] : tableColumns).map(
    ({ props, type: Column }) => {
      const { props: enhancedProps, type: ColumnWithSort } = withSorter(Column)({ ...props });

      return withFilter(
        ColumnWithSort,
        filters,
        handleFiltering,
        handleClearFilter
      )({
        ...enhancedProps,
        key: props.dataIndex,
        hasCustomFilter: true,
        // extraFilters: used for the filter dropdown
        extraFilters: messagesPerField[props.dataIndex]
          ? [
              {
                // backend approach was expecting a column name
                // id: 'mtype',
                id: 'pre',
                label: 'Message',
                type: 'select',
                required: true,
                data: messagesPerField[props.dataIndex],
              },
            ]
          : [],
      });
    }
  );

  const handlePageSizeChanged = (previous: number, next: number) => {
    setPageSize(next);
    onPageSizeChanged(previous, next);
  };

  const filterKeys = Object.keys(filters);

  return (
    <>
      <div className={classes.activeFiltersSelection}>
        <div className={classes.labelContainer}>
          <label className="ant-typography" htmlFor="activeFilters">
            Active filters:
          </label>
        </div>
        <Select
          id="activeFilters"
          aria-label="Active filters"
          mode="tags"
          value={filterKeys}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options={filterKeys.map((key) => buildFilterTagLabel(key, filters[key as keyof typeof filters] as any))}
          loading={dataLoading}
          disabled={dataLoading || !filterKeys.length}
          placeholder="None"
          onDeselect={handleClearFilter}
          className={classes.filterTags}
          popupClassName={classes.filterTagsDropdown}
        />
      </div>
      <PagingDataTable
        data={mappedData}
        tableColumns={columns}
        product={product}
        products={products}
        dataLoading={dataLoading}
        dataError={dataError}
        onSelectProduct={onSelectProduct}
        productServerInfo={productServerInfo}
        onDownload={onDownload}
        paginationType={PaginationType.CURSOR}
        currentPage={currentPage}
        onCursorBack={onCursorBack}
        onCursorNext={onCursorNext}
        pageSize={pageSize}
        onPageSizeChanged={handlePageSizeChanged}
      />
    </>
  );
}
