import { handleError } from '../../services/error-notification';
import { initialGlobalSearchResults, GlobalSearchModel, searchGlobal } from './models/global-search';
import { GlobalSearchViewDelegate } from './views/GlobalSearchView';
import { SearchApi } from './search-api';

export class GlobalSearchController implements GlobalSearchViewDelegate {
  private model = initialGlobalSearchResults();

  private searchApi = new SearchApi();

  private getModel = () => this.model;

  private update = (model: GlobalSearchModel) => {
    this.model = model;
    this.updateViewState(model);
  };

  constructor(private updateViewState: (_: GlobalSearchModel) => void) {}

  async onGlobalSearch(): Promise<void> {
    handleError(await searchGlobal(this.searchApi, this.getModel, this.update));
  }

  onSearchTermChange(term: string) {
    this.update({ ...this.model, searchTerm: term });
  }

  clearSearchResults() {
    this.update({ ...this.model, ...initialGlobalSearchResults() });
  }
}
