import isEqual from 'lodash/isEqual';

export interface PeriodicRequestDelegate<T> {
  interval: number;
  onPeriodicRequest(): Promise<T>;
  onPeriodicRequestResult(value: T): void;
}

export class PeriodicRequest<T> {
  private requestCounter = 0;

  private lastAppliedRequest = 0;

  private lastResponse: T | undefined;

  constructor(private delegate: PeriodicRequestDelegate<T>) {}

  stop: () => void = () => {};

  start(immediately = true) {
    // counter to force periodic requests to be in-order (out-of-order calls are discarded)

    const token = setInterval(() => void this.refresh(), this.delegate.interval);
    if (immediately) {
      void this.refresh();
    }

    this.stop = () => clearInterval(token);

    return this.stop;
  }

  refresh = async (): Promise<void> => {
    const requestNumber = ++this.requestCounter;

    const response = await this.delegate.onPeriodicRequest();

    if (this.lastAppliedRequest > requestNumber) {
      // more recent data has already been applied, discard this call
      return;
    }

    this.lastAppliedRequest = requestNumber;

    if (!isEqual(this.lastResponse, response)) {
      this.lastResponse = response;
      this.delegate.onPeriodicRequestResult(response);
    }
  };
}
