import { get, post } from '../utils/io';
import isEqual from 'lodash/isEqual';

import { useCallback, useEffect, useState } from 'react';
import { ACTIVITY_TAB_ID, USER_DATA_STORE_URL } from '../utils/midas-constants';
import { DataQueryFormItem } from '../pages/types';
import { getAccessTokenFromCookie } from '../../services/auth';
import { DEFAULT_OPEN_CONTROLS } from '../pages/dpActivity/DpActivityPage';

export default function useMappingStore(
  key: string,
  initialValue: DataQueryFormItem,
  mapper = (a: DataQueryFormItem) => a
): [DataQueryFormItem, (valueOrModifier: DataQueryFormItem | ((value: DataQueryFormItem) => DataQueryFormItem)) => Promise<void>] {
  const [storedValue, setStoredValue] = useState(initialValue);

  useEffect(() => {
    void (async () => {
      setStoredValue((currentStoredValue: DataQueryFormItem) => ({ ...currentStoredValue, loading: true }));
      try {
        const token = getAccessTokenFromCookie();
        if (!token) {
          return;
        }

        const response = await get(`${USER_DATA_STORE_URL}?key=${key}`, token, { 'Cache-Control': '' });
        const responseText = await response.text();

        const { message, value } = responseText ? JSON.parse(responseText) : { message: undefined, value: {} };

        if (message) {
          setStoredValue((currentStoredValue: DataQueryFormItem) => ({ ...currentStoredValue, loading: false }));
          console.warn('[useMappingStore] server did not return OK state: ', key, message);
          return;
        }

        setStoredValue((currentStoredValue: DataQueryFormItem) => {
          const mappedValue = mapper({
            ...currentStoredValue,
            ...value,
            loading: false,
          });

          // If it doesn't exist, set static activity page
          if (mappedValue.tabs && !mappedValue.tabs.some((tab) => tab.uuid === ACTIVITY_TAB_ID)) {
            const rawItem = {
              uuid: ACTIVITY_TAB_ID,
              name: `Activity page`,
              meta: { openAccordionControls: { ...DEFAULT_OPEN_CONTROLS } },
            };
            mappedValue.tabs.push(rawItem);
          }

          return mappedValue;
        });
      } catch (error) {
        setStoredValue((currentStoredValue: DataQueryFormItem) => ({ ...currentStoredValue, loading: false }));
        console.error('[useMappingStore] failed to load data: ', key, error);
      }
    })();
  }, [setStoredValue]);

  const setValue = useCallback(
    async (valueOrModifier: DataQueryFormItem | ((value: DataQueryFormItem) => DataQueryFormItem)) => {
      try {
        const token = getAccessTokenFromCookie();

        const value = valueOrModifier instanceof Function ? valueOrModifier(storedValue) : valueOrModifier;

        if (isEqual(value, storedValue)) {
          console.debug('[useMappingStore] Attempted to store unchanged data.');
          return;
        }

        setStoredValue(value);

        await post(
          USER_DATA_STORE_URL,
          {
            key,
            value,
          },
          token
        );
      } catch (error) {
        console.error(`[useMappingStore] failed to store: ${key}!`, error);
      }
    },
    [key, storedValue, setStoredValue]
  );

  return [storedValue, setValue];
}
