import React, { useEffect, useState } from 'react';

import { Button, Space, Select } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { DEFAULT_PAGE_SIZE } from '../../_shared/table-utils';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type CursorPaginationParams = {
  isFirstPage?: boolean;
  isLastPage?: boolean;
  onBack?: React.MouseEventHandler<HTMLElement>;
  onNext?: React.MouseEventHandler<HTMLElement>;
  onShowSizeChange?: (current: number, newSize: number) => void;
  size?: SizeType;
  defaultPageSize?: number;
  pageSizeOptions?: number[];
};

const { Option } = Select;

const DEFAULT_PROPS = {
  onBack: () => {},
  onNext: () => {},
  onShowSizeChange: () => {},
  pageSizeOptions: [10, 20, 50, 100],
};

export default function CursorPagination({
  isFirstPage = false,
  isLastPage = false,
  onBack = DEFAULT_PROPS.onBack,
  onNext = DEFAULT_PROPS.onNext,
  onShowSizeChange = DEFAULT_PROPS.onShowSizeChange,
  size = 'middle',
  defaultPageSize = DEFAULT_PAGE_SIZE,
  pageSizeOptions = DEFAULT_PROPS.pageSizeOptions,
}: CursorPaginationParams) {
  /* Button style changes based on size */
  const [buttonStyle, setButtonStyle] = useState<React.CSSProperties>({});

  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handleChangePageSize = (newSize: number) => {
    onShowSizeChange(pageSize, newSize);
    setPageSize(newSize);
  };

  useEffect(() => {
    switch (size) {
      case 'small':
        setButtonStyle({ paddingLeft: '4px', paddingRight: '4px', borderColor: 'transparent' });
        break;
      case 'middle':
      default:
        setButtonStyle({ paddingLeft: '7px', paddingRight: '7px' });
        break;
    }
  }, [size]);

  return (
    <Space direction="horizontal">
      <Button tabIndex={-1} size={size} disabled={isFirstPage} style={buttonStyle} onClick={onBack}>
        <LeftOutlined />
      </Button>
      <Button tabIndex={-1} size={size} disabled={isLastPage} style={buttonStyle} onClick={onNext}>
        <RightOutlined />
      </Button>

      <Select defaultValue={pageSize} style={{ width: 100 }} onChange={handleChangePageSize}>
        {pageSizeOptions?.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </Space>
  );
}
