import { DeleteOutlined } from '@ant-design/icons';
import { Space, Select, Checkbox, Button } from 'antd';
import React from 'react';
import { User } from '../../../pages/users/users-api';
import styles from './UserPermissions.module.css';

type UserPermissionsProps = {
  index: number;
  allUsers: User[];
  selectedEmail: string;
  hasWritePermissions: boolean;
  disabled: boolean;
  userChangeHandler: (index: number, userSubject: string) => void;
  writeChangeHandler: (index: number, checked: boolean) => void;
  deleteHandler: (index: number) => void;
};

export default function UserPermissions(props: UserPermissionsProps) {
  return (
    <div className={styles.container}>
      <Select
        options={props.allUsers.map((u) => ({ label: u.email, value: u.value }))}
        value={props.selectedEmail === '' ? undefined : props.selectedEmail}
        onChange={(value) => props.userChangeHandler(props.index, value)}
        placeholder="Select a user"
        disabled={props.disabled}
        className={styles.selectUser}
      ></Select>
      <Space>
        <Checkbox
          onChange={({ target: { checked } }) => props.writeChangeHandler(props.index, checked)}
          checked={props.hasWritePermissions}
          disabled={props.disabled}
        >
          Write
        </Checkbox>
        <Button disabled={props.disabled} icon={<DeleteOutlined />} onClick={() => props.deleteHandler(props.index)} />
      </Space>
    </div>
  );
}
