import { NotificationItem } from './notification';
import { NotificationsApi } from '../notifications-api';
import { ErrorNotification } from '../../../../services/error-notification';

export interface NotificationsListModel {
  loading: boolean;
  notifications: NotificationItem[];
}

export function initialNotificationsListModel(): NotificationsListModel {
  return {
    loading: true,
    notifications: [],
  };
}

export function updateNotificationList(model: NotificationsListModel, newNotifications: NotificationItem[]): NotificationsListModel {
  // the update comes in the form of a delta.
  const notifications = [...model.notifications, ...newNotifications].map((notification) => ({
    ...notification,
    updatedAt: new Date(notification.updatedAt),
  }));
  const sortedNotifications = notifications.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
  return { ...model, loading: false, notifications: sortedNotifications };
}

export async function createSetNotificationRead(
  model: NotificationsListModel,
  api: NotificationsApi,
  handleError: (error: ErrorNotification | undefined) => void,
  notificationId?: number
): Promise<NotificationsListModel> {
  const response = await api.setNotificationRead(notificationId);
  if (!response) {
    handleError({
      message: 'Failed to set notification status',
      deduplicationKey: 'notification-update-fail',
    });
    return model;
  }

  return notificationId
    ? {
        ...model,
        notifications: model.notifications.filter(({ id }) => id !== notificationId),
      }
    : {
        ...model,
        notifications: [],
      };
}
