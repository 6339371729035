/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

import { DataQueryConfig, DataQueryFormItem, PreciseTime, WorkflowLoadingState } from '../pages/types';
import { AMERICA_NY_TIMEZONE } from './midas-constants';

const isProbablyADate = (date: string) => /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\dZ$/.test(date);

export const getDayjsOrNone = (date: string) => {
  if (!date) {
    return undefined;
  }
  return dayjs(date);
};

// this must be idempotent, as effects can be called twice by react
// rehydrate dates to dayjs
// I really, really don't like this at all.
export const fixDates = (config: DataQueryFormItem) => {
  const fiddleWithDates = (configItem: DataQueryFormItem) => {
    (Object.keys(configItem.config!) as unknown as (keyof DataQueryConfig)[]).forEach((key) => {
      const value: any = configItem.config![key];

      if (Array.isArray(value) && value.length > 0 && isProbablyADate(value[0] as string)) {
        configItem.config![key] = value.map((v) => dayjs(v)) as any;
      }
      if (typeof value === 'string' && isProbablyADate(value)) {
        configItem.config![key] = dayjs(value) as any;
      }

      // dayjs dates do have a `date` property, so make sure we don't mistake them for objects with schema { date: string; startTime: string; endTime: string; }
      if (value && !isDayjs(value) && value.date && !isDayjs(value.date)) {
        configItem.config![key] = {
          date: dayjs(value.date),
          startTime: value.startTime,
          endTime: value.endTime,
        } as any;
      }
    });
  };

  config.searchConfigs?.forEach(fiddleWithDates);
  config.previousSearches?.forEach(fiddleWithDates);

  return config;
};

export const anyEmpty = (parameters: URLSearchParams, toIgnore: string[] = []) => {
  for (const [key, value] of parameters.entries()) {
    if (toIgnore.includes(key)) {
      continue;
    }

    if (!value || value === 'undefined') {
      // this is OK, now.
      // console.warn('[anyEmpty] empty key! ', key);
      return true;
    }
  }

  return false;
};

export const coerceDateTimesToDatesTimes = (value?: { date: Dayjs; startTime?: PreciseTime; endTime?: PreciseTime }) => {
  const { date, startTime, endTime } = value ?? {};

  if (!date || !startTime || !endTime) {
    return {};
  }

  const coerceDateTime = (time: PreciseTime) =>
    dayjs.tz(
      date.format('YYYYMMDD') + ' ' + time.hours + ':' + time.minutes + ':' + time.seconds + '.' + (time.milliseconds || 0),
      AMERICA_NY_TIMEZONE
    );

  return { startDate: coerceDateTime(startTime), endDate: coerceDateTime(endTime) };
};

export const isGuid = (obj: string) => {
  if (obj.substring(0, 23) === '00000000-0000-0000-0000') {
    return true;
  }
  // eslint-disable-next-line prefer-regex-literals
  const guidRegEx = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
  return guidRegEx.test(obj);
};

export const anyErrors = (values: WorkflowLoadingState[]) => values.some(({ error }) => error !== undefined);

export const anyLoading = (values: WorkflowLoadingState[]) => values.some(({ loading }) => loading === true);

// this is workaround for dayjs issue https://github.com/iamkun/dayjs/issues/2518
export const isDayjs: (d: unknown) => boolean = (date: unknown) => date && (dayjs.isDayjs(date) || (date as any).$isDayjsObject);
