import React from 'react';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import { InstanceUpdateNotification } from '../workbench-api';
import { formatDateTime } from '../../../services/formatting';
import { showNotification } from '../../../_shared/views/Notifications';

export interface InstanceUpdateNotificationsDelegate {
  onInstanceUpdateNotificationUpdate(notification: InstanceUpdateNotification): void;
  onInstanceUpdateNotificationCancel(notification: InstanceUpdateNotification): void;
  onInstanceUpdateNotificationIgnore(notification: InstanceUpdateNotification): void;
}

function notificationKey(model: InstanceUpdateNotification): string {
  return `update-${model.shortId}`;
}

export function showInstanceUpdateNotifications({
  model,
  delegate,
}: {
  model: InstanceUpdateNotification[];
  delegate: InstanceUpdateNotificationsDelegate;
}) {
  if (!model || model.length === 0) {
    return null;
  }

  for (const n of model) {
    const { shortId, cutoffDate } = n;
    const formattedCutoff = formatDateTime(cutoffDate);

    const handleUpdateBtn = () => {
      delegate.onInstanceUpdateNotificationUpdate(n);
    };

    const handleIgnoreBtn = () => {
      delegate.onInstanceUpdateNotificationIgnore(n);
    };

    const btn = (
      <div>
        <Button onClick={handleIgnoreBtn}>Ignore</Button>
        <Button type="primary" style={{ marginLeft: '20px' }} onClick={handleUpdateBtn}>
          Update
        </Button>
      </div>
    );
    showNotification({
      type: 'open',
      message: `Your instance ${shortId} needs to be updated before ${formattedCutoff}.`,
      description: `If you do not update before ${formattedCutoff}, it will be updated automatically on ${formattedCutoff}.`,
      key: notificationKey(n),
      config: {
        placement: 'topRight',
        bottom: 50,
      },
      duration: 300,
      btn,
      onClose: () => delegate.onInstanceUpdateNotificationCancel(n),
    });
  }
}

export function hideInstanceUpdateNotifications({ model }: { model: InstanceUpdateNotification }) {
  notification.destroy(notificationKey(model));
}
