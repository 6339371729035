import React from 'react';
import { MenuProps } from 'antd';
import { HistoryOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { DOCUMENTATION_KEY, DOCUMENTATION_KEY_PREFIX } from '../../../side-nav/SideNav';

import { DocumentationModel } from '../models/documentation-list';

function getDocumentationNavItems(model: DocumentationModel) {
  return model.documentation.length > 0
    ? model.documentation.map((document) => ({
        label: <span title={document.label}>{document.label}</span>,
        icon: document.id === 'release-history' ? <HistoryOutlined /> : <QuestionCircleOutlined />,
        key: `${DOCUMENTATION_KEY_PREFIX}${document.id}`,
      }))
    : [
        {
          label: model.loading ? 'Loading…' : 'No available documentation',
          key: `${DOCUMENTATION_KEY_PREFIX}empty`,
          disabled: true,
        },
      ];
}

export function getDocumentationNav({ model }: { model: DocumentationModel }): Required<MenuProps>['items'][number] {
  return {
    label: 'Documentation',
    key: DOCUMENTATION_KEY,
    icon: <QuestionCircleOutlined />,
    children: getDocumentationNavItems(model),
  };
}
