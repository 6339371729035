import { v4 } from 'uuid';

import { BookQuery, DailyQuery, IntervalQuery, OrderBook, TicksQuery, DipUploadedDataQuery, ProductInfo } from './query-types';
import BookQueryForm from './forms/BookQueryForm';
import BookQueryOutput from './outputs/BookQueryOutput';
import DailyForm from './forms/DailyForm';
import DailyOutput from './outputs/DailyOutput';
import DipDataForm from './forms/DipDataForm';
import DipDataOutput from './outputs/DipDataOutput';
import IntervalForm from './forms/IntervalForm';
import IntervalOutput from './outputs/IntervalOutput';
import OrderBookForm from './forms/OrderBookForm';
import OrderBookOutput from './outputs/OrderBookOutput';
import TicksForm from './forms/TicksForm';
import TicksOutput from './outputs/TicksOutput';
import { DataQueryFormItem, DataQueryForm } from '../../types';
import Nothing from './outputs/Nothing';
import ProductInfoForm from './forms/ProductInfoForm';
import dayjs from 'dayjs';

const joinWithAmpersand = (values?: string[]) => {
  if (!values || values.length === 0) {
    return '';
  }
  if (values.length === 1) {
    return values[0];
  }

  const v = [...values];
  const end = v.pop();

  if (values.length <= 3) {
    return `${v.join(', ')} & ${end}`;
  }

  // take only first two and a last product, add ellipsis when a lot of products selected.
  return `${v.slice(0, 2).join(', ')},... & ${end}`;
};

export function getProductsAsText(item: DataQueryFormItem) {
  return joinWithAmpersand(item.config?.products) || '(No Products)';
}

export const extraConfigOptions = {
  [ProductInfo]: {
    sources: 'Direct',
  },
  [TicksQuery]: {
    messages: ['ALL'],
  },
  [BookQuery]: {
    intervalAndUnit: {
      value: 0,
      timespan: '',
    },
  },
  [OrderBook]: {
    numberOfLevels: 20,
    date: dayjs(),
    time: undefined,
  },
};

const forms: DataQueryForm[] = [
  {
    key: ProductInfo,
    name: 'Product or Index Search',
    description: 'Retrieves information on a product or an index.',
    control: ProductInfoForm,
    outputControl: Nothing,
    createHeader: (item) => `PRD: ${getProductsAsText(item)}`,
    createForm: (item) => ({
      ...item,
      config: {
        ...item.config,
        ...extraConfigOptions[ProductInfo],
      },
    }),
  },
  {
    key: DailyQuery,
    name: 'Daily Data Search',
    description: 'Retrieves daily data for a product.',
    control: DailyForm,
    outputControl: DailyOutput,
    createHeader: (item: DataQueryFormItem) => `DLY: ${getProductsAsText(item)}`,
    createForm: (item: DataQueryFormItem) => item,
  },
  {
    key: TicksQuery,
    name: 'Tick Data Search',
    description: 'Retrieves tick data by day or date range for a product.',
    control: TicksForm,
    outputControl: TicksOutput,
    createHeader: (item: DataQueryFormItem) => `TCK: ${getProductsAsText(item)}`,
    createForm: (item: DataQueryFormItem) => ({
      ...item,
      config: {
        ...item.config,
        ...extraConfigOptions[TicksQuery],
      },
    }),
  },
  {
    key: BookQuery,
    name: 'Book Query Data Search',
    description: 'Retrieves Book Query data by day or date range for a product.',
    control: BookQueryForm,
    outputControl: BookQueryOutput,
    createHeader: (item: DataQueryFormItem) => `Book Query: ${getProductsAsText(item)}`,
    createForm: (item: DataQueryFormItem) => ({
      ...item,
      config: {
        ...item.config,
        ...extraConfigOptions[BookQuery],
      },
    }),
  },
  {
    key: DipUploadedDataQuery,
    name: 'Workflow Results Viewer',
    description: 'Retrieves data that has been generated by the Workflows inside MIDAS..',
    control: DipDataForm,
    outputControl: DipDataOutput,
    createHeader: () => `WF: Workflow Results`,
  },
  {
    key: OrderBook,
    name: 'Order Book Visualizer',
    description: 'Retrieves Order Book data.',
    control: OrderBookForm,
    outputControl: OrderBookOutput,
    createHeader: (item: DataQueryFormItem) => `Order Book: ${getProductsAsText(item)}`,
    createForm: (item: DataQueryFormItem) => ({
      ...item,
      config: {
        ...item.config,
        ...extraConfigOptions[OrderBook],
      },
    }),
  },
  {
    key: IntervalQuery,
    name: 'Interval Data Search',
    description: 'Retrieves interval data by day or date range for a product.',
    control: IntervalForm,
    outputControl: IntervalOutput,
    createHeader: (item: DataQueryFormItem) => `INT: ${getProductsAsText(item)}`,
    createForm: (item: DataQueryFormItem) => item,
  },
];

export function getForm(soughtKey?: string) {
  if (!soughtKey) {
    return undefined;
  }

  return forms.find(({ key }) => soughtKey === key);
}

/**
 * We don't have the data on the same day, start and end date should be before today.
 * If the `startDate` is a weekend, make it a Friday, otherwise we won't have any 'Products' to select.
 * `endDate` doesn't matter as much, it won't give us many problems if it's a weekend.
 */
function createDataRangeField() {
  let startDate = dayjs().subtract(2, 'days');
  const endDate = dayjs().subtract(1, 'days');

  if (startDate.day() === 0) {
    startDate = startDate.subtract(2, 'days');
  }
  if (startDate.day() === 6) {
    startDate = startDate.subtract(1, 'days');
  }

  return [startDate, endDate];
}

export const BASE_TAB_CONFIG: DataQueryFormItem['config'] = {
  sources: undefined,
  feed: undefined,
  dateRange: createDataRangeField(),
  dateAndTimeRange: { date: dayjs() },
  postProcess: [],
  interval: 60,
  products: [],
};

export const createExtendedConfig = (progenitor: DataQueryFormItem) => {
  const newForm: DataQueryFormItem = {
    uuid: v4(),
    name: 'Untitled Item',
    ...progenitor,
    config: {
      ...BASE_TAB_CONFIG,
      ...progenitor.config,
    },
    meta: {
      accordionActiveKeys: [],
      ...progenitor.meta,
    },
    searchDone: false,
  };

  const createForm = getForm(progenitor?.config?.queryType)?.createForm;
  if (!createForm) {
    return newForm;
  }

  return createForm(newForm);
};

export const createHeader = (item: DataQueryFormItem) => {
  const form = getForm(item?.config?.queryType);
  if (!form) {
    console.error('[forms] failed to find form...', item?.config);
  }

  if (form) {
    return {
      ...item,
      name: form.createHeader(item),
    };
  }

  return {
    ...item,
    name: [
      joinWithAmpersand(item.config?.products),
      `${item.config?.dateRange?.[0].format('YYYYMMDD')} - ${item.config?.dateRange?.[1].format('YYYYMMDD')}`,
    ].join(' / '),
  };
};

export default forms;
