/* eslint-disable @typescript-eslint/unbound-method */
import { nextTick } from 'process';

import type * as vscode from 'vscode';

import { WebViewIpcBase } from './web-view-ipc-base';

export class WebViewIpcServerVsCode extends WebViewIpcBase {
  private dispose!: vscode.Disposable;

  constructor(protected webView: vscode.Webview, protected target?: any, protected sessionId: string = '') {
    super(target, sessionId);
  }

  override fire(callback: () => void) {
    nextTick(callback);
  }

  override startListening(): void {
    this.dispose = this.webView.onDidReceiveMessage(this.handleMessage);
  }

  override stopListening(): void {
    this.dispose?.dispose();
  }

  override postMessage(message: any): void {
    void this.webView.postMessage(message);
  }
}
