import { WbInstanceData, WbProvisionRequest } from '../workbench-api';
import { WbInstance } from './instance';
import { InstanceModificationState } from './instance-list';
import { MachineSize } from '../../../types';

export interface InstanceSize {
  state: InstanceModificationState;
  cpu: number;
  memGb: string;
  memGbError: string;
  memGbStep: number;
  diskSize: string;
  diskSizeError: string;
  memGbMax: number;
  memGbMin: number;
}

const MIN_WB_DISK_SIZE = 40;
const MAX_WB_DISK_SIZE = 200;

function gbToMemValue(gb: number) {
  return gb * 1024;
}

export function memValueToGb(value: number) {
  return value / 1024;
}

export function cpuToName(value: number) {
  return getMachine(value)?.name ?? '';
}

const getMachine = (value: number): MachineSize | undefined => {
  return window.CONFIG.validWbMachineSizes.find((m) => m.value === value);
};

export function initialInstanceSize(
  instance: WbInstanceData | Pick<WbInstance, 'cpu' | 'mem' | 'diskSize'> | undefined,
  state: 'loading' | 'ready'
): InstanceSize {
  const machine = getMachine(instance?.cpu ?? 2048);
  return {
    state,
    cpu: instance?.cpu ?? 2048,
    memGb: memValueToGb(instance?.mem ?? 4096).toString(),
    memGbError: '',
    memGbMax: machine?.maxMemGb || 30,
    memGbMin: machine?.minMemGb || 1,
    memGbStep: memValueToGb(machine?.multiplier || 1024),
    diskSize: instance?.diskSize.toString() ?? '100',
    diskSizeError: '',
  };
}

export function instanceSizeProvisionRequest(model: InstanceSize): Pick<WbProvisionRequest, 'mem' | 'cpu' | 'diskSize'> {
  return {
    mem: gbToMemValue(parseInt(model.memGb, 10)),
    cpu: model.cpu,
    diskSize: parseInt(model.diskSize, 10),
  };
}

export function updateInstanceSizeCpu<T extends InstanceSize>(model: T, value: number): T {
  const cpu = getMachine(value);
  if (!cpu) {
    return model;
  }

  const gb = parseInt(model.memGb, 10);
  let memGb: number;
  if (Number.isNaN(gb)) {
    memGb = cpu.minMemGb;
  } else {
    memGb = Math.round(Math.min(Math.max(gb, cpu.minMemGb), cpu.maxMemGb));
  }

  return {
    ...model,
    cpu: value,
    memGb: memGb.toString(),
    memGbError: getMemErrorMsg(memGb, cpu),
    memGbStep: memValueToGb(cpu.multiplier),
    memGbMin: cpu.minMemGb,
    memGbMax: cpu.maxMemGb,
  };
}

function validateInt(value: string): string | undefined {
  if (!value.match(/^[1-9][0-9]*$/)) {
    return 'Invalid integer';
  }

  return undefined;
}

export function updateInstanceSizeMem<T extends InstanceSize>(model: T, value: string): T {
  const intError = validateInt(value);
  if (intError) {
    return {
      ...model,
      memGb: value,
      memGbError: intError,
    };
  }

  const gb = parseInt(value, 10);
  const cpu = getMachine(model.cpu);
  if (!cpu) {
    return model;
  }

  return {
    ...model,
    memGb: value,
    memGbError: getMemErrorMsg(gb, cpu),
  };
}

function getMemErrorMsg(gb: number, cpu: MachineSize) {
  if (gb < cpu.minMemGb || gb > cpu.maxMemGb) {
    return `Memory must be between ${cpu.minMemGb}GB and ${cpu.maxMemGb}GB for this number of vCPUs.`;
  }

  const total = gbToMemValue(gb);
  if (total % cpu.multiplier !== 0) {
    return `Memory must be in a multiple of ${cpu.multiplier / 1024}.`;
  }

  return '';
}

export function updateInstanceSizeDisk<T extends InstanceSize>(model: T, value: string): T {
  const intError = validateInt(value);
  if (intError) {
    return {
      ...model,
      diskSize: value,
      diskSizeError: intError,
    };
  }

  const cpu = getMachine(model.cpu);
  if (!cpu) {
    return model;
  }

  const gb = parseInt(value, 10);

  if (gb < MIN_WB_DISK_SIZE || gb > MAX_WB_DISK_SIZE) {
    return {
      ...model,
      diskSize: value,
      diskSizeError: `Disk size must be between ${MIN_WB_DISK_SIZE}GB and ${MAX_WB_DISK_SIZE}GB.`,
    };
  }

  return {
    ...model,
    diskSize: value,
    diskSizeError: '',
  };
}

export function isInstanceSizeValid(model: InstanceSize): boolean {
  return !model.diskSizeError && !model.memGbError;
}
