import { ServicesApi } from './services-api';
import { handleError } from '../../services/error-notification';
import {
  initialAppList,
  ServicesListModel,
  updateServices,
  stopService,
  stopServiceCancelled,
  deleteService,
  deleteServiceCancelled,
  deployService,
  initialConfirmServiceAction,
  uninstallService,
  uninstallServiceCancelled,
  initialConfirmShareServiceAction,
  shareServiceCancelled,
  shareService,
} from './models/services-list';
import { PeriodicRequest } from '../../services/periodic-request';
import { ServiceDetailsDelegate } from './views/ServiceDetails';
import { WbService, serviceFromData } from './models/service';
import { LONG_POLLING_INTERVAL } from '../../constants';
import { User, UsersApi } from '../users/users-api';
import { collapseUsers } from '../users/utils';

export class ServicesController implements ServiceDetailsDelegate {
  private api = new ServicesApi();

  private usersApi = new UsersApi();

  private model = initialAppList();

  private servicesPeriodicRequest: PeriodicRequest<WbService[] | undefined>;

  private disposeCallbacks: (() => void)[] = [];

  constructor(private updateViewState: (_: ServicesListModel) => void) {
    this.servicesPeriodicRequest = new PeriodicRequest({
      interval: LONG_POLLING_INTERVAL,

      onPeriodicRequest: async () => (await this.api.getUserServices())?.map((data) => serviceFromData(this.model.services, data)) ?? [],

      onPeriodicRequestResult: (value: WbService[] | undefined) => {
        if (value !== undefined) {
          this.update(updateServices(this.model, value));
        }
      },
    });
  }

  start() {
    if (this.disposeCallbacks.length === 0) {
      this.disposeCallbacks.push(this.servicesPeriodicRequest.start());
    }
  }

  private getModel = (): ServicesListModel => this.model;

  private update = (model: ServicesListModel) => {
    this.model = model;
    this.updateViewState(model);
  };

  dispose() {
    for (const cb of this.disposeCallbacks) {
      cb();
    }
    this.disposeCallbacks = [];
  }

  async onDeployService(service: WbService): Promise<void> {
    await deployService(this.api, this.model, service.id);
  }

  onStopService(app: WbService): void {
    this.update({ ...this.model, confirmStopService: initialConfirmServiceAction(app) });
  }

  async onStopServiceSelected(stop: boolean): Promise<void> {
    if (stop) {
      handleError(await stopService(this.api, this.getModel, this.update, this.servicesPeriodicRequest.refresh));
    } else {
      this.update(stopServiceCancelled(this.model));
    }
  }

  async onShareService(service: WbService): Promise<void> {
    let users: User[] = [];
    if (!service.shortId) {
      users = collapseUsers((await this.usersApi.getUsers())?.allUserGroups ?? []);
    } else {
      const usersRes = (await this.api.getAuthorizedUsers(service.shortId)) || [];
      users = usersRes.map((u) => ({ name: u.name, value: u.id, email: u.email, title: `${u.name} (${u.email})`, selectable: true }));
    }
    this.update({ ...this.model, confirmShareService: initialConfirmShareServiceAction(service, users) });
  }

  async onShareServiceSelected(share: boolean): Promise<void> {
    if (share) {
      handleError(await shareService(this.api, this.getModel, this.update, this.servicesPeriodicRequest.refresh));
    } else {
      this.update(shareServiceCancelled(this.model));
    }
  }

  onShareServiceChangePermissionType(type: 'just-me' | 'organization' | 'users'): void {
    if (!this.model.confirmShareService) {
      return;
    }
    this.update({ ...this.model, confirmShareService: { ...this.model.confirmShareService, type: type } });
  }

  onShareServiceSelectUsers(users: string[]) {
    if (!this.model.confirmShareService) {
      return;
    }
    this.update({ ...this.model, confirmShareService: { ...this.model.confirmShareService, selectedUsersIds: users } });
  }

  onDeleteService(service: WbService): void {
    this.update({ ...this.model, confirmRemoveService: initialConfirmServiceAction(service) });
  }

  onUninstallService(service: WbService): void {
    this.update({ ...this.model, confirmUninstallService: initialConfirmServiceAction(service) });
  }

  async onDeleteServiceSelected(remove: boolean, navigate: (target: string) => void): Promise<void> {
    if (remove) {
      const error = await deleteService(this.api, this.getModel, this.update, this.servicesPeriodicRequest.refresh);
      if (error) {
        handleError(error);
      } else {
        navigate('/');
      }
    } else {
      this.update(deleteServiceCancelled(this.model));
    }
  }

  async onUninstallServiceSelected(uninstall: boolean, navigate: (target: string) => void): Promise<void> {
    if (uninstall) {
      const error = await uninstallService(this.api, this.getModel, this.update, this.servicesPeriodicRequest.refresh);
      if (error) {
        handleError(error);
        this.update(uninstallServiceCancelled(this.model));
      } else {
        navigate('/');
      }
    } else {
      this.update(uninstallServiceCancelled(this.model));
    }
  }
}
