import { useEffect } from 'react';
import { NotificationItem } from '../models/notification';
import { handleReferencedEntityItem } from '../utility/notification-related-item-dispatcher';
import { NotificationController } from '../notifications-controller';
import { useNavigate } from 'react-router-dom';

export function NotificationRelatedItemDispatcher({
  notificationsDelegate,
}: {
  notificationsDelegate: NotificationController | undefined;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/require-await
    const callback = async (destination: NotificationItem): Promise<void> => {
      handleReferencedEntityItem(destination, { navigate });
    };

    if (!notificationsDelegate) {
      return;
    }

    notificationsDelegate?.onNotificationInvoked(callback);

    return () => {
      notificationsDelegate?.onNotificationInvoked(undefined);
    };
  }, [navigate, notificationsDelegate]);

  return null;
}
