import pick from 'lodash/pick';
import { DataQueryProductRow, DataQueryGraphDataPoint } from '../../../../types';

const DEFAULT_MAX_DATA_POINTS = 100;

export const MAX_GRAPH_RESULTS = 5000;

export const sampleDataPoints = (
  data: DataQueryProductRow[],
  dataPoints: string[] = [],
  maxDataPoints = DEFAULT_MAX_DATA_POINTS
): DataQueryGraphDataPoint[] =>
  data.reduce((collection: DataQueryGraphDataPoint[], { lineCount, product, output, error }) => {
    const productData: DataQueryGraphDataPoint = { product, error, rows: [] };

    // TODO consider replacing lineCount with output.length
    if (!lineCount) {
      return [...collection, productData];
    }

    const step = lineCount > maxDataPoints ? Math.floor(lineCount / maxDataPoints) : 1;
    const limit = Math.min(lineCount, maxDataPoints);
    const remainder = lineCount > maxDataPoints ? lineCount % maxDataPoints : 0;

    let remainderSum = 0;
    let row = 0;
    let extraStep = 0;
    let loops = 0;

    while (loops < limit) {
      extraStep = 0;
      const normalizeLine = dataPoints.length ? pick(output[row], [...dataPoints, 'dateTicks', 'datetime', 'date']) : output[row];
      productData.rows.push(normalizeLine);

      remainderSum += remainder;

      if (remainderSum > maxDataPoints) {
        extraStep = 1;
        remainderSum -= maxDataPoints;
      }

      row = row + step + extraStep;
      loops++;
    }

    return [...collection, productData];
  }, []);
