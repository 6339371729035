import { CopyOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Typography, Divider, Alert, InputNumber, Input } from 'antd';
import React, { useCallback } from 'react';
import { GenerateApiKeyModel } from '../models/generate-api-key';
import styles from './GenerateApiKeyDialog.module.css';

const { Text, Title } = Typography;
const { TextArea } = Input;

export interface GenerateApiKeyDelegate {
  onCloseGenerateApiKey(): void;
  onGenerateApiKey(): Promise<void>;
  onChangeExpirationDays(expirationDays: number): void;
  onCopyGeneratedKey(): void;
  onStartGenerateApiKey(): void;
}

export function GenerateApiKeyDialog({ model, delegate }: { model: GenerateApiKeyModel; delegate: GenerateApiKeyDelegate }) {
  const handleCancel = useCallback(() => delegate.onCloseGenerateApiKey(), [delegate]);
  const handleGenerateApiKey = async () => {
    await delegate.onGenerateApiKey();
  };
  const handleCopyKey = async () => {
    await navigator.clipboard.writeText(model.apiKey);
    delegate.onCopyGeneratedKey();
  };

  const handleChange = useCallback(
    (value: number | null) => {
      if (!value) {
        return;
      }

      delegate.onChangeExpirationDays(value || 0);
    },
    [delegate]
  );

  return (
    <Modal
      title="Generate API Key"
      maskClosable={false}
      open
      onCancel={handleCancel}
      destroyOnClose
      footer={[
        <Button key="close" onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >
      <Space direction="vertical" className="w-full">
        <div>
          <div>Expiration time (days):</div>
          <InputNumber
            max={window.CONFIG.isMidasPeer ? Number.MAX_SAFE_INTEGER : 90}
            maxLength={window.CONFIG.isMidasPeer ? 5 : 2}
            value={model.expirationDays}
            className="w-full"
            onChange={handleChange}
          />
          <div>
            <Button disabled={!model.expirationDays} title="Generate API Key" style={{ marginTop: '10px' }} onClick={handleGenerateApiKey}>
              Generate
            </Button>
          </div>
        </div>

        {model.apiKey && (
          <Space direction="vertical" className={styles.animatedKeyContainer}>
            <Divider />
            <Title className={styles.animatedKeyContainerTitle} level={2}>
              Generated API Key
            </Title>
            <TextArea
              placeholder="Generate a new API key"
              maxLength={200}
              allowClear
              value={model.apiKey}
              disabled
              autoSize={{ minRows: 1, maxRows: 6 }}
            />
            <Button icon={<CopyOutlined />} onClick={handleCopyKey}>
              Copy to clipboard
            </Button>
            {model.state === 'copied' && <Alert message="Text copied" type="success" />}
            <Text>Please make sure to copy your key before closing the dialog.</Text>
          </Space>
        )}
        {model.error && <Alert message={model.error} type="error" />}
      </Space>
    </Modal>
  );
}
