import axios from 'axios';

import { FeDpFilesRow, FeDpTablesRow } from '@mst-fe/shared/types/midas';
import { throwAxiosError } from '@mst-fe/shared/dist/errors/axios-errors';

import { axiosOptions } from '../services/requests';

import { ROOT_SERVER_URL } from '../midas/utils/midas-constants';

export type S3FileUploaderUploadedFormData = {
  separator?: string;
  noHeaders?: boolean;
  file?: {
    name?: string;
    type?: string;
    size?: number;
  };
};

export type S3FileUploaderFormData = {
  uuid: string;
  url: string;
  fields: { [key: string]: string };
  error: unknown;
};

type DpFxResponse = {
  ok: boolean;
  error?: {
    message: string;
    code?: string;
  };
  statusCode: number;
  data?: {
    uuid?: string;
    formData?: S3FileUploaderFormData;
    fileColumnsData?: unknown;
    existingRow?: FeDpFilesRow;
    preSignedUrl?: string;
  };
};

export const dpFxApiPath = '/api/dp-fx';

export class DpFileExchangeApi {
  buildGetTablesUrl(params: { workflowId?: string | string[]; includePublicData?: boolean }) {
    const target = new URL(`${dpFxApiPath}/tables/${params.workflowId ? params.workflowId.toString() : ''}`, ROOT_SERVER_URL);
    if (params.includePublicData) {
      target.searchParams.set('includePublicData', Boolean(params.includePublicData).toString());
    }

    return target;
  }

  async getTables(params: { workflowId?: string | string[]; includePublicData?: boolean }) {
    const target = this.buildGetTablesUrl(params);

    return await throwAxiosError(axios.get<FeDpTablesRow[]>(target.toString(), await axiosOptions()));
  }

  buildGetFilesUrl(params: { workflowId?: string | string[]; showAll?: boolean; showDeleted?: boolean }) {
    const target = new URL(`${dpFxApiPath}/files`, ROOT_SERVER_URL);
    if (params.showAll) {
      target.searchParams.set('showAll', Boolean(params.showAll).toString());
    }
    if (params.showDeleted) {
      target.searchParams.set('showDeleted', Boolean(params.showDeleted).toString());
    }
    if (params.workflowId) {
      target.searchParams.set('workflowId', params.workflowId.toString());
    }

    return target;
  }

  async getFiles(params: { workflowId?: string | string[]; showAll?: boolean; showDeleted?: boolean }) {
    const target = this.buildGetFilesUrl(params);

    return await throwAxiosError(axios.get<FeDpFilesRow[]>(target.toString(), await axiosOptions()));
  }

  async deleteTable(tableId: string) {
    const target = new URL(`${dpFxApiPath}/tables/${tableId}`, ROOT_SERVER_URL);

    return await throwAxiosError(axios.delete(target.toString(), await axiosOptions()));
  }

  async deleteFiles(params: { uploadId: string; method?: 'hard' | 'soft' }) {
    const target = new URL(`${dpFxApiPath}/files/${params.uploadId}`, ROOT_SERVER_URL);
    if (params.method) {
      target.searchParams.set('method', params.method);
    }

    return await throwAxiosError(axios.delete<DpFxResponse>(target.toString(), await axiosOptions()));
  }

  async createUpload(params: { uploadId?: string; redirectUrl: string }) {
    const target = new URL(`${dpFxApiPath}/files/${params.uploadId}`, ROOT_SERVER_URL);

    return await throwAxiosError(axios.post<DpFxResponse>(target.toString(), { redirectUrl: params.redirectUrl }, await axiosOptions()));
  }

  async startUpload(params: { uploadId: string; formData: S3FileUploaderUploadedFormData }) {
    const target = new URL(`${dpFxApiPath}/files/${params.uploadId}/start`, ROOT_SERVER_URL);

    return await throwAxiosError(axios.post<DpFxResponse>(target.toString(), { formData: params.formData }, await axiosOptions()));
  }

  async notifySuccessfulUpload(params: { uploadId: string; bucket: string; key: string }) {
    const target = new URL(`${dpFxApiPath}/files/${params.uploadId}/notifySuccess`, ROOT_SERVER_URL);

    return await throwAxiosError(
      axios.post<DpFxResponse>(target.toString(), { bucket: params.bucket, key: params.key }, await axiosOptions())
    );
  }

  async parseUpload(params: {
    uploadId: string;
    bucket: string;
    key: string;
    associatedWorkflowId: string;
    formData: S3FileUploaderUploadedFormData;
  }) {
    const target = new URL(`${dpFxApiPath}/files/${params.uploadId}/parse`, ROOT_SERVER_URL);

    return await throwAxiosError(
      axios.post<DpFxResponse>(
        target.toString(),
        { bucket: params.bucket, key: params.key, associatedWorkflowId: params.associatedWorkflowId, formData: params.formData },
        await axiosOptions()
      )
    );
  }

  async getDownloadPresignedUrl(params: { uploadId: string }) {
    const target = new URL(`${dpFxApiPath}/files/${params.uploadId}/download`, ROOT_SERVER_URL);

    const res = await throwAxiosError(axios.get<DpFxResponse>(target.toString(), await axiosOptions()));
    return res;
  }
}
