/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useContext } from 'react';

import isFunction from 'lodash/isFunction';
import startCase from 'lodash/startCase';

import { v4 } from 'uuid';

import useMappingStore from '../hooks/use-mapping-store';

import { HistoryContext } from './history-context';
import UiModules from '../ui-modules';

import { SettingsConfigContext } from './settings-config-context';

import { fixDates } from '../utils/config-utils';
import { DataQueryFormItem } from '../pages/types';
import routes from '../../utils/routes';

export default function SettingsContextProviderWrapper({ children }: { children: JSX.Element }) {
  const { go } = useContext(HistoryContext);
  const [appConfig, setAppConfig] = useMappingStore(
    'mst.fbd.02.state',
    {
      tabs: [],
      searchConfigs: [],
      previousSearches: [],
      workflows: [],
    },
    fixDates
  );

  const updateAppConfig = useCallback(
    (config: DataQueryFormItem | Function) => {
      void setAppConfig((u: DataQueryFormItem) => ({
        ...u,
        ...(isFunction(config) ? config(u) : config),
      }));
    },
    [setAppConfig]
  );

  const updateTabName = useCallback(
    (uuid: string, name: string) => {
      void setAppConfig((u: DataQueryFormItem) => ({
        ...u,
        tabs: u.tabs?.map((tab) => ({ ...tab, name: tab.uuid === uuid ? name : tab.name })),
      }));
    },
    [setAppConfig]
  );

  const getTabById = useCallback(
    (id: string) => {
      return !id ? undefined : appConfig.tabs?.find(({ uuid }) => uuid === id);
    },
    [appConfig]
  );

  const updateTabById = useCallback(
    (uuid: string | undefined, newConfig: DataQueryFormItem | ((data: DataQueryFormItem) => DataQueryFormItem)) => {
      if (!uuid) {
        console.warn('[updateTabById] no UUID is provided!');
        return;
      }

      let seen = false;

      updateAppConfig((u: DataQueryFormItem) => {
        const tabs = u.tabs?.map((tab) => {
          if (tab.uuid !== uuid) {
            return tab;
          }
          seen = true;
          return isFunction(newConfig) ? newConfig(tab) : newConfig;
        });

        return {
          ...u,
          tabs,
        };
      });

      if (!seen) {
        console.warn('[updateTabById] did not find a tab with UUID: ', uuid);
      }
    },
    [updateAppConfig]
  );

  const createTab = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (routeType: string, initialConfig?: DataQueryFormItem) => {
      const rawItem = {
        type: routeType.substring(1),
        uuid: v4(),
        name: `New ${startCase(routeType)} Page`,
        ...initialConfig,
      };

      const handler = UiModules[routeType].createItem;
      const newItem = handler(rawItem, { updateAppConfig });
      if (!newItem) {
        return;
      }

      setTimeout(() => {
        const newRoute = `${routeType}/${newItem.uuid}`;
        go(newRoute);
      }, 1);
    },
    [updateAppConfig, go]
  );

  const createOrSelectTab = useCallback(
    (routeType: string) => {
      const { tabs } = appConfig;
      const existingTab = tabs?.find(({ type }) => type === routeType.substring(1));
      if (existingTab) {
        go(`${routeType}/${existingTab.uuid}`);
        return;
      }

      void createTab(routeType);
    },
    [go, createTab, appConfig]
  );

  const closeTab = useCallback(
    (routeToDelete: string) => {
      // expecting e.g. "/workflow/id" while using _shared/routes
      const [, routeType, selectedUuid] = routeToDelete.split('/');

      const handler = UiModules[`/${routeType}`].deleteItem;
      if (handler) {
        const result = handler(selectedUuid, { updateAppConfig, appConfig }, routeType);

        return result;
      }

      return false;
    },
    [appConfig, updateAppConfig]
  );

  const closeDataTab = useCallback(
    (dpTableName: string) => {
      const openDataQueryTab = appConfig.tabs?.find((t) => t.config?.dpTableName === dpTableName);
      if (openDataQueryTab?.uuid) {
        const result = closeTab(`${routes.Data}/${openDataQueryTab?.uuid}`);

        return result;
      }

      return false;
    },
    [closeTab]
  );

  return (
    <SettingsConfigContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        appConfig,
        updateAppConfig,
        updateTabName,
        getTabById,
        updateTabById,
        createTab,
        createOrSelectTab,
        closeTab,
        closeDataTab,
      }}
    >
      {children}
    </SettingsConfigContext.Provider>
  );
}
