import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';
import axios from 'axios';
import { axiosOptions, blobAxiosOptions } from '../../services/requests';

export type ResourceActionType = 'NOTIFY_WARN' | 'NOTIFY_CRITICAL' | 'SHUTDOWN';

export interface Units {
  compute: '$';
  egress: 'GB';
  storage: 'GB';
}

export interface LimitValue {
  default: number;
  additional: number;
}

export interface Limits {
  compute: LimitValue;
  egress: LimitValue;
  storage: LimitValue;
}

export interface Usage {
  compute: number;
  egress: number;
  storage: number;
}

export interface OverageUsage {
  compute: number;
  egress: number;
  storage: number;
  computeInPlan: number;
  egressInPlan: number;
  storageInPlan: number;
}

export interface ResourceUsage extends Usage {
  title: string;
  usageCosts: Usage | undefined;
}

export interface UserUsage extends Usage {
  userId: string;
  userEmail: string | undefined;
  limits: Limits | undefined;
  overageCosts: OverageUsage | undefined;
  usageCosts: Usage | undefined;
}

export interface OrganisationUsage {
  isAdmin: boolean;
  units: Units | undefined;
  limits: Limits | undefined;
  userUsage: UserUsage[] | undefined;
}

export interface UserInstancesUsage {
  units: Units;
  limits: Limits;
  userId: string;
  userEmail: string | undefined;
  resourceUsage: ResourceUsage[];
}

export class UsageApi {
  async getUsageLevel(): Promise<{ level: ResourceActionType | undefined } | undefined> {
    return await checkAxiosResponse(axios.get<{ level: ResourceActionType | undefined }>('/api/usage/usage-level', await axiosOptions()));
  }

  async acknowledge(): Promise<void> {
    await checkAxiosResponse(axios.post<unknown>('/api/usage/acknowledge', {}, await axiosOptions()));
  }

  async getOrganisationUsage(): Promise<OrganisationUsage | undefined> {
    return await checkAxiosResponse(axios.get<OrganisationUsage | undefined>('/api/usage/summary', await axiosOptions()));
  }

  async getOrganisationAggregatedUsageCSVReport(): Promise<Blob | undefined> {
    return await checkAxiosResponse(
      axios.get<Blob | undefined>('/api/usage/summary-csv-report', await blobAxiosOptions()),
      'Error while getting Organization Usage CSV Report'
    );
  }

  async getOrganisationDetailedUsageCSVReport(): Promise<Blob | undefined> {
    return await checkAxiosResponse(
      axios.get<Blob | undefined>('/api/usage/summary-csv-report', { ...(await blobAxiosOptions()), params: { detailed: true } }),
      'Error while getting Organization Usage CSV Report'
    );
  }

  async getUserUsageCSVReport(userId: string): Promise<Blob | undefined> {
    return await checkAxiosResponse(
      axios.get<Blob | undefined>(`/api/usage/summary-csv-report/${userId}`, await blobAxiosOptions()),
      'Error while getting User Usage CSV Report'
    );
  }

  async getUserInstanceUsage(userId: string): Promise<UserInstancesUsage | undefined> {
    return await checkAxiosResponse(axios.get<UserInstancesUsage | undefined>(`/api/usage/summary/${userId}`, await axiosOptions()));
  }
}
